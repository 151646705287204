import React from 'react';

import './Card.css';
const Card = ({ backgroundImage, className, type, children }) => {
  return (
    <div className={type == 'fuhrpark' ? 'card cardFuhrpark' : 'card'}>
      <div
        className={
          type == 'fuhrpark' ? 'card__leftSideFuhrpark' : 'card__leftSide'
        }
      >
        <div className={className}>{children}</div>
      </div>
      <div
        className={
          type == 'fuhrpark'
            ? 'card__rightSide card__rightSideFuhrpark'
            : 'card__rightSide card__rightSideDashboard'
        }
        style={{ backgroundImage: backgroundImage }}
      ></div>
    </div>
  );
};

export default Card;
