import React, { Component } from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import { css } from '@emotion/core';
import { ToastContainer, toast } from 'react-toastify';
import { withNamespaces } from 'react-i18next';
import BarLoader from 'react-spinners/BarLoader';
import { connect } from 'react-redux';

import { setToken } from '../../redux/actions';
import auth from '../../auth';
import config from '../../config/api.config.js';
import amazonIcon from '../../assets/images/amazon.png';
import googleIcon from '../../assets/images/google.png';
import userIcon from '../../assets/images/user.png';
import fbIcon from '../../assets/images/fb_2.png';
import secureIcon from '../../assets/images/secure.png';

import 'react-toastify/dist/ReactToastify.css';

const override = css`
  position: absolute;
  top: 0px;
  left: 10px;
  width: 95%;
`;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      emailValid: true,
      passwordValid: true,
      validationProcess: false,
      errorResponse: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.notifyUser = this.notifyUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;

    // if (this.state.password == "" || this.state.password.length < 8)
    if (this.state.password == '') {
      this.setState({ passwordValid: false });
      isValid = false;
    }

    if (this.state.email.length < 5) {
      this.setState({ emailValid: false });
      isValid = false;
    }

    let statusOfLogin = true;

    if (isValid) {
      await this.setState({ validationProcess: true });
      await axios
        .post(`${config.apiUrl}/token/`, {
          email: this.state.email.toLowerCase(),
          password: this.state.password
        })
        .then((res) => {
          this.setState({ jwtToken: `Bearer ${res.data.refresh}` });
          this.props.tokenReducer({ token: `Bearer ${res.data.refresh}` });
          if (res.data.type === 'carrier')
            auth.login(
              res.data.access,
              res.data.refresh,
              res.data.user,
              res.data.type,
              () => {
                this.props.history.push('/dashboard');
              }
            );
          else
            auth.login(
              res.data.access,
              res.data.refresh,
              res.data.user,
              res.data.type,
              () => {
                this.props.history.push('/shipments');
              }
            );
        })
        .catch((err) => {
          statusOfLogin = false;
          console.log('error', err);
          this.setState({ errorResponse: err.response.data.detail });
        });
      await this.setState({ validationProcess: false });
      if (!statusOfLogin) {
        this.notifyUser('login');
        this.setState({ password: '' });
        this.setState({ emailValid: false });
        this.setState({ passwordValid: false });
        setTimeout(() => {
          this.setState({
            emailValid: true,
            passwordValid: true
          });
        }, 500);
      }
    } else {
      setTimeout(() => {
        this.setState({
          emailValid: true,
          passwordValid: true
        });
      }, 500);
    }
  };

  notifyUser = (notificationFor) => {
    const Msg_Login = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ fontSize: '18px', marginRight: '5px' }}></span>&nbsp;
          {this.state.errorResponse == 'Email not confirmed'
            ? this.props.t('toast.confirmEmail')
            : this.props.t('toast.emailOrPassIncorrect')}
        </p>
      </div>
    );
    if (notificationFor === 'login')
      toast.error(<Msg_Login />, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
  };

  componentDidMount() {
    let url_string = window.location.href; //window.location.href
    let url = new URL(url_string);
    let code = url.searchParams.get('code');
    let type = url.searchParams.get('type');
    let provider = url.searchParams.get('provider');
    if (code !== null) {
      console.log('Ovo je type', type);

      if (type === 'natural_shipper') {
        this.setState({
          accountType: 'private',
          userType: 'sender'
        });
      }

      if (type === 'legal_shipper') {
        this.setState({
          accountType: 'business',
          userType: 'sender'
        });
      }

      if (type === 'carrier') {
        this.setState({
          accountType: 'business',
          userType: 'transporter'
        });
      }

      axios
        .get(
          `${config.apiUrl}/oauth/${provider}/login?type=${type}&code=${code}&intent=login`
        )
        .then((res) => {
          // console.log('AUTH MI JE VRATIO TOKEN', res.data.token.access);
          // this.setState({ jwtToken: `Bearer ${res.data.token.access}` });
          // localStorage.setItem("access_token", res.data.token.access);
          // localStorage.setItem("refresh_token", res.data.token.refresh);
          this.setState({ jwtToken: `Bearer ${res.data.refresh}` });
          this.props.tokenReducer({ token: `Bearer ${res.data.refresh}` });
          if (type === 'carrier')
            auth.login(
              res.data.token.access,
              res.data.token.refresh,
              res.data.id,
              res.data.type,
              () => {
                this.props.history.push('/dashboard');
              }
            );
          else
            auth.login(
              res.data.token.access,
              res.data.token.refresh,
              res.data.id,
              res.data.type,
              () => {
                this.props.history.push('/shipments');
              }
            );
        })
        .catch((err) => {
          console.log('SOCIAL CREATE GET ERROR', err);
        });
    }
  }

  render() {
    const { t, i18n } = this.props;

    if (i18n.language != 'de' && i18n.language != 'gb') {
      i18n.language = 'de';
    }

    return (
      <div className="loginPopUp__container">
        <BarLoader
          css={override}
          size={150}
          color={'#00acd5'}
          loading={this.state.validationProcess}
        />
        <ToastContainer
          position={toast.POSITION.TOP_RIGHT}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          enableMultiContainer
          containerId={'A'}
        />
        <div className="loginPopUp__container--buttons">
          <button
            className="loginPopUp__button"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `${config.apiUrl}/oauth/google?type=undefined&intent=login`,
                '_self'
              );
            }}
          >
            <span>{t('modal.singIn')}</span>
            <img src={googleIcon} />
          </button>
          {/* <img src={amazonIcon} /> */}
          <img
            onClick={() => {
              window.open(
                `${config.apiUrl}/oauth/facebook?type=undefined&intent=login`,
                '_self'
              );
            }}
            style={{ cursor: 'pointer' }}
            src={fbIcon}
          />
        </div>
        <div className="loginPopUp__or">
          <div className="loginPopUp__line"></div>
          <span>{t('modal.or')}</span>
          <div className="loginPopUp__line"></div>
        </div>
        <div className="loginPopUp__form">
          <form onSubmit={this.handleSubmit}>
            <div className="loginPopUp__holder">
              <input
                type="email"
                value={this.state.email}
                name="email"
                onChange={this.handleChange}
                placeholder={t('registration.email')}
                className={!this.state.emailValid && 'shakeIt'}
                spellCheck="false"
              />
              <img src={userIcon} />
            </div>
            <div className="loginPopUp__holder">
              <input
                type="password"
                value={this.state.password}
                name="password"
                onChange={this.handleChange}
                placeholder={t('registration.password')}
                className={!this.state.passwordValid && 'shakeIt'}
                spellCheck="false"
              />
              <img src={secureIcon} />
            </div>
            <div>
              <span className="loginPop__lostPassword">
                <Link to="/password-reset">{t('modal.password')}</Link>
              </span>
              <br />
              <button
                type="submit"
                onClick={this.handleSubmit}
                className="loginPopUp__form--submit"
              >
                {t('modal.anmelden')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tokenState: state.tokenReducer
});

const mapDispatchToProps = (dispatch) => ({
  tokenReducer: (data) => dispatch(setToken(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withRouter(Login)));
