import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import config from '../../config/api.config.js';

import Header from '../../Layout/Dashboard/DashboardHeader/DashboardHeader';
import Navbar from '../../Layout/Dashboard/DashboardNavbar/DashboardNavbar';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import ShipmentsCard from '../../components/ShipmentsCard/ShipmentsCard';
import ShipmentsMUAImport from '../../components/AddShipment/ShipmentsMUAImport/ShipmentsMUAImport';
import AddVehicleInformation from '../../components/AddShipment/AddVehicleInformation/AddVehicleInformation';
import AddDocument from '../../components/AddShipment/Documentation/Documentation';
import AddTransportInformation from '../../components/AddShipment/AddTransportInformation/AddTransportInformation';
import AddInformationFinished from '../../components/AddShipment/AddInformationFinished/AddInformationFinished';
import FormInput from '../../components/FormInput/FormInput';
import Notifications from '../../components/Notification/Notification';
import transportPicture from '../../assets/images/transportPicture.jpg';
import { useBeforeFirstRender } from '../../components/BeforeFirstRender/useBeforeFirstRender';
import PlacesAutocomplete from '../../components/GoogleMaps/PlacesAutocomplete';
import { ReactComponent as NothingFound } from '../../assets/images/nothingFound.svg';
import TransportCard from '../../components/TransportCard/TransportCard';
import TruckImage from '../../assets/images/_truck.png';

import auth from '../../auth';

import { ReactComponent as LeftArrow } from '../../assets/images/leftArrowSlider.svg';
import { ReactComponent as RightArrow } from '../../assets/images/rightArrowSlider.svg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { Animated } from 'react-animated-css';

import axios from 'axios';

import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';
import moment from 'moment';

import './Shipments.css';

const override = css`
  margin: 0 auto;
  width: 100px;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const Shipments = ({ t, ...props }) => {
  let history = useHistory();
  const element = useRef();
  const userID = auth.getId();
  let shipments = [];
  let shipItems = [];
  let [_slidesToShow, setSlidesToShow] = useState(1);
  const [shipment, setShip] = useState([]);
  const [pickupValid, setPickupValid] = useState(true);
  const [destionationValid, setDestinationValid] = useState(true);
  const [sliderLoaded, setSliderLoaded] = useState(false);

  const [vehicles, setVehicles] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleVehicleInformation, setVisibleVehicleInformation] = useState(
    false
  );
  const [
    visibleTransportInformation,
    setVisibleTransportInformation
  ] = useState(false);
  const [visibleInformationFinished, setVisibleInformationFinished] = useState(
    false
  );
  const [visibleDocumentation, setVisibleDocumentation] = useState(false);
  const [pickup, setPickup] = useState(null);
  const [destination, setDestination] = useState(null);
  const [pickupText, setPickupText] = useState('');
  const [destinationText, setDestinationText] = useState('');

  let transportCard = [];
  const [transportCards, setTransportCards] = useState([]);
  let [slidesTransport, setSlidesTransport] = useState(1);
  const [sliderLiveLoaded, setSliderLiveLoaded] = useState(false);
  let transportCardItems = [];

  const [notification, setNotification] = useState();
  const [notificationItems, setNotificationItems] = useState([]);
  const [userRead, setUserRead] = useState(true);
  const [show, setShow] = useState(false);

  const [mainData, setMainData] = useState({
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    selectedBrand: '',
    selectedModel: '',
    selectedVariaton: '',
    selectedYear: '',
    selectedState: '',
    imageArray: [],
    oldImages: []
  });

  const [shipmentID, setShipmentID] = useState();
  const [pastedURL, setPastedURL] = useState(null);
  const [ownershipId, setOwnershipId] = useState(null);

  let settingsShipments = {
    dots: false,
    arrows: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    infinite: true,
    slidesToShow: _slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,

    responsive: [
      {
        breakpoint: 1056,
        settings: {
          arrows: false
        }
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false,
          arrows: false
        }
      }
    ]
  };
  let settingsTransport = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesTransport,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false,
          arrows: false
        }
      }
    ]
  };

  useBeforeFirstRender(() => {
    if (auth.getType() === 'carrier') {
      history.push('/dashboard');
    }
  });

  const getShipments = async () => {
    setSliderLoaded(true);

    await axios
      .get(`${config.apiUrl}/shipments/user/${auth.getId()}/`)
      .then((res) => {
        res.data.forEach((shipment) => {
          if (shipment.state == 'pending')
            shipments.push({
              shipmentID: shipment.id,
              backgroundImage:
                shipment.photos.length == 0
                  ? `url(${'https://picsum.photos/200'})`
                  : `url(${shipment.photos[0].file})`,
              transportType:
                shipment.vehicle.manufacturer +
                ' ' +
                shipment.vehicle.model +
                ' ' +
                shipment.vehicle.year,
              location: {
                from: {
                  city: shipment.pickup.city,
                  country: shipment.pickup.country
                },
                to: {
                  city: shipment.destination.city,
                  country: shipment.destination.country
                }
              }
            });
        });

        if (shipments.length == 1) setSlidesToShow(1);
        else if (shipments.length == 2 || shipments.length == 3)
          setSlidesToShow(2);
        else setSlidesToShow(3);

        shipments.map((shipment) =>
          shipItems.push(
            <ShipmentsCard key={shipment.shipmentID} data={shipment} />
          )
        );
        setShip(shipItems);
        setSliderLoaded(false);
      })
      .catch((err) => {
        console.log(err);
        setSliderLoaded(false);
      });
  };

  const getNotifications = async () =>
    setNotificationItems(
      notification.map((item, index) => (
        <Notifications key={index} data={item} />
      ))
    );

  useEffect(() => {
    getShipments();
  }, []);

  useEffect(() => {
    if (notification) getNotifications();
  }, [notification]);

  useEffect(() => {
    if (pickupText == '') setPickup(null);
    if (destinationText == '') setDestination(null);
  }, [pickupText, destinationText]);

  useEffect(() => {
    setNotification(props.notificationsState.notifications);
  }, [props.notificationsState.notifications]);

  useEffect(() => {
    setShow(
      visible ||
        visibleVehicleInformation ||
        visibleTransportInformation ||
        visibleInformationFinished ||
        visibleDocumentation
    );
  }, [
    visible,
    visibleVehicleInformation,
    visibleTransportInformation,
    visibleInformationFinished,
    visibleDocumentation
  ]);
  const onClickOpen = () => {
    let isValid = true;

    if (pickup === null) {
      setPickupValid(false);
      isValid = false;
    }

    if (destination === null) {
      setDestinationValid(false);
      isValid = false;
    }

    if (isValid) setVisible(true);
    else {
      setTimeout(() => {
        setPickupValid(true);
        setDestinationValid(true);
      }, 500);
    }
  };
  const onClickClose = () => {
    setVisible(false);
  };
  const onClickOpenVehicleInformation = () => {
    setVisibleVehicleInformation(true);
  };
  const onClickCloseVehicleInformation = () => {
    setVisibleVehicleInformation(false);
  };
  const onClickOpenTransportInformation = () => {
    setVisibleTransportInformation(true);
  };
  const onClickCloseTransportInformation = () => {
    setVisibleTransportInformation(false);
  };
  const onClickOpenInformationFinished = () => {
    setVisibleInformationFinished(true);
  };
  const onClickCloseInformationFinished = () => {
    setVisibleInformationFinished(false);
  };

  const onClickOpenDocumentation = () => {
    setVisibleDocumentation(true);
  };
  const onClickCloseDocumentation = () => {
    setVisibleDocumentation(false);
  };
  return (
    <div
      className="shipments__content"
      ref={element}
      style={{
        overflowY: show ? 'hidden' : 'scroll'
      }}
    >
      <Helmet>
        <title>Autoversand | {t('shipments.shipments')}</title>
      </Helmet>
      <div className="shipmentsContent__left">
        <div className="shipmentsContent__header">
          <ContentHeader>{t('shipments.getTransport')}</ContentHeader>
          <p className="shipmentsContent__paragraph">{t('shipments.p')}</p>
        </div>
        <div
          className="shipmentsContent__getBid"
          style={{ position: 'absolute' }}
        >
          <div className={!pickupValid && 'shakeIt'}>
            <div className="inputHolder" style={{ top: '0', left: '0' }}>
              <PlacesAutocomplete
                placeholder={t('shipments.pickup')}
                id="vehicle_pickup"
                name="vehicle_pickup"
                _value={pickup != null ? pickup.formatted_address : null}
                _setValue={setPickup}
                setTextValue={setPickupText}
              />
            </div>
          </div>
          <div className={!destionationValid && 'shakeIt'}>
            <div className="inputHolder" style={{ top: '0', left: '21.75rem' }}>
              <PlacesAutocomplete
                placeholder={t('shipments.destination')}
                id="vehicle_destination"
                name="vehicle_destination"
                _value={
                  destination != null ? destination.formatted_address : null
                }
                _setValue={setDestination}
                setTextValue={setDestinationText}
              />
            </div>
          </div>

          <button className="getBid__button" onClick={onClickOpen}>
            {t('shipments.getInquiries')}
          </button>
        </div>
        <div className="shipmentsContent__myShipments">
          <ContentHeader>{t('shipments.transportRequests')}</ContentHeader>
          {/* <p className="shipmentsContent__paragraph">
            Transports that are currently waiting for carrier offers.
          </p> */}
          <div className="shipmentsContent__slider">
            {sliderLoaded ? (
              <PuffLoader
                css={override}
                size={60}
                margin={2}
                color={'rgba(116, 38, 251, 0.4)'}
                loading={sliderLoaded}
              />
            ) : shipment.length > 0 ? (
              <Slider {...settingsShipments}>{shipment}</Slider>
            ) : (
              <div className="nothingFound">
                <NothingFound />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="shipmentsContent__right">
        <div className="shipmentsContent__notifications">
          <p className="notifications__paragraph">
            {t('shipments.notifications')}
          </p>
          {notificationItems}
        </div>
      </div>

      <div
        className={
          show ? 'shipments__content-blur' : 'shipments__content-blurDisplay'
        }
      ></div>
      <Animated
        className="addTruckAnimated"
        animationIn="fadeInRight"
        animationOut="fadeOutRight"
        animateOnMount={false}
        animationInDelay={500}
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={visible}
      >
        <ShipmentsMUAImport
          onClickClose={onClickClose}
          onClickOpen={onClickOpenVehicleInformation}
          setVehicleData={setVehicles}
          checkPastedURL={setPastedURL}
        />
      </Animated>

      <Animated
        className="addTruckAnimated"
        animationIn="fadeInRight"
        animationOut="fadeOutRight"
        animateOnMount={false}
        animationInDelay={500}
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={visibleVehicleInformation}
      >
        <AddVehicleInformation
          onClickClose={onClickCloseVehicleInformation}
          onClickOpen={onClickOpenDocumentation}
          openMUAImport={onClickOpen}
          setMainData={setMainData}
          vehicleDataImport={vehicles}
          pastedURL={pastedURL}
        />
      </Animated>
      <Animated
        className="addTruckAnimated"
        animationIn="fadeInRight"
        animationOut="fadeOutRight"
        animateOnMount={false}
        animationInDelay={500}
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={visibleDocumentation}
      >
        <AddDocument
          enableOnClickClose={onClickCloseDocumentation}
          enableOnClickOpen={onClickOpenTransportInformation}
          toggleVehicleInforamtion={onClickOpenVehicleInformation}
          setOwnershipId={setOwnershipId}
        />
      </Animated>
      <Animated
        className="addTruckAnimated"
        animationIn="fadeInRight"
        animationOut="fadeOutRight"
        animateOnMount={false}
        animationInDelay={500}
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={visibleTransportInformation}
      >
        <AddTransportInformation
          onClickClose={onClickCloseTransportInformation}
          onClickOpen={onClickOpenInformationFinished}
          openVehicleInformation={onClickOpenDocumentation}
          pickupLocation={pickup}
          destinationLocation={destination}
          dataFromOtherComponents={mainData}
          setShipmentID={setShipmentID}
          ownershipId={ownershipId}
        />
      </Animated>
      <Animated
        className="addTruckAnimated"
        animationIn="fadeInRight"
        animationOut="fadeOutRight"
        animateOnMount={false}
        animationInDelay={500}
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={visibleInformationFinished}
      >
        <AddInformationFinished
          onClickClose={onClickCloseInformationFinished}
          shipmentID={shipmentID}
        />
      </Animated>
    </div>
  );
};

const mapStateToProps = (state) => ({
  notificationsState: state.notificationsReducer
});

export default connect(mapStateToProps, null)(withNamespaces()(Shipments));
