import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import PhoneInput from 'react-phone-input-2';
import validator from 'validator';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-awesome-modal';
import { withNamespaces } from 'react-i18next';

import FormInput from '../../../components/FormInput/FormInput';
import Button from '../../Buttons/BlueButton';
import Textarea from '../../Textarea/Textarea';
import PhoneVerificationModal from '../PhoneVerificationModal/PhoneVerificationModal';
import auth from '../../../auth';
import config from '../../../config/api.config.js';

import axios from 'axios';
import { FaCheckCircle } from 'react-icons/fa';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import './MainSettings.css';

const MainSettings = ({ t }) => {
  const PHONE_PREFIX = '+';
  const userId = auth.getId();
  const [cips, setCips] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const [values, setValues] = useState({
    email: '',
    profileImage: null,
    phoneNumber: '',
    description: '',
    emailVerified: true,
    phoneVerified: true
  });
  const [uploadedProfileImage, setUploadedProfileImage] = useState(null);
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);

  const handleChange = (e) => {
    const value = e.target.value;

    setValues({
      ...values,
      [e.target.name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateCips();
  };

  const handlePhoneVerification = () => {
    if (values.phoneNumber) {
      setModalVisible(true);
      return;
    }

    setPhoneValid(false);
    setTimeout(() => {
      setPhoneValid(true);
    }, 500);
  };

  const [profileImage, setProfileImage] = useState({ preview: '' });
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: 'image/*',
    noClick: true,
    onDrop: (acceptedFiles) => {
      const [file] = acceptedFiles;
      setProfileImage(
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
    }
  });

  const getCips = async () => {
    await axios
      .get(`${config.apiUrl}/users/cips/${userId}/`)
      .then((res) => {
        setCips(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCips = async () => {
    let isValid = true;

    if (!validator.isEmail(values.email)) {
      setEmailValid(false);
      isValid = false;
    }

    let { phoneNumber } = values;
    const sanitizedPhone = phoneNumber.trim();
    if (
      !isValidPhoneNumber(
        sanitizedPhone.startsWith(PHONE_PREFIX)
          ? sanitizedPhone
          : PHONE_PREFIX.concat(sanitizedPhone)
      )
    ) {
      setPhoneValid(false);
      isValid = false;
    }

    if (!isValid) {
      return setTimeout(() => {
        setEmailValid(true);
        setPhoneValid(true);
      }, 500);
    }

    const formData = new FormData();

    if (uploadedProfileImage !== values.profileImage)
      formData.append('picture', profileImage);

    formData.append('email', values.email.toLowerCase());
    formData.append('phone', values.phoneNumber.split(' ').join(''));
    formData.append('description', values.description ?? '');

    await axios
      .patch(`${config.apiUrl}/users/update/${userId}/`, formData)
      .then((res) => {
        notifyUser('settings');
        getCips();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setValues({
      ...values,
      profileImage: profileImage.preview
    });
  }, [profileImage]);

  useEffect(() => {
    getCips();
  }, []);

  useEffect(() => {
    if (cips) {
      setValues({
        email: cips.email,
        profileImage: `${config.assetUrl}${cips.photo}`,
        phoneNumber: cips.phone,
        description: cips.description,
        emailVerified: cips.email_verified,
        phoneVerified: cips.phone_verified
      });
      setUploadedProfileImage(`${config.assetUrl}${cips.photo}`);
    }
  }, [cips]);

  useEffect(() => {
    if (!values.phoneNumber) return;

    if (values.phoneNumber.length > 1) {
      document.getElementsByClassName(
        'phoneNumber__input'
      )[0].style.backgroundColor = 'white';
      document.getElementsByClassName('phoneNumber__input')[0].style.cssText =
        'border:none !important';
      document.querySelector(
        '.mainSettings__rightSide .flag-dropdown'
      ).style.cssText = 'border:none !important';
    } else {
      document.getElementsByClassName(
        'phoneNumber__input'
      )[0].style.backgroundColor = 'transparent';
      document.getElementsByClassName('phoneNumber__input')[0].style.cssText =
        'border:2px solid rgb(121, 119, 164) !important';
      document.querySelector(
        '.mainSettings__rightSide .flag-dropdown'
      ).style.cssText = 'border:2px solid rgb(121, 119, 164) !important';
    }
  }, [values.phoneNumber]);

  const notifyUser = (notificationFor) => {
    const Msg_Settings = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          {t('toast.success')}
        </p>
      </div>
    );

    if (notificationFor == 'settings') {
      toast.success(<Msg_Settings />, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
    }
  };

  return (
    <div className="mainSettings">
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        enableMultiContainer
        containerId={'A'}
      />
      <div className="mainSettings__leftSide">
        <div
          className="leftSide__profileImage"
          style={{ backgroundImage: `url(${values.profileImage})` }}
        >
          {' '}
        </div>
        <div
          {...getRootProps({
            className: 'dropzone'
          })}
        >
          <input {...getInputProps()} />
          <button type="button" onClick={open}>
            {t('settings.picture')}
          </button>
        </div>
      </div>
      <div className="mainSettings__rightSide">
        <form>
          <div className={!emailValid && 'shakeIt'}>
            <div className="mainSettings__verified">
              <FormInput
                type="text"
                name="email"
                labelText={t('registration.email')}
                settings={true}
                value={values.email}
                required
                onChange={handleChange}
              />
              {values.emailVerified ? (
                <FaCheckCircle className="mainSettings_verifiedIcon" />
              ) : (
                <BsFillQuestionCircleFill
                  className="mainSettings_verifiedIcon"
                  style={{ fill: '#E6BD37' }}
                />
              )}
            </div>
          </div>
          <div className={!phoneValid && 'shakeIt'}>
            <div>
              <p className="phoneNumber__paragraph">{t('settings.phone')}</p>
              <div className="mainSettings__verified">
                <PhoneInput
                  name="phoneNumber"
                  value={values.phoneNumber}
                  enableSearch="true"
                  disableSearchIcon="true"
                  inputClass="phoneNumber__input"
                  country={'at'}
                  onChange={(phoneNumber) =>
                    setValues({ ...values, phoneNumber })
                  }
                />
                {values.phoneVerified ? (
                  <FaCheckCircle className="mainSettings_verifiedIcon" />
                ) : (
                  // <BsFillQuestionOctagonFill
                  //   className="mainSettings_verifiedIcon"
                  //   style={{ fill: '#E6BD37' }}
                  //   onClick={handlePhoneVerification}
                  // />
                  <p
                    className="phoneNumber__verify"
                    onClick={handlePhoneVerification}
                  >
                    VERIFY
                  </p>
                )}
              </div>
            </div>
          </div>
          <Textarea
            name="description"
            labelText={t('settings.description')}
            value={values.description}
            settings={true}
            required
            onChange={handleChange}
          />

          <Button handleOpen={handleSubmit} type="submit">
            {t('settings.save')}
          </Button>
        </form>
      </div>
      <PhoneVerificationModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        getCips={getCips}
      />
    </div>
  );
};

export default withNamespaces()(MainSettings);
