import React from 'react';

import { ReactComponent as Check } from '../../../assets/images/shipmentViewCheck.svg';
import auth from '../../../auth';

import Offers from '../Offers/OfferCard';
import './AcceptedOffers.css';

const AcceptedOffers = ({ data }) => {
  return (
    <div className="acceptedOffers">
      <div className="acceptedOffer__name">
        {' '}
        {data.verified ? (
          <Check className="acceptedOffer__name--check" />
        ) : (
          ' '
        )}{' '}
        {auth.isCarrier()
          ? data.shipper_company_name ??
            `${data.shipper_first_name} ${data.shipper_last_name.slice(0, 1)}.`
          : data.company}
      </div>
      <Offers data={data} />
    </div>
  );
};

export default AcceptedOffers;
