import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { withNamespaces } from 'react-i18next';
import config from '../../../config/api.config.js';

import FormInput from '../../../components/FormInput/FormInput';
import FileUpload from '../../Upload/FileUpload/FileUpload';
import Button from '../../Buttons/BlueButton';
import Textarea from '../../Textarea/Textarea';
import auth from '../../../auth';

import { FaCheckCircle } from 'react-icons/fa';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './CompanySettings.css';

const CompanySettings = ({ t }) => {
  const userId = auth.getId();
  const [cips, setCips] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [values, setValues] = useState({
    companyName: '',
    autorizedPerson: '',
    insuranceAmount: null,
    address: '',
    vatNumber: null,
    numOfVehicle: null,
    vat_verified: false
  });
  const [registrationDate, setRegistrationDate] = useState(null);
  const [files, setFiles] = useState([]);

  const [nameValid, setNameValid] = useState(true);
  const [insuranceValid, setInsuranceValid] = useState(true);
  const [vatValid, setVatValid] = useState(true);
  const [vehicleValid, setVehicleValid] = useState(true);

  const [documentUploaded, setDocumentUploaded] = useState(false);
  const handleChange = (e) => {
    const value = e.target.value;

    setValues({
      ...values,
      [e.target.name]: value
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    updateCips();
  };

  const getCips = async () => {
    await axios
      .get(`${config.apiUrl}/users/cips/${userId}/`)
      .then((res) => {
        setCips(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCips = async () => {
    let isValid = true;

    if (values.companyName === '' || values.companyName === null) {
      isValid = false;
      setNameValid(false);
    }

    if (isNaN(values.insuranceAmount)) {
      isValid = false;
      setInsuranceValid(false);
    }

    if (values.vatNumber === null) {
      isValid = false;
      setVatValid(false);
    }

    if (isNaN(values.numOfVehicle)) {
      isValid = false;
      setVehicleValid(false);
    }

    if (!isValid) {
      return setTimeout(() => {
        setNameValid(true);
        setInsuranceValid(true);
        setVatValid(true);
        setVehicleValid(true);
      }, 500);
    }

    const formData = new FormData();

    const [file = {}] = files;
    if (files.length && `${config.assetUrl}${cips.document}` !== file.path)
      formData.append('document', file);

    formData.append('name', values.companyName);
    formData.append('authorized_person', values.autorizedPerson);
    formData.append('insurance_amount', values.insuranceAmount ?? 0);
    formData.append('address', values.address ?? '');
    formData.append('vat_number', values.vatNumber ?? 0);
    formData.append('number_of_vehicles', values.numOfVehicle ?? 0);

    console.log([...formData]);
    await axios
      .patch(`${config.apiUrl}/users/update/${userId}/`, formData)
      .then((res) => {
        getCips();
        notifyUser('companySettings');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCips();
  }, []);

  useEffect(() => {
    if (!cips) {
      return;
    }

    if (cips.document)
      setFiles([
        ...files,
        {
          path: `${config.assetUrl}` + cips.document,
          name: cips.document
        }
      ]);

    setDocumentUploaded(true);

    setValues({
      ...values,
      companyName: cips.name,
      autorizedPerson: cips.authorized_person,
      insuranceAmount: cips.insurance_amount,
      address: cips.address,
      vatNumber: cips.vat_number,
      numOfVehicle: cips.number_of_vehicles,
      vat_verified: cips.vat_verified
    });
    setRegistrationDate(cips.registration_date);
  }, [cips]);

  useEffect(() => {
    let label = document.createElement('label');
    label.className = 'companySettings__labelClass';
    label.id = 'companySettings_dateLabel';
    label.innerText = t('settings.registrationDate');
    document
      .getElementsByClassName('react-datepicker__input-container')[0]
      .appendChild(label);

    if (registrationDate) {
      document
        .getElementById('companySettings_dateLabel')
        .setAttribute('class', 'companySettings__labelClass--exp');
      document
        .getElementById('companySettings__datePicker')
        .setAttribute('class', 'companySettings__borderStyle');
    } else {
      document
        .getElementById('companySettings_dateLabel')
        .setAttribute('class', 'companySettings__labelClass');
    }
  }, []);

  const notifyUser = (notificationFor) => {
    const Msg_Settings = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          {t('toast.success')}
        </p>
      </div>
    );

    if (notificationFor == 'companySettings') {
      toast.success(<Msg_Settings />, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'C'
      });
    }
  };

  return (
    <div className="companySettings">
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        enableMultiContainer
        containerId={'C'}
      />
      <form>
        <div className="companySettings__leftSide">
          <div className={!nameValid && 'shakeIt'}>
            <FormInput
              type="text"
              name="companyName"
              labelText={t('settings.companyName')}
              settings={true}
              value={values.companyName}
              required
              onChange={handleChange}
            />
          </div>
          <FormInput
            type="text"
            name="autorizedPerson"
            labelText={t('settings.autorizedPerson')}
            settings={true}
            value={values.autorizedPerson}
            required
            onChange={handleChange}
          />
          {!auth.isLegalShipper() ? (
            <div className={!insuranceValid && 'shakeIt'}>
              <FormInput
                type="text"
                name="insuranceAmount"
                labelText={t('settings.insurance')}
                settings={true}
                value={values.insuranceAmount}
                required
                onChange={handleChange}
              />
            </div>
          ) : null}
          {!auth.isLegalShipper() ? (
            documentUploaded ? (
              <FileUpload
                className="form__DocumentUpload"
                uploadedFiles={files}
                setFileArray={setFiles}
              >
                <p className="uploadPlaceholder">
                  {t('settings.dropDocuments')}
                </p>
              </FileUpload>
            ) : null
          ) : null}
        </div>
        <div className="companySettings_rightSide">
          <DatePicker
            selected={registrationDate ? new Date(registrationDate) : null}
            onChange={(date) => setRegistrationDate(date)}
            openToDate={new Date()}
            id="companySettings__datePicker"
            required
            className={registrationDate ? 'registrationDate__borderStyle' : ''}
          />
          <FormInput
            type="text"
            name="address"
            labelText={t('settings.companyAddress')}
            settings={true}
            value={values.address}
            required
            onChange={handleChange}
          />
          <div className={!vatValid && 'shakeIt'}>
            <div className="mainSettings__verified companySettings__verified">
              <FormInput
                type="text"
                name="vatNumber"
                labelText={t('settings.vat')}
                settings={true}
                value={values.vatNumber}
                required
                onChange={handleChange}
              />
              {values.vat_verified ? (
                <FaCheckCircle className="mainSettings_verifiedIcon" />
              ) : (
                <div style={{ position: 'relative' }}>
                  <BsFillQuestionCircleFill
                    className="mainSettings_verifiedIcon"
                    style={{ fill: '#E6BD37' }}
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                  />
                  <p
                    style={{ display: isShown ? 'block' : 'none' }}
                    class="popover__unverified"
                  >
                    Unverified
                  </p>
                </div>
              )}
            </div>
          </div>
          {!auth.isLegalShipper() ? (
            <div className={!vehicleValid && 'shakeIt'}>
              <FormInput
                type="text"
                name="numOfVehicle"
                labelText={t('settings.vehicles')}
                settings={true}
                value={values.numOfVehicle}
                required
                onChange={handleChange}
              />
            </div>
          ) : null}

          <Button handleOpen={handleSubmit} type="submit">
            {t('settings.save')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default withNamespaces()(CompanySettings);
