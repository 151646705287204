import React from 'react';

import './mailConfirmation.css';

const MailConfirmation = () => {
  const carrier_name = 'dkjfghfkjghfd';
  return (
    <body
      leftmargin="0"
      marginwidth="0"
      topmargin="0"
      marginheight="0"
      offset="0"
      bgcolor="#f5f8fa"
      class="body"
    >
      <table
        id="outerWrapper"
        border="0"
        cellpadding="0"
        cellspacing="0"
        height="100%"
        width="100%"
        bgcolor="#f5f8fa"
        style={{
          fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
          fontSize: '16px',
          color: '#425b76',
          lineHeight: '1.5',
          width: '100%',
          minWidth: '100%',
          backgroundColor: '#f5f8fa'
        }}
      >
        <tbody>
          <tr>
            <td
              align="center"
              valign="top"
              style={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <table
                border="0"
                cellpadding="20"
                cellspacing="0"
                width="600"
                style={{ width: '600px' }}
                class="emailContainer"
              >
                <tbody>
                  <tr>
                    <td
                      align="center"
                      valign="top"
                      width="100%"
                      style={{ width: '100%', minWidth: '100%' }}
                    >
                      <table
                        cellpadding="12"
                        border="0"
                        cellspacing="0"
                        width="100%"
                        bgcolor="#ff7a59"
                        style={{ width: '100%', minWidth: '100%' }}
                      >
                        <tbody>
                          <tr>
                            <td
                              align="center"
                              valign="middle"
                              width="100%"
                              class="td-exception"
                            >
                              <img
                                src="https://i.imgur.com/nrkZnuE.png"
                                alt="HubSpot"
                                width="120"
                                height="35"
                                style={{
                                  width: '120px',
                                  height: '35px',
                                  verticalAlign: 'middle',
                                  clear: 'both',
                                  width: 'auto',
                                  maxWidth: '100%'
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        id="backgroundTable"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        height="100%"
                        width="100%"
                        bgcolor="#ffffff"
                        style={{ width: '100%', minWidth: '100%' }}
                      >
                        <tbody>
                          <tr>
                            <td
                              align="left"
                              valign="top"
                              style={{ fontSize: '16px', padding: '0 50px' }}
                            >
                              <table
                                cellpadding="0"
                                border="0"
                                cellspacing="0"
                                width="100%"
                                style={{
                                  color: '#425b76',
                                  backgroundColor: '#fff',
                                  fontSize: '20px',
                                  width: '100%',
                                  margin: 'initial',
                                  minWidth: '100%'
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="middle"
                                      style={{ padding: '0' }}
                                    >
                                      <table
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        style={{
                                          fontSize: '0',
                                          height: '30px',
                                          width: '100%',
                                          minWidth: '100%',
                                          lineHeight: '0'
                                        }}
                                      >
                                        <tbody></tbody>
                                      </table>
                                      <hr
                                        style={{
                                          height: '1px',
                                          color: '#eaf0f6',
                                          backgroundColor: '#eaf0f6',
                                          border: 'none',
                                          margin: '0px',
                                          padding: '0px'
                                        }}
                                      />
                                      <table
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="100%"
                                        style={{
                                          fontSize: '0',
                                          height: '30px',
                                          width: '100%',
                                          minWidth: '100%',
                                          lineHeight: '0'
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td height="75">
                                              <span class="span"> &nbsp;</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <p style={{ margin: '0' }}>
                                Thank You for confirming your email address,
                                enjoy using Autoversand platform.
                              </p>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                style={{
                                  fontSize: '0',
                                  height: '50px',
                                  width: '100%',
                                  minWidth: '100%',
                                  lineHeight: '0'
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td height="85">
                                      <span class="span"> &nbsp;</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                width="100%"
                                border="0"
                                cellspacing="0"
                                cellpadding="0"
                              >
                                <tbody>
                                  <tr>
                                    <td align="center">
                                      <table
                                        border="0"
                                        cellspacing="0"
                                        cellpadding="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              style={{ borderRadius: '3px' }}
                                              bgcolor="#06016c"
                                              width="auto"
                                            >
                                              <a
                                                href="https://demo.autoversand.com/login"
                                                target="_blank"
                                                style={{
                                                  border: '1px solid #06016c',
                                                  borderRadius: '3px',
                                                  color: '#FFFFFF',
                                                  display: 'inline-block',
                                                  fontSize: '14px',
                                                  fontWeight: '500',
                                                  lineHeight: '1',
                                                  padding: '12px 20px',
                                                  textDecoration: 'none',
                                                  width: 'auto',
                                                  minWidth: '170px',
                                                  whiteSpace: 'nowrap'
                                                }}
                                              >
                                                Sign in
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div
                                itemscope
                                itemtype="http://schema.org/EmailMessage"
                              >
                                <div
                                  itemprop="potentialAction"
                                  itemscope
                                  itemtype="http://schema.org/ViewAction"
                                >
                                  {/* <!-- <link itemprop="target" href="https://app.hubspot.com/verify?u=matthew@fathomanddraft.com&amp;t=GS9W_PGY96UmfKORtn3bAYwx65_Ut5sDWEblVD6vmC2u3RJOe063BDGxcvBPBGTC&amp;i=8092426"> --> */}
                                  <meta
                                    itemprop="name"
                                    content="Confirm your email address"
                                  />
                                </div>
                                <meta
                                  itemprop="description"
                                  content="Confirm your email address"
                                />
                              </div>
                              <div
                                itemprop="publisher"
                                itemscope
                                itemtype="http://schema.org/Organization"
                              >
                                <meta itemprop="name" content="Autoversand" />
                                <link
                                  itemprop="url"
                                  content="https://www.autoversand.com"
                                />
                                {/* <!-- <link itemprop="url/googlePlus" content="https://plus.google.com/+hubspot/"> --> */}
                              </div>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                style={{
                                  fontSize: '0',
                                  height: '20px',
                                  width: '100%',
                                  minWidth: '100%',
                                  lineHeight: '0'
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td height="20">
                                      <span class="span"> &nbsp;</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                cellpadding="0"
                                border="0"
                                cellspacing="0"
                                width="100%"
                                style={{
                                  color: '#425b76',
                                  fontSize: '20px',
                                  width: '100%',
                                  margin: 'initial',
                                  minWidth: '100%'
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="middle"
                                      style={{ padding: '0' }}
                                    >
                                      <p
                                        style={{
                                          fontSize: '14px',
                                          margin: '0'
                                        }}
                                      >
                                        <i18n-string
                                          data-key="account.verifyUserEmailAddress.noSignupHelp"
                                          data-locale-at-render="en-us"
                                        >
                                          Do you need additional help?{' '}
                                          <a
                                            href="https://api.whatsapp.com/send?phone=436764893233"
                                            style={{
                                              textDecoration: 'none',
                                              color: '#00a3fa'
                                            }}
                                          >
                                            Contact our support
                                          </a>
                                          .
                                        </i18n-string>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                style={{
                                  fontSize: '0',
                                  height: '50px',
                                  width: '100%',
                                  minWidth: '100%',
                                  lineHeight: '0'
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td height="50">
                                      <span class="span"> &nbsp;</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        id="footer"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        height="100%"
                        width="100%"
                        bgcolor="#f5f8fa"
                        style={{ width: '100%', minWidth: '100%' }}
                      >
                        <tbody>
                          <tr>
                            <td align="center" valign="top">
                              <table
                                cellpadding="0"
                                border="0"
                                cellspacing="0"
                                width="100%"
                                style={{
                                  color: '#425b76',
                                  fontSize: '14px',
                                  width: '100%',
                                  margin: 'initial',
                                  minWidth: '100%',
                                  lineHeight: '24px'
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="middle"
                                      style={{ padding: '20px 0 65px' }}
                                    >
                                      Two Dots Digital GmbH
                                      <br />
                                      Neue Heimat 22
                                      <br />
                                      Laakirchen, Austria
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </body>
  );
};

export default MailConfirmation;
