import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';
import Slider from 'react-slick';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

import Header from '../../Layout/Dashboard/DashboardHeader/DashboardHeader';
import DashboardNavbar from '../../Layout/Dashboard/DashboardNavbar/DashboardNavbar';
import Navbar from '../../Layout/Navbar/Navbar';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import ViewShipment from '../../components/PendingPaymentsCard/PendingPaymentsCard';
import CompanyReviewsCard from '../../components/CompanyReviewsCard/CompanyReviewsCard';
import ChatButton from '../../components/Buttons/ChatButton/ChatButton';

import { ReactComponent as Check } from '../../assets/images/shipmentViewCheck.svg';
import { ReactComponent as NothingFound } from '../../assets/images/nothingFound.svg';

import auth from '../../auth';
import config from '../../config/api.config.js';

import { useWindowSize } from '../../components/WindowSize/WindowSize';

import 'slick-carousel/slick/slick.css';
import './ShipperProfile.css';

const override = css`
  display: block;
  margin: 0 auto;
  margin-right: 95px;
  padding-right: 95px;
  right: 95px;
`;

const ShipperProfile = ({ match, t }, props) => {
  const [sliderLoaded, setSliderLoaded] = useState(false);
  const userId = auth.getId();
  const history = useHistory();
  const [width, height] = useWindowSize();
  const [shipmentsLoaded, setShipmentsLoaded] = useState(false);
  const [userRatings, setUserRatings] = useState([]);
  const [companyReviewsCard, setCompanyReviewsCard] = useState([]);
  const [data, setData] = useState({
    company: {
      picture: null,
      name: null,
      description: null,
      memberDate: null,
      verification: `${t('profile.verified')}`
    },
    shipmentsInfo: {
      shipmentID: null,
      carImage: `url(${'https://picsum.photos/200'})`,
      car: 'Mercedes',
      carType: 'C Class 2019',
      from: {
        city: 'Berlin',
        country: 'Germany'
      },
      to: {
        city: 'Madrid',
        country: 'Spain'
      }
    },
    reviewCard: {
      statusOfReview: 'EXCELENT',
      dateOfReview: '22/8/2020',
      hourOfReview: '15:42',
      reviewer: 'R-Salon',
      picture: `url(${'https://picsum.photos/200'})`,
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ac ex viverra, suscipit mauris sit amet, auctor risus. Sed quis vestibulum neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ac ex viverra, suscipit mauris sit amet, auctor risus. Sed quis vestibulum neque. mauris sit amet, auctor risus. Sed quis vestibulum neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ac ex viverra, suscipit mauris sit amet, auctor risus. Sed quis vestibulum neque.',
      car: 'Volkswagen',
      carType: 'Sharam Blueline',
      transportPrice: '1500',
      transportDate: '20.7.2020'
    }
  });
  const [items, setItems] = useState({
    viewShipment: [1, 2, 3, 4, 5, 6, 7].map((i) => (
      <ViewShipment
        key={i}
        data={data.shipmentsInfo}
        pendingPayments={false}
        buttonAction={t('profile.viewShipment')}
      />
    )),
    companyReviewsCard: [1, 2, 3, 4, 5, 6, 7].map((i) => (
      <CompanyReviewsCard key={i} data={data.reviewCard} />
    ))
  });

  const loadData = async () => {
    await axios
      .get(`${config.apiUrl}/users/cips/${match.params.userId}/`)
      .then((res) => {
        const {
          first_name,
          last_name,
          name,
          description,
          date_joined,
          email_verified,
          photo,
          type
        } = res.data;
        setData({
          ...data,
          company: {
            picture: `url(${config.assetUrl}${photo})`,
            name:
              type == 'legal_shipper'
                ? name
                : `${first_name} ${last_name.slice(0, 1)}.`,
            description: description?.trim()
              ? description
              : `${t('profile.noDescription')}`,
            memberDate: moment(date_joined).format('DD.MM.YYYY'),
            verification: email_verified
              ? `${t('profile.verified')}`
              : `${t('profile.unverified')}`
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`${config.apiUrl}/shipments/user/${match.params.userId}/`)
      .then((res) => {
        let shipments = res.data.map((shipment) => {
          if (shipment.photos.length && shipment.state === 'pending') {
            return {
              shipmentID: shipment.id,
              carImage: shipment.photos[0].file,
              car: shipment.vehicle.manufacturer,
              carType: shipment.vehicle.model,
              from: {
                city: shipment.pickup.city,
                country: shipment.pickup.country
              },
              to: {
                city: shipment.destination.city,
                country: shipment.destination.country
              }
            };
          }
        });

        shipments = shipments.filter(Boolean);

        setItems({
          ...items,
          viewShipment: shipments.map((shipment, index) => (
            <ViewShipment
              key={index}
              data={shipment}
              pendingPayments={false}
              buttonAction={t('profile.viewShipment')}
              handleOpen={() =>
                history.push(`/shipment/${shipment.shipmentID}`)
              }
            />
          ))
        });

        setShipmentsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRatings = async () => {
    setSliderLoaded(true);
    await axios
      .get(`${config.apiUrl}/ratings/rated/${match.params.userId}/`)
      .then((res) => {
        setUserRatings(res.data);
        setSliderLoaded(true);

        let ratings = res.data.map((item) => {
          return {
            statusOfReview: item.rating,
            reviewer: item.rater.company
              ? item.rater.company.name
              : `${
                  item.rater.person.first_name
                } ${item.rater.person.last_name.slice(0, 1)}.`,
            picture: `url(${item.shipment.photos[0].file})`,
            content: item.notes,
            car: item.shipment.vehicle.manufacturer,
            carType: item.shipment.vehicle.model,
            transportDate: moment(item.updated_at).format('DD.MM.YYYY')
          };
        });

        setCompanyReviewsCard(
          ratings.map((rating, index) => (
            <CompanyReviewsCard key={index} data={rating} />
          ))
        );
        setSliderLoaded(false);
      })
      .catch((err) => {
        console.log(err);
        setSliderLoaded(false);
      });
  };

  const getSatisfactionPercentage = () => {
    if (!userRatings.length) return 0;

    const positiveRatings = userRatings.filter(
      (rating) => rating.rating === 'positive'
    );

    return (positiveRatings.length / userRatings.length) * 100;
  };

  const getRatingNumber = (rating) => {
    if (!userRatings.length) return 0;

    const ratings = userRatings.filter((item) => item.rating === rating);

    return ratings.length;
  };

  const getRatingPercentage = (rating) => {
    if (!userRatings.length) return 0;

    const ratings = userRatings.filter((item) => item.rating === rating);

    return ratings.length / userRatings.length;
  };
  useEffect(() => {
    loadData();
    getRatings();
  }, []);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false,
          arrows: false
        }
      }
    ]
  };

  return (
    <div>
      <div className="shipperProfile">
        <Helmet>
          <title>Autoversand | Shipper profile</title>
        </Helmet>
        {auth.isAuthenticated() ? <Header /> : <Navbar />}
        <div className="shipperProfile__main">
          {auth.isAuthenticated() ? <DashboardNavbar /> : null}
          <div
            className={
              auth.isAuthenticated()
                ? 'shipperProfile__content'
                : 'shipperProfile__content shipperProfile__contentPublic'
            }
          >
            <section className="shipperProfile__section1">
              {data.company.picture === null ? (
                <div
                  className="section1__left"
                  style={{
                    background: 'white',
                    boxShadow: '0 20px 40px 0 rgba(6, 1, 108, 0.1)'
                  }}
                >
                  <ContentLoader viewBox="0 0 380 200">
                    <rect x="0" y="0" rx="5" ry="5" width="500" height="200" />
                  </ContentLoader>
                </div>
              ) : (
                <div
                  className="section1__left"
                  style={{ backgroundImage: data.company.picture }}
                ></div>
              )}
              <div className="section1__right">
                <div className="section1Right__shipperInformation">
                  {data.company.name !== null && (
                    <p className="shipperInformation__member">
                      <span>{data.company.verification}</span>{' '}
                      {t('profile.memberSince')} {data.company.memberDate}
                    </p>
                  )}
                  <h2 className="shipperInformation__name">
                    {data.company.name === null ? (
                      <ContentLoader viewBox="0 0 380 20">
                        <rect x="0" y="5" rx="3" ry="3" width="80" height="8" />
                      </ContentLoader>
                    ) : (
                      data.company.name
                    )}
                    {data.company.verification === 'VERIFIED' && (
                      <Check className="companyInformation__check" />
                    )}
                  </h2>
                  <p className="shipperInformation__description">
                    {data.company.description == null ? (
                      <ContentLoader viewBox="0 0 380 70">
                        <rect
                          x="0"
                          y="5"
                          rx="3"
                          ry="3"
                          width="350"
                          height="8"
                        />
                        <rect
                          x="0"
                          y="20"
                          rx="3"
                          ry="3"
                          width="350"
                          height="8"
                        />
                        <rect
                          x="0"
                          y="35"
                          rx="3"
                          ry="3"
                          width="350"
                          height="8"
                        />
                      </ContentLoader>
                    ) : (
                      data.company.description
                    )}
                  </p>
                </div>
                {match.params.userId !== userId ? (
                  <div className="shipperProfile__messageButton">
                    <ChatButton
                      style={{ cursor: 'pointer' }}
                      handleClick={() =>
                        window.open(
                          `/messenger?newChat=${match.params.userId}`,
                          '_self'
                        )
                      }
                    />
                  </div>
                ) : null}
              </div>
            </section>

            <section className="shipperProfile__section2">
              <div className="section2__truckList">
                <p className="section2__truckList--paragraph">
                  <span>
                    {items.viewShipment.length} {t('profile.transport')}
                  </span>{' '}
                  {t('profile.transportRegistered')}
                </p>
                <div className="section2__slide">
                  {!shipmentsLoaded && (
                    <PuffLoader
                      size={60}
                      css={override}
                      color={'#123abc'}
                      loading={true}
                    />
                  )}
                  {shipmentsLoaded &&
                    (items.viewShipment.length === 0 ? (
                      <p className="shipments__notFound">NO SHIPMENTS</p>
                    ) : (
                      <Slider {...settings}>{items.viewShipment}</Slider>
                    ))}
                </div>
              </div>
              <div className="section2__dashedContainer"></div>
            </section>

            <section className="shipperProfile__section2Mobile">
              <p className="section2__truckList--paragraph">
                <span>
                  {items.viewShipment.length} {t('profile.transport')}
                </span>{' '}
                {t('profile.transportRegistered')}
              </p>
              <div className="section2__slide">
                {!shipmentsLoaded && (
                  <PuffLoader
                    size={60}
                    css={override}
                    color={'#123abc'}
                    loading={true}
                  />
                )}
                {shipmentsLoaded &&
                  (items.viewShipment.length === 0 ? (
                    <p className="shipments__notFound">NO SHIPMENTS</p>
                  ) : (
                    <Slider {...settings}>{items.viewShipment}</Slider>
                  ))}
              </div>
            </section>

            <section className="shipperProfile__section3">
              <ContentHeader>{t('ratings.ratings')}</ContentHeader>
              <div className="section3__group">
                <div className="section3__percentages">
                  <div className="percentages__group">
                    <p className="percentages__group--paragraph">
                      {t('profile.positive')}
                    </p>
                    <p className="percentages__group--paragraph">
                      {t('profile.neutral')}
                    </p>
                    <p className="percentages__group--paragraph">
                      {t('profile.negative')}
                    </p>
                  </div>
                  <div className="percentages__group">
                    <input
                      type="range"
                      readOnly
                      min={0}
                      max={userRatings.length}
                      class="slider"
                      name="excelent"
                      value={getRatingNumber('positive')}
                      style={{
                        background:
                          'linear-gradient(to right, #2699fb 0%, #7426fb ' +
                          getRatingPercentage('positive') * 100 +
                          '%, #eef7ff  ' +
                          getRatingPercentage('positive') * 100 +
                          '%,#eef7ff  100%)'
                      }}
                    />
                    <input
                      type="range"
                      readOnly
                      min={0}
                      max={userRatings.length}
                      class="slider"
                      name="mediocore"
                      value={getRatingNumber('neutral')}
                      style={{
                        background:
                          'linear-gradient(to right, #2699fb 0%, #7426fb ' +
                          getRatingPercentage('neutral') * 100 +
                          '%, #eef7ff  ' +
                          getRatingPercentage('neutral') * 100 +
                          '%,#eef7ff  100%)'
                      }}
                    />
                    <input
                      type="range"
                      readOnly
                      min={0}
                      max={userRatings.length}
                      class="slider"
                      name="supbar"
                      value={getRatingNumber('negative')}
                      style={{
                        background:
                          'linear-gradient(to right, #2699fb 0%, #7426fb ' +
                          getRatingPercentage('negative') * 100 +
                          '%, #eef7ff  ' +
                          getRatingPercentage('negative') * 100 +
                          '%,#eef7ff  100%)'
                      }}
                    />
                  </div>
                  <div className="percentages__group">
                    <p className="percentages__group--votes">
                      <span>{getRatingNumber('positive')}</span>{' '}
                      {t('profile.votes')}
                    </p>
                    <p className="percentages__group--votes">
                      <span>{getRatingNumber('neutral')}</span>{' '}
                      {t('profile.votes')}
                    </p>
                    <p className="percentages__group--votes">
                      <span>{getRatingNumber('negative')}</span>{' '}
                      {t('profile.votes')}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="shipperProfile__section4">
              {sliderLoaded ? (
                <PuffLoader
                  css={override}
                  size={60}
                  margin={2}
                  color={'rgba(116, 38, 251, 0.4)'}
                  loading={sliderLoaded}
                />
              ) : companyReviewsCard.length ? (
                <div>{companyReviewsCard}</div>
              ) : null}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(ShipperProfile);
