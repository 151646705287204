import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import ContentHeader from '../../components/ContentHeader/ContentHeader';
import FuhrparkCard from '../../components/FuhrparkCard/FuhrparkCard';
import AddTruck from '../../components/TruckFuhrpark/AddTruck/AddTruck';
import TruckPreview from '../../components/TruckFuhrpark/TruckPreview/TruckPreview';
import UpdateTruck from '../../components/TruckFuhrpark/UpdateTruck/UpdateTruck';
import Button from '../../components/Buttons/BlueButton';
import { ReactComponent as NothingFound } from '../../assets/images/nothingFound.svg';
import { withNamespaces } from 'react-i18next';

import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';

import axios from 'axios';
import config from '../../config/api.config.js';
import './Fuhrpark.css';

import { Animated } from 'react-animated-css';

const override = css`
  margin: 0 auto;
  width: 100px;
  margin-top: 150px;
  margin-bottom: 50px;
`;

const Fuhrpark = ({ t }) => {
  const element = useRef();
  const [numActiveTransport, setNumActiveTransport] = useState(0);

  let trucks = [];
  const [truckItems, setTruckItems] = useState([]);
  const [sliderLoaded, setSliderLoaded] = useState(false);

  const [dataForPreview, setDataForPreivew] = useState();

  const [visible, setVisible] = useState(false);
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);

  const getAllTrucks = async () => {
    setSliderLoaded(true);
    await axios
      .get(`${config.apiUrl}/shipments/transport-vehicle/list/`)
      .then((res) => {
        setNumActiveTransport(res.data.length);
        res.data.forEach((shipment) => {
          trucks.push({
            name: shipment.name,
            year: shipment.year,
            picture: shipment.photos.length
              ? `url(${`${config.assetUrl}` + shipment.photos[0].file})`
              : `url(${'https://picsum.photos/200'})`,
            pictures: shipment.photos,
            documents: shipment.documents,
            capacity: shipment.capacity,
            description: shipment.description
          });
        });

        setTruckItems(
          trucks.map((truck) => (
            <FuhrparkCard
              data={truck}
              onClickOpenPreview={onClickOpenPreview}
              setData={setDataForPreivew}
            />
          ))
        );

        setSliderLoaded(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllTrucks();
  }, []);

  const onClickOpen = () => {
    setVisible(true);
    element.current.style.backgroundColor = 'white';
  };
  const onClickClose = () => {
    setVisible(false);
    element.current.style.backgroundColor = 'rgba(6, 1, 108, 0.05)';
  };

  const onClickOpenPreview = () => {
    setVisiblePreview(true);
    element.current.style.backgroundColor = 'white';
  };
  const onClickClosePreview = () => {
    setVisiblePreview(false);
    element.current.style.backgroundColor = 'rgba(6, 1, 108, 0.05)';
  };

  const onClickOpenEdit = () => {
    setVisibleEdit(true);
    element.current.style.backgroundColor = 'white';
  };
  const onClickCloseEdit = () => {
    setVisibleEdit(false);
    element.current.style.backgroundColor = 'rgba(6, 1, 108, 0.05)';
  };
  return (
    <div className="fuhrpark__content" ref={element}>
      <Helmet>
        <title>Autoversand | {t('vehicles.Park')}</title>
      </Helmet>
      <section className="fuhrparkContent__section">
        <div className="section__top">
          <div>
            <ContentHeader>{t('vehicles.park')}</ContentHeader>
            <p className="sectionTop__paragraph">
              {t('fuhrpark.currently')}{' '}
              <span>
                {numActiveTransport} {t('fuhrpark.activeTransport')}{' '}
              </span>
              {t('fuhrpark.transportPark')}
              <p> {t('fuhrpark.keepInfo')}</p>
            </p>
          </div>
          <Button handleOpen={onClickOpen}>{t('vehicles.addTruck')}</Button>
          <div
            className={
              visible || visiblePreview || visibleEdit
                ? 'fuhrpark__content-blur'
                : 'fuhrpark__content-blurDisplay'
            }
          ></div>

          <Animated
            className="addTruckAnimated"
            animationIn="fadeInRight"
            animationOut="fadeOutRight"
            animateOnMount={false}
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={visible}
          >
            <AddTruck onClickClose={onClickClose} />
          </Animated>
          <Animated
            className="addTruckAnimated"
            animationIn="fadeInRight"
            animationOut="fadeOutRight"
            animateOnMount={false}
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={visiblePreview}
          >
            <TruckPreview
              onClickClosePreview={onClickClosePreview}
              onClickOpenEdit={onClickOpenEdit}
              data={dataForPreview}
            />
          </Animated>
          <Animated
            className="addTruckAnimated"
            animationIn="fadeInRight"
            animationOut="fadeOutRight"
            animationInDelay={500}
            animateOnMount={false}
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={visibleEdit}
          >
            <UpdateTruck onClickCloseEdit={onClickCloseEdit} />
          </Animated>
        </div>
        <div className="section__bottom">
          {sliderLoaded ? (
            <PuffLoader
              css={override}
              size={60}
              margin={2}
              color={'rgba(116, 38, 251, 0.4)'}
              loading={sliderLoaded}
            />
          ) : truckItems.length ? (
            <div
              className="orders__slider"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                overflowY: 'scroll',
                padding: '0rem 1.2rem'
              }}
            >
              {truckItems}
            </div>
          ) : (
            <div className="nothingFound">
              <NothingFound />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default withNamespaces()(Fuhrpark);
