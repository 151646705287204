import React from 'react';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { ReactComponent as Check } from '../../../assets/images/shipmentViewCheck.svg';
import ChatButton from '../../Buttons/ChatButton/ChatButton';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// Animation
import { easeQuadInOut } from 'd3-ease';
import AnimatedProgressProvider from '../../AnimatedProgressProvider/AnimatedProgressProvider';

import './RejectedBid.css';

const RejectedBid = ({ t, data, openModal, setModalMessage }) => {
  const history = useHistory();
  const _openModal = () => {
    openModal(true);
    setModalMessage(data.message);
  };
  const calculateRatingPercentage = () => {
    if (!data.ratings.length) return 0;

    const positiveRatings = data.ratings.filter(
      (rating) => rating.rating === 'positive'
    );

    return (positiveRatings.length / data.ratings.length) * 100;
  };
  return (
    <div className="shipper__rejectedBid">
      <div className="shipper__rejectedBid--heading">
        <div className="shipper__header-left">{data.price} €</div>
        <div className="shipper__header-right">
          {t('offers.offer')} {data.numOfOffer}
        </div>
      </div>
      <div className="shipper__rejectedBid--content">
        <div className="rejectedBid__contentLeft">
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={calculateRatingPercentage()}
            duration={1.4}
            easingFunction={easeQuadInOut}
          >
            {(value) => {
              const roundedValue = Math.round(value);
              return (
                // <CircularProgressbar
                // value={value}
                // text={`${roundedValue}%`}
                // styles={buildStyles({ pathTransition: "none", strokeLinecap: "butt" })}
                // strokeWidth={5}

                // />
                <CircularProgressbarWithChildren
                  value={value}
                  // text={`${roundedValue}%`}
                  styles={buildStyles({
                    pathTransition: 'none',
                    strokeLinecap: 'butt',
                    backgroundColor: 'transparent',
                    pathColor: '#2699fb',
                    trailColor: 'transparent'
                  })}
                  strokeWidth={5}
                >
                  <img
                    style={{
                      width: '100%',
                      borderRadius: '9999999px',
                      background: 'transparent',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                    src={data.userImage}
                    alt="user image"
                    onClick={() =>
                      history.push(`/company-profile/${data.carrier}`)
                    }
                  />
                </CircularProgressbarWithChildren>
              );
            }}
          </AnimatedProgressProvider>
          <div className="rejectedBid__user">
            {data.verified ? <Check className="rejectedBid__check" /> : '   '}
            <label>{data.userName}</label>
          </div>
          <ChatButton
            handleClick={() =>
              window.open(`/messenger?newChat=${data.carrier}`, '_self')
            }
          />
        </div>
        <div className="rejectedBid__contentRight">
          <div
            className="rejectedBid__contentRightMessage"
            onClick={_openModal}
          >
            <div>{data.message}</div>
          </div>
          <div className="rejectedBid__dates">
            <div className="rejectedBid__pickupDate">
              {t('shipments.pickupDate')} <label>{data.pickupDate}</label>
            </div>
            <div className="rejectedBid__deliveryDate">
              {t('offers.deliveryDate')}{' '}
              <label>
                {t('offers.after')} {data.deliveryDate} {t('offers.days')}
              </label>
            </div>
          </div>
          <button className="rejectedBid__button">
            {t('offers.rejectedButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(RejectedBid);
