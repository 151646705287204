import React from "react";
import { Range, getTrackBackground } from "react-range";

const STEP = 100;
const MIN = 0;
const MAX = 20000;

class TwoThumbs extends React.Component {
  state = {
    values: [2500, 8000],
  };
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Range
          values={this.state.values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => {
            this.setState({ values });
          }}
          onFinalChange={(values) => {
            this.setState({ values });
            this.props.setWeight(values);
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: this.state.values,
                    colors: ["#EEF7FF", "#548BF4", "#EEF7FF"],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                backgroundColor: "#7426FB",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA",
              }}
            >
              {/* <div
                style={{
                  height: '16px',
                  width: '5px',
                  backgroundColor: isDragged ? '#548BF4' : '#CCC'
                }}
              /> */}
            </div>
          )}
        />
        <output
          style={{
            marginTop: "5px",
            width: "100%",
            textAlign: "right",
            color: "#7977A4",
          }}
          id="output"
        >
          {this.state.values[0].toFixed(0)}kg -{" "}
          {this.state.values[1].toFixed(0)}kg
        </output>
      </div>
    );
  }
}

export default TwoThumbs;
