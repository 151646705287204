import React from 'react';
import ReactDOM from 'react-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import { withNamespaces } from 'react-i18next';

import './HandleTag.css';

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class HandleTags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      suggestions: []
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  async handleDelete(i) {
    const { tags } = this.state;
    await this.setState({
      tags: tags.filter((tag, index) => index !== i)
    });
    await this.props.getTags(this.state.tags.map((tag) => tag.text));
  }

  async handleAddition(tag) {
    if (this.state.tags.length == 5) return;

    await this.setState((state) => ({ tags: [...state.tags, tag] }));
    await this.props.getTags(this.state.tags.map((tag) => tag.text));
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });

    this.props.getTags(this.state.tags.map((tag) => tag.text));
  }

  render() {
    const { tags, suggestions } = this.state;
    const { t } = this.props;
    return (
      <div>
        <ReactTags
          tags={tags}
          suggestions={suggestions}
          placeholder={t('shipments.tags')}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          handleDrag={this.handleDrag}
          delimiters={delimiters}
        />
      </div>
    );
  }
}

export default withNamespaces()(HandleTags);
