export const lngSetup = (i18n) => {
  const SUPPORTED_LANGUAGES = ['de', 'gb'];
  const DEFAULT_LANGUAGE = 'gb';

  if (!SUPPORTED_LANGUAGES.includes(i18n.language))
    i18n.changeLanguage(DEFAULT_LANGUAGE);
};

export const changeLanguage = (i18n, lng) => {
  i18n.changeLanguage(lng);
};
