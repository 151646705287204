import React from "react";
import auth from "../../../auth";

const firebase = require("firebase");

class NewChatComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      username: null,
      message: null,
    };
  }

  render() {
    return (
      <main style={{ width: "200px", margin: "0 auto" }}>
        <div>
          <h1>Send a message</h1>
          <form onSubmit={(e) => this.submitNewChat(e)}>
            <div>
              <label for="new-chat-username">Enter Your Friend's ID</label>
              <input
                required
                autofocus
                onChange={(e) => this.userTyping("username", e)}
                id="new-chat-username"
              />
            </div>
            <div>
              <label for="new-chat-message">Enter your message</label>
              <input
                required
                onChange={(e) => this.userTyping("message", e)}
                id="new-chat-message"
              />
            </div>
            <button type="submit">SEND MESSAGE</button>
          </form>
        </div>
      </main>
    );
  }

  userTyping = (type, e) => {
    switch (type) {
      case "username":
        this.setState({
          username: e.target.value,
        });
        break;
      case "message":
        this.setState({
          message: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  submitNewChat = async (e) => {
    e.preventDefault();
    const userExists = await this.userExists();
    if (userExists) {
      const chatExists = await this.chatExists();
      chatExists ? this.goToChat() : this.createChat();
    }
  };

  createChat = () => {
    this.props.newChatSubmitFn({
      sendTo: this.state.username,
      message: this.state.message,
    });
  };

  goToChat = () =>
    this.props.goToChatFn(this.buildDocKey(), this.state.message);

  buildDocKey = () => {
    return [parseInt(auth.getId()), this.state.username].sort().join(":");
  };

  chatExists = async () => {
    const docKey = this.buildDocKey();
    const chat = await firebase
      .firestore()
      .collection("chats")
      .doc(docKey)
      .get();
    console.log("CHAT EXISTS", chat.exists);
    return chat.exists;
  };

  userExists = async () => {
    // const usersSnapshost = await firebase
    //     .firestore()
    //     .collection('users')
    //     .get();
    // const exists = user.usersSnapshost.docs
    //     .map(_doc => _doc.data().email)
    //     .includes(this.state.username);
    this.setState({
      serverError: false,
    });
    return true;
  };
}

export default NewChatComponent;
