import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { ReactComponent as WhatsappIcon } from '../../../assets/images/whatsapp.svg';
import { ReactComponent as ViberIcon } from '../../../assets/images/viber.svg';
import { ReactComponent as Check } from '../../../assets/images/shipmentViewCheck.svg';
import ChatButton from '../../Buttons/ChatButton/ChatButton';
import auth from '../../../auth';

import Modal from 'react-awesome-modal';

import {
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// Animation
import { easeQuadInOut } from 'd3-ease';
import AnimatedProgressProvider from '../../AnimatedProgressProvider/AnimatedProgressProvider';

import './ShipmentViewUserCard.css';

const ShipmentViewUserCard = ({ t, data }) => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const calculateRatingPercentage = () => {
    if (!data.ratings.length) return 0;

    const positiveRatings = data.ratings.filter(
      (rating) => rating.rating === 'positive'
    );

    return (positiveRatings.length / data.ratings.length) * 100;
  };

  return (
    <div className="shipmentViewUserCard">
      <div className="shipmentViewUserCard__left">
        <div className="shipmentViewUserCard__user">
          <div
            className="shipment__userImage"
            // style={{ backgroundImage: data.userImage }}
            onClick={() => history.push(`/shipper-profile/${data.userId}`)}
          >
            <AnimatedProgressProvider
              valueStart={0}
              valueEnd={calculateRatingPercentage()}
              duration={1.4}
              easingFunction={easeQuadInOut}
            >
              {(value) => {
                const roundedValue = Math.round(value);
                return (
                  <CircularProgressbarWithChildren
                    value={value}
                    styles={buildStyles({
                      pathTransition: 'none',
                      strokeLinecap: 'butt',
                      pathColor: '#7426FB',
                      trailColor: 'transparent',
                      backgroundColor: 'transparent'
                    })}
                    strokeWidth={5}
                  >
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '9999999px',
                        objectFit: 'cover'
                      }}
                      src={data.photo}
                      alt="user image"
                    />
                  </CircularProgressbarWithChildren>
                );
              }}
            </AnimatedProgressProvider>
          </div>
          <div className="shipment__userName">
            <div>{data.userName}</div>
          </div>
        </div>
        <div className="shipmentViewUserCard__message">
          <div className="shipmentViewUserCard__infoBox">
            <div className="arrow-left"></div>
            <div className="infoBox" onClick={() => openModal()}>
              <div>{data.description}</div>
            </div>
            <Modal
              visible={modalVisible}
              width="42%"
              effect="fadeInUp"
              onClickAway={() => closeModal()}
            >
              <div>
                {data.description}
                <a
                  href="javascript:void(0);"
                  onClick={() => closeModal()}
                  className="infoBoxModal__close"
                >
                  {t('ratings.close')}
                </a>
              </div>
            </Modal>
          </div>
          {auth.isAuthenticated() &&
          (auth.isCarrier() || auth.getId() != data.userId) ? (
            <ChatButton
              handleClick={() =>
                window.open(`/messenger?newChat=${data.userId}`, '_self')
              }
            />
          ) : null}
        </div>
      </div>
      <div className="shipmentViewUserCard__right">
        <p>{t('shipmentView.needInfo')}</p>
        <div className="shipmentViewUserCard__rightButtons">
          <a
            href="https://api.whatsapp.com/send?phone=436765539656"
            target="_blank"
          >
            <button>
              <WhatsappIcon className="shipment__whatsappIcon" /> WhatsApp
            </button>
          </a>
          <a href="viber://chat?number=+436765539656" target="_blank">
            <button>
              {' '}
              <ViberIcon className="shipment__viberIcon" /> Viber
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(ShipmentViewUserCard);
