import React, { useState, useEffect } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';

import { ReactComponent as ListIcon } from '../../assets/images/dashboard.svg';
import { ReactComponent as LogOut } from '../../assets/images/NavbarIcons/Logout.svg';
import { ReactComponent as ArrowDown } from '../../assets/images/NavbarIcons/User.svg';
import { ReactComponent as HomeIcon } from '../../assets/images/NavbarIcons/Home.svg';
import { ReactComponent as ReviewsIcon } from '../../assets/images/NavbarIcons/Reviews.svg';
import { ReactComponent as OffersIcon } from '../../assets/images/NavbarIcons/MyOffers.svg';
import { ReactComponent as OrdersIcon } from '../../assets/images/NavbarIcons/MyOrders.svg';
import { ReactComponent as PaymentsIcon } from '../../assets/images/NavbarIcons/Payments.svg';

import auth from '../../auth';
import config from '../../config/api.config.js';
import './navbar.css';

const MobileNavbar = (props) => {
  const [active, setActive] = useState(true);
  const [state, setState] = useState({
    userImage: null,
    userName: null
  });

  useEffect(() => {
    axios.get(`${config.apiUrl}/users/cips/${auth.getId()}/`).then((res) => {
      const { first_name, last_name, name } = res.data;
      const userName = first_name
        ? `${first_name}  ${last_name ?? ''}`.trim()
        : name;
      setState({
        userName,
        userImage: `url(${config.assetUrl}${res.data.photo})`
      });
    });
  }, []);

  return (
    <div className="mobileNavbar">
      <div className="navbar__bottomSide">
        <ul className="bottomSide__navmenu">
          {!auth.isCarrier() && (
            <li>
              <NavLink
                exact
                activeClassName="mobileNavbar__active"
                to="/shipments"
              >
                <HomeIcon className="listIcon" />
                Home
              </NavLink>
            </li>
          )}
          {auth.isCarrier() && (
            <li>
              <NavLink
                exact
                activeClassName="mobileNavbar__active"
                to="/dashboard"
              >
                <HomeIcon className="listIcon" />
                Home
              </NavLink>
            </li>
          )}
          {auth.isCarrier() && (
            <li>
              <NavLink activeClassName="mobileNavbar__active" to="/fuhrpark">
                <OrdersIcon className="listIcon" />
                {props.t('navbar.vehicles')}
              </NavLink>
            </li>
          )}
          {!auth.isCarrier() && (
            <li>
              <NavLink activeClassName="mobileNavbar__active" to="/orders">
                <OrdersIcon className="listIcon" />
                {props.t('navbar.orders')}
              </NavLink>
            </li>
          )}

          <li>
            <NavLink activeClassName="mobileNavbar__active" to="/offers">
              <OffersIcon className="listIcon" />
              {props.t('navbar.offers')}
            </NavLink>
          </li>

          <li>
            <NavLink activeClassName="mobileNavbar__active" to="/reviews">
              <ReviewsIcon className="listIcon" />
              {props.t('navbar.ratings')}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="mobileNavbar__active" to="/payments">
              <PaymentsIcon className="listIcon" />
              {props.t('navbar.payments')}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withRouter(withNamespaces()(MobileNavbar));
