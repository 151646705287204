import React, { useEffect, useState } from 'react';
import {
  Route,
  BrowserRouter,
  Switch,
  Redirect,
  useLocation
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { Animated } from 'react-animated-css';

import Homepage from './pages/Homepage/Homepage';
import Registration from './pages/Registration/Registration';
import Login from './pages/Login/Login';
import About from './pages/About/About';
import AGB from './pages/AGB/AGB';
import AGB2 from './pages/AGB/AGB2';
import Datenschutz from './pages/Datenschutz/Datenschutz';
import DatenschutzMobile from './pages/Datenschutz/DatenschutzMobile';
import Impressum from './pages/Impressum/Impressum';
import ImpressumMobile from './pages/Impressum/impresumMobile';
import AboutMobile from './pages/About/aboutMobile';
import Experiment from './pages/Registration/Experiment';
import Dashboard from './pages/Dashboard/Dashboard';
import Fuhrpark from './pages/Fuhrpark/Fuhrpark';
import Reviews from './pages/Reviews/Reviews';
import Shipments from './pages/Shipments/Shipments';
import ShipmentView from './pages/ShipmentView/ShipmentView';
import Account from './pages/Account/Account';
import Offers from './pages/Offers/Offers';
import CompanyProfile from './pages/CompanyProfile/CompanyProfile';
import ShipperProfile from './pages/ShipperProfile/ShipperProfile';
import Payments from './pages/Payments/Payments';
import Orders from './pages/Orders/Orders';
import Messenger from './pages/Messenger/Messenger';
import Results from './pages/Results/Results';
import Oauth from './pages/Oauth/Oauth';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import NewPassword from './pages/PasswordReset/NewPassword/NewPassword';
import PaymentPlayground from './pages/Playground/Payment';
import AccountMobile from './pages/Mobile/AccountMobile/AccountMobile';
import MobileNotifications from './pages/Mobile/MobileNotifications/MobileNotifications';
import MailConfirmation from './pages/MailConfirmation/MailConfirmation';
import MobileDownload from './components/MobileDownload/MobileDownload';
import { useWindowSize } from './components/WindowSize/WindowSize';

import Algolia from './components/Algolia/algolia';
import {
  DefaultProtection,
  DashboardProtection
} from './components/DefaultProtection/DefaultProtection';

import { connect } from 'react-redux';
import { getShipments } from '../src/redux/actions';

import './App.css';

function App(props) {
  const [width, height] = useWindowSize();
  const [visible, setVisible] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const location = useLocation();
  useEffect(() => {
    props.shipmentsReducer();
  }, []);

  useEffect(() => {
    if (
      [
        '/aboutmobile',
        '/agbmobile',
        '/datenschutzmobile',
        '/impressummobile'
      ].includes(location.pathname)
    )
      return;

    setVisible(() => width <= 1200 && !isClosed);
  }, [width]);
  return (
    <BrowserRouter>
      <div className="mainClass">
        <Helmet>
          <title>Autoversand | Home</title>
        </Helmet>
        <Animated
          className="mobileDownloadAnimation"
          animateOnMount={false}
          animationIn="fadeInRight"
          animationOut="fadeOutRight"
          animationInDelay={500}
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={visible}
        >
          <MobileDownload setVisible={setVisible} setIsClosed={setIsClosed} />
        </Animated>

        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route path="/oauth" component={Oauth} />
          <Route path="/about-us" component={About} />
          <Route path="/aboutmobile" component={AboutMobile} />
          <Route path="/agb" component={AGB} />
          <Route path="/agbmobile" component={AGB2} />
          <Route path="/datenschutzmobile" component={DatenschutzMobile} />
          <Route path="/datenschutz" component={Datenschutz} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/impressummobile" component={ImpressumMobile} />
          <Route path="/register" component={Registration} />
          <Route path="/login" component={Login} />
          <Route path="/password-reset" component={PasswordReset} />
          <Route path="/new-password" component={NewPassword} />
          <DefaultProtection path="/messenger" component={Messenger} />
          <DashboardProtection path="/dashboard" component={Dashboard} />
          <DashboardProtection path="/fuhrpark" component={Fuhrpark} />
          <DashboardProtection path="/reviews" component={Reviews} />
          <DashboardProtection path="/shipments" component={Shipments} />
          <Route path="/shipment/:shipmentId" component={ShipmentView} />
          <DashboardProtection path="/account" component={Account} />
          <DashboardProtection path="/main-settings" component={Account} />
          <DashboardProtection path="/company-settings" component={Account} />
          <DashboardProtection path="/personal-settings" component={Account} />
          <DashboardProtection path="/change-password" component={Account} />
          <DefaultProtection path="/offers" component={Offers} />
          <Route path="/company-profile/:userId" component={CompanyProfile} />
          <Route path="/shipper-profile/:userId" component={ShipperProfile} />
          <DefaultProtection path="/payments" component={Payments} />
          <DashboardProtection path="/orders" component={Orders} />
          <Route path="/results" component={Results} />
          <Route path="/mail-confirmed" component={MailConfirmation} />
          <DashboardProtection
            path="/account-mobile"
            component={AccountMobile}
          />
          <DashboardProtection
            path="/notifications"
            component={MobileNotifications}
          />
          <Route path="*" render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => ({
  shipmentsState: state.shipmentsReducer
});

const mapDispatchToProps = (dispatch) => ({
  shipmentsReducer: () => dispatch(getShipments())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
