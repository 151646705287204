import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';

import axios from 'axios';

import CarPicture from '../../../assets/images/bmw.png';
import Button from '../../Buttons/BlueButton';
import BackButton from '../../Buttons/BackButton';
import FormInput from '../../FormInput/FormInput';

import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';

import './ShipmentsMUAImport.css';

const override = css`
  margin: 0 auto;
  width: 100px;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const ShipmentsMUAImport = ({
  t,
  onClickClose,
  onClickOpen,
  setVehicleData,
  checkPastedURL
}) => {
  const handleClick = () => {
    onClickClose(false);
  };
  const handleOpen = () => {
    setVehicleData(vehicle);
    onClickClose(false);
    onClickOpen(true);
  };
  const [url, setUrl] = useState('');
  const [pasted, setPasted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const handleChange = (e) => {
    // if (!pasted) {
    setUrl(e.target.value);
    // }
    setPasted(false);
    checkPastedURL(e.target.value);
  };

  const handlePaste = (e) => {
    setPasted(true);
    setUrl(e.target.value);
    setFirstLoad(false);
    checkPastedURL(e.target.value);
  };
  const [data, setData] = useState({
    backgroundImage: `url(${CarPicture})`,
    name: 'BMW',
    meta_data: {
      kilometers: '6.300',
      power: '5.0 V8',
      fuel: 'Petrol'
    }
  });

  const [vehicle, setVehicle] = useState(null);
  const [sliderLoaded, setSliderLoaded] = useState(false);
  const [invalidLink, setInvalidLink] = useState(false);

  const getImportedImages = (muaCarData) => {
    return muaCarData
      ? muaCarData.images
          .filter((item) => !item.deleted)
          .map((item) => `https://api.meinunfallauto.online${item.path}`)
          .slice(0, 9)
      : [];
  };

  const getFromMua = async () => {
    setSliderLoaded(true);

    const slug = url ? url.split('/').pop() : null;
    await axios
      .get(`https://api.meinunfallauto.online/v1/vehicles/find/single/${slug}`)
      .then((res) => {
        if (!res.data.id) {
          setInvalidLink(true);
          setSliderLoaded(false);
          return;
        }

        setInvalidLink(false);
        setVehicle({
          images: getImportedImages(res.data),
          name: res.data.name,
          brand: res.data.meta_data.brand,
          brandId: res.data.meta_data.brandId,
          brandKey: res.data.meta_data.brandKey,
          model: res.data.meta_data.model,
          modelId: res.data.meta_data.modelId,
          modelKey: res.data.meta_data.modelKey
        });

        setSliderLoaded(false);
      })
      .catch((err) => {
        console.error(err);
        setInvalidLink(true);
        setSliderLoaded(false);
      });
  };

  useEffect(() => {
    if (!firstLoad) getFromMua();
  }, [url]);

  return (
    <div className="ShipmentsMUAImport">
      <div className="ShipmentsMUAImport__content">
        <h2 className="ShipmentsMUAImport__content--heading">
          {t('shipments.importVehicle')}
        </h2>
        <p className="ShipmentsMUAImport__content--paragraph">
          {t('shipments.vehicleBase')}
        </p>
        <div className="ShipmentsMUAImport__content--url">
          <FormInput
            type="url"
            name="inputurl"
            labelText={t('shipments.pasteURL')}
            value={url}
            required
            onChange={handleChange}
            onPaste={handlePaste}
          />
        </div>
        {sliderLoaded ? (
          <PuffLoader
            css={override}
            size={60}
            margin={2}
            color={'rgba(116, 38, 251, 0.4)'}
            loading={sliderLoaded}
          />
        ) : vehicle && invalidLink == false ? (
          <div className="ShipmentsMUAImport__content--urlPreview">
            <p className="ShipmentsMUAImport__urlPreview--paragraph">
              {t('shipments.pastedURL')}
            </p>
            <div className="urlPreview">
              <div
                className="urlPreview__top"
                style={{
                  backgroundImage: `url(${vehicle.images[0]})`
                }}
              ></div>
              <div className="urlPreview__bottom">
                <p className="urlPreview__bottom--heading">
                  <span>{vehicle.name}</span>
                </p>
              </div>
            </div>
          </div>
        ) : invalidLink == false ? null : (
          <p
            style={{
              color: '#07026d',
              fontSize: '1.15rem',
              marginTop: '0.5rem',
              textAlign: 'center'
            }}
          >
            {t('shipments.invalidURL')}
          </p>
        )}
        {vehicle == null ? (
          <div>
            <div className="ShipmentsMUAImport__content--OR">
              <hr />
              <p>{t('modal.or')}</p>
              <hr />
            </div>
            <Button handleOpen={handleOpen}>
              {t('shipments.addManually')}
            </Button>
          </div>
        ) : vehicle && invalidLink == false ? (
          <Button handleOpen={handleOpen}>{t('shipments.next')}</Button>
        ) : (
          <Button handleOpen={handleOpen}>{t('shipments.addManually')}</Button>
        )}
      </div>
      <BackButton handleClick={handleClick} />
    </div>
  );
};

export default withNamespaces()(ShipmentsMUAImport);
