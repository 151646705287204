import React from 'react';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import { RiCloseLine } from 'react-icons/ri';
import { ReactComponent as GooglePlay } from '../../assets/images/googlePlay.svg';
import { ReactComponent as AppStore } from '../../assets/images/appStore.svg';
import { withNamespaces } from 'react-i18next';

import './mobileDownload.css';

const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=io.meta5.autoversandv2';
const APP_STORE_URL = 'https://apps.apple.com/us/app/id1542202254';

const MobileDownload = ({ t, setVisible, setIsClosed }) => {
  const handleClick = () => {
    setVisible(false);
    setIsClosed(true);
  };
  return (
    <div className="mobileDownload">
      <RiCloseLine className="download__closeButton" onClick={handleClick} />
      <ContentHeader>Download mobile App</ContentHeader>
      <p>{t('mobile.download')}</p>
      <div className="mobileDownload__downloadButtons">
        <AppStore onClick={() => window.open(APP_STORE_URL, '_blank')} />
        <GooglePlay onClick={() => window.open(GOOGLE_PLAY_URL, '_blank')} />
      </div>
    </div>
  );
};

export default withNamespaces()(MobileDownload);
