import React from "react";

import "./ShipmentViewCardRowData.css";

const ShipmentViewCardRowData = ({ description, data }) => {
  return (
    <div className="content__rowData">
      <p className="rowData__description">{description}</p>
      <p className="rowData__data">{data}</p>
    </div>
  );
};

export default ShipmentViewCardRowData;
