import React from 'react';
import { ReactComponent as ArrowButton } from '../../assets/images/arrowButton.svg';
import { withNamespaces } from 'react-i18next';

import './BackButton.css';
const BackButton = ({ t, handleClick, ...otherProps }) => {
  return (
    <button onClick={handleClick} className="customBackButton">
      <ArrowButton /> <span>{t('button.back')}</span>
    </button>
  );
};

export default withNamespaces()(BackButton);
