import React from "react";
import { ReactComponent as Line } from "../../assets/images/line.svg";

const SectionHeader = ({ type, children }) => {
  return (
    <div>
      <p className="fontStyle__title">
        {type == "autoversand" ? "AUTOVERSAND" : "AGB"}
      </p>
      <h2 className="fontStyle__h2">{children}</h2>
      <Line />
    </div>
  );
};

export default SectionHeader;
