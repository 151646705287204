import React, { useEffect, useRef } from "react";

import "./FormInput.css";

const FormInput = ({
  handleChange,
  handleBlur,
  handlePaste,
  value = null,
  settings = false,
  labelText,
  spanText,
  ...otherProps
}) => {
  let element = useRef();
  useEffect(() => {
    if (element.current.value != "") {
      //element.current.style.border = "solid 2px rgba(121,119,164,0.5)";
      if (settings) element.current.style.backgroundColor = "white";
      else element.current.style.backgroundColor = "rgba(6, 1, 108, 0.03)";
      element.current.style.border = "none";
    } else {
      element.current.style.border = "solid 2px #7977a4";
      element.current.style.backgroundColor = "transparent";
    }
    //else element.current.style.backgroundColor = "transparent";
  });
  return (
    <div className="group">
      <input
        className="form-input"
        onChange={handleChange}
        onBlur={handleBlur}
        onPaste={handlePaste}
        value={value}
        {...otherProps}
        ref={element}
      />
      {/* <label htmlFor={otherProps.name} className="form-label">
        {labelText} <span>{spanText}</span>
      </label> */}
      <label
        htmlFor={otherProps.name}
        className={
          value != "" && value != null ? "form-label-exep" : "form-label"
        }
      >
        {labelText} <span>{spanText}</span>
      </label>
    </div>
  );
};

export default FormInput;
