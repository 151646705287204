import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../../Layout/Header/Header';
import Footer from '../../Layout/Footer/Footer';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import Navbar from '../../Layout/Navbar/Navbar';
import { useWindowSize } from '../../components/WindowSize/WindowSize';
import config from '../../config/api.config.js';

import './agb.css';

const AGB = () => {
  const [width, height] = useWindowSize();

  return (
    <div className="agb">
      <div>
        <Helmet>
          <title>Autoversand | AGB</title>
        </Helmet>
        <Navbar />
        <Header />
        <main className="main__about">
          <div className="main__sections">
            <section>
              <SectionHeader>
                Geltungsbereich, Nutzungseinschränkung und Widerrufsbelehrung
              </SectionHeader>
              <ol className="paragraph__list fontStyle__paragraph">
                <li>
                  Die vorliegenden AGB gelten für die Nutzung der
                  Online-Plattform (kurz: AUTOVERSAND, www.autoversand.com und
                  der dazugehörenden Sub-Domains) und die daraus resultierenden
                  Anwendungen. Die Website ermöglicht dem Nutzer, die bereit
                  gestellten Inhalte und Funktionen auf Basis dieser AGB und der
                  jeweils geltenden rechtlichen Vorschriften zu nutzen.
                  Entgegenstehende AGB gelten nur, wenn sich Two Dots Digital
                  GmbH diesen ausdrücklich und schriftlich unterworfen hat. Two
                  Dots Digital behält sich vor, diese AGB jederzeit und ohne
                  Angabe von Gründen abzuändern. Der Nutzer wird auf die
                  Änderung durch Zusendung einer E-Mail aufmerksam gemacht; bei
                  weiterer Nutzung der AUTOVERSAND-Dienste gilt die Zustimmung
                  zu den geänderten AGB als erteilt. Jedem Besucher ist ein
                  Zugriff auf online gestellte Fotos- und Rankings möglich.
                </li>
                <li>
                  Für Verbraucher im Sinne des Konsumentenschutzgesetzes gilt
                  folgendes Widerrufsrecht (Widerrufsbelehrung):
                  <ol className="paragraph__list--nested">
                    <li>
                      Der Verbraucher hat das Recht, binnen vierzehn Tagen ohne
                      Angabe von Gründen Verträge zu widerrufen. Die
                      Widerrufsfrist beträgt vierzehn Tage ab dem Tag des
                      Vertragsabschlusses. Um das Widerrufsrecht auszuüben, muss
                      der Nutzer Two Dots Digital mittels einer eindeutigen
                      Erklärung (z. B. ein mit der Post versandter Brief,
                      Telefax oder E-Mail) über den Entschluss, diesen Vertrag
                      zu widerrufen, informieren (
                      <a
                        href={`${config.assetUrl}/media/widerrufsformular.pdf`}
                        style={{ color: '#2699fb' }}
                      >
                        Widerufsformular
                      </a>
                      ). Zur Wahrung der Widerrufsfrist reicht es aus, dass der
                      Nutzer die Mitteilung über die Ausübung des
                      Widerrufsrechts vor Ablauf der Widerrufsfrist schriftlich
                      an Two Dots Digital GmbH, Neue Heimat 22, 4664 Laakirchen,
                      e-mail: office@autoversand.com sendet. Ab dem 15. Tag wird
                      bei Widerruf ein Betrag in der Höhe von 50% des
                      angenommenen Angebots bzw. abgeschlossenen Vertrags
                      einbehalten, nach weiteren 7 Tagen bzw. in Summe 21 Tagen
                      nach Vertragsabschluss werden 100% einbehalten. Das Recht
                      zur Kündigung aus wichtigem Grund bleibt für beide
                      Parteien unberührt. Löscht der Nutzer seinen Account, so
                      gilt, dass die abgeschlossenen Verträge in jedem Fall als
                      vollständig konsumiert, auch wenn die Verträge
                      zeitabhängig sind und deren Dauer noch nicht vollständig
                      abgelaufen ist. Wird binnen 14 Tagen die Leistung
                      vollständig erbracht, ist das Leistungsentgelt zu 100% zu
                      begleichen.
                    </li>
                    <li>
                      Wenn der Nutzer diesen Vertrag widerruft, hat ihm Two Dots
                      Digital alle Zahlungen, die sie von ihm erhalten hat,
                      unverzüglich und spätestens binnen vierzehn Tagen ab dem
                      Tag zurückzuzahlen, an dem die Mitteilung über den
                      Widerruf dieses Vertrages bei Two Dots Digital eingegangen
                      ist. Für diese Rückzahlung verwendet Two Dots Digital
                      dasselbe Zahlungsmittel, das der Nutzer bei der
                      ursprünglichen Transaktion eingesetzt hat. Hat der Nutzer
                      verlangt, dass die Dienstleistungen während der
                      Widerrufsfrist beginnen sollen, so hat er einen
                      angemessenen Betrag zu zahlen, der dem Anteil der bis zu
                      dem Zeitpunkt, zu dem Two Dots Digital von der Ausübung
                      des Widerrufsrechts hinsichtlich dieses Vertrages
                      unterrichtet wird, bereits erbrachten Dienstleistungen im
                      Vergleich zum Gesamtumfang der im Vertrag vorgesehenen
                      Dienstleistungen entspricht.
                    </li>
                  </ol>
                </li>
                <li>
                  Two Dots Digital bemüht sich, dass AUTOVERSAND ohne
                  Unterbrechung verfügbar ist, und Übermittlungen fehlerfrei
                  sind. Es kann jedoch nicht gewährleistet werden, dass
                  AUTOVERSAND zu jeder Zeit technisch einwandfrei funktioniert.
                  Der Zugriff auf AUTOVERSAND kann gelegentlich unterbrochen
                  oder beschränkt sein, um Instandsetzungen, Wartungen oder die
                  Einführung von neuen Einrichtungen, Funktionalitäten oder
                  Services zu ermöglichen. Informationen, die der Nutzer auf
                  AUTOVERSAND vorfindet, werden direkt von Nutzern in das
                  AUTOVERSAND -System eingespielt; die Nutzer sind für diese
                  verantwortlich. Die auf AUTOVERSAND enthaltenen Inhalte dienen
                  lediglich zu Informationszwecken und sind rechtlich
                  unverbindlich. Die Angaben für ein Autotransportangebot
                  entsprechen den zum Zeitpunkt der Dateneingabe vorhandenen
                  Kenntnissen des jeweiligen Nutzers. Insoweit sind Änderungen
                  ausdrücklich vorbehalten. Two Dots Digital kann die Nutzung
                  von AUTOVERSAND oder einzelner Funktionen daraus an bestimmte
                  Voraussetzungen knüpfen
                </li>
                <li>
                  Eine Überprüfung der bei der Anmeldung hinterlegten Daten
                  führt Two Dots Digital nur begrenzt durch, da die
                  Identifizierung von Personen im Internet nur eingeschränkt
                  möglich ist. Trotz verschiedenartiger Sicherheitsvorkehrungen
                  ist es daher nicht auszuschließen, dass für ein
                  AUTOVERSAND-Konto falsche Kontaktdaten hinterlegt wurden, bzw.
                  diese sich zwischenzeitlich geändert haben.
                </li>
              </ol>
            </section>
            <section>
              <SectionHeader>
                Leistungsbeschreibung von Two Dots Digital
              </SectionHeader>
              <ol className="paragraph__list fontStyle__paragraph">
                <li>
                  Two Dots Digital bietet als Betreiber von AUTOVERSAND einen
                  Marktplatz an, über den Autotransporte vermittelt werden. Two
                  Dots Digital ermöglicht Transaktionen zwischen den Nutzern,
                  ohne selbst Transportdienstleistungen anzubieten, agiert
                  ausschließlich als Vermittler und wird nicht Vertragspartner
                  der ausschließlich zwischen den Nutzern geschlossenen
                  Verträge. Der Vertrag wird zwischen den Nutzern geschlossen,
                  wobei Two Dots Digital keinerlei Verantwortung für den Vertrag
                  übernimmt.
                </li>
              </ol>
              <dl className="paragraph__list fontStyle__paragraph">
                <dt>2. Definition</dt>
                <dd>
                  Autoversender bzw. der Kunde ist beschrieben als eine Person
                  oder Firma, welche die Beförderung von Fahrzeugen im eigenen
                  Namen arrangiert. Der Autoversender kann der Empfänger, der
                  Versender oder ein Dritter sein. Der Transportdienstleister
                  ist eine Person, ein Agent oder Frachtführer, welche/r
                  Fahrzeugtransporte und damit verbundene Dienstleistungen an
                  einen Autoversender liefert. (Verlinkung
                  Güterbeförderungsgewerbe:
                  https://www.wko.at/branchen/transport-verkehr/gueterbefoerderungsgewerbe/start.html)
                  <br />
                  Erfolgsgebühr: die Erfolgsgebühr ist bei erfolgreichem
                  Vertragsabschluss vom Transportdienstleister zu begleichen.
                  Die Höhe der Erfolgsgebühr finden Sie unter Punkt 7
                  PREISPOLITIK UND PREISE.
                </dd>
                <dt>3. AUTOVERSAND</dt>
                <dd>
                  ist ein Marktplatz und kein Transportdienstleister. Unsere
                  Internetseite stellt einen Marktplatz dar, auf dem
                  Autoversender und Transportdienstleister sich treffen und
                  Verträge abschließen können. Wir sind nicht an der
                  eigentlichen Transaktion und dem tatsächlichen Vertrag
                  zwischen Versandkunden und Transportdienstleistern beteiligt.
                  Daher haben wir keinen direkten Einfluss auf die Qualität, die
                  Sicherheit oder auf rechtliche Aspekte der Transaktionen, die
                  auf unserer Internetseite bzw. unserem Marktplatz stattfinden.
                  AUTOVERSAND ist der Marktplatz, wo sich die beiden Parteien
                  finden und zu einem vereinbarten Preis eine bestimmte
                  Fahrzeugtransportleistung vereinbaren können. Wir verwenden
                  Verfahren zum Nachweis der Identität von
                  Transportdienstleistern, wenn diese sich auf unserer
                  Internetseite registrieren; wir sind nicht in der Lage, die
                  Identität jedes Spediteurs zu bestätigen. Bei Unklarheiten und
                  Fragen können Sie jederzeit mit uns Kontakt aufnehmen.
                  (info@autoversand.com - email / WhatsApp / ...)
                </dd>
                <dt>4. Registrierung</dt>
                <dd>
                  Unsere Dienstleistungen stehen nur geschäftsfähigen Personen
                  und Unternehmen zur Verfügung, die unter Anwendung gültigem
                  rechtsbindende Verträge abschließen können. Als
                  Transportdienstleister müssen Sie die erforderlichen
                  Berechtigungen inklusive einer gültigen Lizenz zur Nutzung
                  Ihrer Fahrzeuge haben. Sie stimmen zu, dass die Internetseite
                  AUTOVERSAND Automobil-Transportdienstleistungen dient.
                </dd>
              </dl>
              <ol className="paragraph__list paragraph__list--exeption fontStyle__paragraph">
                <li>
                  Autoversender verpflichten sich, die Transaktion mit dem
                  Transportdienstleister durchzuführen, indem das Angebot des
                  Transportdienstleisters angenommen bzw. akzeptiert haben. Sie
                  stimmen zu, indem Sie ein Angebot eines
                  Transportdienstleisters akzeptieren, und sind damit an die
                  enthaltenen Bedingungen des Angebots gebunden, außer es
                  widerspricht unseren AGB und bestehenden Gesetzen. Die Annahme
                  kann grundsätzlich nicht zurückgezogen werden, es sei denn:
                  (1) der Transportdienstleister ändert sein Angebot wesentlich,
                  nachdem Sie es akzeptiert haben; (2) es liegt ein
                  Schreibfehler vor; oder (3) Sie sind nicht in der Lage, die
                  Identität des Transportdienstleisters zu bestätigen (Siehe
                  Punkt 12. Stornierung.). Der Transportdienstleister behält
                  sich das Recht vor, bei der Übernahme vor Ort den Autoversand
                  vor Lieferung auf Rechtmäßigkeit inklusive der geforderten
                  Dokumente zu überprüfen. Es wird empfohlen, sich mit dem
                  Transportdienstleister abzustimmen und bestätigen zu lassen,
                  dass die Autoversanddienstleistung wie vereinbart durchgeführt
                  werden kann. WICHTIG: AUTOVERSAND führt keine aktive
                  Überprüfung oder Vorqualifizierung von Transportdienstleistern
                  im Hinblick auf die Befolgung geltender Gesetze oder
                  Rechtsvorschriften durch. Wir empfehlen, dass Sie sich die
                  Einhaltung solcher Rechtsvorschriften direkt vom
                  Transportdienstleister bestätigen lassen, bevor dieser die
                  Autotransport Serviceleistungen durchführt. Benutzerkonten
                  können jederzeit mithilfe eines Formulars, schriftlich
                  gekündigt werden. Two Dots Digital kann jederzeit ohne
                  Information einseitig das Benutzerkonto löschen, wenn dieses
                  rechtswidrig verwendet wird. (
                  <a
                    href={`${config.assetUrl}/media/mitteilungsformular.pdf`}
                    style={{ color: '#2699fb' }}
                  >
                    Mitteilungsformular
                  </a>
                  )
                </li>
              </ol>
              <dl className="paragraph__list fontStyle__paragraph">
                <dt>5. Angebotsmodus</dt>
                <dd>
                  Die Transportanfrage wird vom Autoversender vollständig und
                  genau auf der Autoversand Plattform definiert. Die Anfrage ist
                  4 Werktage auf der Plattform sichtbar, die
                  Transportdienstleister können in dieser Zeit Angebote stellen
                  Der Angebotsmodus erlischt durch: Ablauf der 4 Werktage First,
                  Löschung der Anfrage durch den Autoversender bzw.
                  Plattformbetreibe und Angebotsannahme.
                </dd>
                <dt>6. Auftragsmodus</dt>
                <dd>
                  Nach Auswahl des Transportdienstleisters durch den
                  Autoversender und beidseitiger Willenserklärung, entsteht der
                  gültige Auftrag. Beide Parteien bekommen eine schriftliche
                  Auftragsbestätigung und können via Chat weitere Details zur
                  Abholung definieren.
                </dd>
                <dt>7. Transportdienstleister</dt>
                <dd>
                  Es ist erforderlich, dass Sie gesetzlich dazu in der Lage
                  sind, die Autoversand Dienstleistungen, die Sie anbieten, zu
                  transportieren. Sie stimmen zu, dass es in Ihrer Verantwortung
                  liegt, sämtliche gültigen Gesetze und Rechtsvorschriften
                  einzuhalten und zu befolgen. Sie haben die Verpflichtung, Ihr
                  Angebot und sämtliche Vertragsbedingungen für Ihre
                  Serviceleistungen in dem auf unserer Seite abgegebenen alles
                  inklusive Angebot zu beschreiben. Für den Fall, dass Sie
                  „zusätzliche Gebühren“ außerhalb des akzeptierten Angebots in
                  Rechnung stellen, und wir davon Kenntnis erlangen, behält sich
                  AUTOVERSAND das Recht vor, Ihr Konto zu sperren oder zu
                  stornieren. Sie stimmen zu, dass AUTOVERSAND eine
                  Vermittlungsgebühr nach Annahme des Autoversandangebots bzw.
                  Vertragsabschlusses und erfolgreicher Lieferung abbucht. (
                  <a
                    href={`${config.assetUrl}/media/reklamationsformular.pdf`}
                    style={{ color: '#2699fb' }}
                  >
                    Vermittlungsgebühren nach Registrierung des
                    Transportdienstleisters, bzw. nach individuellen Vertrag
                  </a>
                  ).
                </dd>
                <dt>8. Preispolitik und Preise</dt>
                <dd>
                  AUTOVERSAND setzt keine Preise fest, die von Autoversendern
                  oder Transportdienstleistern angeboten werden. Angebote von
                  Transportdienstleistern sind alles-inklusive Angebote mit
                  allen anfallenden Kosten auf Basis der Informationen des
                  Autoversenders. Wenn die tatsächlichen Transportdetails
                  wesentlich von den vom Autoversender bzw. Kunden angegebenen
                  abweichen, und wesentliche Änderungen notwendig sind, dann
                  können Transportdienstleister einen anderen Betrag als das
                  abgegebene Angebot in Rechnung stellen, und der Autoversender
                  ist für alle anfallenden Kosten verantwortlich. Sollte es
                  grobe Abweichungen geben, kontaktieren Sie unser 24h
                  Kundenservice. office@autoversand.com
                </dd>
                <dt>9. Bewertungssystem </dt>
                <dd>
                  Autoversand stellt den Benutzern ein Bewertungssystem zur
                  Verfügung das den Austausch von Bewertungen ermöglicht. Nach
                  erfolgreicher Auftragsabwicklung haben die Plattformnutzer die
                  Möglichkeit diese zu bewerten. Erhaltene und abgegebene
                  Bewertungen werden auf der Profilseite des Benutzers allen
                  Besuchern von Autoversand öffentlich angezeigt. Der Benutzer
                  veröffentlicht seine Aussagen freiwillig. Mit der
                  Veröffentlichung der Inhalte stimmt der Benutzer deren
                  Publikation und Verbreitung auf Autoversand zu.
                  <ol>
                    <li>
                      Die Bewertung kann positiv, negativ oder neutral ausfallen
                      und wird dem jeweiligen Benutzerkonto und Auftrag
                      zugeordnet.
                    </li>
                    <li>
                      Die Bewertungen sollten in Übereinstimmung mit dem
                      geltenden Recht sein, der Wahrheit entsprechen, leserlich
                      sein und dürfen nicht gegen Rechte Dritter verstoßen. So
                      lange die Inhalte nicht illegal, obszön, beleidigend,
                      bedrohend, diffamierend, in die Privatsphäre eindringend,
                      rechtsverletzend sind oder anderweitig Dritte verletzen
                      oder unzulässig sind und nicht aus Softwareviren,
                      politischen Kampagnen, werblicher Ansprache,
                      Kettenbriefen, Massensendungen oder jegliche Form von
                      "Spam" besteht oder dies enthält. Sie dürfen keine falsche
                      E-Mailadresse verwenden, sich als irgendeine andere Person
                      oder Gesellschaft ausgeben oder anderweitig über die
                      Herkunft einer Bank- oder Kreditkarte oder anderer Inhalte
                      täuschen.
                    </li>
                    <li>
                      In berechtigten Fällen können Bewertungen gelöscht oder
                      der Benutzer zur Bewertungsänderung aufgefordert werden.
                      Dies erfolgt, wenn:
                    </li>
                    <li>
                      Die Bewertung unabsichtlich dem falschen Benutzer oder zur
                      falschen Transportdienstleistung ausgestellt wurde, die
                      gleiche Bewertung bereits dem richtigen Benutzer
                      ausgestellt wurde und der Inhalt eindeutig auf einen
                      Irrtum hinweist
                    </li>
                    <li>
                      Der Bewertungsinhalt eindeutig auf einen Irrtum des
                      Bewertungstyps hinweist (positiv, negativ oder neutral)
                    </li>
                    <li>
                      Die Bewertung Zeichen beinhaltet, die die Bewertungsliste
                      unlesbar machen.
                    </li>
                    <li>
                      Die Bewertung ist bis spätestens 10 Arbeitstage nach
                      erfolgreicher Auftragsabwicklung durchzuführen.
                    </li>
                    <li>
                      Nach Löschung der Bewertung ist eine erneute Einstellung
                      der Bewertung zum gleichen Angebotsmodus nicht möglich.
                      Das obige Widerrufsverfahren der Bewertungen kann nur
                      einmal zur jeweiligen Transaktion eingeleitet werden. (
                      <a
                        href={`${config.assetUrl}/media/mitteilungsformular.pdf`}
                        style={{ color: '#2699fb' }}
                      >
                        Mitteilungsformular
                      </a>
                      )
                    </li>
                    <li>
                      Falsche Bewertungen, die nicht der Wahrheit entsprechen
                      und nur ausgestellt wurden, um die Anzahl der positiven
                      Bewertungen und die Vertrauenswürdigkeit zu erhöhen, sind
                      nicht erlaubt.
                    </li>
                  </ol>
                </dd>
                <dt>10. Benutzer-Nachrichtensystem</dt>
                <dd>
                  <ol>
                    <li>
                      Die Plattformbenutzer haben die Möglichkeit, sich durch
                      das Benutzer-Nachrichtensystem auf Autoversand zu
                      kontaktieren (betreffend der Auftragsabwicklung) Die
                      Nachrichten werden in Form eines Dialoges, automatisch auf
                      der persönlichen Seite des jeweiligen Benutzers platziert
                      und sind öffentlich nicht ersichtlich. Der
                      Nachrichtenempfänger wird jedes Mal über eine neue
                      Nachricht per Messenger Dienste informiert. Im
                      Angebotsmodus ist den Parteien keine Absprache erlaubt.{' '}
                    </li>
                    <li>
                      Der Nachrichtenaustausch zwischen Transportanbieter und
                      Versandkunde dient zur Klärung der detaillierten
                      Versandvereinbarung. Die Benutzer haben die Möglichkeit
                      den Vertrag mittels Widerrufformular (
                      <a
                        href={`${config.assetUrl}/media/widerrufsformular.pdf`}
                        style={{ color: '#2699fb' }}
                      >
                        Widerufsformular
                      </a>
                      ) zu widerrufen, wenn die Daten (Autotyp, Lieferadresse,
                      Preis, etc.) gravierend vom Angebot abweichen sollten. Das
                      Angeben von Kontaktdaten und sonstigen Inhalten in den
                      Nachrichten ist streng verboten und führt zur Sperrung
                      bzw. zur Löschung des Benutzeraccounts.{' '}
                    </li>
                    <li>
                      Der Benutzer haftet für den von sich veröffentlichten
                      Nachrichteninhalt.
                    </li>
                    <li>
                      Nachrichten, die gegen diese Allgemeinen
                      Geschäftsbedingungen verstoßen, werden gelöscht.
                    </li>
                  </ol>
                </dd>
                <dt>11. Zahlung</dt>
                <dd>
                  Der Autoversender bezahlt den vollen Betrag über die
                  verschiedenen Zahlungsmöglichkeiten des Marktplatzes. Sobald
                  die Zahlung erfolgt ist und dem Konto gutgeschrieben, kann der
                  Autoversand durchgeführt werden. Der Transportdienstleister
                  wird darüber informiert, dass die Zahlung eingetroffen ist.
                  Nach erfolgreich durchgeführter Autoüberstellung und Freigabe
                  vom Autoversender oder von ihm beauftragten Dritten (wird von
                  beiden Seiten mittels der Autoversand App z.B. QR Code
                  freigegeben) und Auslieferungsbestätigung vom
                  Transportdienstleister wird der vereinbarte Zahlungsbetrag an
                  den Transportdienstleister minus Dienstleistungsentgelt
                  überwiesen. Der Autoversender stimmt zu, dass die Zahlung an
                  den Transportdienstleister bei Lieferung des Transports
                  freizugeben ist, unabhängig von Schäden, Verzögerungen oder
                  anderen Problemen, die während des Transports aufgetreten
                  sind. AUTOVERSAND wird wirtschaftlich vertretbare Bemühungen
                  unternehmen, um eine ordnungsgemäße Auszahlung zwischen
                  Autoversendern und Transportdienstleistern sicherzustellen;
                  und AUTOVERSAND haftet nicht für Lieferverzögerungen, Schäden
                  während des Transports oder anderen Serviceproblemen. Sollten
                  bei der Auszahlung des Autoversandbetrags an den
                  Transportdienstleister Bankgebühren oder andere Spesen des
                  Zahlungsverkehrs anfallen, sind diese vom Zahlungsempfänger zu
                  tragen. (
                  <a
                    href={`${config.assetUrl}/media/mitteilungsformular.pdf`}
                    style={{ color: '#2699fb' }}
                  >
                    Mitteilungsformular
                  </a>
                  )
                </dd>
                <dt>12. Stornierung</dt>
                <dd>
                  Grundsätzlich ist eine Stornierung durch den Autoversender
                  nach Vertragsabschluss, der durch die Zahlung zustande kommt,
                  NICHT möglich. Ausnahmen sind, wenn das zu transportierende
                  Fahrzeug nachweislich zerstört ist, oder rechtliche bzw.
                  sittenwidrige Umstände eingetreten sind. Die Anerkennung einer
                  Stornierung erfolgt nur in schriftlicher Form von Seiten
                  AUTOVERSAND. (WIDERRUFSFORMULAR) Für die Handling und
                  Abwicklungskosten der Stornierung behält sich AUTOVERSAND
                  einen Minimumbetrag von 15% des Auftragswertes ein. Der
                  Transportdienstleister kann nach Annahme seines
                  Transportangebots den Fahrzeugtransport grundsätzlich nicht
                  mehr stornieren. Sollte der Transportdienstleister den
                  Transport nicht durchführen können, muss er einen
                  Ersatzdienstleister nominieren, der zu den gleichen
                  Konditionen den Autotransport durchführt. Sollte das nicht der
                  Fall sein, werden dem Transportdienstleister pauschal 500 Euro
                  Handling Kosten und die Differenz des durchgeführten
                  Transportauftrages eines weiteren Transportdienstleisters in
                  Rechnung gestellt. AUTOVERSAND behält sich vor, bei
                  übergebührenden Stornierungen die betroffenen Parteien vom
                  Marktplatz auszuschließen.
                </dd>
                <dt>13. Außerordentliche Stornierung</dt>
                <dd>
                  Ist nur unter folgenden Aspekten erlaubt: Abweichungen der
                  Daten (Autotyp, Lieferadresse, Abholadresse, Preis, etc.) Eine
                  außerordentliche Stornierung erfordert eine Schriftform
                  (WIDERRUFSFORMULAR) und die ausdrückliche Zustimmung von
                  Autoversand.
                </dd>
              </dl>
            </section>
            <section>
              <SectionHeader>
                Supportleistungen von Two Dots Digital
              </SectionHeader>
              <p className="fontStyle__paragraph">
                Two Dots Digital bietet den Nutzern folgenden Nutzersupport an:
                24h Kundenservice (office@autoversand.com), Messengerdienste
                (VIBER, WHATSAPP), Mitteilungsformular, Reklamationsformular und
                Widerrufsformular.
              </p>
            </section>
            <section>
              <SectionHeader>Registrierung durch den Nutzer</SectionHeader>
              <ol className="paragraph__list fontStyle__paragraph">
                <li>
                  Bei der Registrierung hat der Nutzer seinen Namen,
                  gegebenenfalls seine Anschrift, seine Telefonnummer, seine
                  E-Mail-Adresse sowie ein Passwort anzugeben. Der Nutzer hat
                  die Angaben auf dem Registrierungsformular wahrheitsgemäß zu
                  machen und bei Änderungen die Angaben unverzüglich zu
                  aktualisieren. Jeder Nutzer darf sich nur einmal bei
                  AUTOVERSAND registrieren. Registrierungen sind nicht
                  übertragbar und dürfen nur von dem registrierten Nutzer
                  verwendet werden. Des Weiteren gibt es die Möglichkeit, sich
                  mit einem vorhandenen Facebook-Profil bzw. einem Google
                  Account anzumelden. LINK: Datenschutzrichtlinie:{' '}
                  <a
                    href="https://developers.facebook.com/terms/"
                    style={{ color: '#2699fb' }}
                  >
                    Facebook
                  </a>{' '}
                  /
                  <a
                    href="https://developers.google.com/terms"
                    style={{ color: '#2699fb' }}
                  >
                    {' '}
                    Google API
                  </a>
                </li>
                <li>
                  Die E-Mail-Adresse und das Passwort sind die Zugangsdaten für
                  die Nutzung von AUTOVERSAND. Der Nutzer hat sein Passwort
                  geheim zu halten und darf dieses nicht an Dritte weitergeben.
                  Sollten Dritte vom Passwort des Nutzers Kenntnis erlangen, so
                  hat der Nutzer dieses umgehend bei Two Dots Digital zu melden
                  und das Passwort zu ändern.
                </li>
                <li>
                  Two Dots Digital behält sich vor, Registrierungen, bei welchen
                  sich der Nutzer länger als 12 Monate nicht auf AUTOVERSAND
                  angemeldet hat oder nicht vollständig durchgeführten
                  Registrierungen, zu löschen.
                </li>
                <li>
                  Two Dots Digital ist berechtigt, Fotos und erfolgreich
                  durchgeführte Transporte für Social Media Zwecke zu verwenden.
                </li>
                <li>
                  Für die bei der Registrierung erhobenen personenbezogenen
                  Daten des Nutzers gelten unsere Hinweise in der
                  Datenschutzerklärung.
                </li>
              </ol>
            </section>
            <section>
              <SectionHeader>Nutzung der Dienste von AUTOVERSAND</SectionHeader>
              <ol className="paragraph__list fontStyle__paragraph">
                <li>
                  Unter der Voraussetzung der Einhaltung dieser AGB gewährt Two
                  Dots Digital den Nutzern die beschränkte, einfache, nicht
                  übertragbare Nutzungserlaubnis der Dienste von AUTOVERSAND und
                  der AUTOVERSAND verlinkten Dienste.
                </li>
                <li>
                  Der Nutzer darf AUTOVERSAND-Dienste nicht verwenden (a) in
                  einer Weise, die dazu geeignet ist, AUTOVERSAND-Dienste oder
                  den Zugang dazu zu unterbrechen, zu beschädigen oder in
                  sonstiger Art zu beeinträchtigen, oder (b) für betrügerische
                  Zwecke in Verbindung mit einer Straftat oder einer
                  rechtswidrigen Aktivität oder (c) um Belästigung,
                  Unannehmlichkeiten oder Angst zu verursachen.
                </li>
                <li>
                  Der anbietende Nutzer räumt Two Dots Digital ein, nicht
                  ausschließliches, gebührenfreies, unwiderrufliches und
                  vollständig unterlizenzierbares Recht ein, die eingebrachten
                  Daten in beliebigen Medien zu verwenden, zu reproduzieren, zu
                  verändern, zu modifizieren, zu veröffentlichen, zu übersetzen
                  und abgeleitete Werke daraus zu erstellen, sie zu verteilen
                  und darzustellen, solange der Nutzer nach dem geltenden Recht
                  zur Erteilung einer entsprechenden Lizenz berechtigt ist. Der
                  Nutzererklärt und versichert, dass er im Besitz der Rechte an
                  den von ihm eingereichten Inhalten ist bzw. berechtigt ist,
                  über die Rechte hieran zu verfügen, dass die Inhalte korrekt
                  sind, dass die Verwendung der von ihm eingereichten Inhalte
                  nicht gegen diese Nutzungsbestimmungen verstößt und hierdurch
                  keine natürliche oder juristische Person geschädigt wird, und
                  dass er Two Dots Digital gegen sämtliche aufgrund der von ihm
                  eigereichten Inhalten vollkommen schad- und klaglos hält.
                </li>
                <li>
                  Der Vertrag für gewerbliche Kunden bzw. Transportdienstleister
                  wird nach Registrierung auf 6 Monate befristet. Nach einer
                  erfolgreichen 6-monatigen Zusammenarbeit wird durch ein
                  Prüfungsverfahren unter Berücksichtigung der
                  Qualitätsrichtlinien von Autoversand, eine nochmalige
                  Überprüfung des Status gemacht. Nach einem positiven Bescheid
                  wird das Vertragsverhältnis in ein unbefristetes umgewandelt
                  und der Transportdienstleister ist offizieller Partner von
                  Autoversand (dies erscheint auch öffentlich). Die
                  halbjährlichen Überprüfungen sind verpflichtend und sollten
                  den weiteren Verbesserungen unserer Dienstleistungen dienen.
                  Der Transportdienstleister ist verpflichtet etwaige Änderungen
                  (Firmenname, Steuernummer, Lizenzen, etc.) selbstständig und
                  unaufgefordert, schriftlich an Autoversand bekannt zu geben.
                  (office@autoversand.com,{' '}
                  <a
                    href={`${config.assetUrl}/media/mitteilungsformular.pdf`}
                    style={{ color: '#2699fb' }}
                  >
                    Mitteilungsformular
                  </a>
                  )
                </li>
                <li>
                  Der Vertrag beginnt nach Einreichung aller geforderten
                  Unterlagen und Unterzeichnung sofort. Es gilt bei den
                  unbefristeten Verträgen ein beiderseitiges ordentliches
                  Kündigungsrecht von einem Monat zum Quartal als vereinbart,
                  bei Verträgen mit Befristung gilt ein beiderseitiges
                  Kündigungsrecht von einem Monat zum Ende des letzten Monats
                  der Laufzeit. Das Recht zur außerordentlichen Kündigung bleibt
                  unberührt.
                </li>
              </ol>
            </section>
            <section>
              <SectionHeader>Sanktionen, Sperrung und Kündigung</SectionHeader>
              <ol className="paragraph__list fontStyle__paragraph">
                <li>
                  Two Dots Digital kann ihren Service einschränken oder beenden,
                  Nutzer verwarnen und vorläufig oder dauerhaft sperren, wenn
                  konkrete Anhaltspunkte dafür vorliegen, dass ein Nutzer
                  gesetzliche Vorschriften, Rechte Dritter oder diese
                  Nutzungsbedingungen verletzt.
                </li>
                <li>
                  Two Dots Digital kann folgende Maßnahmen ergreifen, wenn
                  konkrete Anhaltspunkte bestehen, dass ein Nutzer gesetzliche
                  Vorschriften, Rechte Dritter, diese Nutzungsbedingungen oder
                  Two Dots Digital ein sonstiges Interesse hat, insbesondere zum
                  Schutz vor Nutzern vor betrügerischen Aktivitäten:
                  <br />
                  Löschen von Angeboten und Aufträgen
                  <br />
                  Verwarnung von Nutzern;
                  <br />
                  Einschränkung der Nutzung der AUTOVERSAND-Dienste;
                  <br />
                  temporäre Sperre des Nutzers oder
                  <br />
                  endgültige Sperre des Nutzers.
                </li>
                <p>
                  Bei der Wahl einer Maßnahme berücksichtigt Two Dots Digital
                  die berechtigten Interessen des betroffenen Nutzers,
                  insbesondere ob Anhaltspunkte dafür vorliegen, dass der Nutzer
                  den Verstoß nicht verschuldet hat.
                </p>
                <li>
                  Two Dots Digital kann einen Nutzer endgültig von der Nutzung
                  der AUTOVERSAND-Dienste ausschließen (endgültige Sperre), wenn
                  <br />
                  er falsche Kontaktdaten angegeben hat;
                  <br />
                  er sein AUTOVERSAND-Konto überträgt oder Dritten hierzu Zugang
                  gewährt;
                  <br />
                  er andere Nutzer oder Two Dots Digital in erheblichem Maße
                  schädigt;
                  <br /> er wiederholt gegen diese AGB verstößt
                  <br /> er der Verpflichtung nicht nachkommt, die
                  Qualitätsrichtlinien- und die wiederkehrenden halbjährlichen
                  Überprüfungen durchzuführen,
                  <br /> oder ein anderer wichtiger Grund vorliegt.
                  <br /> Nach Sperre des Nutzers, besteht kein Anspruch auf
                  Wiederherstellung des gesperrten Profils des Nutzers.
                </li>
                <li>
                  Nutzer können diese AGB jederzeit, jedoch unter Einhaltung
                  einer 14-tägigen Kündigungsfrist zum Monatsletzten, kündigen.
                  Eine Kündigung aus wichtigen Gründen bleibt hiervon
                  unbetroffen. Nach Ablauf dieser Frist wird das Konto des
                  Nutzers gelöscht. (
                  <a
                    href={`${config.assetUrl}/media/mitteilungsformular.pdf`}
                    style={{ color: '#2699fb' }}
                  >
                    Mitteilungsformular
                  </a>
                  )
                </li>
                <li>
                  Two Dots Digital kann die AGB mit einer Frist von 14 Tagen zum
                  Monatsende kündigen. Das Recht zur Sperrung bleibt hiervon
                  unberührt. Eine Kündigung aus wichtigen Gründen bleibt hiervon
                  unbetroffen.
                </li>
                <li>
                  Sobald ein Nutzer gesperrt oder die AGB seitens Two Dots
                  Digital gekündigt wurden, ist der Nutzer nicht mehr
                  berechtigt, die AUTOVERSAND-Dienste mit anderen
                  AUTOVERSAND-Konten zu nutzen oder sich neu anzumelden.
                </li>
                <li>
                  Mündliche Nebenabreden bestehen nicht. Allfällige Änderungen
                  und Ergänzungen bedürfen jedenfalls der Schriftform; dies gilt
                  auch für Nebenabreden und Zusicherungen sowie nachträgliche
                  Vertragsänderungen wie auch das Abgehen von dem
                  Schriftformerfordernis. Als Schriftform sind E-Mail
                  (office@autoversand.com) und die zur Verfügung gestellten
                  Formulare (
                  <a
                    href={`${config.assetUrl}/media/reklamationsformular.pdf`}
                    style={{ color: '#2699fb' }}
                  >
                    LINK
                  </a>
                  ) zulässig.
                </li>
              </ol>
            </section>
            <section>
              <SectionHeader>Haftung und Gewährleistung</SectionHeader>
              <ol className="paragraph__list fontStyle__paragraph">
                <li>
                  Two Dots Digital als reiner Online-Plattformbetreiber trifft
                  keine Verantwortung für die von den Nutzern auf der
                  AUTOVERSAND-Plattform zugänglich gemachten Inhalte.
                </li>
                <li>
                  Two Dots Digital haftet daher auch für keinen bestimmten
                  Erfolg oder für die Durchsetzbarkeit eines auf der
                  AUTOVERSAND-Plattform geschlossenen Vertrages. Two Dots
                  Digital als reiner Plattformbetreiber trifft keine wie auch
                  immer geartete Haftung für auf AUTOVERSAND angebotene
                  Dienstleistung, weder aus Gewährleistung, Garantie noch aus
                  Schadenersatz-, Dienstleistungshaftung, rechtlichen
                  Rahmenbedingungen oder ähnlichem.
                </li>
                <li>
                  Two Dots Digital haftet, soweit die Einschränkung gesetzlich
                  zulässig ist, nur in Fällen, in denen die Schadensursache auf
                  einer vorsätzlichen oder grob fahrlässigenPflichtverletzung
                  von Two Dots Digital oder eines gesetzlichen Vertreters oder
                  Erfüllungsgehilfen von Two Dots Digital beruht. Die Haftung
                  ist überdies auf die Beseitigung des Mangels eingeschränkt;
                  die Haftung für mittelbare Schäden, Mangelfolgeschäden und
                  entgangenen Gewinn ist im weitest gesetzlich möglichen Ausmaß
                  ausgeschlossen. Eine Haftung von Two Dots Digital aufgrund
                  höherer Gewalt ist jedenfalls ausgeschlossen.
                </li>
                <li>
                  Der Nutzer nutzt die AUTOVERSAND-Plattform nur im Rahmen der
                  vertraglichen oder gesetzlichen Bestimmungen und ohne
                  Verletzung von Rechten Dritter. Insbesondere wird der Nutzer
                  keine Leistungsschutzrechte wie insbesondere Urheberrechte,
                  Wettbewerbsrechte, Markenrechte oder sonstige Dritten
                  zustehende Rechte ohne deren ausdrückliche Zustimmung nutzen
                  bzw. verarbeiten, bearbeiten und/oder löschen.
                </li>
                <li>
                  Der Nutzer hält Two Dots Digital von etwaigen Ansprüchen
                  Dritter, die in seinen Verantwortungsbereich fallen,
                  vollkommen schad- und klaglos. Der Nutzer stellt Two Dots
                  Digital von sämtlichen Ansprüchen frei, die andere Nutzer
                  wegen der Verletzungihrer Rechte durch den Nutzer gegenüber
                  Two Dots Digital geltend machen. Der Nutzer übernimmt hierbei
                  die Kosten der notwendigen Rechtsvertretung von Two Dots
                  Digital einschließlich sämtlicher Gerichtskosten.
                </li>
                <li>
                  Two Dots Digital trifft keine Haftung für Schäden, die dem
                  Nutzer aus dem Missbrauch von an Dritte übermittelten Daten
                  (z.B. E-Mail etc.) resultieren. Die Nutzer sind selbst dafür
                  verantwortlich, dass sämtliche Daten, die sie für die
                  Vertragsabwicklung benötigen, auf einem geeigneten externen
                  Speichermedium gesichert aufbewahrt werden. Two Dots Digital
                  trifft keine Haftung für eine Aufbewahrung von Daten, die von
                  den Nutzern der Plattform eingestellt werden.
                </li>
              </ol>
            </section>
            <section>
              <SectionHeader>Urheberrecht und Datenbankrechte</SectionHeader>
              <ol className="paragraph__list fontStyle__paragraph">
                <li>
                  Der gesamte Inhalt, der auf AUTOVERSAND enthalten oder durch
                  Two Dots Digital bereitgestellt wird, ist entweder Eigentum
                  von Two Dots Digital bzw. ist Two Dots Digital nutzungs- und
                  verfügungsberechtigt, oder er wird von Nutzern zugeliefertoder
                  auf AUTOVERSAND bereitgestellt und ist urheberrechtlich
                  geschützt. Auch der Gesamtbestand der Inhalte, der in einem
                  AUTOVERSAND-Dienst enthalten oder durch ihn bereitgestellt
                  wird, ist ausschließliches Eigentum von Two Dots Digital und
                  urheberrechtlich geschützt.
                </li>
                <li>
                  Nutzer dürfen ohne ausdrückliche schriftliche Zustimmung von
                  Two Dots Digital nicht Teile eines AUTOVERSAND-Dienstes
                  systematisch extrahieren und/oder wiederverwenden.
                  Insbesondere dürfen Nutzer ohne ausdrückliche schriftliche
                  Zustimmung von Two Dots Digital kein Data Mining, keine Robots
                  oder ähnliche Datensammel- und Extraktionsprogramme einsetzen,
                  um auch nur Teile eines AUTOVERSAND-Dienste zur
                  Wiederverwendung zu extrahieren. Sie dürfen ferner ohne die
                  ausdrückliche schriftliche Zustimmung von Two Dots Digital
                  keine eigenen Datenbanken herstellen und/oder veröffentlichen,
                  die wesentliche Teile eines AUTOVERSAND-Dienstes beinhalten.
                </li>
              </ol>
            </section>
            <section>
              <SectionHeader>Schlussbestimmungen</SectionHeader>
              <ol className="paragraph__list fontStyle__paragraph section--exeption">
                <li>
                  Es gilt österreichisches Recht unter Ausschluss des
                  UN-Kaufrechts. Soweit gesetzlich nicht ausgeschlossen, gelten
                  die zwischen Unternehmern anzuwendenden gesetzlichen
                  Bestimmungen. Für eventuelle Streitigkeiten gilt die örtliche
                  Zuständigkeit des sachlich zuständigen Gerichtes in Wels als
                  vereinbart. Für Verbraucher iSd KSchG gilt der Gerichtsstand
                  des Wohnsitzes, des gewöhnlichen Aufenthaltes oder des Ortes
                  der Beschäftigung gem. § 14 KSchG.
                </li>
                <li>
                  Two Dots Digital behält sich das Recht vor, Änderungen an
                  AUTOVERSAND, Regelwerken, Bedingungen etc. jederzeit
                  vorzunehmen. Falls eine Regelung in diesen AGB unwirksam,
                  nichtig oder aus irgendeinem Grund durchsetzbar ist, gilt
                  diese Regelung als abtrennbar und beeinflusst die Gültigkeit
                  und Durchsetzbarkeit der verbleibenden Regelungen nicht.
                </li>
                <li>
                  Sollte ein Nutzer gegen die AGB verstoßen und Two Dots Digital
                  nicht ad hoc Maßnahmen gegen diese Verletzung setzen, behält
                  sich Two Dots Digital das Recht vor, bei jeder anderen
                  Gelegenheit, in der der Nutzer die Nutzungsbedingungen
                  verletzt, von diesem Grund Gebrauch zu machen.
                </li>
                <li>
                  Mündliche Nebenabreden bestehen nicht. Allfällige Änderungen
                  und Ergänzungen bedürfen jedenfalls der Schriftform; dies gilt
                  auch für Nebenabreden und Zusicherungen sowie nachträgliche
                  Vertragsänderungen wie auch das Abgehen von dem
                  Schriftformerfordernis.
                </li>
              </ol>
            </section>
            <p className="main__sections--stand">Stand: 11.07.2020</p>
            <Footer styleFooter="about__footer" />
          </div>
        </main>
      </div>
    </div>
  );
};

export default AGB;
