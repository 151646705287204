import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';
import config from '../../config/api.config.js';

import Header from '../../Layout/Dashboard/DashboardHeader/DashboardHeader';
import Navbar from '../../Layout/Dashboard/DashboardNavbar/DashboardNavbar';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import LeaveReviewsCard from '../../components/ReviewsCards/LeaveReviewsCard/LeaveReviewsCard';
import RecievedLeftReviewsCard from '../../components/ReviewsCards/RecievedLeftReviewsCard/RecievedLeftReviewsCard';
import CarPicture from '../../assets/images/bmw.png';
import { ReactComponent as NothingFound } from '../../assets/images/nothingFound.svg';

import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import './Reviews.css';

const override = css`
  margin: 0 auto;
  width: 100px;
  margin-top: 80px;
  margin-bottom: 80px;
`;

const Reviews = ({ t }) => {
  const [sliderLoaded, setSliderLoaded] = useState(false);
  const leftReviews = [];
  const recievedReviews = [];
  const pendingReviews = [];
  const [leftReviewsItems, setLeftReviewsItems] = useState([]);
  const [recievedReviewsItems, setRecievedReviewsItems] = useState([]);
  const [pendingReviewsItems, setPendingReviewsItems] = useState([]);
  const [slidesLeft, setSlidesLeft] = useState(0);
  const [slidesRecieved, setSlidesRecieved] = useState(0);
  const [slidesPending, setSlidesPending] = useState(0);

  const [numOfRecievedReviews, SetNumOfRecievedReviews] = useState({
    positive: 0,
    neutral: 0,
    negative: 0
  });
  const [numOfLeftReviews, SetNumOfLeftReviews] = useState({
    positive: 0,
    neutral: 0,
    negative: 0
  });
  const [reloadPage, setReloadPage] = useState(false);

  var settingsLeaveReviews = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesPending,
    slidesToScroll: 1,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false,
          arrows: false
        }
      }
    ]
  };
  var settingsRecieved = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesRecieved,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false,
          arrows: false
        }
      }
    ]
  };

  var settingsLeft = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesLeft,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false,
          arrows: false
        }
      }
    ]
  };

  const getRecievedRatings = async () => {
    setSliderLoaded(true);

    await axios
      .get(`${config.apiUrl}/me/ratings/rated/`)
      .then((res) => {
        let positive = 0,
          neutral = 0,
          negative = 0;

        res.data.map((rating) => {
          if (rating.rating == 'positive') positive += 1;
          else if (rating.rating == 'neutral') neutral += 1;
          else negative += 1;

          recievedReviews.push({
            location: {
              from: {
                city: rating.shipment.pickup.city,
                country: rating.shipment.pickup.country
              },
              to: {
                city: rating.shipment.destination.city,
                country: rating.shipment.destination.country
              }
            },
            nameOfPerson: rating.rater.company
              ? rating.rater.company.name
              : `${
                  rating.rater.person.first_name
                } ${rating.rater.person.last_name.slice(0, 1)}.`,
            backgroundImage: `url(${rating.shipment.photos[0].file})`,
            review: rating.notes,
            rating: rating.rating
          });
        });

        SetNumOfRecievedReviews({
          positive: positive,
          neutral: neutral,
          negative: negative
        });
        if (recievedReviews.length == 0) setSlidesRecieved(0);
        else if (recievedReviews.length == 1) setSlidesRecieved(1);
        else setSlidesRecieved(2);

        recievedReviews.map((review, index) =>
          setRecievedReviewsItems((recievedReviewsItems) => [
            ...recievedReviewsItems,
            <RecievedLeftReviewsCard key={index} data={review} />
          ])
        );

        setSliderLoaded(false);
      })
      .catch((err) => {
        console.log(err);
        setSliderLoaded(false);
      });
  };

  const getLeftRatings = async () => {
    setSliderLoaded(true);

    await axios
      .get(`${config.apiUrl}/me/ratings/rater/`)
      .then((res) => {
        let positive = 0,
          neutral = 0,
          negative = 0;

        res.data.map((rating) => {
          if (rating.rating == 'positive') positive += 1;
          else if (rating.rating == 'neutral') neutral += 1;
          else negative += 1;

          leftReviews.push({
            location: {
              from: {
                city: rating.shipment.pickup.city,
                country: rating.shipment.pickup.country
              },
              to: {
                city: rating.shipment.destination.city,
                country: rating.shipment.destination.country
              }
            },
            nameOfPerson: rating.rated.company
              ? rating.rated.company.name
              : `${
                  rating.rated.person.first_name
                } ${rating.rated.person.last_name.slice(0, 1)}.`,
            backgroundImage: `url(${rating.shipment.photos[0].file})`,
            review: rating.notes,
            rating: rating.rating
          });
        });

        SetNumOfLeftReviews({
          positive: positive,
          neutral: neutral,
          negative: negative
        });

        setLeftReviewsItems([]);
        if (leftReviews.length == 0) setSlidesLeft(0);
        else if (leftReviews.length == 1) setSlidesLeft(1);
        else setSlidesLeft(2);

        leftReviews.map((review, index) =>
          setLeftReviewsItems((leftReviewsItems) => [
            ...leftReviewsItems,
            <RecievedLeftReviewsCard key={index} data={review} />
          ])
        );

        setSliderLoaded(false);
      })
      .catch((err) => {
        console.log(err);
        setSliderLoaded(false);
      });
  };
  const getPendingReviews = async () => {
    setSliderLoaded(true);

    await axios
      .get(`${config.apiUrl}/me/ratings/pending/`)
      .then((res) => {
        res.data.map((rating) => {
          pendingReviews.push({
            shipmentId: rating.shipment.id,
            location: {
              from: {
                city: rating.shipment.pickup.city,
                country: rating.shipment.pickup.country
              },
              to: {
                city: rating.shipment.destination.city,
                country: rating.shipment.destination.country
              }
            },
            nameOfPerson: rating.rated.company
              ? rating.rated.company.name
              : `${
                  rating.rated.person.first_name
                } ${rating.rated.person.last_name.slice(0, 1)}.`,
            backgroundImage: `url(${rating.shipment.photos[0].file})`,
            review: rating.notes,
            rating: rating.rating
          });
        });

        setPendingReviewsItems([]);

        if (pendingReviews.length == 0) setSlidesPending(0);
        else if (pendingReviews.length == 1) setSlidesPending(1);
        else setSlidesPending(2);

        pendingReviews.map((review, index) =>
          setPendingReviewsItems((pendingReviewsItems) => [
            ...pendingReviewsItems,
            <LeaveReviewsCard
              key={index}
              data={review}
              setReloadPage={setReloadPage}
            />
          ])
        );

        setSliderLoaded(false);
      })
      .catch((err) => {
        console.log(err);
        setSliderLoaded(false);
      });
  };
  useEffect(() => {
    getLeftRatings();
    getRecievedRatings();
    getPendingReviews();
  }, []);

  useEffect(() => {
    if (reloadPage) {
      getPendingReviews();
      getLeftRatings();
      setReloadPage(false);
    }
  }, [reloadPage]);

  return (
    <div className="reviews__content">
      <Helmet>
        <title>Autoversand | {t('ratings.ratings')}</title>
      </Helmet>
      <div className="reviews__rightSide--shadow"></div>
      <section className="reviewContent__section">
        <div className="reviewContent__section--heading">
          <ContentHeader>{t('ratings.leave')}</ContentHeader>
          <div className="reviewContent__section_numbers reviewContent__section_numOfReviews">
            {slidesPending}
          </div>
        </div>
        {/* <p className="shipmentView__paragraph">
          Here are the transports that are completed and are waiting for your
          feedback.
        </p> */}
        {sliderLoaded ? (
          <PuffLoader
            css={override}
            size={60}
            margin={2}
            color={'rgba(116, 38, 251, 0.4)'}
            loading={sliderLoaded}
          />
        ) : pendingReviewsItems.length ? (
          <div className="section__leaveReviews">
            <Slider {...settingsLeaveReviews}>{pendingReviewsItems}</Slider>
          </div>
        ) : (
          <div className="nothingFound">
            <NothingFound />
          </div>
        )}
      </section>
      <section className="reviewContent__section">
        <div className="reviewContent__section--heading2">
          <ContentHeader>{t('ratings.recieved')}</ContentHeader>
          <div className="reviewContent__section--inner">
            <div className="reviewContent__section_numbers reviewContent__section_numOfPositive">
              {numOfRecievedReviews.positive}
            </div>
            <div className="reviewContent__section_numbers reviewContent__section_numOfAverage">
              {numOfRecievedReviews.neutral}
            </div>
            <div className="reviewContent__section_numbers reviewContent__section_numOfNegative">
              {numOfRecievedReviews.negative}
            </div>
          </div>
        </div>
        {/* <p className="shipmentView__paragraph">
          The feedback that you got from other users.
        </p> */}
        {sliderLoaded ? (
          <PuffLoader
            css={override}
            size={60}
            margin={2}
            color={'rgba(116, 38, 251, 0.4)'}
            loading={sliderLoaded}
          />
        ) : recievedReviewsItems.length ? (
          <div className="section__recievedReviews">
            <Slider {...settingsRecieved}>{recievedReviewsItems}</Slider>
          </div>
        ) : (
          <div className="nothingFound">
            <NothingFound />
          </div>
        )}
      </section>
      <section className="reviewContent__section">
        <div className="reviewContent__section--heading2">
          <ContentHeader>{t('ratings.left')}</ContentHeader>
          <div className="reviewContent__section--inner">
            <div className="reviewContent__section_numbers reviewContent__section_numOfPositive">
              {numOfLeftReviews.positive}
            </div>
            <div className="reviewContent__section_numbers reviewContent__section_numOfAverage">
              {numOfLeftReviews.neutral}
            </div>
            <div className="reviewContent__section_numbers reviewContent__section_numOfNegative">
              {numOfLeftReviews.negative}
            </div>
          </div>
        </div>
        {/* <p className="shipmentView__paragraph">
          The feedback that you gave other users.
        </p> */}
        {sliderLoaded ? (
          <PuffLoader
            css={override}
            size={60}
            margin={2}
            color={'rgba(116, 38, 251, 0.4)'}
            loading={sliderLoaded}
          />
        ) : leftReviewsItems.length ? (
          <div className="section__leftReviews">
            <Slider {...settingsLeft}>{leftReviewsItems}</Slider>
          </div>
        ) : (
          <div className="nothingFound">
            <NothingFound />
          </div>
        )}
      </section>
    </div>
  );
};

export default withNamespaces()(Reviews);
