import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import logo from '../../../assets/images/av_logo.png';
import Notifications from '../../../components/Notification/Notification';
import auth from '../../../auth';

import { ReactComponent as NotificationIcon } from '../../../assets/images/HeaderIcons/notifications.svg';
import { ReactComponent as MessageIcon } from '../../../assets/images/HeaderIcons/message.svg';
import HeaderSearchList from '../../../components/HeaderSearchList/HeaderSearchList';

import { connect } from 'react-redux';

import { getShipments, getNumOfChats } from '../../../redux/actions';

import { setNotificationRead } from '../../../notifications';

import './DashboardHeader.css';
const DashboardHeader = ({ setSearchTerm = null, t, ...props }) => {
  const userId = parseInt(auth.getId());
  const [background, showBackground] = useState(true);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notification, setNotification] = useState();
  const [notificationItems, setNotificationItems] = useState([]);
  const [userRead, setUserRead] = useState(true);
  const [recieverHasRead, setRecieverHasRead] = useState(true);
  const [lastSender, setLastSender] = useState(null);
  const [numOfChats, setNumOfChats] = useState(0);

  const handleVisible = () => {
    setNotificationVisible(!notificationVisible);
  };

  const [searchTerm, setSearchTermLocal] = useState('');
  const handleInput = (event) => {
    if (setSearchTerm != null) setSearchTerm(event.target.value);
    else setSearchTermLocal(event.target.value);

    if (event.target.value === '') showBackground(true);
    else showBackground(false);
  };
  let history = useHistory();

  let arrayOfShipments = [];
  let shipItems = [];
  const [shipment, setShip] = useState([]);
  const [shipments, setShipments] = useState(props.shipmentsState.shipments);

  const getShipments = () => {
    shipments.map((shipment) => {
      arrayOfShipments.push({
        shipmentID: shipment.id,
        transportType:
          shipment.vehicle.manufacturer +
          ' ' +
          shipment.vehicle.model +
          ' ' +
          shipment.vehicle.year,
        location: {
          from: {
            city: shipment.pickup.city,
            country: shipment.pickup.country
          },
          to: {
            city: shipment.destination.city,
            country: shipment.destination.country
          }
        }
      });
    });

    arrayOfShipments.map((shipment) =>
      shipItems.push(
        <HeaderSearchList key={shipment.shipmentID} data={shipment} />
      )
    );
    setShip(shipItems);
  };

  const getNotifications = () =>
    setNotificationItems(
      notification.map((item, index) => (
        <Notifications key={index} data={item} />
      ))
    );

  useEffect(() => {
    getShipments();
  }, [shipments]);

  useEffect(() => {
    props.messangerReducer(userId);
    setNumOfChats(props.recieverHasReadState.numOfChats);
  }, []);
  useEffect(() => {
    setShipments(props.shipmentsState.shipments);
  }, [props.shipmentsState.shipments]);

  useEffect(() => {
    setNotification(props.notificationsState.notifications);
    setUserRead(props.notificationsState.hasRead);
  }, [props.notificationsState.notifications]);

  useEffect(() => {
    setRecieverHasRead(props.recieverHasReadState.hasRead);
    setLastSender(props.recieverHasReadState.lastSender);
  }, [props.recieverHasReadState.hasRead]);

  useEffect(() => {
    setNumOfChats(props.recieverHasReadState.numOfChats);
  }, [props.recieverHasReadState.numOfChats]);

  useEffect(() => {
    const filter = () => {
      if (searchTerm === '') {
        setShipments(props.shipmentsState.shipments);
      } else {
        const temp = props.shipmentsState.shipments.filter(
          (item) =>
            item.vehicle.manufacturer
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item.vehicle.model
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item.pickup.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.pickup.country
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item.destination.city
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item.destination.country
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        );
        setShipments(temp);
      }
    };
    filter();
  }, [searchTerm]);

  useEffect(() => {
    if (notification) getNotifications();
  }, [notification]);

  return (
    <div className="dashboardHeader">
      <div className="header__leftSide">
        <Link to="/">
          <div className="header__logo">
            <img src={logo} alt="" />
          </div>
        </Link>
      </div>
      <div className="header__rightSide">
        <div style={{ position: 'relative' }}>
          <MessageIcon
            onClick={() => history.push('/messenger')}
            style={{ cursor: 'pointer' }}
          />
          <div
            className="notificationDot"
            style={{
              display:
                !recieverHasRead &&
                lastSender &&
                lastSender !== userId &&
                numOfChats
                  ? 'block'
                  : 'none'
            }}
          ></div>
        </div>
        <div className="notification" onClick={() => setNotificationRead()}>
          <div style={{ position: 'relative' }}>
            <NotificationIcon
              className="notificationBell"
              onClick={handleVisible}
            />
            <div
              className="notificationDot"
              style={{ display: userRead ? 'none' : 'block' }}
            ></div>
          </div>
          {notificationItems.length ? (
            <div
              className={
                notificationVisible
                  ? 'shipmentsContent__notifications'
                  : 'hideNotification'
              }
            >
              <p className="notifications__paragraph">
                {t('shipments.notifications')}
              </p>
              <div className="notificationHeader__content">
                {notificationItems}
              </div>
            </div>
          ) : null}
        </div>
        <div style={{ position: 'relative', marginLeft: '2.706rem' }}>
          <input
            type="text"
            className={background ? 'searchBox' : 'searchBox__none'}
            placeholder={t('header.search')}
            onChange={handleInput}
            onFocus={() => props.shipmentsReducer()}
          />
          {searchTerm != '' ? (
            <ul className="header__searchResults">
              {shipment.length ? (
                shipment
              ) : (
                <p className="searchResults__nothingFound">Nothing found</p>
              )}
            </ul>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  shipmentsState: state.shipmentsReducer,
  notificationsState: state.notificationsReducer,
  recieverHasReadState: state.messangerReducer
});

const mapDispatchToProps = (dispatch) => ({
  shipmentsReducer: () => dispatch(getShipments()),
  messangerReducer: (userId) => dispatch(getNumOfChats(userId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(DashboardHeader));
