import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';

import Header from '../../Layout/Dashboard/DashboardHeader/DashboardHeader';
import Navbar from '../../Layout/Dashboard/DashboardNavbar/DashboardNavbar';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import MainSettings from '../../components/AccountSettings/MainSettings/MainSettings';
import CompanySettings from '../../components/AccountSettings/CompanySettings/CompanySettings';
import PersonalSettings from '../../components/AccountSettings/PersonalSettings/PersonalSettings';
import ChangePassword from '../../components/AccountSettings/ChangePassword/ChangePassword';

import './Account.css';
import auth from '../../auth';
import config from '../../config/api.config.js';

const Settings = ({ t }) => {
  const currentLocation = useLocation().pathname;
  const userId = auth.getId();
  const [account, setAccount] = useState(
    auth.isNaturalShipper() ? 'personal' : 'company'
  );
  const [cips, setCips] = useState();
  const [customPassword, setCustomPassword] = useState(false);

  const getCips = async () => {
    await axios.get(`${config.apiUrl}/users/cips/${userId}/`).then((res) => {
      setCips(res.data);
      setCustomPassword(res.data.custom_password);
    });
  };

  useEffect(() => {
    getCips();
  }, []);
  return (
    <div className="account__content">
      <Helmet>
        <title>Autoversand | {t('settings.settings')}</title>
      </Helmet>
      <div className="account__header">
        <ContentHeader>{t('settings.settings')}</ContentHeader>
      </div>
      <div className="accountSettings">
        <div className="accountSettings__nav">
          <Link
            className={currentLocation === '/main-settings' && 'activeSetting'}
            to="/main-settings"
          >
            {t('settings.account')}
          </Link>
          {account == 'personal' ? (
            <Link
              className={
                currentLocation === '/personal-settings' && 'activeSetting'
              }
              to="/personal-settings"
            >
              {t('settings.personal')}
            </Link>
          ) : (
            <Link
              className={
                currentLocation === '/company-settings' && 'activeSetting'
              }
              to="/company-settings"
            >
              {t('settings.company')}
            </Link>
          )}
          <Link
            className={
              currentLocation === '/change-password' && 'activeSetting'
            }
            to="/change-password"
          >
            {t('settings.changePassword')}
          </Link>
        </div>

        {currentLocation === '/main-settings' ? (
          <MainSettings />
        ) : currentLocation === '/personal-settings' ? (
          <PersonalSettings />
        ) : currentLocation === '/company-settings' ? (
          <CompanySettings />
        ) : (
          <ChangePassword customPassword={customPassword} />
        )}
      </div>
    </div>
  );
};
export default withNamespaces()(Settings);
