import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';

import FromToSection from '../../FromToSection/FromToSection';
import Modal from 'react-awesome-modal';

import happyColor from '../../../assets/images/Emoji/happyColor.png';
import smileColor from '../../../assets/images/Emoji/smileColor.png';
import sadColor from '../../../assets/images/Emoji/sadColor.png';
import { ReactComponent as Check } from '../../../assets/images/shipmentViewCheck.svg';

import './RecievedLeftReviewsCard.css';

const RecievedLeftReviewsCard = ({ t, data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  function imgEmoji() {
    if (data.rating === 'positive')
      return <img className="recievedReviews__emoji" src={happyColor} />;
    else if (data.rating === 'neutral')
      return <img className="recievedReviews__emoji" src={smileColor} />;
    else return <img className="recievedReviews__emoji" src={sadColor} />;
  }
  return (
    <div>
      <div className="recievedReviewsCard">
        <div className="recievedReviewsCard__card">
          <div className="recievedReviews__left">
            <div className="reviewsLeft__top">
              <div className="reviewsLeft__topIcon">{imgEmoji()}</div>
              <FromToSection data={data.location} />
            </div>
            <div
              className="reviewsLeft__review"
              onClick={() => setModalVisible(true)}
            >
              {data.review}
            </div>
          </div>
          <div
            className="recievedReviews__right"
            style={{ backgroundImage: data.backgroundImage }}
          ></div>
        </div>
        <div className="recievedReviews__person">
          {/* <Check className="recievedReviews__check" /> */}
          {data.nameOfPerson}
        </div>
      </div>
      <Modal
        visible={modalVisible}
        width="42%"
        effect="fadeInUp"
        onClickAway={() => setModalVisible(false)}
      >
        <div className="popup__modal">
          {data.review}
          <a
            href="javascript:void(0);"
            onClick={() => setModalVisible(false)}
            className="infoBoxModal__close"
          >
            {t('ratings.close')}
          </a>
        </div>
      </Modal>
    </div>
  );
};

export default withNamespaces()(RecievedLeftReviewsCard);
