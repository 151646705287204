import React from "react";
import { useWindowSize } from "./WindowSize";

export const withHooksHOC = (Component: any) => {
  return (props: any) => {
    const [width, height] = useWindowSize();

    return <Component width={width} {...props} />;
  };
};
