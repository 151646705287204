import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { ReactComponent as Check } from '../../../assets/images/shipmentViewCheck.svg';
import ChatButton from '../../Buttons/ChatButton/ChatButton';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Modal from 'react-awesome-modal';
import axios from 'axios';
import config from '../../../config/api.config.js';
// Animation
import { easeQuadInOut } from 'd3-ease';
import AnimatedProgressProvider from '../../AnimatedProgressProvider/AnimatedProgressProvider';

import './AcceptRejectBid.css';

const AcceptRejectBid = ({
  t,
  data,
  shipmentID,
  openModal,
  setModalMessage
}) => {
  const history = useHistory();
  const _openModal = () => {
    openModal(true);
    setModalMessage(data.message);
  };

  const handleAccept = async () => {
    const offer = {
      shipment: shipmentID,
      carrier: data.carrier,
      amount: data.price,
      state: data.state,
      pickup_date: data.pickupDateSend,
      delivery_date: data.deliveryDateSend,
      description: data.message
    };
    await axios
      .patch(`${config.apiUrl}/offers/accept/${data.id}/`, offer)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleReject = async () => {
    const offer = {
      shipment: shipmentID,
      carrier: data.carrier,
      amount: data.price,
      state: data.state,
      pickup_date: data.pickupDateSend,
      delivery_date: data.deliveryDateSend,
      description: data.message
    };
    await axios
      .patch(`${config.apiUrl}/offers/reject/${data.id}/`, offer)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const calculateRatingPercentage = () => {
    if (!data.ratings.length) return 0;

    const positiveRatings = data.ratings.filter(
      (rating) => rating.rating === 'positive'
    );

    return (positiveRatings.length / data.ratings.length) * 100;
  };

  return (
    <div className="shipper__acceptRejectedBid">
      <div className="shipper__acceptRejectedBid--heading">
        <div className="shipper__header-left">{data.price} €</div>
        <div className="shipper__header-right">
          {t('offers.offer')} {data.numOfOffer}
        </div>
      </div>
      <div className="shipper__acceptRejectedBid--content">
        <div className="acceptRejectedBid__contentLeft">
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={calculateRatingPercentage()}
            duration={1.4}
            easingFunction={easeQuadInOut}
          >
            {(value) => {
              const roundedValue = Math.round(value);
              return (
                // <CircularProgressbar
                // value={value}
                // text={`${roundedValue}%`}
                // styles={buildStyles({ pathTransition: "none", strokeLinecap: "butt" })}
                // strokeWidth={5}

                // />
                <CircularProgressbarWithChildren
                  value={value}
                  // text={`${roundedValue}%`}
                  styles={buildStyles({
                    pathTransition: 'none',
                    strokeLinecap: 'butt',
                    backgroundColor: 'transparent',
                    pathColor: '#2699fb',
                    trailColor: 'transparent'
                  })}
                  strokeWidth={5}
                >
                  <img
                    style={{
                      width: '100%',
                      borderRadius: '9999999px',
                      background: 'transparent',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                    src={data.userImage}
                    alt="user image"
                    onClick={() =>
                      history.push(`/company-profile/${data.carrier}`)
                    }
                  />
                </CircularProgressbarWithChildren>
              );
            }}
          </AnimatedProgressProvider>
          <div className="acceptRejectedBid__user">
            {data.verified ? <Check className="rejectedBid__check" /> : '   '}
            <label>{data.userName}</label>
          </div>
          <ChatButton
            handleClick={() =>
              window.open(`/messenger?newChat=${data.carrier}`, '_self')
            }
          />
        </div>
        <div className="acceptRejectedBid__contentRight">
          <div
            className="acceptRejectedBid__contentRightMessage"
            onClick={_openModal}
          >
            <div>{data.message}</div>
          </div>
          <div className="acceptRejectedBid__dates">
            <div className="acceptRejectedBid__pickupDate">
              {t('shipments.pickupDate')} <label>{data.pickupDate}</label>
            </div>
            <div className="acceptRejectedBid__deliveryDate">
              {t('offers.deliveryDate')}{' '}
              <label>
                {t('offers.after')} {data.deliveryDate} {t('offers.days')}
              </label>
            </div>
          </div>
          <div className="acceptRejectedBid__buttons">
            <button
              className="acceptRejectedBid__button--accept"
              onClick={handleAccept}
            >
              {t('offers.acceptButton')}
            </button>
            <button
              className="acceptRejectedBid__button-reject"
              onClick={handleReject}
            >
              {t('offers.rejectButton')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(AcceptRejectBid);
