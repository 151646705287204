import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Animated } from 'react-animated-css';
import validator from 'validator';
import { css } from '@emotion/core';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import ReactFlagsSelect from 'react-flags-select';
import { withNamespaces } from 'react-i18next';
import BarLoader from 'react-spinners/BarLoader';
import { connect } from 'react-redux';

import { setToken } from '../../redux/actions';
import auth from '../../auth';
import config from '../../config/api.config.js';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as InstagramLogo } from '../../assets/images/instagram_logo.svg';
import { ReactComponent as FacebookLogo } from '../../assets/images/facebook_logo.svg';
import { ReactComponent as Check } from '../../assets/images/check.svg';
import { ReactComponent as CarIllustration } from '../../assets/images/carBg.svg';

import fbIcon from '../../assets/images/fb_2.png';
import googleIcon from '../../assets/images/google.png';
import userIcon from '../../assets/images/user.png';
import secureIcon from '../../assets/images/secure.png';

import 'react-toastify/dist/ReactToastify.css';
import 'react-flags-select/css/react-flags-select.css';
import './login.css';

const override = css`
  position: absolute;
  top: 0px;
  width: 40%;
`;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zeroWindowVisible: true,
      email: '',
      password: '',
      emailValid: true,
      passwordValid: true,
      validationProcess: false,
      errorResponse: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.notifyUser = this.notifyUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;

    if (this.state.password == '' || this.state.password.length < 5) {
      this.setState({ passwordValid: false });
      isValid = false;
    }

    let statusOfLogin = true;

    if (isValid) {
      await this.setState({ validationProcess: true });
      await axios
        .post(`${config.apiUrl}/token/`, {
          email: this.state.email.toLowerCase(),
          password: this.state.password
        })
        .then((res) => {
          this.setState({ jwtToken: `Bearer ${res.data.refresh}` });
          this.props.tokenReducer({ token: `Bearer ${res.data.refresh}` });
          if (res.data.type === 'carrier')
            auth.login(
              res.data.access,
              res.data.refresh,
              res.data.user,
              res.data.type,
              () => {
                this.props.history.push('/dashboard');
              }
            );
          else
            auth.login(
              res.data.access,
              res.data.refresh,
              res.data.user,
              res.data.type,
              () => {
                this.props.history.push('/shipments');
              }
            );
        })
        .catch((err) => {
          this.setState({ passwordValid: false });
          statusOfLogin = false;
          this.setState({ errorResponse: err.response.data.detail });
        });
      await this.setState({ validationProcess: false });
      if (!statusOfLogin) {
        this.notifyUser('login');
        this.setState({ password: '' });
        this.setState({ emailValid: false });
        this.setState({ passwordValid: false });
        setTimeout(() => {
          this.setState({
            emailValid: true,
            passwordValid: true
          });
        }, 500);
      }
    } else {
      setTimeout(() => {
        this.setState({
          emailValid: true,
          passwordValid: true
        });
      }, 500);
    }
  };

  notifyUser = (notificationFor) => {
    const Msg_Email = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ fontSize: '18px', marginRight: '5px' }}>📬</span>&nbsp;
          That <strong>&nbsp;email address&nbsp;</strong> already exists.
        </p>
      </div>
    );
    const Msg_Phone = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ fontSize: '18px', marginRight: '5px' }}>📱</span>&nbsp;
          That <strong>&nbsp;phone number&nbsp;</strong> already exists.
        </p>
      </div>
    );
    const Msg_Login = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ fontSize: '18px', marginRight: '5px' }}></span>&nbsp;
          {this.state.errorResponse == 'Email not confirmed'
            ? 'Confirm your email address.'
            : 'Email or password is incorrect.'}
        </p>
      </div>
    );
    if (notificationFor === 'login')
      toast.error(<Msg_Login />, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
  };

  componentDidMount() {
    let url_string = window.location.href; //window.location.href
    let url = new URL(url_string);
    let code = url.searchParams.get('code');
    let type = url.searchParams.get('type');
    let provider = url.searchParams.get('provider');

    console.log();

    if (code !== null) {
      console.log('Ovo je type', type);

      if (type === 'natural_shipper') {
        this.setState({
          accountType: 'private',
          userType: 'sender'
        });
      }

      if (type === 'legal_shipper') {
        this.setState({
          accountType: 'business',
          userType: 'sender'
        });
      }

      if (type === 'carrier') {
        this.setState({
          accountType: 'business',
          userType: 'transporter'
        });
      }

      axios
        .get(
          `${config.apiUrl}/oauth/${provider}/login?type=${type}&code=${code}&intent=login`
        )
        .then((res) => {
          this.setState({ jwtToken: `Bearer ${res.data.refresh}` });
          this.props.tokenReducer({ token: `Bearer ${res.data.refresh}` });
          if (type === 'carrier')
            auth.login(
              res.data.token.access,
              res.data.token.refresh,
              res.data.id,
              res.data.type,
              () => {
                this.props.history.push('/dashboard');
              }
            );
          else
            auth.login(
              res.data.token.access,
              res.data.token.refresh,
              res.data.id,
              res.data.type,
              () => {
                this.props.history.push('/shipments');
              }
            );
        })
        .catch((err) => {
          console.log('SOCIAL CREATE GET ERROR', err);
        });
    }
  }

  render() {
    const { t, i18n } = this.props;

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    if (i18n.language != 'de' && i18n.language != 'gb') {
      i18n.language = 'de';
    }

    return (
      <div
        className={
          this.state.accountType != ''
            ? 'registration newBg login'
            : 'registration login'
        }
      >
        <Helmet>
          <title>Autoversand | {t('login.login')}</title>
        </Helmet>
        <div className="container">
          <ToastContainer
            position={toast.POSITION.TOP_RIGHT}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            enableMultiContainer
            containerId={'A'}
          />

          <Link to="/">
            <Logo className="logo" />
          </Link>

          <Animated
            animationIn="fadeInRightBig"
            animationOut="fadeOutLeftBig"
            animationInDuration={900}
            animationOutDuration={1000}
            isVisible={this.state.zeroWindowVisible}
          >
            <main className="content secondStep loginNew">
              <BarLoader
                css={override}
                size={150}
                color={'#06016c'}
                loading={this.state.validationProcess}
              />
              <div>
                <ReactFlagsSelect
                  defaultCountry={i18n.language.toLocaleUpperCase()}
                  onSelect={(value) =>
                    changeLanguage(value.toLocaleLowerCase())
                  }
                  countries={['DE', 'GB']}
                  customLabels={{ DE: 'Deutsch', GB: 'English' }}
                />

                <h1>{t('login.login')}.</h1>
                <h2>{t('login.startUsingAV')}</h2>

                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="register__googleFbButton">
                    <button className="loginPopUp__button">
                      <span
                        onClick={() => {
                          window.open(
                            `${config.apiUrl}/oauth/google?type=undefined&intent=login`,
                            '_self'
                          );
                        }}
                      >
                        {t('modal.singIn')}
                      </span>
                      <img
                        src={googleIcon}
                        onClick={() => {
                          window.open(
                            `${config.apiUrl}/oauth/google?type=undefined&intent=login`,
                            '_self'
                          );
                        }}
                      />
                    </button>
                    <img
                      src={fbIcon}
                      onClick={() => {
                        window.open(
                          `${config.apiUrl}/oauth/facebook?type=undefined&intent=login`,
                          '_self'
                        );
                      }}
                    />
                  </div>
                  <div className="loginPopUp__or">
                    <div className="loginPopUp__line"></div>
                    <span>{t('modal.or')}</span>
                    <div className="loginPopUp__line"></div>
                  </div>
                  <div className="row">
                    <input
                      type="email"
                      value={this.state.email}
                      name="email"
                      onChange={this.handleChange}
                      placeholder={t('registration.email')}
                      className={!this.state.emailValid && 'shakeIt'}
                      spellCheck="false"
                    />
                    <img src={userIcon} />
                  </div>
                  <div className="row">
                    <input
                      type="password"
                      value={this.state.password}
                      name="password"
                      onChange={this.handleChange}
                      placeholder={t('registration.password')}
                      className={!this.state.passwordValid && 'shakeIt'}
                      spellCheck="false"
                    />
                    <img src={secureIcon} />
                  </div>
                  <div className="login__forgetPassword">
                    <Link to="/password-reset">
                      {t('login.forgotPassword')}
                    </Link>
                  </div>
                  <button type="submit" onClick={this.handleSubmit}>
                    {t('login.login')}
                  </button>
                </form>
              </div>
            </main>
          </Animated>

          <footer className="footer">
            <h3>COPYRIGHT</h3>
            <div className="footer__copyright">
              <p>© Autoversand. {t('homepage.copyright')}</p>
              <div>
                <InstagramLogo
                  className="footer__copyright--icon"
                  onClick={() =>
                    window.open('https://instagram.com/autoversand', '_blank')
                  }
                />
                <FacebookLogo
                  className="footer__copyright--icon"
                  onClick={() =>
                    window.open('https://facebook.com/autoversand', '_blank')
                  }
                />
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tokenState: state.tokenReducer
});

const mapDispatchToProps = (dispatch) => ({
  tokenReducer: (data) => dispatch(setToken(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Login));
