import React, { useState } from 'react';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';
import config from '../../../config/api.config.js';
import FromToSection from '../../FromToSection/FromToSection';

import happy from '../../../assets/images/Emoji/happy.png';
import happyColor from '../../../assets/images/Emoji/happyColor.png';
import smile from '../../../assets/images/Emoji/smile.png';
import smileColor from '../../../assets/images/Emoji/smileColor.png';
import sad from '../../../assets/images/Emoji/sad.png';
import sadColor from '../../../assets/images/Emoji/sadColor.png';
import { ReactComponent as Check } from '../../../assets/images/shipmentViewCheck.svg';

import './LeaveReviewCard.css';

const LeaveReviewsCard = ({ t, data, setReloadPage }) => {
  const [toggleState, setToggleState] = useState();
  const [rating, setRating] = useState('');
  const [notes, setNotes] = useState('');
  const [ratingValid, setRatingValid] = useState(true);
  const [notesValid, setNotesValid] = useState(true);

  const Switch = (number) => {
    setToggleState(number);
    setRating(number == 0 ? 'positive' : number == 1 ? 'neutral' : 'negative');
  };

  const patchRating = async () => {
    let isValid = true;

    if (rating === '') {
      isValid = false;
      setRatingValid(false);
    }
    if (notes === '') {
      isValid = false;
      setNotesValid(false);
    }

    if (!isValid)
      return setTimeout(() => {
        setRatingValid(true);
        setNotesValid(true);
      }, 500);

    await axios
      .patch(`${config.apiUrl}/ratings/`, {
        shipment_id: data.shipmentId,
        notes,
        rating
      })
      .then((res) => {
        console.log(res);
        setReloadPage(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="leaveReviewsCard">
      <div className="leaveReviews__name">
        {' '}
        <Check className="leaveReviews__name--check" /> {data.nameOfPerson}
      </div>
      <div className="reviews__card">
        <div
          className="leaveReviews__top"
          style={{ backgroundImage: data.backgroundImage }}
        ></div>
        <div className="leaveReviews__bottom">
          <FromToSection data={data.location} />
          <div className={!ratingValid && 'shakeIt'}>
            <div className="leaveReviews__bottom--emoticons">
              <img
                className="emoticonsImg"
                onClick={() => {
                  Switch(0);
                }}
                src={toggleState == 0 ? happyColor : happy}
              />
              <img
                className="emoticonsImg"
                onClick={() => {
                  Switch(1);
                }}
                src={toggleState == 1 ? smileColor : smile}
              />
              <img
                className="emoticonsImg"
                onClick={() => {
                  Switch(2);
                }}
                src={toggleState == 2 ? sadColor : sad}
              />
            </div>
          </div>
          <div className={!notesValid && 'shakeIt'}>
            <textarea
              className="bottom__textarea"
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            ></textarea>
          </div>
        </div>
        <button className="leaveReviews__button" onClick={patchRating}>
          {t('ratings.submit')}
        </button>
      </div>
    </div>
  );
};

export default withNamespaces()(LeaveReviewsCard);
