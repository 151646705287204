import React from "react";
import { ReactComponent as Chat } from "../../../assets/images/shipmentChat.svg";

import "./ChatButton.css";

const ChatButton = ({ handleClick, ...otherProps }) => {
  return (
    <button className="chatButton" onClick={handleClick} {...otherProps}>
      START CHAT
      <Chat className="chatButton__chatIcon" />
    </button>
  );
};

export default ChatButton;
