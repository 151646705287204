import React from "react";
import { ReactComponent as Line } from "../../assets/images/line.svg";

import "./ContentHeader.css";

const ContentHeader = ({ children }) => {
  return (
    <div className="content__header">
      <h2>{children}</h2>
      <Line className="content__header--line" />
    </div>
  );
};

export default ContentHeader;
