import React, { useEffect, useState } from "react";
import axios from "axios";

const GoogleMapDistance = () => {
  let origin = "Sarajevo, Bosna i Hercegovina";
  let destination = "Cazin, Bosna i Hercegovina";

  origin = origin.split(" ").join("+").replace(",", "");
  destination = destination.split(" ").join("+").replace(",", "");
  const [distance, setDistance] = useState(null);

  const getMapDistance = async () => {
    await axios(
      `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/distancematrix/xml?origins=${origin}&destinations=${destination}&mode=driving&key=AIzaSyBQKpE6JxPpRDnbINcFFdM7XNBEg-yOX9c`
    )
      .then((result) => {
        const parser = new DOMParser();
        const xmlResult = parser.parseFromString(result.data, "text/xml");
        // console.log(xmlResult); // cijeli XML
        //console.log(xmlResult.getElementsByTagName("distance")[0].getElementsByTagName("text")[0].innerHTML); // ono što nam treba
        setDistance(
          xmlResult
            .getElementsByTagName("distance")[0]
            .getElementsByTagName("text")[0].innerHTML
        );
      })
      .catch((error) =>
        console.log("Dogodila se greska, obavijestite support!")
      );
  };
  useEffect(() => {
    getMapDistance();
  }, []);

  return <div />;
};

export default GoogleMapDistance;
