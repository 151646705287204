import React from 'react';
import { withNamespaces } from 'react-i18next';

import Loading from '../../assets/images/Loading.png';
import { ReactComponent as Arrow } from '../../assets/images/arrowTransport.svg';
import FromToSection from '../FromToSection/FromToSection';

import auth from '../../auth';
import { useHistory } from 'react-router-dom';

import './TransportCard.css';
const TransportCard = ({ t, data }) => {
  let history = useHistory();
  return (
    <div className="transportCard">
      <div className="transportCard__leftSide">
        <img src={Loading} className="detailImg" alt="" />
        <img src={data.image} className="truckImg" alt="" />
      </div>
      <div className="transportCard__rightSide">
        <FromToSection data={data} />
        <div
          className="rightSide__Currently currently__exept"
          style={{ color: 'white' }}
        >
          {t('dashboard.inTransit')}
        </div>
        <div className="rightSide__Currently">
          {t('dashboard.arrival')}{' '}
          <span className="stateCurrently">{data.arrivalDate}</span>
        </div>
        <button
          className="rightSide_Details"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push(`/shipment/${data.transportId}/`);
          }}
        >
          Details
        </button>
      </div>
    </div>
  );
};

export default withNamespaces()(TransportCard);
