import React, { useState, useEffect } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import axios from 'axios';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { ReactComponent as ListIcon } from '../../../assets/images/dashboard.svg';
import { ReactComponent as LogOut } from '../../../assets/images/NavbarIcons/Logout.svg';
import { ReactComponent as ArrowDown } from '../../../assets/images/NavbarIcons/User.svg';
import { ReactComponent as HomeIcon } from '../../../assets/images/NavbarIcons/Home.svg';
import { ReactComponent as ReviewsIcon } from '../../../assets/images/NavbarIcons/Reviews.svg';
import { ReactComponent as OffersIcon } from '../../../assets/images/NavbarIcons/MyOffers.svg';
import { ReactComponent as OrdersIcon } from '../../../assets/images/NavbarIcons/MyOrders.svg';
import { ReactComponent as PaymentsIcon } from '../../../assets/images/NavbarIcons/Payments.svg';

import {
  startNotificationListener,
  getRecieverHasRead
} from '../../../redux/actions';

import auth from '../../../auth';
import config from '../../../config/api.config.js';
import './DashboardNavbar.css';

const DashboardNavbar = (props) => {
  const { t } = props;
  const [state, setState] = useState({
    userImage: null,
    userName: null
  });
  useEffect(() => {
    axios.get(`${config.apiUrl}/users/cips/${auth.getId()}/`).then((res) => {
      const { first_name, last_name, name } = res.data;
      const userName = name ? name : `${first_name} ${last_name}`;
      setState({
        userName,
        userImage: `url(${config.assetUrl}${res.data.photo})`
      });
    });

    props.notificationsReducer(auth.getId());
    props.messengerReducer(auth.getId());
  }, []);

  return (
    <div className="dashboardNavbar">
      <Link exact to="/main-settings" style={{ textDecoration: 'none' }}>
        <div className="navbar__topSide">
          <div
            className="userImage"
            style={{ backgroundImage: state.userImage }}
          >
            {state.userImage === null && (
              <ContentLoader viewBox="0 0 205 205">
                <rect x="0" y="0" rx="100" ry="100" width="205" height="205" />
              </ContentLoader>
            )}
          </div>
          {state.userName === null ? (
            <div style={{ width: '11.25rem', marginTop: '30px' }}>
              <ContentLoader viewBox="0 0 200 20">
                <rect x="0" y="0" rx="5" ry="5" width="200" height="20" />
              </ContentLoader>
            </div>
          ) : (
            <p>{state.userName}</p>
          )}
        </div>
      </Link>

      <div style={{ overflowY: 'auto', height: 'calc(100vh - 25.5rem)' }}>
        <div className="navbar__bottomSide">
          <ul className="bottomSide__navmenu">
            {auth.isShipper() && (
              <li>
                <NavLink
                  exact
                  activeClassName="bottomSide__navmenu--active"
                  to="/shipments"
                >
                  <HomeIcon className="listIcon" />
                  Home
                </NavLink>
              </li>
            )}
            {auth.isCarrier() && (
              <li>
                <NavLink
                  exact
                  activeClassName="bottomSide__navmenu--active"
                  to="/dashboard"
                >
                  <HomeIcon className="listIcon" />
                  Home
                </NavLink>
              </li>
            )}
            {auth.isCarrier() && (
              <li>
                <NavLink
                  activeClassName="bottomSide__navmenu--active"
                  to="/fuhrpark"
                >
                  <OrdersIcon className="listIcon" />
                  {t('navbar.vehicles')}
                </NavLink>
              </li>
            )}
            {auth.isShipper() && (
              <li>
                <NavLink
                  activeClassName="bottomSide__navmenu--active"
                  to="/orders"
                >
                  <OrdersIcon className="listIcon" />
                  {t('navbar.orders')}
                </NavLink>
              </li>
            )}

            <li>
              <NavLink
                activeClassName="bottomSide__navmenu--active"
                to="/offers"
              >
                <OffersIcon className="listIcon" />
                {t('navbar.offers')}
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="bottomSide__navmenu--active"
                to="/payments"
              >
                <PaymentsIcon className="listIcon" />
                {t('navbar.payments')}
              </NavLink>
            </li>

            <li>
              <NavLink
                activeClassName="bottomSide__navmenu--active"
                to="/reviews"
              >
                <ReviewsIcon className="listIcon" />
                {t('navbar.ratings')}
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="bottomSide__navmenu--active"
                to="/main-settings"
              >
                <ArrowDown className="listIcon" />
                {t('navbar.settings')}
              </NavLink>
            </li>
          </ul>
        </div>
        <div
          className="navbar__logout"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            auth.logout(() => {
              props.history.push('/');
            });
          }}
        >
          <LogOut />
          <p>{t('navbar.logout')}</p>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  notificationsReducer: (userId) => dispatch(startNotificationListener(userId)),
  messengerReducer: (userId) => dispatch(getRecieverHasRead(userId))
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withNamespaces()(DashboardNavbar)));
