import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';

import Footer from '../../Layout/Footer/Footer';
import Navbar from '../../Layout/Navbar/Navbar';
import SliderContainer from '../../components/SliderContent/SliderContent';
import Header from '../../Layout/Header/Header';

import { ReactComponent as VerifiedIcon } from '../../assets/images/verified.svg';
import { ReactComponent as TimeIcon } from '../../assets/images/time.svg';
import { ReactComponent as WalletIcon } from '../../assets/images/wallet.svg';
import { ReactComponent as LikeIcon } from '../../assets/images/like.svg';
import { ReactComponent as MoneyIcon } from '../../assets/images/money.svg';
import { ReactComponent as ChatIcon } from '../../assets/images/chat.svg';
import { ReactComponent as Line } from '../../assets/images/line.svg';

import { useWindowSize } from '../../components/WindowSize/WindowSize';

import './about.css';

const About = ({ t }) => {
  const [language, setLanguage] = useState('gb');
  const [width, height] = useWindowSize();

  return (
    <div style={{ background: '#06016c' }} className="about__wrapper">
      <Helmet>
        <title>Autoversand | {t('about.title')}</title>
      </Helmet>
      <Navbar setLanguage={setLanguage} />
      <Header />
      <main className="main__about">
        <div className="main__sections">
          <section className="main__section1" id="aboutUsSection">
            <div className="section__about">
              <p
                className="fontStyle__title"
                style={{ textTransform: 'uppercase' }}
              >
                {t('about.title')}
              </p>
              <h2 className="fontStyle__h2 about__heading">
                {t('about.wersindwir')}
              </h2>
              <Line />
              <p className="fontStyle__paragraph">{t('about.intro')}</p>
              <div className="section__about--icons">
                <div className="section__icons">
                  <div className="iconStyle">
                    <VerifiedIcon />
                  </div>
                  <p>
                    {t('about.verified')}{' '}
                    <strong> {t('about.transport')}</strong>
                  </p>
                </div>
                <div className="section__icons">
                  <div className="iconStyle">
                    <TimeIcon />
                  </div>
                  <p>
                    24h <strong>{t('about.support')}</strong>
                  </p>
                </div>
                <div className="section__icons">
                  <div className="iconStyle">
                    <WalletIcon />
                  </div>
                  <p>
                    {t('about.bestprice')}{' '}
                    <strong>{t('about.yourTransport')}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="section__video--temp">
              <ReactPlayer
                url={
                  language == 'gb'
                    ? 'https://vimeo.com/431400282'
                    : 'https://vimeo.com/436812045'
                }
              />
            </div>
          </section>
          <section className="main__section2" id="serviceSection">
            <div>
              <h2 className="fontStyle__h2 about__heading">
                {t('about.offer')}
              </h2>
            </div>
            <Line />
            <SliderContainer />
            <div className="section__services--icons">
              <div className="section__icons">
                <div>
                  <VerifiedIcon className="iconStyle" />
                </div>
                <p>
                  {t('about.verified')} <strong>{t('about.customers')}</strong>
                </p>
              </div>
              <div className="section__icons" style={{ width: 'fit-content' }}>
                <div>
                  <ChatIcon className="iconStyle" />
                </div>
                <p>
                  {t('about.fast')} <strong>{t('about.notifications')}</strong>
                </p>
              </div>
              <div className="section__icons">
                <div>
                  <LikeIcon className="iconStyle" />
                </div>
                <p>
                  {t('about.simple')} <strong>{t('about.handling')}</strong>
                </p>
              </div>
              <div className="section__icons">
                <div>
                  <MoneyIcon className="iconStyle" />
                </div>
                <p>
                  {t('about.added')} <strong>{t('about.value')}</strong>
                </p>
              </div>
            </div>
          </section>
          <section className="main__section3" id="spediteureSection">
            <div className="section__spediteure">
              <p className="fontStyle__title">{t('about.spediture')}</p>
              <h2 className="fontStyle__h2 about__heading">
                {t('about.serviceh2')}
              </h2>
              <Line />
              <p className="fontStyle__paragraph">{t('about.servicep')}</p>
            </div>
            <div className="section__button">
              <Link to="/register" style={{ textDecoration: 'none' }}>
                <button>{t('about.freeregister')}</button>
              </Link>
            </div>
          </section>
          <Footer styleFooter="about__footer" />
        </div>
      </main>
    </div>
  );
};

export default withNamespaces()(About);
