import React, { useState } from 'react';
import GoogleMapShipment from '../../GoogleMapShipment/GoogleMapShipment';
import { ReactComponent as TransportLine } from '../../../assets/images/transportLine.svg';
import { ReactComponent as TransportPosition } from '../../../assets/images/transportPosition.svg';
import { withNamespaces } from 'react-i18next';

import './ShipmentPosition.css';

const ShipmentPosition = ({ t, data }) => {
  let transportPositionStyle;
  let currentLocation = data.state;

  if (currentLocation == 'pending') transportPositionStyle = 'start';
  else if (currentLocation == 'live') transportPositionStyle = 'inProgress';
  else if (currentLocation == 'completed')
    transportPositionStyle = 'destination';

  return (
    <div className="shipmentPosition">
      <div className="shipmentPosition__left">
        <div className="shipmentPosition__leftLine">
          <TransportPosition
            style={{ width: '1rem', height: '1rem', position: 'absolute' }}
            className={transportPositionStyle}
          />
          <TransportLine style={{ height: '13.969rem' }} />
        </div>
        <div>
          <div
            className={
              transportPositionStyle == 'start'
                ? 'shipmentPosition__from locationColor'
                : 'shipmentPosition__from'
            }
          >
            <p className="positionFrom__city">{data.from.city}</p>
            <p className="positionFrom__country">
              {data.from.country},{' '}
              <label className="positionFrom__date">
                {t('shipments.pickupDate')} {data.from.pickupDate}
              </label>
            </p>
          </div>
          <div
            className={
              transportPositionStyle == 'inProgress'
                ? 'shipmentPosition__inprogress locationColor'
                : 'shipmentPosition__inprogress'
            }
          >
            {t('shipments.inProgress')}
          </div>
          <div className="shipmentPosition__to">
            <p className="positionTo__city">{data.to.city}</p>
            <p className="positionTo__country">
              {data.to.country},{' '}
              <label className="positionTo__date">
                {t('offers.deliveryDate')} {data.to.deliveryDate}
              </label>
            </p>
          </div>
        </div>
      </div>
      <div className="shipmentPosition__right">
        <GoogleMapShipment
          fromPosition={data.from.fromPosition}
          toPosition={data.to.toPosition}
        />
      </div>
    </div>
  );
};

export default withNamespaces()(ShipmentPosition);
