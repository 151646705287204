import React, { useEffect, useRef } from "react";

import "./Textarea.css";
const Textarea = ({
  handleChange,
  handleBlur,
  settings = false,
  labelText,
  ...otherProps
}) => {
  let element = useRef();
  useEffect(() => {
    // if (element.current.value != "")
    //   element.current.style.border = "solid 2px rgba(121,119,164,0.5)";
    // else element.current.style.border = "solid 2px #7977a4";
    if (element.current.value != "") {
      if (settings) element.current.style.backgroundColor = "white";
      else element.current.style.backgroundColor = "rgba(6, 1, 108, 0.03)";
      element.current.style.border = "none";
    } else {
      element.current.style.border = "solid 2px #7977a4";
      element.current.style.backgroundColor = "transparent";
    }
  });
  return (
    <div className="group-textarea">
      <textarea
        className="form-textareaInput"
        onChange={handleChange}
        onBlur={handleBlur}
        {...otherProps}
        ref={element}
      ></textarea>
      <label htmlFor={otherProps.name} className="form-textareaLabel">
        {labelText}
      </label>
    </div>
  );
};

export default Textarea;
