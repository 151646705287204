const defaultState = {
  notifications: [],
  hasRead: true
};

const notificationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_NOTIFICATIONS':
      defaultState.notifications = action.data;
      return { ...state, ...action.data };
    case 'SET_HASREAD':
      defaultState.hasRead = action.data;
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default notificationsReducer;
