import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../../auth';

import Header from '../../Layout/Dashboard/DashboardHeader/DashboardHeader';
import MobileHeader from '../../Layout/Mobile/Header';
import MobileNavbar from '../../Layout/Mobile/Navbar';
import Navbar from '../../Layout/Dashboard/DashboardNavbar/DashboardNavbar';

export const DefaultProtection = ({ component: Component, ...otherProps }) => {
  return (
    <Route
      {...otherProps}
      render={(props) => {
        if (auth.isAuthenticated()) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

export const DashboardProtection = ({
  component: Component,
  ...otherProps
}) => (
  <Route
    {...otherProps}
    render={(props) => {
      if (auth.isAuthenticated()) {
        return (
          <div className="component__content">
            <Header />
            <MobileHeader />
            <div className="content__main">
              <Navbar />
              <Component {...props} />
            </div>
            <MobileNavbar />
          </div>
        );
      } else {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location
              }
            }}
          />
        );
      }
    }}
  />
);
