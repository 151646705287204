import React from "react";
import { ReactComponent as Arrow } from "../../assets/images/arrowTransport.svg";

import "./FromToSection.css";

const FromToSection = ({ data }) => {
  return (
    <div className="fromToSection">
      <div>
        <p className="fromTo__city">{data.from.city}</p>
        <p className="fromTo__country">{data.from.country}</p>
      </div>
      <Arrow className="fromToSection__arrow" />
      <div>
        <p className="fromTo__city">{data.to.city}</p>
        <p className="fromTo__country">{data.to.country}</p>
      </div>
    </div>
  );
};

export default FromToSection;
