import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import { withNamespaces } from 'react-i18next';

import DocumentUpload from '../DocumentUpload/DocumentUpload';
import './FileUpload.css';

const FileUpload = ({
  t,
  className,
  updateFile = false,
  uploadedFiles = [],
  onFileChange = null,
  children,
  setFileArray
}) => {
  const [dragOver, setDragOver] = useState(false);
  const [filesArray, setFilesArray] = useState(uploadedFiles);
  const [replace, setReplace] = useState(false);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: '.pdf',
    noClick: true,
    // maxSize:1000000,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length && acceptedFiles[0].size > 10000000) {
        notifyUser('fileSize');
        return;
      }
      if (acceptedFiles.length > 1) return;
      setFilesArray(acceptedFiles);
      // setFileArray(filesArray);

      if (updateFile) {
        onFileChange(filesArray);
        setFileArray(filesArray);
      }
      setDragOver(false);
    },
    onDragEnter: () => {
      setDragOver(true);
    },
    onDragLeave: () => {
      setDragOver(false);
    }
  });

  useEffect(() => {
    setFileArray(filesArray);
  }, [filesArray]);

  const removeFile = (file) => {
    const newFiles = [...filesArray];
    newFiles.splice(file, 1);
    setFilesArray(newFiles);
  };

  let _files = filesArray.map((file) => (
    <DocumentUpload key={file.path} file={file} />
  ));
  const createFiles = () => {
    setFileArray(filesArray);
    _files = filesArray.map((file) => (
      <DocumentUpload key={file.path} file={file} />
    ));
  };

  const notifyUser = (notificationFor) => {
    const Msg_FileSize = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          {t('toast.fileSize')}
        </p>
      </div>
    );

    if (notificationFor == 'fileSize') {
      toast.error(<Msg_FileSize />, {
        position: 'top-right',
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
    }
  };

  return (
    <div className="fileUpload">
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        enableMultiContainer
        containerId={'A'}
      />
      <aside
        className="thumbsContainer"
        className={_files.length > 0 ? 'fileAside' : ''}
      >
        {_files}
      </aside>
      <div className={dragOver ? 'dragOver ' + className : className}>
        <div
          {...getRootProps({
            className: updateFile
              ? 'dropzoneFile dropzoneFileUpdate'
              : 'dropzoneFile'
          })}
        >
          <input {...getInputProps()} />
          {children}
          <button type="button" className="fileUpload__button" onClick={open}>
            {_files.length ? t('upload.browseOther') : t('upload.browse')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(FileUpload);
