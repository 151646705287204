import React from 'react';
import moment from 'moment';
import axios from 'axios';

import ChatListComponent from './chatlist/chatlist';
import ChatViewComponent from './chatview/chatview';
import ChatTextBoxComponent from './chatTextBox/chatTextBox';
import NewChatComponent from './newChat/newChat';
import Navbar from '../../Layout/Dashboard/DashboardHeader/DashboardHeader';
import MobileHeader from '../../Layout/Mobile/Header';
import MobileNavbar from '../../Layout/Mobile/Navbar';
import { ReactComponent as NothingFound } from '../../assets/images/nothingFound.svg';

import auth from '../../auth';
import { IoIosColorPalette } from 'react-icons/io';
import config from '../../config/api.config.js';
import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';

import './messenger.css';

const firebase = require('firebase');
require('firebase/firestore');

// firebase.initializeApp({
//   apiKey: 'AIzaSyAnKu4t7FU2KyNjWfoICI1tFMHIDGhBKs4',
//   authDomain: 'autoversand-messenger.firebaseapp.com',
//   databaseURL: 'https://autoversand-messenger.firebaseio.com',
//   projectId: 'autoversand-messenger',
//   storageBucket: 'autoversand-messenger.appspot.com',
//   messagingSenderId: '213615394329',
//   appId: '1:213615394329:web:fdb5e6fd60daae87d03488'
// });

const override = css`
  margin: 0 auto;
  width: 100px;
  margin-top: 200px;
  margin-bottom: 50px;
`;

class DashboardComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedChat: null,
      newChatFormVisible: false,
      currentUserId: auth.getId(),
      email: null,
      chats: [],
      visible: true,
      isLoading: true
    };
  }

  render() {
    return (
      <div>
        <div
          style={{ backgroundColor: 'white', height: '100vh' }}
          className="messenger"
        >
          <Navbar />
          {this.state.isLoading ? (
            <PuffLoader
              css={override}
              size={60}
              margin={2}
              color={'rgba(116, 38, 251, 0.4)'}
              loading={this.state.isLoading}
            />
          ) : this.state.chats.length ? (
            <div>
              <ChatListComponent
                history={this.props.history}
                newChatBtnFn={this.newChatBtnClicked}
                selectChatFn={this.selectChat}
                chats={this.state.chats}
                usersId={this.state.idCollections}
                userEmail={this.state.id}
                selectChatIndex={this.state.selectedChat}
                changeVisibility={this.changeVisibility}
                visible={this.state.visible}
              />
              {this.state.newChatFormVisible ? null : (
                <ChatViewComponent
                  user={this.state.id}
                  chat={this.state.chats[this.state.selectedChat]}
                  changeVisibility={this.changeVisibility}
                  visible={this.state.visible}
                />
              )}
              {this.state.selectedChat !== null &&
              !this.state.newChatFormVisible ? (
                <ChatTextBoxComponent
                  messageReadFn={this.messageRead}
                  submitMessageFn={this.submitMessage}
                ></ChatTextBoxComponent>
              ) : null}
              {this.state.newChatFormVisible ? (
                <NewChatComponent
                  goToChatFn={this.goToChat}
                  newChatSubmitFn={this.newChatSubmit}
                ></NewChatComponent>
              ) : null}
            </div>
          ) : (
            <div>
              <MobileHeader />
              <div style={{ width: '57%', margin: '27px auto' }}>
                <NothingFound style={{ width: '100%', height: '100%' }} />
              </div>
            </div>
          )}
        </div>
        <MobileNavbar />
      </div>
    );
  }

  signOut = () => firebase.auth().signOut();

  changeVisibility = (value) => {
    this.setState({
      visible: value
    });
  };
  selectChat = async (chatIndex) => {
    await this.setState({
      selectedChat: chatIndex
    });
    this.messageRead();
  };

  submitMessage = (msg) => {
    const docKey = this.buildDocKey(
      this.state.chats[this.state.selectedChat].users.filter(
        (_usr) => _usr !== this.state.id
      )[0]
    );
    firebase
      .firestore()
      .collection('chats')
      .doc(docKey)
      .update({
        messages: firebase.firestore.FieldValue.arrayUnion({
          sender: this.state.id,
          message: msg,
          timestamp: moment().valueOf()
        }),
        receiverHasRead: false
      });
  };

  buildDocKey = (friend) => [this.state.id, friend].sort().join(':');

  messageRead = () => {
    const chatIndex = this.state.selectedChat;
    const docKey = this.buildDocKey(
      this.state.chats[chatIndex].users.filter(
        (_usr) => _usr !== this.state.id
      )[0]
    );
    if (this.clickedChatWhereNotSender(this.state.selectedChat)) {
      firebase.firestore().collection('chats').doc(docKey).update({
        receiverHasRead: true
      });
    } else {
      console.log('Clicked message where the usere was the sender');
    }
  };

  goToChat = async (docKey, msg) => {
    let usersInChat = docKey.split(':');
    usersInChat = new Array(parseInt(usersInChat[0]), parseInt(usersInChat[0]));

    const chat = this.state.chats.find((_chat) =>
      usersInChat.every((_user) => _chat.users.includes(_user))
    );
    this.setState({ newChatFormVisible: false });
    await this.selectChat(this.state.chats.indexOf(chat));
    if (msg.trim()) this.submitMessage(msg);
  };

  newChatSubmit = async (chatObj) => {
    const docKey = this.buildDocKey(chatObj.sendTo);

    await firebase
      .firestore()
      .collection('chats')
      .doc(docKey)
      .set({
        receiverHasRead: true,
        usersData: [
          {
            id: parseInt(auth.getId()),
            name: chatObj.secondUser,
            picture: chatObj.secondUserPhoto
          },
          {
            id: parseInt(chatObj.sendTo),
            name: chatObj.firstUser,
            picture: chatObj.firstUserPhoto
          }
        ],
        users: [this.state.id, parseInt(chatObj.sendTo)],
        messages: [
          {
            message: chatObj.message,
            sender: this.state.id,
            timestamp: moment().valueOf()
          }
        ]
      });
    this.setState({
      newChatFormVisible: false
    });
    this.selectChat(this.state.chats.length - 1);
  };

  clickedChatWhereNotSender = (chatIndex) =>
    this.state.chats[chatIndex].messages[
      this.state.chats[chatIndex].messages.length - 1
    ].sender !== this.state.id;

  newChatBtnClicked = () => {
    this.setState({
      newChatFormVisible: true,
      selectChat: null
    });
  };

  componentDidMount = async () => {
    await firebase
      .firestore()
      .collection('chats')
      .where('users', 'array-contains', parseInt(this.state.currentUserId))
      .onSnapshot(async (res) => {
        const chats = res.docs.map((_doc) => _doc.data());
        await this.setState({
          id: parseInt(this.state.currentUserId),
          chats: chats
        });
        await this.setState({ isLoading: false });
      });

    let url_string = window.location.href;
    let url = new URL(url_string);
    let recipientId = url.searchParams.get('newChat');

    if (recipientId) {
      const chat = await firebase
        .firestore()
        .collection('chats')
        .doc(
          [parseInt(auth.getId()), recipientId].sort((a, b) => a - b).join(':')
        )
        .get();
      if (!chat.exists) {
        let firstUserName = '';
        let firstUserPhoto = '';
        let secondUserName = '';
        let secondUserPhoto = '';

        await axios
          .get(`${config.apiUrl}/users/cips/${recipientId}/`)
          .then((res) => {
            console.log(res);
            if (res.data.hasOwnProperty('name')) firstUserName = res.data.name;
            else
              firstUserName = `${res.data.first_name} ${
                res.data.type == 'natural_shipper'
                  ? res.data.last_name.slice(0, 1) + '.'
                  : res.data.last_name
              }`;
            firstUserPhoto = res.data.photo;
          });
        await axios
          .get(`${config.apiUrl}/users/cips/${auth.getId()}/`)
          .then((res) => {
            if (res.data.hasOwnProperty('name')) secondUserName = res.data.name;
            else
              secondUserName = `${res.data.first_name} ${
                res.data.type == 'natural_shipper'
                  ? res.data.last_name.slice(0, 1) + '.'
                  : res.data.last_name
              }`;
            secondUserPhoto = res.data.photo;
          });
        await this.newChatSubmit({
          sendTo: recipientId,
          message: ' ',
          firstUser: firstUserName,
          secondUser: secondUserName,
          firstUserPhoto,
          secondUserPhoto
        });
      }
      this.goToChat(
        [parseInt(auth.getId()), recipientId].sort((a, b) => a - b).join(':'),
        ' '
      );
    }
  };
}

export default DashboardComponent;
