import React, { Component } from "react";
import { Animated } from "react-animated-css";

class Experiment extends Component {
  constructor() {
    super();
    this.state = {
      animationStarted: false,
    };
  }
  componentDidMount() {
    this.setState({ animationStarted: true });
  }
  render() {
    return (
      <Animated
        animationIn="bounceInLeft"
        animationOut="fadeOut"
        isVisible={this.state.animationStarted}
      >
        <div>hello world ;)</div>
      </Animated>
    );
  }
}

export default Experiment;
