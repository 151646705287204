import axios from 'axios';
import LocalStorageService from './LocalStorageService';
import apiConfig from './config/api.config.js';

let calledOnce = false;

axios.interceptors.request.use(
  (config) => {
    if (
      config.url !== `${apiConfig.apiUrl}/shipments/photo-upload/` &&
      config.url !== `${apiConfig.apiUrl}/token/refresh/` &&
      config.url !== `${apiConfig.apiUrl}/shipments/all/` &&
      config.url.split('/api')[1].slice(0, 6) !== '/oauth' &&
      !config.url.includes('cips') &&
      config.url !== `${apiConfig.apiUrl}/payments/stripe/`
    ) {
      const token = LocalStorageService.get('access_token');
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === `${apiConfig.apiUrl}/token/` &&
      originalRequest.url.split('/api')[1].slice(0, 6) !== '/oauth'
    ) {
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !calledOnce &&
      originalRequest.url.split('/api')[1].slice(0, 6) !== '/oauth'
    ) {
      originalRequest._retry = true;
      calledOnce = true;
      const refreshToken = LocalStorageService.get('refresh_token');
      if (refreshToken === undefined) {
        console.log('REFRESH IS UNDEFINED');
      }
      console.log('Ovo je refresh token iz localStorage', refreshToken);
      return axios
        .post(`${apiConfig.apiUrl}/token/refresh/`, {
          refresh: refreshToken
        })
        .then((res) => {
          console.log('Resultat requesta', res);
          if (res.status === 200) {
            const { access } = res.data;
            LocalStorageService.set({
              access_token: access,
              refresh_token: refreshToken
            });
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access;

            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);
