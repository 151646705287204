import React from 'react';

import './BlueButton.css';

const BlueButton = ({ handleOpen, children, ...otherProps }) => {
  return (
    <button className="blueButton" onClick={handleOpen} {...otherProps}>
      {children}
    </button>
  );
};

export default BlueButton;
