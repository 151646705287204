import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { ReactComponent as Confirmation } from '../../assets/images/Notifications/confirmation.svg';
import { ReactComponent as Info } from '../../assets/images/Notifications/info.svg';
import { ReactComponent as Warning } from '../../assets/images/Notifications/warning.svg';

import auth from '../../auth';
import './Notification.css';

const Notification = ({ data }) => {
  const getBackgroundColor = () => {
    switch (data.type) {
      case 'info':
        return 'rgba(38, 153, 251, 0.4)';
      case 'warning':
        return 'rgba(230, 189, 55, 0.4)';
      case 'confirmation':
        return 'rgba(55, 230, 119, 0.4)';
    }
  };

  const getRoute = () => {
    switch (data.route) {
      case 'null':
        return;
      case 'Orders':
        return '/orders';
      case 'Search':
        return '/results';
      case 'Truck':
        return '/fuhrpark';
      case 'Offers':
        return '/offers';
      case 'Payment':
        return '/payments';
      case 'CarrierHome':
        return '/dashboard';
      case 'Ratings':
        return '/reviews';
      case 'Account':
        return auth.isCarrier()
          ? `company-profile/${auth.getId()}`
          : `shipper-profile/${auth.getId()}`;
      default:
        return null;
    }
  };
  return (
    <Link to={getRoute()} style={{ textDecoration: 'none' }}>
      <div className="notifications">
        <div
          className="notification__userPicture"
          style={{ backgroundColor: getBackgroundColor() }}
        >
          {data.type === 'confirmation' ? (
            <Confirmation />
          ) : data.type === 'info' ? (
            <Info />
          ) : (
            <Warning />
          )}
        </div>
        <div className="notification__content">
          <div className="notification__contentTitle">{data.title}</div>
          <div className="notification__contentMessage">
            <p className="notification__contentMessage--paragr">{data.body}</p>
          </div>
          <p className="notification__contentHour">
            {moment.unix(data.timestamp).format('DD.MM.YYYY. HH:mm')}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default Notification;
