import React from 'react';
import FromToSection from '../FromToSection/FromToSection';
import { ReactComponent as Check } from '../../assets/images/shipmentViewCheck.svg';

import auth from '../../auth';
import { useHistory } from 'react-router-dom';

import './ShipmentsCard.css';

const ShipmentsCard = ({ data, handleClick }) => {
  let history = useHistory();
  return (
    <div className="shipmentsCard">
      <div className="shipmentsCard__card">
        <div
          className="shipmentsCard__top"
          style={{ backgroundImage: data.backgroundImage }}
        ></div>
        <div className="shipmentsCard__bottom">
          <div className="shipmentsCard__carType">{data.transportType}</div>
          <FromToSection data={data.location} />
        </div>
        <button
          className="shipmentsCard__button"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push(`/shipment/${data.shipmentID}/`);
          }}
        >
          DETAILS
        </button>
      </div>
    </div>
  );
};
export default ShipmentsCard;
