import axios from 'axios';
import auth from '../../auth';
import config from '../../config/api.config.js';

const firebase = require('firebase');
require('firebase/firestore');

export const setToken = (data) => {
  return {
    type: 'CREATE_TOKEN',
    data
  };
};

export const getShipments = () => {
  return (dispatch) =>
    axios.get(`${config.apiUrl}/shipments/all/`).then((response) => {
      dispatch({ type: 'GET_SHIPMENTS', data: response.data });
      // shipments.push(response.data)
    });
};

export const startNotificationListener = (_userId) => {
  return async (dispatch) => {
    await firebase
      .firestore()
      .collection('notifications')
      .where('userId', '==', parseInt(_userId))
      .onSnapshot(async (res) => {
        res.docs.map((_doc) => {
          //  console.log('NOTIFICATIONS LISTENER', _doc.data());

          const _data = _doc.data() ?? [];

          if (_data.notifications.length) {
            _data.notifications = _data.notifications.reverse();
          }
          dispatch({
            type: 'GET_NOTIFICATIONS',
            data: _data
          });
          dispatch({
            type: 'SET_HASREAD',
            data: _data.hasRead
          });
        });
      });
  };
};

export const getRecieverHasRead = (_userId) => {
  return async (dispatch) => {
    await firebase
      .firestore()
      .collection('chats')
      .where('users', 'array-contains', parseInt(_userId))
      .onSnapshot(async (res) => {
        res.docs.map((_doc) => {
          const _data = _doc.data() ?? [];
          dispatch({
            type: 'SET_MESSEGESREAD',
            data: {
              hasRead: _data.receiverHasRead,
              lastSender: _data.messages.slice(-1)[0].sender
            }
          });
        });
      });
  };
};

export const getNumOfChats = (_userId) => {
  return async (dispatch) => {
    await firebase
      .firestore()
      .collection('chats')
      .where('users', 'array-contains', parseInt(_userId))
      .onSnapshot(async (res) => {
        const chats = res.docs.map((_doc) => _doc.data());
        dispatch({
          type: 'GET_NUM_OF_CHATS',
          data: {
            numberOfChats: 2
          }
        });
      });
  };
};
