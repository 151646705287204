import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

import ClipLoader from 'react-spinners/ClipLoader';
import FormInput from '../../FormInput/FormInput';
import { useDropdown } from '../../useDropdown/useDropdown';
import Button from '../../Buttons/BlueButton';
import BackButton from '../../Buttons/BackButton';
import Textarea from '../../Textarea/Textarea';
import auth from '../../../auth';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './giveBid.css';
import { createSelectorHook } from 'react-redux';
import config from '../../../config/api.config.js';
import Select from 'react-select';

const GiveBid = ({
  t,
  onClickClose,
  data,
  setOfferAccepted,
  offerId = null,
  setReloadPage = null
}) => {
  const [priceValid, setPriceValid] = useState(true);
  const [descriptionValid, setDescriptionValid] = useState(true);
  const [startDateValid, setStartDateValid] = useState(true);
  const [deliveryDateValid, setDeliveryDateValid] = useState(true);
  const [values, setValues] = useState({
    bidPrice: 0,
    description: ''
  });
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [daysUntilArrival, setDaysUntilArrival] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(
    [...Array(10).keys()].map((idx) => ({
      value: idx + 1,
      label: `After ${idx + 1} days`
    }))
  );

  let selectedDeliveryDate = useRef();

  const handleClick = () => {
    onClickClose(false);
  };

  const handleOpen = async () => {
    let isValid = true;
    if (values.bidPrice == 0 || isNaN(values.bidPrice)) {
      setPriceValid(false);
      isValid = false;
    }
    if (values.description == '') {
      setDescriptionValid(false);
      isValid = false;
    }
    if (startDate == null) {
      setStartDateValid(false);
      isValid = false;
    }
    if (daysUntilArrival == null) {
      setDeliveryDateValid(false);
      isValid = false;
    }

    if (!isValid) {
      return setTimeout(() => {
        setPriceValid(true);
        setDescriptionValid(true);
        setStartDateValid(true);
        setDeliveryDateValid(true);
      }, 500);
    }

    await setLoading(true);

    await axios
      .post(`${config.apiUrl}/offers/`, {
        shipment: data.shipmentId,
        amount: parseInt(values.bidPrice, 10),
        pickup_date: moment(startDate).format('YYYY-MM-DD'),
        delivery_date: moment(startDate)
          .add('days', daysUntilArrival.value)
          .format('YYYY-MM-DD'),
        description: values.description
      })
      .then((res) => {
        setOfferAccepted(true);
      })
      .catch((err) => {
        console.log(err);
      });

    await onClickClose(false);
    await setLoading(false);
  };

  const getOffer = async () => {
    if (!offerId) return;

    await axios
      .get(`${config.apiUrl}/offers/single/${offerId}/`)
      .then((res) => {
        setValues({
          bidPrice: res.data.amount,
          description: res.data.description
        });
        setStartDate(res.data.pickup_date);
        const dateDiff = moment(res.data.delivery_date).diff(
          res.data.pickup_date,
          'days'
        );
        setDaysUntilArrival({
          value: dateDiff,
          label: 'After ' + dateDiff + ' days'
        });
      });
  };

  const updateOffer = async () => {
    let isValid = true;
    if (values.bidPrice == 0 || isNaN(values.bidPrice)) {
      setPriceValid(false);
      isValid = false;
    }
    if (values.description == '') {
      setDescriptionValid(false);
      isValid = false;
    }
    if (startDate == null) {
      setStartDateValid(false);
      isValid = false;
    }
    if (daysUntilArrival == null) {
      setDeliveryDateValid(false);
      isValid = false;
    }

    if (!isValid) {
      return setTimeout(() => {
        setPriceValid(true);
        setDescriptionValid(true);
        setStartDateValid(true);
        setDeliveryDateValid(true);
      }, 500);
    }

    await setLoading(true);
    await axios
      .patch(`${config.apiUrl}/offers/update/${offerId}/ `, {
        amount: parseInt(values.bidPrice, 10),
        pickup_date: moment(startDate).format('YYYY-MM-DD'),
        delivery_date: moment(startDate)
          .add('days', daysUntilArrival.value)
          .format('YYYY-MM-DD'),
        description: values.description
      })
      .catch((error) => {
        console.log(error);
      });
    await onClickClose(false);
    await setLoading(false);
    await setReloadPage(true);
  };
  const handleChange = (e) => {
    const value = e.target.value;

    setValues({
      ...values,
      [e.target.name]: value
    });
  };

  const changeInputStyle = (element) => {
    const controlRef = element.current.select.controlRef;

    if (element.current.props.value != null) {
      controlRef.style.backgroundColor = 'rgba(6, 1, 108, 0.03)';
      controlRef.style.border = 'none';
    } else {
      controlRef.style.backgroundColor = 'transparent';
    }
  };

  useEffect(() => {
    let label = document.createElement('label');
    label.className = 'givBid__labelClass';
    label.id = 'givBid_dateLabel';
    label.innerText = `${t('shipments.pickupDate')}`;
    document
      .getElementsByClassName('react-datepicker__input-container')[0]
      .appendChild(label);
  }, []);

  useEffect(() => {
    if (startDate != null) {
      document
        .getElementById('givBid_dateLabel')
        .setAttribute('class', 'givBid__labelClass--exp');
    } else {
      document
        .getElementById('givBid_dateLabel')
        .setAttribute('class', 'givBid__labelClass');
    }
  }, [startDate]);

  useEffect(() => {
    changeInputStyle(selectedDeliveryDate);
  });

  useEffect(() => {
    getOffer();
  }, [offerId]);

  return (
    <div className="givBid">
      <div className="givBid__content">
        <h2 className="givBid__content--heading">
          {offerId ? t('offers.edit') : t('offers.Offer')}
        </h2>
        <p className="givBid__content--paragraph">
          {offerId ? t('offers.editOffer') : t('offers.bestPrice')}
        </p>
        <div className="givBid__form">
          <div className="form__inputField">
            <div className={!priceValid && 'shakeIt'}>
              <FormInput
                type="text"
                name="bidPrice"
                labelText={t('offers.offerPrice')}
                value={values.bidPrice != 0 ? values.bidPrice : null}
                required
                onChange={handleChange}
              />
            </div>
            <p>
              {' '}
              {t('offers.commision')}{' '}
              <label>
                {values.bidPrice != 0
                  ? (values.bidPrice * 0.8).toFixed(2)
                  : values.bidPrice * 0.8}
                €
              </label>
            </p>
          </div>
          <div className="form__inputField">
            <div className={!startDateValid && 'shakeIt'}>
              <DatePicker
                selected={startDate ? new Date(startDate) : null}
                onChange={(date) => setStartDate(date)}
                openToDate={new Date()}
                id="givBid__datePicker"
                required
                className={startDate != null ? 'givBid__borderStyle' : ''}
              />
            </div>
          </div>
          <div className="form__inputField deliveryDate">
            <div className={!deliveryDateValid && 'shakeIt'}>
              <Select
                options={deliveryDate}
                value={daysUntilArrival}
                placeholder=""
                className="customSelectAddShipment"
                onChange={(newValue) => {
                  setDaysUntilArrival(newValue);
                }}
                ref={selectedDeliveryDate}
              />
              <label
                className={daysUntilArrival ? 'form-label-exep' : 'form-label'}
              >
                {t('offers.deliveryDate')}
              </label>
            </div>
          </div>
          <div className="form__inputField">
            <div className={!descriptionValid && 'shakeIt'}>
              <Textarea
                name="description"
                labelText={t('fuhrpark.shortDesc')}
                required
                value={values.description}
                onChange={handleChange}
                maxlength="1000"
              />
            </div>
          </div>
        </div>
        {offerId ? (
          <Button handleOpen={updateOffer}>
            {loading ? (
              <ClipLoader size={15} color={'#fff'} loading={loading} />
            ) : (
              t('settings.save')
            )}
          </Button>
        ) : (
          <Button handleOpen={handleOpen}>
            {loading ? (
              <ClipLoader size={15} color={'#fff'} loading={loading} />
            ) : (
              t('offers.placeOffer')
            )}
          </Button>
        )}
      </div>
      <BackButton handleClick={handleClick} />
    </div>
  );
};

export default withNamespaces()(GiveBid);
