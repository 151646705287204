import React from 'react';
import Send from '@material-ui/icons/Send';

import './chattextbox.css';

class ChatTextBoxComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      chatText: ''
    };
  }

  render() {
    return (
      <div className="chatContainer">
        <input
          placeholder="Type your message..."
          onKeyUp={(e) => this.userTyping(e)}
          id="chattextbox"
          onFocus={this.userClickedInput}
          className="inputSendMessage"
          maxLength={250}
        />
        <div>
          <Send
            style={{ color: '#fff', cursor: 'pointer', fontSize: '18px' }}
            onClick={this.submitMessage}
          />
        </div>
      </div>
    );
  }

  userTyping = (e) =>
    e.keyCode === 13
      ? this.submitMessage()
      : this.setState({ chatText: e.target.value });

  messageValid = (txt) => txt && txt.replace(/\s/g, '').length;

  userClickedInput = () => this.props.messageReadFn();

  submitMessage = () => {
    if (this.messageValid(this.state.chatText)) {
      this.props.submitMessageFn(this.state.chatText);
      document.getElementById('chattextbox').value = '';
    }
  };
}

export default ChatTextBoxComponent;
