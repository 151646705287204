import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import request from 'superagent';
import { ToastContainer, toast } from 'react-toastify';
import { withNamespaces } from 'react-i18next';
import config from '../../../config/api.config.js';
import './ImageUpload.css';

const ImageUpload = ({
  t,
  className,
  updateImage = false,
  uploadedImages = [],
  onImageChange,
  children,
  setImageArray,
  isShipment = false
}) => {
  const thumb = {
    width: '11.813rem',
    position: 'relative',
    objectFit: 'contain',
    margin: '0.125rem',
    height: '7rem'
  };
  const img = {
    display: 'block',
    width: '11.813rem',
    objectFit: 'cover',
    height: '7rem'
  };

  const thumbSecond = {
    width: '7.6rem',
    position: 'relative',
    objectFit: 'contain',
    height: '6rem',
    margin: '0.125rem'
  };
  const imgSecond = {
    display: 'block',
    width: '7.6rem',
    objectFit: 'cover',
    height: '6rem'
  };

  const notifyUser = (notificationFor) => {
    const Msg_NumOfImage = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          {t('toast.images')}
        </p>
      </div>
    );

    const Msg_SecondNumOfImage = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          {t('toast.imagesUpload')}
        </p>
      </div>
    );

    if (notificationFor == 'imageLength') {
      toast.error(<Msg_NumOfImage />, {
        position: 'top-right',
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
    }

    if (notificationFor == 'imageLengthSecond') {
      toast.error(<Msg_SecondNumOfImage />, {
        position: 'top-right',
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
    }
  };

  const createImagesArray = () => {
    let files = [];
    uploadedImages.map((file, i) => {
      files.push({ preview: file });
    });
    return files;
  };
  const [dragOver, setDragOver] = useState(false);
  const [files, setFiles] = useState(createImagesArray);
  const [replace, setReplace] = useState(false);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*',
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      if (isShipment) {
        if (files.length >= 9) {
          notifyUser('imageLengthSecond');
          return;
        }
        if (acceptedFiles.length > 9) {
          notifyUser('imageLengthSecond');
          return;
        }
        if (files.length + acceptedFiles.length >= 10) {
          notifyUser('imageLengthSecond');
          return;
        }
      } else {
        if (files.length >= 3) {
          notifyUser('imageLength');
          return;
        }
        if (acceptedFiles.length > 3) {
          notifyUser('imageLength');
          return;
        }
        if (files.length + acceptedFiles.length >= 4) {
          notifyUser('imageLength');
          return;
        }
      }
      acceptedFiles.forEach((file) => {
        let formData = new FormData();
        formData.append('file', file);
        axios
          .post(`${config.apiUrl}/shipments/photo-upload/`, formData)
          .then((res) => {
            // console.log(res.data.file);
          })
          .catch((err) => {
            console.log(err);
          });
      });

      if (replace == true) {
        const tempFiles = [...files];
        tempFiles.splice(index, 1);
        setFiles(
          tempFiles.concat(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file)
              })
            )
          )
        );
        if (updateImage) {
          onImageChange(files);
        }
        createThumb();
        setReplace(false);
        return;
      }
      setFiles(
        files.concat(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        )
      );
      if (updateImage) {
        onImageChange(files);
      }
      setDragOver(false);
    },
    onDragEnter: () => {
      setDragOver(true);
    },
    onDragLeave: () => {
      setDragOver(false);
    }
  });

  const removeFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);
    setFiles(newFiles);
  };
  var index = 0;
  const replaceFile = (i, fun) => {
    fun();
    index = i;
    setReplace(true);
  };
  const createThumb = () => {
    if (updateImage) {
      // console.log(files);
      setImageArray(files);
      return files.map((file, i) => (
        <div key={file.preview} style={thumb} className="thumbImage">
          <div className="divHover">
            <button
              className="buttonReplace"
              onClick={() => {
                replaceFile(i, open);
              }}
            >
              {t('upload.replace')}
            </button>
            <button
              className="buttonDelete"
              onClick={() => {
                removeFile(i);
              }}
            >
              {t('upload.delete')}
            </button>
          </div>
          <img src={file.preview} style={img} />
        </div>
      ));
    } else {
      setImageArray(files);

      return files.map((file, i) => (
        <div key={file.preview} style={thumb} className="thumbImage">
          <img src={file.preview} style={img} />
        </div>
      ));
    }
  };

  const createSecondThumb = () => {
    if (updateImage) {
      setImageArray(files);
      return files.map((file, i) => (
        <div
          key={file.preview}
          style={thumbSecond}
          className="thumbSecondImage"
        >
          <div className="divHover">
            <button
              className="buttonReplace"
              onClick={() => {
                replaceFile(i, open);
              }}
            >
              {t('upload.replace')}
            </button>
            <button
              className="buttonDelete"
              onClick={() => {
                removeFile(i);
              }}
            >
              {t('upload.delete')}
            </button>
          </div>
          <img src={file.preview} style={imgSecond} />
        </div>
      ));
    } else {
      setImageArray(files);

      return files.map((file, i) => (
        <div
          key={file.preview}
          style={thumbSecond}
          className="thumbSecondImage"
        >
          <img src={file.preview} style={imgSecond} />
        </div>
      ));
    }
  };

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  return (
    <div className="imageUpload" onClick={open}>
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        enableMultiContainer
        containerId={'A'}
      />

      <div className={dragOver ? 'dragOver ' + className : className}>
        <div
          {...getRootProps({
            className: 'dropzone'
          })}
        >
          <input {...getInputProps()} />
          {isShipment ? (
            <aside
              className="thumbsSecondContainer"
              style={{ display: files.length > 0 ? 'flex' : 'none' }}
            >
              {createSecondThumb()}
            </aside>
          ) : (
            <aside
              className="thumbsContainer"
              style={{ display: files.length > 0 ? 'flex' : 'none' }}
            >
              {createThumb()}
            </aside>
          )}
          <div
            className="imageUpload__placeholder"
            style={{ display: files.length > 0 ? 'none' : 'flex' }}
          >
            {children}
            <button
              type="button"
              className="imageUpload__button"
              onClick={open}
            >
              {t('upload.browse')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(ImageUpload);
