import React from 'react';
import axios from 'axios';
import Stripe from 'stripe';
import config from '../../config/api.config.js';

class Payment extends React.Component {
  constructor() {
    super();

    axios
      .get(`${config.apiUrl}/payments/stripe/config/`)
      .then((res) => {
        const stripe = Stripe(res.data.publicKey);
        axios
          .post(`${config.apiUrl}/payments/stripe/`, {
            offer: 1
          })
          .then(({ data }) => {
            return stripe.redirectToCheckout({ sessionId: data.sessionId });
          })
          .catch(console.log);
      })
      .catch(console.log);
  }
  render() {
    return <h1>works</h1>;
  }
}

export default Payment;
