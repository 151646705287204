import React, { useState } from "react";
import Button from "../Button/Button";

import { withNamespaces } from "react-i18next";

import { ReactComponent as Einzeltransport } from "../../assets/images/Einzeltransport.svg";
import { ReactComponent as Messetransport } from "../../assets/images/Messetransport.svg";
import { ReactComponent as Sammeltransport } from "../../assets/images/Sammeltransport.svg";

import "./sliderContent.css";

const SliderContent = ({ t }) => {
  const [cardsVisible, setCardsVisible] = useState(false);

  const [activeClass, setActiveClass] = useState({
    0: false,
    1: false,
    2: false,
  });
  const [previous, setPrevious] = useState([null, null]);
  function addActiveClass(index, previous) {
    setActiveClass({
      [index]: true,
      [previous[0]]: false,
      [previous[1]]: false,
    });
  }

  return (
    <div className="slider">
      <div className="slider__buttons">
        <Button
          onClick={() => {
            addActiveClass(0, previous);
            setPrevious(1, 2);
            setCardsVisible(true);
          }}
          className={activeClass[0] ? "buttonFocused" : " "}
        >
          {t("about.h1")}
        </Button>
        <Button
          onClick={() => {
            addActiveClass(1, previous);
            setPrevious(0, 2);
            setCardsVisible(true);
          }}
          className={activeClass[1] ? "buttonFocused" : " "}
          style={{
            borderLeft: "1px solid rgba(6, 1, 108, 0.1)",
            borderRight: "1px solid rgba(6, 1, 108, 0.1)",
          }}
        >
          {t("about.h2")}
        </Button>
        <Button
          onClick={() => {
            addActiveClass(2, previous);
            setPrevious(0, 1);
            setCardsVisible(true);
          }}
          className={activeClass[2] ? "buttonFocused" : " "}
        >
          {t("about.h3")}
        </Button>
      </div>
      <div
        className={
          cardsVisible
            ? "showServiceCards sliderContent"
            : "hideServiceCards sliderContent"
        }
      >
        <div className={activeClass[0] ? "active" : "sliderContent__slide"}>
          <h3>{t("about.h1")}</h3>
          <div className="slide__content">
            <p>{t("about.firstp1")}</p>
            <p>{t("about.secondp2")}</p>
            <Einzeltransport
              width={200}
              style={{ margin: "0 auto", paddingBottom: "20px" }}
            />
          </div>
        </div>

        <div className={activeClass[1] ? "active" : "sliderContent__slide"}>
          <h3>{t("about.h2")}</h3>
          <div className="slide__content">
            <p>{t("about.secondp1")}</p>
            <p>{t("about.secondp2")}</p>
            <Messetransport
              width={200}
              style={{ margin: "0 auto", paddingBottom: "20px" }}
            />
          </div>
        </div>

        <div className={activeClass[2] ? "active" : "sliderContent__slide"}>
          <h3>{t("about.h3")}</h3>
          <div className="slide__content">
            <p>{t("about.thirdp1")}</p>
            <p>{t("about.secondp2")}</p>
            <Sammeltransport
              width={200}
              style={{ margin: "0 auto", paddingBottom: "20px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(SliderContent);
