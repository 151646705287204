import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SimpleImageSlider from 'react-simple-image-slider';
import { withNamespaces } from 'react-i18next';

import { PhotoSwipeGallery } from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';

import Carousel from 'nuka-carousel';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import RowData from '../ShipmentViewCardRowData/ShipmentViewCardRowData';
import Button from '../../Buttons/BlueButton';
import config from '../../../config/api.config.js';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download.svg';

import auth from '../../../auth';

import './ShipmentViewCard.css';

const ShipmentViewCard = ({ t, data, shipperId, winningOffer }) => {
  let images = [];
  const [stateImages, setStateImages] = useState(null);

  useEffect(() => {
    data.backgroundImages.forEach((img) =>
      images.push({
        original: img.file,
        thumbnail: img.file,
        originalClass: 'image'
      })
    );
    setStateImages(images);
  }, [data.backgroundImages]);

  const downloadShipmentPdf = async (type) => {
    await axios
      .get(`${config.apiUrl}/shipments/pdf/${data.shipmentId}/?type=${type}`)
      .then((res) => {
        window.open(res.data.file, '_blank');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="shipmentViewCard__main">
      <div className="shipmentViewCard">
        <div className="shipmentViewCard__left">
          {stateImages && (
            <ImageGallery
              items={stateImages}
              showPlayButton={false}
              showThumbnails={false}
            />
          )}
        </div>
        <div className="shipmentViewCard__right">
          <div className="shipmentViewCard__right--content">
            <div className="right__content--firstRow right__content--rows">
              <RowData
                description={t('shipmentView.manufacturer')}
                data={data.manufacturer}
              />
              <RowData
                description={`${t('shipments.weight')} (kg)`}
                data={data.weight}
              />
              <RowData
                description={`${t('shipments.height')} (cm)`}
                data={data.height}
              />
              <RowData
                description={t('shipmentView.transportType')}
                data={data.transportType}
              />
            </div>
            <div className="right__content--secondRow right__content--rows">
              <RowData
                description={t('shipmentView.model')}
                data={data.model}
              />
              <RowData description={t('fuhrpark.year')} data={data.year} />
              <RowData
                description={`${t('shipments.length')} (cm)`}
                data={data.length}
              />
              <RowData
                description={t('shipmentView.additionalThings')}
                data={data.aditionalThings}
              />
            </div>
            <div className="right__content--thirdRow right__content--rows">
              <RowData
                description={t('shipments.serialNum')}
                data={data.fin_number}
              />
              <RowData
                description={t('shipmentView.state')}
                data={data.state}
              />
              <RowData
                description={`${t('shipments.width')} (cm)`}
                data={data.width}
              />
            </div>
          </div>
          {shipperId == auth.getId() && winningOffer ? (
            <Button
              onClick={() => downloadShipmentPdf('ticket')}
              style={{ right: '13.5rem' }}
            >
              DOWNLOAD TICKET
            </Button>
          ) : null}
          <Button onClick={() => downloadShipmentPdf('download')}>
            DOWNLOAD INFO
          </Button>
        </div>
      </div>
      {shipperId == auth.getId() && winningOffer ? (
        <Button onClick={() => downloadShipmentPdf('ticket')}>
          <DownloadIcon style={{ marginRight: '0.8rem' }} /> TICKET
        </Button>
      ) : null}
      <Button onClick={() => downloadShipmentPdf('download')}>
        <DownloadIcon style={{ marginRight: '0.8rem' }} /> INFO
      </Button>
    </div>
  );
};

export default withNamespaces()(ShipmentViewCard);
