import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { Helmet } from 'react-helmet';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
//  import "slick-carousel/slick/slick-theme.css";
import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';

import Header from '../../Layout/Dashboard/DashboardHeader/DashboardHeader';
import Navbar from '../../Layout/Dashboard/DashboardNavbar/DashboardNavbar';
import TransportCard from '../../components/TransportCard/TransportCard';
import AnfragenCard from '../../components/AnfragenCard/AnfragenCard';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import CalendarTimeline from '../../components/CalendarTimeline/CalendarTimeline';

import Truck from '../../assets/images/_truck.png';
import CarPicture from '../../assets/images/bmw.png';
import { ReactComponent as NothingFound } from '../../assets/images/nothingFound.svg';
import axios from 'axios';
import auth from '../../auth.js';
import config from '../../config/api.config.js';

import { ReactComponent as LeftArrow } from '../../assets/images/leftArrowSlider.svg';
import { ReactComponent as RightArrow } from '../../assets/images/rightArrowSlider.svg';

import { useBeforeFirstRender } from '../../components/BeforeFirstRender/useBeforeFirstRender';

import moment from 'moment';

import './Dashboard.css';

const override = css`
  margin: 0 auto;
  width: 100px;
  margin-top: 70px;
  margin-bottom: 70px;
`;

const Dashboard = ({ t }) => {
  let history = useHistory();
  let shipments = [];
  let anfragenCard = [];
  let transportCard = [];
  let anfragenCardItems = [];
  let transportCardItems = [];
  const [anfragenCards, setAnfragenCards] = useState([]);
  const [transportCards, setTransportCards] = useState([]);
  let [slidesAnfragen, setSlidesAnfragen] = useState(1);
  let [slidesTransport, setSlidesTransport] = useState(1);

  const [sliderLoaded, setSliderLoaded] = useState(false);
  const [sliderLiveLoaded, setSliderLiveLoaded] = useState(false);

  const [dataForCalendar, setDataForCalendar] = useState(null);

  let settingsTransport = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesTransport,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false,
          arrows: false
        }
      }
    ]
  };
  let settingsAnfragen = {
    dots: false,
    arrows: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    infinite: true,
    slidesToShow: slidesAnfragen,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false,
          arrows: false
        }
      }
    ]
  };

  useBeforeFirstRender(() => {
    if (!auth.isCarrier()) history.push('/shipments');
  });

  const getShipmentData = async () => {
    setSliderLoaded(true);
    await axios
      .get(`${config.apiUrl}/shipments/all/`)
      .then((res) => {
        shipments = res.data;
        shipments = shipments.sort(function (a, b) {
          return b.id - a.id;
        });
        shipments.map((shipment) => {
          if (shipment.state == 'pending') {
            anfragenCard.push({
              shipmentId: shipment.id,
              backgroundImage:
                shipment.photos.length == 0
                  ? `url(${'https://picsum.photos/200'})`
                  : `url(${shipment.photos[0].file})`,
              numberOfBids: shipment.offer_user_ids.length,
              from: {
                city: shipment.pickup.city,
                country: shipment.pickup.country
              },
              to: {
                city: shipment.destination.city,
                country: shipment.destination.country
              },
              duedate_latest: shipment.duedate_latest,
              created_at: shipment.created_at,
              published: moment(shipment.created_at).fromNow()
            });
          }
        });

        anfragenCard.map((shipment) =>
          anfragenCardItems.push(
            <AnfragenCard key={shipment.shipmentId} data={shipment} />
          )
        );

        if (anfragenCard.length == 1 || anfragenCard.length == 2)
          setSlidesAnfragen(1);
        else setSlidesAnfragen(2);

        setAnfragenCards(anfragenCardItems);
        setSliderLoaded(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getLiveTransport = async () => {
    setSliderLiveLoaded(true);
    await axios
      .get(`${config.apiUrl}/shipments/live/`)
      .then((res) => {
        setDataForCalendar(res.data);
        res.data.map((shipment) => {
          transportCard.push({
            transportId: shipment.id,
            from: {
              city: shipment.pickup_city,
              country: shipment.pickup_country
            },
            to: {
              city: shipment.destination_city,
              country: shipment.destination_country
            },
            arrivalDate: moment(
              shipment.offer_deliver_date,
              'YYYYMMDD'
            ).fromNow(),
            image: Truck
          });
        });

        transportCard.map((shipment) =>
          transportCardItems.push(
            <TransportCard key={shipment.shipmentID} data={shipment} />
          )
        );

        if (transportCard.length == 1 || transportCard.length == 2)
          setSlidesTransport(1);
        else setSlidesTransport(2);

        setTransportCards(transportCardItems);
        setSliderLiveLoaded(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getShipmentData();
    getLiveTransport();
  }, []);

  return (
    <div className="dashboard__content">
      <Helmet>
        <title>Autoversand | {t('navbar.dashboard')}</title>
      </Helmet>
      <section className="content__section">
        <ContentHeader>{t('dashboard.liveTransport')}</ContentHeader>
        {/* <p className="shipmentView__paragraph">
          Transports that are currently in transit.
        </p> */}
        <div className="section__transport">
          <div className="rightSide__shadow"></div>
          {sliderLiveLoaded ? (
            <PuffLoader
              css={override}
              size={60}
              margin={2}
              color={'rgba(116, 38, 251, 0.4)'}
              loading={sliderLiveLoaded}
            />
          ) : transportCards.length > 0 ? (
            <Slider {...settingsTransport}>{transportCards}</Slider>
          ) : (
            <div className="nothingFound">
              <NothingFound />
            </div>
          )}
        </div>
      </section>
      <section className="content__section">
        <ContentHeader>{t('dashboard.transportRequests')}</ContentHeader>
        {/* <p className="shipmentView__paragraph">
          Transport requests that are still open for offer placement. Place your
          offer and wait for Shipper's feedback.
        </p> */}
        <div className="section__anfragen">
          {sliderLoaded ? (
            <PuffLoader
              css={override}
              size={60}
              margin={2}
              color={'rgba(116, 38, 251, 0.4)'}
              loading={sliderLoaded}
            />
          ) : anfragenCards.length > 0 ? (
            <Slider {...settingsAnfragen}>{anfragenCards}</Slider>
          ) : (
            <div className="nothingFound">
              <NothingFound />
            </div>
          )}
        </div>
      </section>

      <section className="content__section">
        <ContentHeader>{t('dashboard.calendar')}</ContentHeader>
        {/* <p className="shipmentView__paragraph">
          All your live transports will automatically be synced in with the
          calendar.
        </p> */}
        <div className="section__calendar">
          {sliderLiveLoaded ? (
            <PuffLoader
              css={override}
              size={60}
              margin={2}
              color={'rgba(116, 38, 251, 0.4)'}
              loading={sliderLiveLoaded}
            />
          ) : (
            <CalendarTimeline data={dataForCalendar} />
          )}
        </div>
      </section>
    </div>
  );
};

export default withNamespaces()(Dashboard);
