import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';

import { ReactComponent as PickupIcon } from '../../../assets/images/Pickup.svg';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import './transportSection.css';

const TransportSection = ({ t, data }) => {
  const [photos, setPhotos] = useState(null);

  const RenderItems = () =>
    data.items.map((element) => (
      <div className="checkbox__group">
        <input
          type="checkbox"
          name={element.id}
          value={element.name}
          checked={element.checked}
        />
        <label for={element.id}>{element.name}</label>
        <br />
      </div>
    ));

  useEffect(() => {
    setPhotos(
      data.photos.map((img) => {
        return {
          original: img.file,
          thumbnail: img.file,
          originalClass: 'image'
        };
      })
    );
  }, [data.photos]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
      }}
    >
      <div className="transportSection">
        <div className="transportSection__left">
          {photos && (
            <ImageGallery
              items={photos}
              showPlayButton={false}
              showThumbnails={false}
            />
          )}
        </div>
        <div className="transportSection__right">
          <div className="transportSection__right--items">
            <p className="transportSection__right--heading">
              {data.type} {t('shipmentView.items')}
            </p>
            <div className="transportSection__right--list">{RenderItems()}</div>
            <p>*{t('shipmentView.checkedItems')}</p>
          </div>
          <div className="transportSection__right--notes">
            <p className="transportSection__right--heading">
              {data.type} {t('shipmentView.notes')}
            </p>
            <div>
              <div>{data.notes}</div>
            </div>
          </div>
        </div>
      </div>
      <p
        style={{
          fontFamily: 'SF Pro DisplayMedium',
          fontSize: '1.125rem',
          lineHeight: '1.33',
          color: '#06016c',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {data.type}{' '}
        <PickupIcon style={{ width: '1.125rem', marginLeft: '0.375rem' }} />
      </p>
    </div>
  );
};

export default withNamespaces()(TransportSection);
