import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import illustration from '../../assets/images/illustration.png';
import line from '../../assets/images/the_line.png';
import searchIcon from '../../assets/images/searchIcon.png';
import stepLine1 from '../../assets/images/stepLine_1.png';
// import stepLine2 from "../assets/images/stepLine_2.png";

import Navbar from '../../Layout/Navbar/Navbar';
import Footer from '../../Layout/Footer/Footer';
import PlacesAutocomplete from '../../components/GoogleMaps/PlacesAutocomplete';
import GoogleMapDistance from '../../components/GoogleMaps/GoogleMapDistance';

import { useWindowSize } from '../../components/WindowSize/WindowSize';

import './naslovnica.css';

const Homepage = ({ t }) => {
  let history = useHistory();
  const [width, height] = useWindowSize();

  const [pickup, setPickup] = useState(null);
  const [destination, setDestination] = useState(null);

  const [pickupText, setPickupText] = useState('');
  const [destinationText, setDestinationText] = useState('');

  useEffect(() => {
    if (pickupText == '') setPickup(null);

    if (destinationText == '') setDestination(null);
  }, [pickupText, destinationText]);

  return (
    <div>
      {/* {width < 1200 || height < 650 ? (
        <MobileDownload />
      ) : ( */}
      <div
        className="homepageMain"
        style={{ background: '#06016c', minHeight: '100vh' }}
      >
        <Navbar setLanguage={() => {}} />
        <GoogleMapDistance />
        <main style={{ position: 'relative' }}>
          <h1>{t('homepage.transportiere')}</h1>
          <div className="searchArea" style={{ zIndex: 999 }}>
            <img
              src={illustration}
              className="coverPhotoSimulated"
              style={{ pointerEvents: 'none', userSelect: 'none' }}
              alt="illustration"
            />
            <div>
              <div className="inputHolder">
                <label>{t('inputHolder.from')}</label>
                <PlacesAutocomplete
                  placeholder="ex. Berlin"
                  id="vehicle_pickup"
                  name="vehicle_pickup"
                  _value={pickup != null ? pickup.formatted_address : null}
                  _setValue={setPickup}
                  setTextValue={setPickupText}
                />
                <PlacesAutocomplete
                  placeholder={t('inputHolder.from')}
                  id="vehicle_pickup_resp"
                  name="vehicle_pickup"
                  style={{ display: 'none' }}
                />
                <input
                  type="hidden"
                  name="vehicle_pickup_id"
                  id="vehicle_pickup_id"
                />
              </div>
              <div className="inputHolder">
                <label>{t('inputHolder.to')}</label>
                <PlacesAutocomplete
                  placeholder="ex. Vienna"
                  id="vehicle_destination"
                  name="vehicle_destination"
                  _value={
                    destination != null ? destination.formatted_address : null
                  }
                  _setValue={setDestination}
                  setTextValue={setDestinationText}
                />
                <PlacesAutocomplete
                  placeholder={t('inputHolder.to')}
                  id="vehicle_destination_resp"
                  name="vehicle_destination"
                  style={{ display: 'none' }}
                />
                <input
                  type="hidden"
                  name="vehicle_destination_id"
                  id="vehicle_destination_id"
                />
              </div>
              <img src={line} className="theFirstLine" alt="" />
              <img src={line} className="theSecondLine" alt="" />
              <img
                src={searchIcon}
                onClick={() =>
                  history.push({
                    pathname: '/results',
                    state: {
                      _pickup: pickup
                        ? {
                            formatted_address: pickup.formatted_address,
                            place_id: pickup.place_id
                          }
                        : null,
                      _destination:
                        destination != null
                          ? {
                              formatted_address: destination.formatted_address,
                              place_id: destination.place_id
                            }
                          : null
                    }
                  })
                }
                style={{ cursor: 'pointer' }}
                className="searchIcon"
                alt=""
              />
              <button
                className="searchIcon"
                id="searchButton"
                style={{ display: 'none' }}
                onClick={() =>
                  history.push({
                    pathname: '/results',
                    state: {
                      _pickup: pickup
                        ? {
                            formatted_address: pickup.formatted_address,
                            place_id: pickup.place_id
                          }
                        : null,
                      _destination:
                        destination != null
                          ? {
                              formatted_address: destination.formatted_address,
                              place_id: destination.place_id
                            }
                          : null
                    }
                  })
                }
              >
                Search
              </button>
            </div>
          </div>
          <div className="steps" style={{ zIndex: 999 }}>
            <div className="steps__single">
              <h2>1</h2>
              <p>{t('steps.beschriben')}</p>
            </div>
            <img src={stepLine1} className="stepLine_1" alt="" />
            <div className="steps__single">
              <h2>2</h2>
              <p>{t('steps.Vergleichen')}</p>
            </div>
            <img src={stepLine1} className="stepLine_2" alt="" />
            <div className="steps__single">
              <h2>3</h2>
              <p>{t('steps.Wahlen')}</p>
            </div>
          </div>
        </main>
        {/* <div className="line" /> */}
        <Footer />
      </div>
      {/* )} */}
    </div>
  );
};

export default withNamespaces()(Homepage);
