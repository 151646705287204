import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import logo from '../../assets/images/av_logo.png';
import Notifications from '../../components/Notification/Notification';
import { setNotificationRead } from '../../notifications';

import { ReactComponent as NotificationIcon } from '../../assets/images/HeaderIcons/notifications.svg';
import { ReactComponent as MessageIcon } from '../../assets/images/HeaderIcons/message.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/HeaderIcons/search.svg';
import HeaderSearchList from '../../components/HeaderSearchList/HeaderSearchList';

import { connect } from 'react-redux';
import axios from 'axios';

import auth from '../../auth';
import config from '../../config/api.config.js';

import { getShipments } from '../../redux/actions';

import './header.css';

const MobileHeader = () => {
  const [userImage, setUserImage] = useState();

  let history = useHistory();

  useEffect(() => {
    axios
      .get(`${config.apiUrl}/users/cips/${auth.getId()}/`)
      .then((res) => setUserImage(`url(${config.assetUrl}${res.data.photo})`));
  }, []);

  return (
    <div className="mobileHeader">
      <div className="header__leftSide">
        <Link to="/">
          <div className="header__logo">
            <img src={logo} alt="" />
          </div>
        </Link>
      </div>
      <div className="header__rightSide">
        <MessageIcon
          onClick={() => history.push('/messenger')}
          style={{ cursor: 'pointer' }}
        />
        <div className="notification" onClick={() => setNotificationRead()}>
          <Link to="/notifications">
            <NotificationIcon className="notificationBell" />
          </Link>
        </div>
        <div style={{ position: 'relative', marginLeft: '2.706rem' }}>
          <Link to="/results">
            <SearchIcon />
          </Link>
        </div>
        <Link to="/account-mobile">
          <div
            className="header__profile"
            style={{ backgroundImage: userImage }}
          ></div>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  shipmentsState: state.shipmentsReducer
});

const mapDispatchToProps = (dispatch) => ({
  shipmentsReducer: () => dispatch(getShipments())
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
