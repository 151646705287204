import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../../Layout/Header/Header';
import Navbar from '../../Layout/Navbar/Navbar';
import Footer from '../../Layout/Footer/Footer';
import SectionHeader from '../../components/SectionHeader/SectionHeader';

import { useWindowSize } from '../../components/WindowSize/WindowSize';
const Datenschutz = () => {
  const [width, height] = useWindowSize();

  return (
    <div>
      <Helmet>
        <title>Autoversand | Datenschutz</title>
      </Helmet>
      <Navbar />
      <Header />
      <main className="main__about">
        <div className="main__sections">
          <section>
            <SectionHeader type="autoversand">DATENSCHUTZ</SectionHeader>
            <dl className="paragraph__list fontStyle__paragraph">
              <dt>I. Erklärung zum Datenschutz</dt>
              <dd>
                Two Dots Digital als Betreiber von der Online-Plattform
                AUTOVERSAND steht zum Schutz der Privatsphäre des Einzelnen im
                Internet. Mit dieser Datenschutzerklärung unterstreicht Two Dots
                Digital sein Engagement für den Schutz der Privatsphäre und des
                Persönlichkeitsrechts des Einzelnen sowie für den sicheren und
                vertrauenswürdigen Handel im Internet.
              </dd>
              <dt>II. Datensicherheit</dt>
              <dd>
                Die Two Dots Digital hat zum Schutz Ihrer Daten technische und
                organisatorische Vorkehrungen getroffen, insbesondere gegen
                Verlust, Manipulation oder unberechtigten Zugriff. Die Maßnahmen
                und Vorkehrungen werden regelmäßig an die laufende technische
                Entwicklung angepasst.
              </dd>
              <dt>III. Einwilligung</dt>
              <dd>
                Mit der Nutzung unserer Website AUTOVERSAND stimmen Sie der
                unter dieser Erklärung beschriebenen Speicherung und Nutzung
                ihrer Daten zu. Änderungen an dieser Datenschutzerklärung werden
                direkt auf dieser Seite vorgenommen, so dass Sie immer darüber
                informiert sind, welche Daten Two Dots Digital speichert und
                nutzt.
              </dd>
              <dt>IV. Verwendung und Zweckbindung persönlicher Daten</dt>
              <dd>
                Das Speichern von personenbezogenen Daten über unsere Websites
                unterliegt Ihrer vorherigen Zustimmung. Damit entscheiden Sie
                allein, ob Sie uns z. B. bei einer Registrierung oder Umfrage
                die dort abgefragten Daten bekannt geben. Ihre Daten können von
                Two Dots Digital zu Anfragen, Auftragsbearbeitung oder zur
                Verbesserung unserer Services und Produkte verwendet werden.
                Übermittlungen an Dritte außerhalb von Two Dots Digital erfolgen
                allerdings nur mit Ihrer ausdrücklichen Zustimmung, es sei denn,
                eine Weitergabe ist durch geltende Rechtsvorschriften
                legitimiert. Ihre Daten werden nur zu den im Folgenden näher
                beschriebenen Zwecken verwendet.
              </dd>
              <dt>V. Details</dt>
              <dd>
                <dl>
                  <dt>
                    1. Welche Informationen sammeln wir und zu welchem Zweck?
                  </dt>
                  <dd>
                    <p>Erhebung und Verarbeitung personenbezogener Daten</p>
                    <p>
                      Personenbezogene Daten werden gemäß den anwendbaren
                      telekommunikations- und datenschutzrechtlichen
                      Vorschriften nur dann verarbeitet, wenn Sie der
                      Verarbeitung zustimmen oder ein anderer gesetzlicher
                      Erlaubnistatbestand besteht.
                    </p>
                    <p style={{ marginBottom: '30px' }}>
                      Wir verarbeiten von Ihnen folgende personenbezogenen
                      Daten:
                    </p>
                    <div>
                      <hr /> Name
                    </div>
                    <div>
                      <hr /> Adresse inkl. Bundesland
                    </div>
                    <div>
                      <hr /> E-Mailadresse
                    </div>
                    <div>
                      <hr /> Telefonnummer
                    </div>
                    <div>
                      <hr /> Fahrzeugdaten
                    </div>
                    <div>
                      <hr /> Vom Nutzer gewählter Benutzername
                    </div>
                    <div>
                      <hr /> Vom Nutzer gesetztes Passwort
                    </div>
                    <div>
                      <hr /> Nutzungsverhalten Websit
                    </div>
                  </dd>
                  <dt>Benutzerregistrierung</dt>
                  <dd>
                    Wenn Sie sich registrieren, werden wir die angeforderten
                    Informationen und/oder Produkte sowie sonstige
                    Produktinformationen, Nachrichten oder Werbematerialien
                    zusenden.
                  </dd>
                  <dt>E-Mail-Adressen</dt>
                  <dd>
                    Wenn Sie uns Ihre E-Mail-Adresse angeben, kommunizieren wir
                    mit Ihnen über E-Mail. Wir werden Ihre E-Mail-Adresse nicht
                    an Dritte außerhalb von Two Dots Digital weitergeben. Wenn
                    Sie keine E-Mails von uns mehr erhalten wollen, so können
                    Sie diese jederzeit abstellen. Bei der Versendung einer
                    E-Mail an Two Dots Digital können, je nach der Einstellung
                    Ihres E-Mail-Programms, automatisch personenbezogene Daten
                    übermittelt werden. Two Dots Digital wird diese ebenfalls
                    vertraulich behandeln.
                  </dd>
                  <dt>IP-Adressen</dt>
                  <dd>
                    IP-Adressen werden für Problemdiagnosen, Website-Verwaltung
                    und demographische Informationen benötigt. Wenn Sie unsere
                    Site besuchen, erkennen wir nur Ihren Domänennamen, nicht
                    aber Ihre E-Mail-Adresse. Wir sehen Ihre E-Mail-Adresse nur
                    dann, wenn Sie uns diese durch Ausfüllen eines Formulars
                    oder Senden einer E-Mail direkt angeben.
                  </dd>
                  <dt>Cookies und Web-Beacons</dt>
                  <dd>
                    <p>
                      Cookies werden für personalisierte Inhalte, zur Vermeidung
                      wiederholter Passworteingabe, oder zur Anpassung des
                      Informationsangebots an das Benutzerverhalten verwendet.
                    </p>
                    <p>
                      Unbedingt erforderliche Cookies können über die Funktion
                      dieser Seite nicht deaktiviert werden. Du kannst Cookies
                      jederzeit generell in deinem Web-Browser deaktivieren.
                    </p>
                    <p>
                      Cookie Einstellungen: Sie haben die Möglichkeit, die
                      Verwendung von Cookies zu steuern und gegebenenfalls zu
                      verhindern, indem Sie Ihren Web-Browser wie folgt
                      konfigurieren:
                    </p>
                    <p>
                      Internet Explorer, siehe hier
                      (http://windows.microsoft.com/en-in/windows7/block-enable-or-allow-cookies)
                      Extras - Internetoptionen - Registerkarte "Erweitert" -
                      unter dem Punkt Sicherheit das Häkchen setzen bei "An mit
                      Internet Explorer besuchte Websites " Do Not
                      Track"-Requests senden" - bestätigen Firefox, siehe hier
                      (https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences)
                      Menü - Einstellungen - Datenschutz - beim Punkt Chronik
                      auf "nach benutzerdefinierten Einstellungen anlegen"
                      setzen - beim Punkt "Cookies" die gewünschten
                      Einstellungen machen - bestätigen Google Chrome, siehe
                      hier
                      (https://support.google.com/chrome/answer/95647?hl=en)
                      Menü - Einstellungen - Erweiterte Einstellungen - im
                      Abschnitt "Datenschutz" auf "Inhaltseinstellungen" klicken
                      - beim Punkt "Cookies" die gewünschten Häkchen setzen -
                      bestätigen Safari, siehe hier
                      (https://support.apple.com/kb/ph5042?locale=en_US) Safari
                      - Einstellungen - Datenschutz - beim Punkt "Cookies und
                      Website-Daten" gewünschte Einstellungen vornehmen -
                      bestätigen Two Dots Digital verwendet Web-Beacons oder
                      auch Pixel Tags genannt, mit dem das Online-Verhalten der
                      Besucher auf der Webseite betrachtet wird. Beacons werden
                      von Drittanbietern zur Verfügung gestellt wie von Google
                      Inc. und Facebook verwendet. Diese Software erlaubt die
                      Betrachtung von Online-Verhalten in Bezug auf Zeit,
                      geografische Lage und Nutzung dieser Webseite.
                    </p>
                  </dd>
                  <dt>2. Links auf andere Sites</dt>
                  <dd>
                    Unsere Website enthält ebenfalls Links auf andere Sites von
                    Drittanbietern. Two Dots Digital ist nicht verantwortlich
                    für die Datenschutzvorkehrungen oder den Inhalt anderer
                    Websites.
                  </dd>
                  <dt>3. Opt-out</dt>
                  <dd>
                    Wenn Sie keine E-Mail-Nachrichten von Two Dots Digital mehr
                    erhalten wollen, können Sie jederzeit die zuvor erteilte
                    Genehmigung widerrufen. Um aus der Two Dots
                    Digital-E-Mail-Liste oder Two Dots
                    Digital-Web-Registrierungsdatenbank entfernt zu werden,
                    reicht eine E-Mail-Nachricht an
                    datenschutz@autoversand.online aus. Registrierte Benutzer
                    können jederzeit im Two Dots Digital -Benutzerprofil von
                    ihrem Widerrufsrecht Gebrauch machen.
                  </dd>
                  <dt>4. Auskunft und Fragen</dt>
                  <dd>
                    Falls Sie Fragen zu dieser Datenschutzerklärung haben, z. B.
                    zur Einsicht oder Aktualisierung Ihrer personenbezogenen
                    Daten, schreiben Sie uns ein E-Mail
                    datenschutz@autoversand.online oder unter folgenden
                    Kontaktdaten: Two Dots Digital GmbH, Neue Heimat 22, 4664
                    Laakirchen.
                  </dd>
                  <dt>Verwendung von Google Analytics</dt>
                  <dd>
                    Diese Website benutzt Google Analytics, einen
                    Webanalysedienst der Google Inc. („Google“) Google Analytics
                    verwendet sog. „Cookies“, Textdateien, die auf Ihrem
                    Computer gespeichert werden und die eine Analyse der
                    Benutzung der Website durch Sie ermöglicht. Die durch den
                    Cookie erzeugten Informationen über Ihre Benutzung dieser
                    Website (einschließlich Ihrer IP-Adresse) wird an einen
                    Server von Google in den USA übertragen und dort
                    gespeichert. Google wird diese Informationen benutzen, um
                    Ihre Nutzung der Website auszuwerten, um Reports über die
                    Websiteaktivitäten für die Websitebetreiber
                    zusammenzustellen und um weitere mit der Websitenutzung und
                    der Internetnutzung verbundene Dienstleistungen zu
                    erbringen. Auch wird Google diese Informationen
                    gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
                    vorgeschrieben oder soweit Dritte diese Daten im Auftrag von
                    Google verarbeiten. Google wird in keinem Fall Ihre
                    IP-Adresse mit anderen Daten der Google in Verbindung
                    bringen. Sie können die Installation der Cookies durch eine
                    entsprechende Einstellung Ihrer Browser Software verhindern;
                    wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                    gegebenenfalls nicht sämtliche Funktionen dieser Website
                    voll umfänglich nutzen können. Durch die Nutzung dieser
                    Website erklären Sie sich mit der Bearbeitung der über Sie
                    erhobenen Daten durch Google in der zuvor beschriebenen Art
                    und Weise und zu dem zuvor benannten Zweck einverstanden.
                  </dd>
                  <dt>Verwendung von Google AdWords Remarketing:</dt>
                  <dd>
                    <p>
                      Diese Website verwendet Cookies, mit dem Zweck, die
                      Besucher via Remarketing-Kampagnen mit Online Werbung zu
                      einem späteren Zeitpunkt im Google Werbenetzwerk
                      anzusprechen. Zur Schaltung von Remarketing-Anzeigen
                      verwenden Drittanbieter wie Google Cookies auf der
                      Grundlage eines Besuchs der vorliegenden Website. Sie als
                      User haben die Möglichkeit, die Verwendung von Cookies
                      durch Google zu deaktivieren, indem Sie die Seite zur
                      Deaktivierung von Google unter
                      www.google.com/ads/preferences aufrufen.
                    </p>
                    <p>
                      Erfassung von Informationen aus sozialen Medien und
                      Sign-on-Services
                      <br />
                      Wir bieten Sign-on-Services an, die es Ihnen ermöglichen,
                      mithilfe von Anmeldedaten anderer Services (wie Facebook
                      oder Google+) auf unsere Websites, Anwendungen, Services
                      und Tools zuzugreifen. Außerdem bieten wir Services an,
                      die es Ihnen ermöglichen, Informationen mit Websites
                      anderer sozialer Medien wie Facebook, Google+, Twitter und
                      anderen auszutauschen.
                    </p>
                    <p>
                      Es ist Ihnen freigestellt, uns Zugriff auf bestimmte
                      personenbezogene Daten zu geben, die auf solchen
                      Drittanbieter-Websites gespeichert werden. Auf welche
                      personenbezogenen Daten wir zugreifen können, hängt von
                      der jeweiligen Website ab und wird durch Ihre
                      Datenschutzeinstellungen auf dieser Website sowie Ihre
                      Einwilligung gesteuert. Durch die Verknüpfung eines von
                      einem Drittanbieter verwalteten Kontos mit Ihrem bei uns
                      eingerichteten Nutzerkonto und das Autorisieren des
                      Zugriffs auf diese Informationen erklären Sie sich damit
                      einverstanden, dass wir in Übereinstimmung mit dieser
                      Datenschutzerklärung Informationen von diesen Websites
                      erfassen, nutzen und speichern können.
                    </p>
                  </dd>
                </dl>
              </dd>
            </dl>
          </section>
          <Footer styleFooter="about__footer" />
        </div>
      </main>
    </div>
  );
};

export default Datenschutz;
