import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { ReactComponent as Check } from '../../../assets/images/shipmentViewCheck.svg';
import auth from '../../../auth';

import Offers from '../Offers/OfferCard';
import './PendingOffers.css';

const PendingOffers = ({ t, data, handleOpen, handleClose, setOfferId }) => {
  let history = useHistory();

  const handleClick = () => {
    handleOpen(true);
    setOfferId();
  };

  return (
    <div className="pendingOffers">
      <div className="pendingOffers__name">
        {' '}
        {data.verified ? (
          <Check className="pendingOffers__name--check" />
        ) : (
          ' '
        )}{' '}
        {auth.isCarrier()
          ? data.shipper_company_name ??
            `${data.shipper_first_name} ${data.shipper_last_name.slice(0, 1)}.`
          : data.company}
      </div>
      <div className="pendingOffers__content">
        <Offers data={data} />
        {auth.isCarrier() ? (
          <button onClick={handleClick} className="pendingOffers__button">
            {t('offer.edit')}
          </button>
        ) : (
          <button
            onClick={() => {
              history.push({
                pathname: `/shipment/${data.shipmentId}/`,
                state: { detail: 'scroll' }
              });
            }}
            className="pendingOffers__button"
          >
            {t('offer.view')}
          </button>
        )}
      </div>
    </div>
  );
};

export default withNamespaces()(PendingOffers);
