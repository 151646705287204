import React from "react";
import { useHistory } from "react-router-dom";

import FromToSection from "../../components/FromToSection/FromToSection";
import { ReactComponent as Check } from "../../assets/images/shipmentViewCheck.svg";

import auth from "../../auth";

import "./PendingPaymentsCard.css";

const PendingPaymentsCard = ({
  data,
  pendingPayments = true,
  buttonAction,
  handleOpen,
}) => {
  let history = useHistory();

  return (
    <div className="pendingPaymentsCard">
      {pendingPayments ? (
        <div
          className="pendingPaymentsCard__name"
          style={{ marginLeft: "5px" }}
        >
          {" "}
          {data.verified ? (
            <Check className="pendingPaymentsCard__name--check" />
          ) : (
            "   "
          )}{" "}
          {data.userName}
        </div>
      ) : null}
      <div className="pendingPaymentsCard__content">
        <div
          className="pendingPaymentsCard__top"
          style={{ backgroundImage: `url(${data.carImage})` }}
        >
          {" "}
        </div>
        <div className="pendingPaymentsCard__bottom">
          <div className="pendingPaymentsCard__price">{data.price}</div>
          <div className="pendingPaymentsCard__bottomMiddle">
            <div className="pendingPaymentsCard__carType">
              {data.car} <span>{data.carType}</span>
            </div>
            <div className="pendingPaymentsCard__fromTo">
              <FromToSection data={data} />
            </div>
          </div>
        </div>
        <button onClick={handleOpen} className="pendingPaymentsCard__button">
          {buttonAction}
        </button>
      </div>
    </div>
  );
};

export default PendingPaymentsCard;
