import React, { useState, useEffect } from 'react';
import FormInput from '../../FormInput/FormInput';
import Button from '../../Buttons/BlueButton';
import BackButton from '../../Buttons/BackButton';
import { withNamespaces } from 'react-i18next';

import { ReactComponent as ShipmentsFinishedImage } from '../../../assets/images/shipmentFinished.svg';

import './PaymentSuccessful.css';

const Payment = ({ t, onClickClose, onClickBack }) => {
  return (
    <div className="paymentSuccessful">
      <div className="paymentSuccessful__content">
        <h2 className="paymentSuccessful__content--heading">
          {t('payments.successful')}
        </h2>
        <p className="paymentSuccessful__content--paragraph">
          {t('payments.checkEmail')}
        </p>

        <ShipmentsFinishedImage className="paymentSuccessful__contentImage" />
        <Button handleOpen={onClickClose}>{t('shipments.finish')}</Button>
      </div>
      <BackButton handleClick={onClickBack} />
    </div>
  );
};

export default withNamespaces()(Payment);
