const defaultState = {
  messages: [],
  hasRead: true,
  lastSender: null,
  numOfChats: 0
};

const messangerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_MESSEGESREAD':
      defaultState.hasRead = action.data.hasRead;
      defaultState.lastSender = action.data.lastSender;
      return { ...state, ...action.data };
    case 'GET_NUM_OF_CHATS':
      defaultState.numOfChats = action.data.numberOfChats;
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default messangerReducer;
