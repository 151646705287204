import React from 'react';
import { withNamespaces } from 'react-i18next';

import { ReactComponent as Arrow } from '../../assets/images/arrowTransport.svg';
// import CarPicture from '../../assets/images/bmw.png';

import Countdown from '../Countdown/Countdown';
import Card from '../Card/Card';
import FromToSection from '../FromToSection/FromToSection';

import auth from '../../auth';

import { useHistory } from 'react-router-dom';

import './AnfragenCard.css';
const AnfragentCard = ({ t, data }) => {
  let history = useHistory();
  return (
    <div className="anfragenCard">
      <Card backgroundImage={data.backgroundImage} className="styleLeftSide">
        <FromToSection data={data} />
        <button
          className="anfragen__button"
          onClick={() => {
            history.push(`/shipment/${data.shipmentId}/`);
          }}
        >
          {t('anfragenCard.placeOffer')}
        </button>
        <div className="anfragen__bids">
          {t('offers.offers')} <span>{data.numberOfBids}</span>
        </div>
      </Card>
      <div className="anfragenCard__bottom">
        <button
          onClick={() => {
            history.push(`/shipment/${data.shipmentId}/`);
          }}
        >
          Details
        </button>
        <div className="timeCounter">
          {t('anfragenCard.published')} <span>{data.published}</span>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(AnfragentCard);
