import React, { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

import Header from '../../Layout/Dashboard/DashboardHeader/DashboardHeader';
import Navbar from '../../Layout/Dashboard/DashboardNavbar/DashboardNavbar';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import AcceptedOffers from '../../components/MyOffers/AcceptedOffers/AcceptedOffers';
import PendingOffers from '../../components/MyOffers/PendingOffers/PendingOffers';
import GiveBid from '../../components/ShipmentViewContent/GiveBid/GiveBid';
import MobileHeader from '../../Layout/Mobile/Header';
import MobileNavbar from '../../Layout/Mobile/Navbar';
import { ReactComponent as NothingFound } from '../../assets/images/nothingFound.svg';

import { Animated } from 'react-animated-css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';

import moment from 'moment';
import config from '../../config/api.config.js';
import axios from 'axios';

import './Offers.css';
import { off } from 'superagent';

const override = css`
  margin: 0 auto;
  width: 100px;
  margin-top: 80px;
  margin-bottom: 80px;
`;

const Offers = ({ t }) => {
  const [sliderLoaded, setSliderLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [globalOfferId, setGlobalOfferId] = useState();
  const [reloadPage, setReloadPage] = useState(false);
  let pendingOffers = [];
  let acceptedOffers = [];
  let rejectedOffers = [];
  let pendingOffersItems = [];
  let acceptedOffersItems = [];
  let rejectedOffersItems = [];
  const [pendingOffersArray, setPendingOffersArray] = useState([]);
  const [acceptedOffersArray, setAcceptedOffersArray] = useState([]);
  const [rejectedOffersArray, setRejectedOffersArray] = useState([]);
  let [slidesPending, setSlidesPending] = useState(1);
  let [slidesAccepted, setSlidesAccepted] = useState(1);
  let [slidesRejected, setSlidesRejected] = useState(1);

  const [numOfPendingOffers, setNumOfPendingOffers] = useState(0);
  const [numOfAcceptedOffers, setNumOfAcceptedOffers] = useState(0);
  const [numOfRejectedOffers, setNumOfRejectedOffers] = useState(0);

  const onClickOpen = () => {
    setVisible(true);
  };
  const onClickClose = () => {
    setVisible(false);
  };

  const getOffers = async () => {
    setSliderLoaded(true);

    await axios
      .get(`${config.apiUrl}/offers/my/`)
      .then((res) => {
        const offers = res.data;

        offers.map((offer) => {
          offer.state == 'pending'
            ? pendingOffers.push({
                company: offer.company,
                photo: `${config.assetUrl}${offer.photo}`,
                amount: offer.amount + '€',
                carrier: offer.carrier,
                manufacturer: offer.manufacturer,
                model: offer.model,
                from: {
                  city: offer.pickup_city,
                  country: offer.pickup_country
                },
                to: {
                  city: offer.destination_city,
                  country: offer.destination_country
                },
                pickup_date: offer.pickup_date,
                delivery_date: offer.delivery_date,
                id: offer.id,
                state: offer.state,
                verified: offer.verified,
                shipmentId: offer.shipment,
                shipper_company_name: offer.shipper_company_name,
                shipper_first_name: offer.shipper_first_name,
                shipper_last_name: offer.shipper_last_name
              })
            : offer.state == 'accepted'
            ? acceptedOffers.push({
                company: offer.company,
                photo: `${config.assetUrl}${offer.photo}`,
                amount: offer.amount + '€',
                carrier: offer.carrier,
                manufacturer: offer.manufacturer,
                model: offer.model,
                from: {
                  city: offer.pickup_city,
                  country: offer.pickup_country
                },
                to: {
                  city: offer.destination_city,
                  country: offer.destination_country
                },
                pickup_date: offer.pickup_date,
                delivery_date: offer.delivery_date,
                id: offer.id,
                state: offer.state,
                verified: offer.verified,
                shipmentId: offer.shipment,
                shipper_company_name: offer.shipper_company_name,
                shipper_first_name: offer.shipper_first_name,
                shipper_last_name: offer.shipper_last_name
              })
            : rejectedOffers.push({
                company: offer.company,
                photo: `${config.assetUrl}${offer.photo}`,
                amount: offer.amount + '€',
                carrier: offer.carrier,
                manufacturer: offer.manufacturer,
                model: offer.model,
                from: {
                  city: offer.pickup_city,
                  country: offer.pickup_country
                },
                to: {
                  city: offer.destination_city,
                  country: offer.destination_country
                },
                pickup_date: offer.pickup_date,
                delivery_date: offer.delivery_date,
                id: offer.id,
                state: offer.state,
                verified: offer.verified,
                shipmentId: offer.shipment,
                shipper_company_name: offer.shipper_company_name,
                shipper_first_name: offer.shipper_first_name,
                shipper_last_name: offer.shipper_last_name
              });
        });
        setNumOfPendingOffers(pendingOffers.length);
        setNumOfAcceptedOffers(acceptedOffers.length);
        setNumOfRejectedOffers(rejectedOffers.length);

        if (pendingOffers.length == 1) setSlidesPending(1);
        else if (pendingOffers.length == 2) setSlidesPending(2);
        else if (pendingOffers.length == 3) setSlidesPending(3);
        else setSlidesPending(4);

        if (acceptedOffers.length == 1) setSlidesAccepted(1);
        else if (acceptedOffers.length == 2) setSlidesAccepted(2);
        else if (acceptedOffers.length == 3) setSlidesAccepted(3);
        else setSlidesAccepted(4);

        if (rejectedOffers.length == 1) setSlidesRejected(1);
        else if (rejectedOffers.length == 2) setSlidesRejected(2);
        else if (rejectedOffers.length == 3) setSlidesRejected(3);
        else setSlidesRejected(4);

        pendingOffers.map((offer) =>
          pendingOffersItems.push(
            <PendingOffers
              key={offer.id}
              data={offer}
              handleOpen={onClickOpen}
              handleClose={onClickClose}
              setOfferId={() => {
                setGlobalOfferId(offer.id);
              }}
            />
          )
        );

        acceptedOffers.map((offer) =>
          acceptedOffersItems.push(
            <AcceptedOffers key={offer.id} data={offer} />
          )
        );
        rejectedOffers.map((offer) =>
          rejectedOffersItems.push(
            <AcceptedOffers key={offer.id} data={offer} />
          )
        );

        setPendingOffersArray(pendingOffersItems);
        setAcceptedOffersArray(acceptedOffersItems);
        setRejectedOffersArray(rejectedOffersItems);

        setSliderLoaded(false);
      })
      .catch((err) => {
        console.log(err);
        setSliderLoaded(false);
      });
  };

  useEffect(() => {
    getOffers();
  }, []);

  useEffect(() => {
    if (reloadPage) {
      getOffers();
      setReloadPage(false);
    }
  }, [reloadPage]);

  var settingsPendingOffers = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesPending,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false
        }
      }
    ]
  };
  var settingsAcceptedOffers = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesAccepted,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false
        }
      }
    ]
  };
  var settingsRejectedOffers = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesRejected,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false
        }
      }
    ]
  };

  return (
    <div className="offers">
      <Helmet>
        <title>Autoversand | {t('offers.offers')}</title>
      </Helmet>
      <Header />
      <MobileHeader />
      <div className="offers__main">
        <Navbar />
        <div className="offers__content">
          <section className="offers__section">
            <div className="offers__section--heading">
              <ContentHeader>{t('offers.pending')}</ContentHeader>
              <div className="offers__section_numbers">
                {numOfPendingOffers}
              </div>
            </div>
            {/* <p className="shipmentView__paragraph">
              Here are offers that are still being evaluated.
            </p> */}

            {sliderLoaded ? (
              <PuffLoader
                css={override}
                size={60}
                margin={2}
                color={'rgba(116, 38, 251, 0.4)'}
                loading={sliderLoaded}
              />
            ) : pendingOffersArray.length > 0 ? (
              <div className="section__pendingOffers">
                <Slider {...settingsPendingOffers}>{pendingOffersArray}</Slider>
              </div>
            ) : (
              <div className="nothingFound">
                <NothingFound />
              </div>
            )}
          </section>
          <section className="offers__section">
            <div className="offers__section--heading">
              <ContentHeader>{t('offers.accepted')}</ContentHeader>
              <div className="offers__section_numbers">
                {numOfAcceptedOffers}
              </div>
            </div>
            {/* <p className="shipmentView__paragraph">
              Here are all your accepted offers.
            </p> */}
            {sliderLoaded ? (
              <PuffLoader
                css={override}
                size={60}
                margin={2}
                color={'rgba(116, 38, 251, 0.4)'}
                loading={sliderLoaded}
              />
            ) : acceptedOffersArray.length > 0 ? (
              <div className="section__acceptedOffers">
                <Slider {...settingsAcceptedOffers}>
                  {acceptedOffersArray}
                </Slider>
              </div>
            ) : (
              <div className="nothingFound">
                <NothingFound />
              </div>
            )}
          </section>
          <section className="offers__section">
            <div className="offers__section--heading">
              <ContentHeader>{t('offers.rejected')}</ContentHeader>
              <div className="offers__section_numbers">
                {numOfRejectedOffers}
              </div>
            </div>
            {/* <p className="shipmentView__paragraph">
              Here are all your rejected offers.
            </p> */}
            {sliderLoaded ? (
              <PuffLoader
                css={override}
                size={60}
                margin={2}
                color={'rgba(116, 38, 251, 0.4)'}
                loading={sliderLoaded}
              />
            ) : rejectedOffersArray.length > 0 ? (
              <div className="section__pendingOffers">
                <Slider {...settingsRejectedOffers}>
                  {rejectedOffersArray}
                </Slider>
              </div>
            ) : (
              <div className="nothingFound">
                <NothingFound />
              </div>
            )}
          </section>
        </div>
        <Animated
          className="editBidAnimated"
          animationIn="fadeInRight"
          animationOut="fadeOutRight"
          animateOnMount={false}
          animationInDelay={500}
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={visible}
        >
          <GiveBid
            onClickClose={onClickClose}
            offerId={globalOfferId}
            setReloadPage={setReloadPage}
          />
        </Animated>
      </div>
      <MobileNavbar />
    </div>
  );
};

export default withNamespaces()(Offers);
