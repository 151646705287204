import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

import { RiArrowLeftLine } from 'react-icons/ri';

import auth from '../../auth';
import config from '../../config/api.config.js';

import './messengerHeader.css';

const MessengerHeader = ({ user, onClick }) => {
  const [userImage, setUserImage] = useState();
  useEffect(() => {
    axios
      .get(`${config.apiUrl}/users/cips/${auth.getId()}/`)
      .then((res) => setUserImage(`url(${config.assetUrl}${res.data.photo})`));
  }, []);

  return (
    <div className="mobileHeader messengerHeader">
      <div className="header__leftSideMobile">
        <a onClick={onClick}>
          <RiArrowLeftLine />
        </a>
        <p>{user}</p>
      </div>
      <div className="header__rightSideMobile">
        <Link to="/account-mobile">
          <div
            className="header__profile"
            style={{ backgroundImage: userImage }}
          ></div>
        </Link>
      </div>
    </div>
  );
};

export default MessengerHeader;
