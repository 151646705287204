import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

import ContentHeader from '../../components/ContentHeader/ContentHeader';
import ShipmentsCard from '../../components/ShipmentsCard/ShipmentsCard';

import { ReactComponent as NothingFound } from '../../assets/images/nothingFound.svg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import config from '../../config/api.config.js';
import axios from 'axios';
import auth from '../../auth';
import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';

import './Orders.css';

const override = css`
  margin: 0 auto;
  width: 100px;
  margin-top: 100px;
  margin-bottom: 100px;
`;

const Orders = ({ t }) => {
  let shipments = [];
  let activeOrders = [];
  let expiredOrders = [];
  let liveOrders = [];
  let completedOrders = [];
  let activeOrderItems = [];
  let liveOrderItems = [];
  let expiredOrderItems = [];
  let completedOrderItems = [];
  const [activeOrderArray, setActiveOrderArray] = useState([]);
  const [expiredOrderArray, setExpiredOrderArray] = useState([]);
  const [liveOrderArray, setLiveOrderArray] = useState([]);
  const [completedOrderArray, setCompletedOrderArray] = useState([]);
  let [slidesActive, setSlidesActive] = useState(1);
  let [slidesExpired, setSlidesExpired] = useState(1);
  let [slidesLive, setSlidesLive] = useState(1);
  let [slidesCompleted, setSlidesCompleted] = useState(1);
  const [sliderLoaded, setSliderLoaded] = useState(false);

  let settingsActiveOrders = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesActive,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false
        }
      }
    ]
  };

  let settingsExpiredOrders = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesExpired,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false
        }
      }
    ]
  };

  let settingsLiveOrders = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesLive,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false
        }
      }
    ]
  };

  let settingsCompletedOrders = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesCompleted,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false
        }
      }
    ]
  };

  const getShipmentData = async () => {
    setSliderLoaded(true);

    await axios
      .get(`${config.apiUrl}/shipments/user/${auth.getId()}/`)
      .then((res) => {
        shipments = res.data;

        shipments.map((shipment) => {
          if (['paid', 'unpaid'].includes(shipment.state)) {
            activeOrders.push({
              shipmentID: shipment.id,
              backgroundImage:
                shipment.photos.length == 0
                  ? `url(${'https://picsum.photos/200'})`
                  : `url(${shipment.photos[0].file})`,
              transportType:
                shipment.vehicle.manufacturer +
                ' ' +
                shipment.vehicle.model +
                ' ' +
                shipment.vehicle.year,
              location: {
                from: {
                  city: shipment.pickup.city,
                  country: shipment.pickup.country
                },
                to: {
                  city: shipment.destination.city,
                  country: shipment.destination.country
                }
              }
            });
          } else if (shipment.state == 'live') {
            liveOrders.push({
              shipmentID: shipment.id,
              backgroundImage:
                shipment.photos.length == 0
                  ? `url(${'https://picsum.photos/200'})`
                  : `url(${shipment.photos[0].file})`,
              transportType:
                shipment.vehicle.manufacturer +
                ' ' +
                shipment.vehicle.model +
                ' ' +
                shipment.vehicle.year,
              location: {
                from: {
                  city: shipment.pickup.city,
                  country: shipment.pickup.country
                },
                to: {
                  city: shipment.destination.city,
                  country: shipment.destination.country
                }
              }
            });
          } else if (shipment.state == 'expired') {
            expiredOrders.push({
              shipmentID: shipment.id,
              backgroundImage:
                shipment.photos.length == 0
                  ? `url(${'https://picsum.photos/200'})`
                  : `url(${shipment.photos[0].file})`,
              transportType:
                shipment.vehicle.manufacturer +
                ' ' +
                shipment.vehicle.model +
                ' ' +
                shipment.vehicle.year,
              location: {
                from: {
                  city: shipment.pickup.city,
                  country: shipment.pickup.country
                },
                to: {
                  city: shipment.destination.city,
                  country: shipment.destination.country
                }
              }
            });
          } else if (shipment.state == 'completed') {
            completedOrders.push({
              shipmentID: shipment.id,
              backgroundImage:
                shipment.photos.length == 0
                  ? `url(${'https://picsum.photos/200'})`
                  : `url(${shipment.photos[0].file})`,
              transportType:
                shipment.vehicle.manufacturer +
                ' ' +
                shipment.vehicle.model +
                ' ' +
                shipment.vehicle.year,
              location: {
                from: {
                  city: shipment.pickup.city,
                  country: shipment.pickup.country
                },
                to: {
                  city: shipment.destination.city,
                  country: shipment.destination.country
                }
              }
            });
          }
        });

        if (activeOrders.length == 1) setSlidesActive(1);
        else if (activeOrders.length == 2) setSlidesActive(2);
        else if (activeOrders.length == 3) setSlidesActive(3);
        else setSlidesActive(4);

        if (liveOrders.length == 1) setSlidesLive(1);
        else if (liveOrders.length == 2) setSlidesLive(2);
        else if (liveOrders.length == 3) setSlidesLive(3);
        else setSlidesLive(4);

        if (expiredOrders.length == 1) setSlidesExpired(1);
        else if (expiredOrders.length == 2) setSlidesExpired(2);
        else if (expiredOrders.length == 3) setSlidesExpired(3);
        else setSlidesExpired(4);

        if (completedOrders.length == 1) setSlidesCompleted(1);
        else if (completedOrders.length == 2) setSlidesCompleted(2);
        else if (completedOrders.length == 3) setSlidesCompleted(3);
        else setSlidesCompleted(4);

        activeOrders.map((shipment) =>
          activeOrderItems.push(
            <ShipmentsCard key={shipment.shipmentID} data={shipment} />
          )
        );

        liveOrders.map((shipment) =>
          liveOrderItems.push(
            <ShipmentsCard key={shipment.shipmentID} data={shipment} />
          )
        );

        expiredOrders.map((shipment) =>
          expiredOrderItems.push(
            <ShipmentsCard key={shipment.shipmentID} data={shipment} />
          )
        );

        completedOrders.map((shipment) =>
          completedOrderItems.push(
            <ShipmentsCard key={shipment.shipmentID} data={shipment} />
          )
        );

        setActiveOrderArray(activeOrderItems);
        setLiveOrderArray(liveOrderItems);
        setExpiredOrderArray(expiredOrderItems);
        setCompletedOrderArray(completedOrderItems);

        setSliderLoaded(false);
      })
      .catch((err) => {
        console.log(err);
        setSliderLoaded(false);
      });
  };

  useEffect(() => {
    getShipmentData();
  }, []);

  return (
    <div className="orders__content">
      <Helmet>
        <title>Autoversand | {t('orders.orders')}</title>
      </Helmet>
      <section className="orders__section1">
        <ContentHeader>{t('orders.active')}</ContentHeader>
        {/* <p className="orders__section--paragraph">
          Want to send or recieve car? Use this page to give us more information
          and recieve offers from available carriers.
        </p> */}
        {sliderLoaded ? (
          <PuffLoader
            css={override}
            size={60}
            margin={2}
            color={'rgba(116, 38, 251, 0.4)'}
            loading={sliderLoaded}
          />
        ) : activeOrderArray.length > 0 ? (
          <div className="orders__slider">
            <Slider {...settingsActiveOrders}>{activeOrderArray}</Slider>
          </div>
        ) : (
          <div className="nothingFound">
            <NothingFound />
          </div>
        )}
      </section>

      <section className="orders__section1a">
        <ContentHeader>{t('orders.live')}</ContentHeader>
        {sliderLoaded ? (
          <PuffLoader
            css={override}
            size={60}
            margin={2}
            color={'rgba(116, 38, 251, 0.4)'}
            loading={sliderLoaded}
          />
        ) : liveOrderArray.length > 0 ? (
          <div className="orders__slider">
            <Slider {...settingsLiveOrders}>{liveOrderArray}</Slider>
          </div>
        ) : (
          <div className="nothingFound">
            <NothingFound />
          </div>
        )}
      </section>

      <section className="orders__section2">
        <ContentHeader>{t('orders.expired')}</ContentHeader>
        {/* <p className="orders__section--paragraph">
          Want to send or recieve car? Use this page to give us more information
          and recieve offers from available carriers.
        </p> */}
        {sliderLoaded ? (
          <PuffLoader
            css={override}
            size={60}
            margin={2}
            color={'rgba(116, 38, 251, 0.4)'}
            loading={sliderLoaded}
          />
        ) : expiredOrderArray.length > 0 ? (
          <div className="orders__slider orders__sliderExpired">
            <Slider {...settingsExpiredOrders}>{expiredOrderArray}</Slider>
          </div>
        ) : (
          <div className="nothingFound">
            <NothingFound />
          </div>
        )}
      </section>

      <section className="orders__section3">
        <ContentHeader>{t('orders.completed')}</ContentHeader>
        {/* <p className="orders__section--paragraph">
          Want to send or recieve car? Use this page to give us more information
          and recieve offers from available carriers.
        </p> */}
        {sliderLoaded ? (
          <PuffLoader
            css={override}
            size={60}
            margin={2}
            color={'rgba(116, 38, 251, 0.4)'}
            loading={sliderLoaded}
          />
        ) : completedOrderArray.length > 0 ? (
          <div className="orders__slider">
            <Slider {...settingsCompletedOrders}>{completedOrderArray}</Slider>
          </div>
        ) : (
          <div className="nothingFound">
            <NothingFound />
          </div>
        )}
      </section>
    </div>
  );
};

export default withNamespaces()(Orders);
