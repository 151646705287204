import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { withNamespaces } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';

import { lngSetup, changeLanguage } from './LanguageSettings';
import auth from '../../auth';

import { Animated } from 'react-animated-css';

import './burgerNavbar.css';

const BurgerNavbar = (props) => {
  const { t, i18n } = props;
  lngSetup(i18n);

  const [visible, setVisible] = useState(false);
  const toogleVisible = () => setVisible(!visible);

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    if (visible) html.style.overflowY = 'hidden';
    else html.style.overflowY = 'scroll';
  }, [visible]);

  return (
    <div className="burgerNavbar">
      <div id="menuToggle">
        <input type="checkbox" onClick={() => toogleVisible()} />

        <span></span>
        <span></span>
        <span></span>
      </div>
      <Animated
        className="burgerAnimation"
        animationIn="fadeInRight"
        animationOut="fadeOutRight"
        animateOnMount={false}
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={visible}
      >
        <ul id="menu">
          {!auth.isAuthenticated() && (
            <Link to="/login">
              <li>{t('login.login')}</li>
            </Link>
          )}
          {auth.isAuthenticated() && auth.isCarrier() && (
            <Link to="/dashboard">
              <li>{t('navbar.dashboard')}</li>
            </Link>
          )}
          {auth.isAuthenticated() && !auth.isCarrier() && (
            <Link to="/shipments">
              <li>{t('navbar.dashboard')}</li>
            </Link>
          )}
          {!auth.isAuthenticated() && (
            <Link to="/register">
              <li>{t('navbar.signup')}</li>
            </Link>
          )}
          <Link to={'/about-us'}>
            <li> {t('navbar.wer')}</li>
          </Link>
          <a
            href={'https://twodotsdigital.zohorecruit.eu/jobs/Careers'}
            target="_blank"
            nofollow
          >
            <li> {t('navbar.careers')}</li>
          </a>
          <li className="burgerMenu__flags">
            <ReactFlagsSelect
              defaultCountry={i18n.language.toLocaleUpperCase()}
              onSelect={(value) =>
                changeLanguage(i18n, value.toLocaleLowerCase())
              }
              countries={['DE', 'GB']}
              customLabels={{ DE: 'Deutsch', GB: 'English' }}
            />
          </li>
        </ul>
      </Animated>
    </div>
  );
};

export default withNamespaces()(BurgerNavbar);
