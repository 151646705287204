import React from "react";
import ReactDOM from "react-dom";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  InfiniteHits,
  Configure,
  connectMenu,
  connectStats,
  RangeInput,
} from "react-instantsearch-dom";

const searchClient = algoliasearch(
  "5YO70YNTRI",
  "7028ae0ec0daedc97b492a5fe6254437"
);

const MenuSelect = ({ items }) => (
  <select>
    <option value="">See all options</option>
    {items.map((item) => (
      <option key={item.label} value={item.value}>
        {item.label}
      </option>
    ))}
  </select>
);

const Stats = ({ nbHits }) => <p>{nbHits}</p>;

const Hit = ({ hit }) => <p>{hit.name}</p>;

const Algolia = () => {
  return (
    <InstantSearch indexName="vehicles" searchClient={searchClient}>
      <CustomMenuSelect attribute="brand" />
      <RangeInput attribute="registrationYear" />
      <SearchBox />
      <Configure filters="" />
      <button>+</button>
      <CustomStats />
      <InfiniteHits hitComponent={Hit} />
    </InstantSearch>
  );
};

const CustomStats = connectStats(Stats);
const CustomMenuSelect = connectMenu(MenuSelect);

export default Algolia;
