import React, { useEffect, useState } from 'react';
import { FaAnchor } from 'react-icons/fa';
import MarkerIcon from '../../assets/images/marker.png';
const { compose, withProps, withState, withHandlers } = require('recompose');
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} = require('react-google-maps');

const waterStyle = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  }
];

const MapWithControlledZoom = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBQKpE6JxPpRDnbINcFFdM7XNBEg-yOX9c&v=3.exp&language=en&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withState('zoom', 'onZoomChange', 4),
  withHandlers(() => {
    const refs = {
      map: undefined
    };

    return {
      onMapMounted: () => (ref) => {
        refs.map = ref;
      },
      onZoomChanged: ({ onZoomChange }) => () => {
        onZoomChange(refs.map.getZoom());
      }
    };
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap
      center={{
        lat:
          (parseFloat(props.markers.from.lat) +
            parseFloat(props.markers.to.lat)) /
          2,
        lng:
          (parseFloat(props.markers.from.long) +
            parseFloat(props.markers.to.long)) /
          2
      }}
      zoom={props.zoom}
      ref={props.onMapMounted}
      onZoomChanged={props.onZoomChanged}
      options={{
        styles: waterStyle,
        disableDefaultUI: true
      }}
    >
      <Marker
        position={{
          lat: parseFloat(props.markers.from.lat),
          lng: parseFloat(props.markers.from.long)
        }}
        onClick={props.onToggleOpen}
        icon={{
          url: require('../../assets/images/marker1.svg'),
          scaledSize: { width: 32, height: 32 }
        }}
      />
      <Marker
        position={{
          lat: parseFloat(props.markers.to.lat),
          lng: parseFloat(props.markers.to.long)
        }}
        onClick={props.onToggleOpen}
        icon={{
          url: require('../../assets/images/marker.svg'),
          scaledSize: { width: 32, height: 32 }
        }}
      />
    </GoogleMap>
  );
});

const MyMap = ({ fromPosition, toPosition }) => {
  const [markers, setMarkers] = useState({
    from: {
      lat: '',
      long: ''
    },
    to: {
      lat: '',
      long: ''
    }
  });
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0
  });

  useEffect(() => {
    setMarkers({
      from: { lat: fromPosition.lat, long: fromPosition.long },
      to: { lat: toPosition.lat, long: toPosition.long }
    });
    setCenter({
      lat: Math.min(parseFloat(fromPosition.lat), parseFloat(toPosition.lat)),
      lng: Math.max(parseFloat(fromPosition.long), parseFloat(toPosition.long))
    });
  }, [fromPosition, toPosition]);

  return <MapWithControlledZoom markers={markers} center={center} />;
};
export default MyMap;
