import auth from './auth';

const firebase = require('firebase');
require('firebase/firestore');

firebase.initializeApp({
  apiKey: 'AIzaSyAnKu4t7FU2KyNjWfoICI1tFMHIDGhBKs4',
  authDomain: 'autoversand-messenger.firebaseapp.com',
  databaseURL: 'https://autoversand-messenger.firebaseio.com',
  projectId: 'autoversand-messenger',
  storageBucket: 'autoversand-messenger.appspot.com',
  messagingSenderId: '213615394329',
  appId: '1:213615394329:web:fdb5e6fd60daae87d03488'
});

const userId = parseInt(auth.getId());

export const firestore = firebase.firestore();

export const setNotificationRead = () => {
  // return async () => {
  firebase.firestore().collection('notifications').doc(auth.getId()).update({
    hasRead: true
  });
  // };
};

export default firebase;
