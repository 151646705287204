import React from "react";
import { ReactComponent as TruckIcon } from "../../assets/images/NavbarIcons/MyOrders.svg";
import FromToSection from "../FromToSection/FromToSection";
import { useHistory, Link } from "react-router-dom";
import { Redirect } from "react-router";
import { createBrowserHistory } from "history";

import "./headerSearchList.css";

const HeaderSearchList = ({ data }) => {
  const history = createBrowserHistory({ forceRefresh: true });

  return (
    <li
      className="headerSearchList"
      onClick={() => {
        history.push(`/shipment/${data.shipmentID}`);
      }}
    >
      <TruckIcon />
      <div className="headerSearchList__left">
        <div className="headerSearchList__carType">{data.transportType}</div>
        <FromToSection data={data.location} />
      </div>
    </li>
  );
};

export default HeaderSearchList;
