import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { withNamespaces } from 'react-i18next';
import config from '../../../config/api.config.js';

import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';

import { DatePicker } from '../../DatePicker/DatePicker';
import Button from '../../Buttons/BlueButton';
import BackButton from '../../Buttons/BackButton';
import Textarea from '../../Textarea/Textarea';
import HandleTags from './HandleTags';

import moment from 'moment';

import './AddTransportInformation.css';

const AddTransportInformation = ({
  t,
  onClickClose,
  onClickOpen,
  openVehicleInformation,
  returnVehicleInformations,
  destinationLocation,
  pickupLocation,
  dataFromOtherComponents,
  setShipmentID,
  ownershipId
}) => {
  let history = useHistory();

  const [descriptionValid, setDescriptionValid] = useState(true);
  const [tagsValid, setTagsValid] = useState(true);
  const [buttonValid, setButtonValid] = useState(true);
  const [pickupStartDateValid, setPickupStartDateValid] = useState(true);
  const [pickupEndDateValid, setPickupEndDateValid] = useState(true);
  const [deliveryStartDateValid, setDeliveryStartDateValid] = useState(true);
  const [deliveryEndDateValid, setDeliveryEndDateValid] = useState(true);
  const buttons = [
    {
      value: 'open',
      name: 'Open'
    },
    {
      value: 'closed',
      name: 'Closed'
    },
    {
      value: 'organized',
      name: 'Organized'
    },
    {
      value: 'all',
      name: 'All'
    }
  ];
  const handleClick = () => {
    onClickClose(false);
    openVehicleInformation(true);
  };

  const prepareImageUpload = async (formData) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/shipments/photo-upload/`,
        formData
      );
      const { data } = response;
      return data;
    } catch (e) {
      console.log('TRY ERROR', e);
    }
  };

  const uploadImages = async () => {
    const images = await Promise.all(
      dataFromOtherComponents.imageArray.map((item) => prepareImageUpload(item))
    );

    const paths = images.map((image) => image.file);

    const formattedUrls = paths.map((item) => {
      return item.split('/media/')[1];
    });

    return formattedUrls;
  };

  const handleOpen = async () => {
    let isValid = true;

    if (values.shortDescription == '') {
      setDescriptionValid(false);
      isValid = false;
    }
    if (tags.length == 0) {
      setTagsValid(false);
      isValid = false;
    }
    if (choosenButton == null) {
      setButtonValid(false);
      isValid = false;
    }
    if (pickupStartDate == null) {
      setPickupStartDateValid(false);
      isValid = false;
    }
    if (pickupEndDate == null) {
      setPickupEndDateValid(false);
      isValid = false;
    }
    if (deliveryStartDate == null) {
      setDeliveryStartDateValid(false);
      isValid = false;
    }
    if (deliveryEndDate == null) {
      setDeliveryEndDateValid(false);
      isValid = false;
    }
    if (
      pickupEndDate &&
      deliveryStartDate &&
      !moment(deliveryStartDate).isAfter(pickupStartDate)
    ) {
      isValid = false;
      notifyUser('dates');
    }
    if (isValid) {
      setLoading(true);
      const imageUrls = await uploadImages();

      let body = {
        manufacturer: dataFromOtherComponents.selectedBrand.label,
        model: dataFromOtherComponents.selectedModel.label,
        year: dataFromOtherComponents.selectedYear.value,
        state: dataFromOtherComponents.selectedState.value,
        category: dataFromOtherComponents.selectedCategory.value,
        weight: dataFromOtherComponents.weight,
        length: dataFromOtherComponents.length,
        width: dataFromOtherComponents.width,
        height: dataFromOtherComponents.height,
        pickup: {
          place_id: pickupLocation.place_id,
          city: pickupLocation.address_components.filter((address) => {
            if (address.types.includes('locality')) return address.long_name;
          })[0].long_name,
          country: pickupLocation.address_components.filter((address) => {
            if (address.types.includes('country')) return address.long_name;
          })[0].long_name,
          formatted_address: pickupLocation.formatted_address,
          latitude: pickupLocation.geometry.location.lat().toString(),
          longitude: pickupLocation.geometry.location.lng().toString()
        },
        pickup_date_from: moment(pickupStartDate).format('YYYY-MM-DD'),
        pickup_date_to: moment(pickupEndDate).format('YYYY-MM-DD'),
        delivery: {
          place_id: destinationLocation.place_id,
          city: destinationLocation.address_components.filter((address) => {
            if (address.types.includes('locality')) return address.long_name;
          })[0].long_name,
          country: destinationLocation.address_components.filter((address) => {
            if (address.types.includes('country')) return address.long_name;
          })[0].long_name,
          formatted_address: destinationLocation.formatted_address,
          latitude: destinationLocation.geometry.location.lat().toString(),
          longitude: destinationLocation.geometry.location.lng().toString()
        },
        delivery_date_from: moment(deliveryStartDate).format('YYYY-MM-DD'),
        delivery_date_to: moment(deliveryEndDate).format('YYYY-MM-DD'),
        type: type,
        items: tags,
        description: values.shortDescription,
        ownership: ownershipId,
        fin_number: dataFromOtherComponents.serialNumber
      };
      dataFromOtherComponents.imageArray.length
        ? (body = { ...body, photos: imageUrls })
        : (body = {
            ...body,
            import_photos: dataFromOtherComponents.importedPhotos
          });

      const createResponse = await axios.post(
        `${config.apiUrl}/shipments/create/`,
        body
      );

      setShipmentID(createResponse.data.id);

      await onClickClose(false);
      await onClickOpen(true);
      setLoading(false);
    }
    setTimeout(() => {
      setPickupStartDateValid(true);
      setPickupEndDateValid(true);
      setDeliveryStartDateValid(true);
      setDeliveryEndDateValid(true);
      setButtonValid(true);
      setTagsValid(true);
      setDescriptionValid(true);
    }, 500);
  };
  const [pickupStartDate, pickupEndDate, PickupDatePicker] = DatePicker(
    'Pickup date from',
    'Pickup date to',
    'pickupStartDate',
    'pickupEndDate'
  );
  const [deliveryStartDate, deliveryEndDate, DeliveryDatePicker] = DatePicker(
    'Delivery date from',
    'Delivery date to',
    'deliveryStartDate',
    'deliveryEndDate'
  );

  const [values, setValues] = useState({
    pickupLocation: '',
    deliveryLocation: '',
    shortDescription: ''
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setValues({
      ...values,
      [e.target.name]: value
    });
  };

  const createButton = (index, value, name) => (
    <input
      type="button"
      className={
        choosenButton == index
          ? 'addTransportInformation__input addTransportInformation__selectedButton'
          : 'addTransportInformation__input'
      }
      value={value}
      name={name}
      onClick={(e) => {
        setChoosenButton(index);
        setType(e.target.value);
      }}
    />
  );

  const [tags, getTags] = useState([]);
  const [choosenButton, setChoosenButton] = useState(null);
  const [type, setType] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);

  const notifyUser = (notificationFor) => {
    const Msg_Dates = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          {t('toast.deliveryDate')}
        </p>
      </div>
    );

    if (notificationFor == 'dates') {
      toast.error(<Msg_Dates />, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'B'
      });
    }
  };

  return (
    <div className="addTransportInformation">
      <div className="addTransportInformation__content">
        <ToastContainer
          position={toast.POSITION.TOP_RIGHT}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          enableMultiContainer
          containerId={'B'}
        />
        <h2 className="addTransportInformation__content--heading">
          {t('shipments.transportInfo')}
        </h2>
        <p className="addTransportInformation__content--paragraph">
          {t('shipments.givInfo')}
        </p>
        <div
          className={
            (!pickupStartDateValid || !pickupEndDateValid) && 'shakeIt'
          }
        >
          <div className="addTransportInformation__groupInput">
            <PickupDatePicker />
          </div>
        </div>
        <div
          className={
            (!deliveryStartDateValid || !deliveryEndDateValid) && 'shakeIt'
          }
        >
          <div className="addTransportInformation__groupInput2">
            <DeliveryDatePicker />
          </div>
        </div>
        <p className="addTransportInformation__input--paragraph">
          {t('shipments.whatTransport')}
        </p>
        <div className={!buttonValid && 'shakeIt'}>
          <div className="addTransportInformation__buttons">
            {buttons.map((value, index) =>
              createButton(index, value.value, value.name)
            )}
          </div>
        </div>
        <p className="addTransportInformation__input--paragraph">
          {t('shipments.additionalThings')}
        </p>

        <div className={!tagsValid && 'shakeIt'}>
          <HandleTags getTags={getTags} />
        </div>

        <div className={!descriptionValid && 'shakeIt'}>
          <Textarea
            name="shortDescription"
            required
            onChange={handleChange}
            labelText={t('shipments.additionalInfo')}
            maxLength="1000"
          />
        </div>
        {loading ? (
          <Button>
            <ClipLoader size={15} color={'#fff'} loading={loading} />
          </Button>
        ) : (
          <Button handleOpen={handleOpen}>{t('shipments.finish')}</Button>
        )}
      </div>
      <BackButton handleClick={handleClick} />
    </div>
  );
};

export default withNamespaces()(AddTransportInformation);
