import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { withNamespaces } from 'react-i18next';
import config from '../../../config/api.config.js';

import FormInput from '../../../components/FormInput/FormInput';
import Button from '../../Buttons/BlueButton';
import auth from '../../../auth';

import './ChangePassword.css';

const ChangePasword = ({ t, customPassword }) => {
  const userId = auth.getId();
  const [values, setValues] = useState({
    oldPassword: '',
    newPassword: '',
    repeatPassword: ''
  });

  const [oldPasswordValid, setOldPasswordValid] = useState(true);
  const [newPasswordValid, setNewPasswordValid] = useState(true);
  const [repeatPasswordValid, setRepeatPasswordValid] = useState(true);

  const handleChange = (e) => {
    const value = e.target.value;

    setValues({
      ...values,
      [e.target.name]: value
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    updateCips();
  };

  const updateCips = async () => {
    let isValid = true;

    if (values.oldPassword == '' && customPassword) {
      isValid = false;
      setOldPasswordValid(false);
    }

    if (values.newPassword == '' || values.newPassword.length < 8) {
      isValid = false;
      setNewPasswordValid(false);
    }

    if (
      values.newPassword !== values.repeatPassword ||
      values.repeatPassword == ''
    ) {
      isValid = false;
      setRepeatPasswordValid(false);
    }

    if (!isValid) {
      return setTimeout(() => {
        setOldPasswordValid(true);
        setNewPasswordValid(true);
        setRepeatPasswordValid(true);
      }, 500);
    }
    const formData = new FormData();

    if (customPassword) formData.append('old_password', values.oldPassword);

    formData.append('new_password', values.newPassword);

    await axios
      .patch(`${config.apiUrl}/users/update/${userId}/`, formData)
      .then((res) => {
        console.log(res);
        notifyUser('passwordChanged');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const notifyUser = (notificationFor) => {
    const Msg_Password = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          {t('toast.success')}
        </p>
      </div>
    );

    if (notificationFor == 'passwordChanged') {
      toast.success(<Msg_Password />, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
    }
  };

  return (
    <div className="changePassword">
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        enableMultiContainer
        containerId={'A'}
      />
      <form>
        <div className="changePassword__content">
          {customPassword && (
            <div className={!oldPasswordValid && 'shakeIt'}>
              <FormInput
                type="password"
                name="oldPassword"
                labelText={t('settings.oldPassword')}
                settings={true}
                value={values.oldPassword}
                required
                onChange={handleChange}
              />
            </div>
          )}
          <div className={!newPasswordValid && 'shakeIt'}>
            <FormInput
              type="password"
              name="newPassword"
              labelText={t('settings.newPassword')}
              settings={true}
              value={values.newPassword}
              required
              onChange={handleChange}
            />
          </div>
          <div className={!repeatPasswordValid && 'shakeIt'}>
            <FormInput
              type="password"
              name="repeatPassword"
              labelText={t('settings.repeatPassword')}
              settings={true}
              value={values.repeatPassword}
              required
              onChange={handleChange}
            />
          </div>
          <Button handleOpen={handleSubmit} type="submit">
            {t('settings.save')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default withNamespaces()(ChangePasword);
