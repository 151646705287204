import React from 'react';

import FromToSection from '../../FromToSection/FromToSection';
import { ReactComponent as Truck } from '../../../assets/images/offersTruck.svg';
import { withNamespaces } from 'react-i18next';

import './OfferCard.css';

const OfferCard = ({ t, data }) => {
  return (
    <div className="offerCard">
      <div
        className="offerCard__top"
        style={{ backgroundImage: `url(${data.photo})` }}
      >
        {' '}
      </div>
      <div className="offerCard__bottom">
        <div className="offerCard__price">{data.amount}</div>
        <div className="offerCard__bottomMiddle">
          <div className="offerCard__carType">
            {data.manufacturer} <span>{data.model}</span>
          </div>
          <div className="offerCard__fromTo">
            <FromToSection data={data} />
          </div>
        </div>
        <div className="offerCard__dates">
          <div className="offerCard__icon">
            <Truck />
          </div>
          <div className="offerCard__fromToDate">
            <div className="offerCard__Date">
              <span>{t('offerCard.pickup')}</span>
              <span>{data.pickup_date}</span>
            </div>
            <div className="offerCard__Date">
              <span>{t('offerCard.delivery')}</span>
              <span>{data.delivery_date}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(OfferCard);
