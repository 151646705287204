import React, { useEffect, useState } from 'react';
import AcceptedBid from '../../ShipperCurrentBid/AcceptedBid/AcceptedBid';
import RejectedBid from '../../ShipperCurrentBid/RejectedBid/RejectedBid';
import AcceptRejectBid from '../../ShipperCurrentBid/AcceptRejectBid/AcceptRejectBid';
import ContentHeader from '../../ContentHeader/ContentHeader';
import { ReactComponent as NothingFound } from '../../../assets/images/nothingFound.svg';
import { withNamespaces } from 'react-i18next';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import moment from 'moment';
import config from '../../../config/api.config.js';
import axios from 'axios';
import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';
import Modal from 'react-awesome-modal';

import './shipperBidsShipment.css';

const override = css`
  margin: 0 auto;
  width: 100px;
  margin-top: 80px;
  margin-bottom: 80px;
`;

const ShipperBidsShipment = ({ t, data, shipmentId, callback }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  let pendingOffers = [];
  let acceptedOffers = [];
  let rejectedOffers = [];
  let pendingOffersItems = [];
  let acceptedOffersItems = [];
  let rejectedOffersItems = [];
  const [offers, setOffers] = useState([]);

  let slidesOffersTemp = [];
  let [slidesOffers, setSlidesOffers] = useState(1);
  const [sliderLoaded, setSliderLoaded] = useState(false);
  let _numOfOffer = 0;

  var settingsBids = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesOffers,
    slidesToScroll: 1,
    autoplay: false,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    pauseOnHover: true
  };

  const getOffersData = async () => {
    setSliderLoaded(true);

    await axios
      .get(`${config.apiUrl}/offers/${shipmentId}/`)
      .then((res) => {
        res.data.map((offer) => {
          offer.state == 'pending'
            ? pendingOffers.push({
                price: offer.amount,
                userImage: `${config.assetUrl}${offer.photo}`,
                userName: offer.company,
                carrier: offer.carrier,
                message: offer.description,
                pickupDate: moment(offer.pickup_date).format('DD.MM.YYYY'),
                deliveryDate: moment(offer.delivery_date).diff(
                  moment(offer.pickup_date),
                  'days'
                ),
                pickupDateSend: offer.pickup_date,
                deliveryDateSend: offer.delivery_date,
                id: offer.id,
                ratings: offer.ratings.length == 0 ? 0 : offer.ratings,
                state: offer.state,
                verified: offer.verified,
                numOfOffer: ++_numOfOffer,
                ratings: offer.ratings
              })
            : offer.state == 'accepted'
            ? acceptedOffers.push({
                price: offer.amount,
                userImage: `${config.assetUrl}${offer.photo}`,
                userName: offer.company,
                carrier: offer.carrier,
                message: offer.description,
                pickupDate: moment(offer.pickup_date).format('DD.MM.YYYY'),
                deliveryDate: moment(offer.delivery_date).diff(
                  moment(offer.pickup_date),
                  'days'
                ),
                id: offer.id,
                ratings: offer.ratings.length == 0 ? 0 : offer.ratings,
                state: offer.state,
                verified: offer.verified,
                numOfOffer: ++_numOfOffer,
                ratings: offer.ratings
              })
            : rejectedOffers.push({
                price: offer.amount,
                userImage: `${config.assetUrl}${offer.photo}`,
                userName: offer.company,
                carrier: offer.carrier,
                message: offer.description,
                pickupDate: moment(offer.pickup_date).format('DD.MM.YYYY'),
                deliveryDate: moment(offer.delivery_date).diff(
                  moment(offer.pickup_date),
                  'days'
                ),
                id: offer.id,
                ratings: offer.ratings.length == 0 ? 0 : offer.ratings,
                state: offer.state,
                verified: offer.verified,
                numOfOffer: ++_numOfOffer,
                ratings: offer.ratings
              });
        });

        pendingOffers.map((offer) =>
          pendingOffersItems.push(
            <AcceptRejectBid
              data={offer}
              shipmentId={shipmentId}
              openModal={openModal}
              setModalMessage={setModalMessage}
            />
          )
        );

        acceptedOffers.map((offer) =>
          acceptedOffersItems.push(
            <AcceptedBid
              data={offer}
              openModal={openModal}
              setModalMessage={setModalMessage}
            />
          )
        );
        rejectedOffers.map((offer) =>
          rejectedOffersItems.push(
            <RejectedBid
              data={offer}
              openModal={openModal}
              setModalMessage={setModalMessage}
            />
          )
        );

        pendingOffersItems.map((offer) => {
          slidesOffersTemp.push(offer);
        });
        acceptedOffersItems.map((offer) => {
          slidesOffersTemp.push(offer);
        });
        rejectedOffersItems.map((offer) => {
          slidesOffersTemp.push(offer);
        });
        setOffers(slidesOffersTemp);

        if (slidesOffersTemp.length == 1) setSlidesOffers(1);
        else if (slidesOffersTemp.length == 2) setSlidesOffers(1);
        else setSlidesOffers(2);

        setSliderLoaded(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOffersData();
    let _document = document.getElementById('shipper_currentBids');
    callback(_document);
  }, []);

  return (
    <div className="shipmentViewShipper__currentBids">
      <div style={{ marginBottom: '3rem' }}>
        <ContentHeader>{t('navbar.offers')}</ContentHeader>
      </div>
      {sliderLoaded ? (
        <PuffLoader
          css={override}
          size={60}
          margin={2}
          color={'rgba(116, 38, 251, 0.4)'}
          loading={sliderLoaded}
        />
      ) : offers.length ? (
        <div className="shipmentViewShipper__listOfBids">
          <Slider {...settingsBids}>{offers}</Slider>
        </div>
      ) : (
        <div className="nothingFound">
          <NothingFound />
        </div>
      )}

      <Modal
        visible={modalVisible}
        width="42%"
        effect="fadeInUp"
        onClickAway={() => closeModal()}
      >
        <div>
          {modalMessage}
          <a
            href="javascript:void(0);"
            onClick={() => closeModal()}
            className="infoBoxModal__close"
          >
            {t('modal.close')}
          </a>
        </div>
      </Modal>
    </div>
  );
};

export default withNamespaces()(ShipperBidsShipment);
