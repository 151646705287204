import React from 'react';
import config from '../../../config/api.config.js';
import documentIcon from '../../../assets/images/document.png';
import './DocumentUpload.css';

const DocumentUpload = ({ file }) => {
  let type = '.pdf';

  const downloadDocumentsLocaly = () => {
    const filePath = `${config.assetUrl}${file.path}`;
    fetch(filePath).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file.name;
        a.click();
      });
    });
  };

  const downloadDocument = () => {
    if (file.path.includes(`${config.assetUrl}`)) window.open(file.path);
    else downloadDocumentsLocaly();
  };
  return (
    <div className="documentUpload" onClick={downloadDocument}>
      <img className="document__image" src={documentIcon} alt="" />
      <p className="document__fileType">{type}</p>
      <p className="document__fileName">{file.name}</p>
    </div>
  );
};

export default DocumentUpload;
