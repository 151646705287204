import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

import Header from '../../Layout/Dashboard/DashboardHeader/DashboardHeader';
import Navbar from '../../Layout/Dashboard/DashboardNavbar/DashboardNavbar';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import PendingPaymentsCard from '../../components/PendingPaymentsCard/PendingPaymentsCard';
import Billing from '../../components/BillingTableRow/BillingTableRow';
import MakePayment from '../../components/MakePayment/Payment/Payment';
import PaymentSuccessful from '../../components/MakePayment/PaymentSuccessful/PaymentSuccessful';
import MobileHeader from '../../Layout/Mobile/Header';
import MobileNavbar from '../../Layout/Mobile/Navbar';
import { ReactComponent as NothingFound } from '../../assets/images/nothingFound.svg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { Animated } from 'react-animated-css';
import { ToastContainer, toast } from 'react-toastify';

import auth from '../../auth';
import config from '../../config/api.config.js';
import axios from 'axios';
import moment from 'moment';
import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';

import './PaymentsShipper.css';

const override = css`
  margin: 0 auto;
  width: 100px;
  margin-top: 100px;
  margin-bottom: 100px;
`;

const Payments = ({ t }) => {
  const [visiblePaymentSuccess, setVisiblePaymentSuccess] = useState(false);
  const [status, setStatus] = useState(null);

  const history = useHistory();
  const [visible, setVisible] = useState(false);

  let pendingPayments = [];
  let pendingPaymentsItems = [];
  const [pendingPaymentsArray, setPendingPaymentsArray] = useState([]);

  let billingHistoryCounter = 0;
  let billingHistory = [];
  let billingHistoryItems = [];
  let billingHistoryMobileItems = [];
  const [billingHistoryArray, setBillingHistoryArray] = useState({
    billingHistory: [],
    billingHistoryMobile: []
  });

  let [slidesOffers, setSlidesOffers] = useState(1);
  const [sliderLoaded, setSliderLoaded] = useState(false);

  const [selectedOffer, setSelectedOffer] = useState(0);
  const [cips, setCips] = useState();

  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: slidesOffers,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          className: 'center',
          centerPadding: '0',
          autoplay: false
        }
      }
    ]
  };

  const getParam = () =>
    new URLSearchParams(window.location.search).get('status');

  useEffect(() => {
    setStatus(getParam());
    if (status != null && status == 'success') setVisiblePaymentSuccess(true);
  }, [status]);

  const getCips = async () => {
    await axios
      .get(`${config.apiUrl}/users/cips/${auth.getId()}/`)
      .then((res) => {
        setCips(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getOffersData = async () => {
    setSliderLoaded(true);

    await axios
      .get(`${config.apiUrl}/offers/my/`)
      .then((res) => {
        console.log(res);
        const offers = res.data;
        offers.map((offer) => {
          if (offer.payment != null) {
            if (offer.payment.status == 'pending' && offer.state == 'accepted')
              pendingPayments.push({
                userName: offer.company,
                carImage: `${config.assetUrl}${offer.photo}`,
                price: offer.payment.total_charge + '€',
                carrier: offer.carrier,
                car: offer.manufacturer,
                carType: offer.model,
                from: {
                  city: offer.pickup_city,
                  country: offer.pickup_country
                },
                to: {
                  city: offer.destination_city,
                  country: offer.destination_country
                },
                id: offer.id,
                state: offer.state,
                verified: offer.verified,
                shipmentID: offer.shipment
              });
          }
        });

        pendingPayments.map((offer) =>
          pendingPaymentsItems.push(
            <PendingPaymentsCard
              key={offer.id}
              data={offer}
              buttonAction={
                !auth.isCarrier()
                  ? t('payments.makePayment')
                  : t('profile.viewShipment')
              }
              handleOpen={() => {
                if (!auth.isCarrier()) {
                  setVisible(true);
                  setSelectedOffer(offer.id);
                } else history.push(`/shipment/${offer.shipmentID}`);
              }}
            />
          )
        );

        setPendingPaymentsArray(pendingPaymentsItems);

        if (pendingPayments.length == 1) setSlidesOffers(1);
        else if (pendingPayments.length == 2) setSlidesOffers(2);
        else if (pendingPayments.length == 3) setSlidesOffers(3);
        else if (pendingPayments.length == 4) setSlidesOffers(4);
        else if (pendingPayments.length == 5) setSlidesOffers(4);
        else setSlidesOffers(5);

        setSliderLoaded(false);
      })
      .catch((err) => {
        console.log(err);
        setSliderLoaded(false);
      });
  };

  const getPayments = async () => {
    await axios
      .get(`${config.apiUrl}/payments/`)
      .then((res) => {
        res.data.map((payment) => {
          billingHistory.push({
            date: moment(payment.updated_at).format('DD.MM.YYYY'),
            method: payment.source == 'stripe' ? 'Credit Card' : 'Klarna',
            description: 'Payment no.' + payment.id,
            amount: auth.isCarrier()
              ? Math.round(payment.amount * 0.75)
              : payment.total_charge,
            status: payment.status,
            offerId: payment.offer
          });
        });

        billingHistory.map((payment) => {
          billingHistoryCounter++;
          billingHistoryItems.push(
            <Billing
              key={billingHistoryCounter}
              data={payment}
              cips={cips}
              numOfRow={billingHistoryCounter}
            />
          );
          billingHistoryMobileItems.push(
            <Billing
              key={billingHistoryCounter}
              data={payment}
              cips={cips}
              numOfRow={billingHistoryCounter}
              mobile={true}
            />
          );
        });

        setBillingHistoryArray({
          billingHistory: billingHistoryItems,
          billingHistoryMobile: billingHistoryMobileItems
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCips();
    getOffersData();
    // getPayments();
  }, []);

  useEffect(() => {
    getPayments();
  }, [cips]);

  const [data, setData] = useState({
    billingInfo: {
      date: '5/12/2024',
      method: 'Credit Card',
      description: 'Shipmet no.75233',
      amount: 15000,
      status: 'paid'
    }
  });

  const [items, setItems] = useState({
    billing: [1, 2, 3, 4, 5].map((i) => (
      <Billing key={i} data={data.billingInfo} numOfRow={i} />
    )),
    billingMobile: [1, 2, 3, 4, 5, 6, 7].map((i) => (
      <Billing key={i} data={data.billingInfo} numOfRow={i} mobile={true} />
    ))
  });

  return (
    <div className="paymentsShipper">
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        enableMultiContainer
        containerId={'A'}
      />
      <Header />
      <Helmet>
        <title>Autoversand | {t('payments.payments')}</title>
      </Helmet>
      <MobileHeader />
      <div className="paymentsShipper__main">
        <Navbar />
        <div className="paymentsShipper__content">
          <section className="paymentsShipper__section">
            <div className="paymentsShipper__section--heading">
              <ContentHeader>{t('payments.pending')}</ContentHeader>
              {/* <p>Payments that awaiting payment from Shipper's side.</p> */}
            </div>

            {sliderLoaded ? (
              <PuffLoader
                css={override}
                size={60}
                margin={2}
                color={'rgba(116, 38, 251, 0.4)'}
                loading={sliderLoaded}
              />
            ) : pendingPaymentsArray.length > 0 ? (
              <div className="paymentsShipper__pandingPayments">
                <Slider {...settings}>{pendingPaymentsArray}</Slider>
              </div>
            ) : (
              <div className="nothingFound">
                <NothingFound />
              </div>
            )}
          </section>

          <section className="billingHistory__section">
            <div className="billingHistory__section--heading">
              <ContentHeader>{t('payments.billing')}</ContentHeader>
              {/* <p>All your previous payments.</p> */}
            </div>
            {sliderLoaded ? (
              <PuffLoader
                css={override}
                size={60}
                margin={2}
                color={'rgba(116, 38, 251, 0.4)'}
                loading={sliderLoaded}
              />
            ) : billingHistoryArray.billingHistory.length ? (
              <div>
                <div className="billingTable">
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th>{t('payments.date')}</th>
                        <th>{t('payments.description')}</th>
                        <th>{t('payments.method')}</th>
                        <th>{t('payments.amount')}</th>
                        <th>Status</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>{billingHistoryArray.billingHistory}</tbody>
                  </table>
                </div>
                <div className="billingTableMobile">
                  <table>
                    <tbody>{billingHistoryArray.billingHistoryMobile}</tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="nothingFound">
                <NothingFound />
              </div>
            )}
          </section>
        </div>
        <Animated
          className="makePaymentAnimated"
          animationIn="fadeInRight"
          animationOut="fadeOutRight"
          animateOnMount={false}
          animationInDelay={500}
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={visible}
        >
          <MakePayment
            onClickClose={() => {
              setVisible(false);
            }}
            onClickOpen={() => {
              setVisiblePaymentSuccess(true);
              setVisible(false);
            }}
            selectedOffer={selectedOffer}
          />
        </Animated>
        <Animated
          className="makePaymentAnimated"
          animationIn="fadeInRight"
          animationOut="fadeOutRight"
          animateOnMount={false}
          animationInDelay={500}
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={visiblePaymentSuccess}
        >
          <PaymentSuccessful
            onClickClose={() => {
              setVisiblePaymentSuccess(false);
            }}
            onClickBack={() => {
              setVisiblePaymentSuccess(false);
              setVisible(true);
            }}
          />
        </Animated>
      </div>
      <MobileNavbar />
    </div>
  );
};

export default withNamespaces()(Payments);
