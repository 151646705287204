import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';

import PlaceholderImage1 from '../../../assets/images/placeholderImage1.png';
import PlaceholderImage2 from '../../../assets/images/placeholderImage2.png';
import PlaceholderImage3 from '../../../assets/images/placeholderImage3.png';

import brands from '../../../assets/data/brands.json';
import models from '../../../assets/data/models.json';
import metadata from '../../../assets/data/metadata_body.json';
import categories from '../../../assets/data/categories.json';

import Select, { createFilter } from 'react-select';
import ImageUpload from '../../Upload/ImageUpload/ImageUpload';
import FormInput from '../../FormInput/FormInput';
import Button from '../../Buttons/BlueButton';
import BackButton from '../../Buttons/BackButton';

import { useBeforeFirstRender } from '../../BeforeFirstRender/useBeforeFirstRender';

import './AddVehicleInformation.css';

const AddVehicleInformation = ({
  t,
  onClickClose,
  onClickOpen,
  openMUAImport,
  setMainData,
  vehicleDataImport,
  pastedURL
}) => {
  const [weightValid, setWeightValid] = useState(true);
  const [widthValid, setWidthValid] = useState(true);
  const [heightValid, setHeightValid] = useState(true);
  const [lengthValid, setLengthValid] = useState(true);
  const [brandValid, setBrandValid] = useState(true);
  const [modelValid, setModelValid] = useState(true);
  const [categoryValid, setCategoryValid] = useState(true);
  const [serialNumValid, setSerialNumValid] = useState(true);
  const [yearValid, setYearValid] = useState(true);
  const [stateValid, setStateValid] = useState(true);
  const [imageValid, setImageValid] = useState(true);
  const [imageArray, setImageArray] = useState([]);

  const [values, setValues] = useState({
    weight: null,
    length: null,
    width: null,
    height: null,
    serialNum: ''
  });

  const [defaultData, setDefaultData] = useState({
    brands: null,
    models: null,
    years: null,
    category: null
  });

  const handleClick = () => {
    onClickClose(false);
    openMUAImport(true);
  };
  const handleOpen = () => {
    let tempImageArray = [];
    let isValid = true;
    imageArray.forEach((file) => {
      let formData = new FormData();
      formData.append('file', file);
      tempImageArray = [...tempImageArray, formData];
    });

    if (values.weight == null || values.weight == 0 || isNaN(values.weight)) {
      setWeightValid(false);
      isValid = false;
    }
    if (values.width == null || values.width == 0 || isNaN(values.width)) {
      setWidthValid(false);
      isValid = false;
    }
    if (values.height == null || values.height == 0 || isNaN(values.height)) {
      setHeightValid(false);
      isValid = false;
    }
    if (values.length == null || values.length == 0 || isNaN(values.length)) {
      setLengthValid(false);
      isValid = false;
    }
    if (selectedBrand == null || selectedBrand == '') {
      setBrandValid(false);
      isValid = false;
    }
    if (selectedModel == null || selectedModel == '') {
      setModelValid(false);
      isValid = false;
    }
    if (selectedYear == null || selectedYear == '') {
      setYearValid(false);
      isValid = false;
    }
    if (selectedState == null || selectedState == '') {
      setStateValid(false);
      isValid = false;
    }
    if (selectedCategory == null || selectedCategory == '') {
      setCategoryValid(false);
      isValid = false;
    }
    if (values.serialNum == '') {
      setSerialNumValid(false);
      isValid = false;
    }

    if (vehicleDataImport == null && imageArray.length < 4) {
      setImageValid(false);
      isValid = false;
    }

    if (isValid) {
      onClickClose(false);
      onClickOpen(true);
      setMainData({
        weight: values.weight,
        length: values.length,
        width: values.width,
        height: values.height,
        serialNumber: values.serialNum,
        selectedBrand,
        selectedModel,
        selectedYear,
        selectedState,
        selectedCategory,
        imageArray: tempImageArray,
        oldImages: imageArray,
        importedPhotos: vehicleDataImport?.images
      });
    } else {
      setTimeout(() => {
        setWeightValid(true);
        setWidthValid(true);
        setHeightValid(true);
        setLengthValid(true);
        setBrandValid(true);
        setModelValid(true);
        setYearValid(true);
        setStateValid(true);
        setCategoryValid(true);
        setSerialNumValid(true);
        setImageValid(true);
      }, 500);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    setValues({
      ...values,
      [e.target.name]: value
    });
  };

  useEffect(() => {
    let years = new Array();
    for (let i = 2020; i > 1980; i--) {
      years.push({
        value: i,
        label: i
      });
    }

    let brandOptions = new Array();
    let brandOptionsState = new Array();
    brandOptions = brands.sort((a, b) => a.name > b.name);
    brandOptions.forEach((singleBrand) => {
      brandOptionsState.push({
        value: singleBrand.id,
        label: singleBrand.name,
        key: singleBrand.key
      });
    });

    setDefaultData({
      brands: brandOptionsState,
      years
    });
  }, []);

  let selectedBrandGlobal = null;
  let selectedModelGlobal = null;

  const updateModels = (selectedBrand) => {
    let modelsOptions = new Array();
    models.forEach((singleModel) => {
      if (singleModel.brand_id == selectedBrand.value) {
        modelsOptions.push(singleModel);
      }
    });

    let modelsOptionsState = new Array();
    modelsOptions = modelsOptions.sort((a, b) => a.name > b.name);
    modelsOptions.forEach((singleModel) => {
      modelsOptionsState.push({
        value: singleModel.id,
        label: singleModel.name,
        key: singleModel.key
      });
    });

    setDefaultData({
      ...defaultData,
      models: modelsOptionsState
    });

    setSelectedBrand(selectedBrand);
    setSelectedModel(null);
  };

  const updateNumericValues = (model) => {
    if (metadata[selectedBrand.key][model.key] == undefined) return;

    const { category, ...rest } = metadata[selectedBrand.key][model.key];
    const { height, width, length, weight } = Object.entries(rest).reduce(
      (carry, [key, value]) => {
        carry[key] = value || null;

        return carry;
      },
      {}
    );

    setValues({
      weight,
      length,
      width,
      height
    });
    setSelectedCategory(
      category
        ? {
            label: category.charAt(0).toUpperCase() + category.slice(1),
            value: category
          }
        : null
    );
  };

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  let elementSelectedBrand = useRef();
  let elementSelectedModel = useRef();
  let elementSelectedYear = useRef();
  let elementSelectedState = useRef();
  let elementSelectedCategory = useRef();
  const changeInputStyle = (element) => {
    if (element.current.props.value) {
      element.current.select.controlRef.style.backgroundColor =
        'rgba(6, 1, 108, 0.03)';
      element.current.select.controlRef.style.border = 'none';
    } else {
      element.current.select.controlRef.style.backgroundColor = 'transparent';
      element.current.select.controlRef.style.border = '2px solid #06016c';
    }
  };
  useEffect(() => {
    changeInputStyle(elementSelectedBrand);
    changeInputStyle(elementSelectedModel);
    changeInputStyle(elementSelectedYear);
    changeInputStyle(elementSelectedState);
    changeInputStyle(elementSelectedCategory);
  });

  useEffect(() => {
    if (vehicleDataImport) {
      setSelectedBrand({
        value: vehicleDataImport.brandId,
        label: vehicleDataImport.brand,
        key: vehicleDataImport.brandKey
      });
    }
  }, [vehicleDataImport]);

  useEffect(() => {
    if (selectedBrand && vehicleDataImport) {
      updateModels(selectedBrand);
      setSelectedModel({
        value: vehicleDataImport.modelId,
        label: vehicleDataImport.model,
        key: vehicleDataImport.modelKey
      });
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (selectedModel && vehicleDataImport) {
      updateNumericValues(selectedModel);
    }
  }, [selectedModel]);

  let inputs = null;
  useEffect(() => {
    inputs = document.querySelectorAll('.customSelectAddShipment input');

    inputs.forEach((el) => {
      const selectedEl = el;
      const closestParent = selectedEl.closest('.customSelectAddShipment');
      el.addEventListener('focus', () => {
        console.log('ellllll', el);
        console.log(elementSelectedBrand.current);
        closestParent.nextSibling.setAttribute('class', 'form-label-exep');
      });
      el.addEventListener('focusout', () => {
        //   closestParent.nextSibling.setAttribute('class', 'form-label');
      });
    });

    return () => {
      inputs.forEach((el) => {
        const selectedEl = el;
        const closestParent = selectedEl.closest('.customSelectAddShipment');
        el.removeEventListener('focus', () => {
          closestParent.nextSibling.setAttribute('class', 'form-label');
        });
        // el.removeEventListener('focusout', () => {
        //   closestParent.nextSibling.setAttribute('class', 'form-label');
        // });
      });
    };
  }, []);

  return (
    <div className="addVehicleInformation">
      <div className="addVehicleInformation__content">
        <h2 className="addVehicleInformation__content--heading">
          {t('shipments.vehicleInfo')}
        </h2>
        <p className="addVehicleInformation__content--paragraph">
          {t('shipments.givInfo')}
        </p>
        <div className="addVehicleInformation__content--select">
          <div
            style={{ position: 'relative' }}
            className={!brandValid && 'shakeIt'}
          >
            <Select
              options={defaultData.brands}
              // value={formState.selectedBrand}
              value={selectedBrand}
              placeholder=""
              className="customSelectAddShipment"
              onChange={(newValue) => {
                setSelectedBrand(newValue);
                selectedBrandGlobal = newValue;
                changeInputStyle(elementSelectedModel);
                updateModels(newValue);
              }}
              ref={elementSelectedBrand}
            />
            <label
              className={
                selectedBrand != null ? 'form-label-exep' : 'form-label'
              }
            >
              {t('shipments.brand')}
            </label>
          </div>

          <div
            style={{ position: 'relative' }}
            className={!modelValid && 'shakeIt'}
          >
            <Select
              options={defaultData.models}
              // value={formState.selectedModel}
              value={selectedModel}
              placeholder=""
              className="customSelectAddShipment"
              onChange={(newValue) => {
                setSelectedModel(newValue);
                updateNumericValues(newValue);
                changeInputStyle(elementSelectedCategory);
                selectedModelGlobal = newValue;
              }}
              ref={elementSelectedModel}
            />
            <label
              className={
                selectedModel != null ? 'form-label-exep' : 'form-label'
              }
            >
              {t('shipments.model')}
            </label>
          </div>

          <div
            style={{ position: 'relative' }}
            className={!yearValid && 'shakeIt'}
          >
            <Select
              options={defaultData.years}
              value={selectedYear}
              placeholder=""
              className="customSelectAddShipment"
              onChange={(newValue) => {
                setSelectedYear(newValue);
              }}
              ref={elementSelectedYear}
            />
            <label
              className={
                selectedYear != null ? 'form-label-exep' : 'form-label'
              }
            >
              {t('shipments.year')}
            </label>
          </div>

          <div
            style={{ position: 'relative' }}
            className={!stateValid && 'shakeIt'}
          >
            <Select
              options={[
                {
                  label: t('shipments.excellentDrop'),
                  value: 'excellent'
                },
                {
                  label: t('shipments.goodDrop'),
                  value: 'good'
                },
                {
                  label: t('shipments.damagedDrop'),
                  value: 'damaged'
                },
                {
                  label: t('shipments.notDrivable'),
                  value: 'not drivable'
                }
              ]}
              value={selectedState}
              placeholder=""
              className="customSelectAddShipment"
              onChange={(newValue) => {
                setSelectedState(newValue);
              }}
              ref={elementSelectedState}
            />
            <label
              className={
                selectedState != null ? 'form-label-exep' : 'form-label'
              }
            >
              {t('shipments.status')}
            </label>
          </div>
          <div
            style={{ position: 'relative' }}
            className={!categoryValid && 'shakeIt'}
          >
            <Select
              options={categories}
              value={selectedCategory}
              placeholder=""
              className="customSelectAddShipment"
              onChange={(newValue) => {
                setSelectedCategory(newValue);
              }}
              ref={elementSelectedCategory}
            />
            <label
              className={
                selectedCategory != null ? 'form-label-exep' : 'form-label'
              }
            >
              {t('shipments.categories')}
            </label>
          </div>

          <div className={!weightValid && 'shakeIt'}>
            <FormInput
              type="text"
              name="weight"
              labelText={t('shipments.weight')}
              spanText="(kg)"
              value={values.weight}
              required
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="addVehicleInformation__content--input">
          <div className={!lengthValid && 'shakeIt'}>
            <FormInput
              type="text"
              name="length"
              labelText={t('shipments.length')}
              spanText="(mm)"
              value={values.length}
              required
              onChange={handleChange}
            />
          </div>

          <div className={!widthValid && 'shakeIt'}>
            <FormInput
              type="text"
              name="width"
              spanText="(mm)"
              labelText={t('shipments.width')}
              value={values.width}
              required
              onChange={handleChange}
            />
          </div>
          <div className={!heightValid && 'shakeIt'}>
            <FormInput
              type="text"
              name="height"
              labelText={t('shipments.height')}
              spanText="(mm)"
              value={values.height}
              required
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="serialNum__inputField">
          <div className={!serialNumValid && 'shakeIt'}>
            <FormInput
              type="text"
              name="serialNum"
              labelText={t('shipments.serialNum')}
              value={values.serialNum}
              required
              onChange={handleChange}
            />
          </div>
        </div>
        {pastedURL == null || pastedURL == '' ? (
          <div>
            <p className="addVehicleInformation__content--imageParagraph">
              {t('shipments.selectImages')}
            </p>
            <div className={!imageValid && 'shakeIt'}>
              <ImageUpload
                className="form__ImageUpload"
                setImageArray={setImageArray}
                isShipment={true}
              >
                <img src={PlaceholderImage1} className="firstImgPlaceholder" />
                <img src={PlaceholderImage2} className="imagePlaceholder" />
                <img src={PlaceholderImage3} className="imagePlaceholder" />
              </ImageUpload>
            </div>
          </div>
        ) : null}
        <Button
          handleOpen={handleOpen}
          style={{
            marginTop:
              pastedURL != null || pastedURL == '' ? '13.125rem' : '0rem'
          }}
        >
          {t('shipments.next')}
        </Button>
      </div>
      <BackButton handleClick={handleClick} />
    </div>
  );
};

export default withNamespaces()(AddVehicleInformation);
