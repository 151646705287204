import React from "react";

class Oauth extends React.Component {
  constructor() {
    super();
  }
  componentWillMount() {
    let url_string = window.location.href; //window.location.href
    let url = new URL(url_string);
    let code = url.searchParams.get("code");
    let type = url.searchParams.get("type");
    let provider = url.searchParams.get("provider");
    let intent = url.searchParams.get("intent");
    window.open(
      `https://demo.autoversand.com/${intent}?code=${code}&type=${type}&provider=${provider}&intent=${intent}`,
      "_self"
    );
  }
  render() {
    return <h1>Redirecting...</h1>;
  }
}

export default Oauth;
