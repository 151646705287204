import React, { useState, useEffect } from 'react';
import PinInput from 'react-pin-input';
import BarLoader from 'react-spinners/BarLoader';
import { css } from '@emotion/core';
import Modal from 'react-awesome-modal';
import { withNamespaces } from 'react-i18next';

import axios from 'axios';

import auth from '../../../auth';
import config from '../../../config/api.config.js';

import './phoneVerificationModal.css';

const override = css`
  position: absolute;
  top: 0px;
  width: 40%;
`;

const PINinput = css`
  width: 70px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 10px;
  color: #06016c;
  line-height: 1.33;
  font-size: 36px;
`;

const PhoneVerificationModal = ({
  t,
  pin,
  modalVisible,
  setModalVisible,
  getCips
}) => {
  const [state, setState] = useState({
    resetTimer: 0,
    validationProcess: false
  });

  const [PIN, setPIN] = useState('');
  const userId = auth.getId();
  const [pinValid, setPinValid] = useState(true);

  const closeModal = () => {
    setState({ ...state, resetTimer: 0 });
    setModalVisible(false);
  };

  const sendSMSAgain = async () => {
    if (state.resetTimer != 0) {
      return;
    }
    pin.clear();
    setState({ ...state, resetTimer: 30 });
    for (let i = 30; i >= 0; i--) {
      setTimeout(() => setState({ ...state, resetTimer: i }), (30 - i) * 1000);
    }
    await axios
      .post(`${config.apiUrl}/send_sms_verification_code/`, {
        user: userId
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const PINsubmit = async () => {
    if (PIN.length != 4) {
      setPinValid(false);
      pin.clear();
      setTimeout(() => {
        setPinValid(true);
      }, 500);
      return;
    } else {
      let isPinValid = false;
      setState({ ...state, validationProcess: true });
      await axios
        .post(`${config.apiUrl}/verify_phone/`, {
          user: userId,
          code: PIN
        })
        .then((res) => {
          //   console.log(res);
          setPinValid(true);
          isPinValid = true;
          setModalVisible(false);
        })
        .catch((err) => {
          setPinValid(false);
          setTimeout(() => {
            setPinValid(true);
          }, 500);
        });
      await setState({ ...state, validationProcess: false });

      if (isPinValid) {
        getCips();
      }
    }
  };

  const PINlistener = (value) => {
    setPIN(value);
  };

  return (
    <Modal
      visible={modalVisible}
      width="42%"
      effect="fadeInUp"
      onClickAway={() => closeModal()}
    >
      <main className="phoneVerification contentModified">
        <BarLoader
          css={override}
          size={150}
          color={'#06016c'}
          loading={state.validationProcess}
        />
        <div className="SMSholder">
          <h1>{t('registration.smsConfirm')}</h1>
          <h2>{t('phoneVerification.checkMobile')}</h2>
          <div className={!pinValid && 'shakeItAlt'}>
            <PinInput
              length={4}
              initialValue=""
              focus={true}
              ref={(p) => (pin = p)}
              onChange={PINlistener}
              type="numeric"
              style={{ padding: '10px' }}
              onComplete={(value, index) => {}}
            />
          </div>
          <h2 style={{ color: '#B4B2D3', fontSize: '1.25rem' }}>
            {t('registration.enter4Digit')}.
          </h2>
          <div className="phoneVerification__bottom">
            <span onClick={sendSMSAgain}>
              {state.resetTimer != 0
                ? '00:' + (state.resetTimer < 10 ? '0' : '') + state.resetTimer
                : 'Resend Code'}
            </span>
            <button
              type="submit"
              onClick={PINsubmit}
              style={{ marginTop: '20px' }}
            >
              {t('phoneVerification.send')}
            </button>
          </div>
        </div>
      </main>
    </Modal>
  );
};

export default withNamespaces()(PhoneVerificationModal);
