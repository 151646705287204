import React from "react";

import "./button.css";

const Button = ({ onClick, className, style, children }) => {
  return (
    <button onClick={onClick} className={className} style={style} type="button">
      {children}
    </button>
  );
};

export default Button;
