import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';
import Slider from 'react-slick';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

import Navbar from '../../Layout/Navbar/Navbar';
import Header from '../../Layout/Dashboard/DashboardHeader/DashboardHeader';
import DashboardNavbar from '../../Layout/Dashboard/DashboardNavbar/DashboardNavbar';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import TruckCard from '../../components/FuhrparkCard/FuhrparkCard';
import CompanyReviewsCard from '../../components/CompanyReviewsCard/CompanyReviewsCard';
import ChatButton from '../../components/Buttons/ChatButton/ChatButton';
import { ReactComponent as Check } from '../../assets/images/shipmentViewCheck.svg';

import { useWindowSize } from '../../components/WindowSize/WindowSize';
import { ReactComponent as NothingFound } from '../../assets/images/nothingFound.svg';

import auth from '../../auth';
import config from '../../config/api.config.js';
import 'slick-carousel/slick/slick.css';
import './CompanyProfile.css';

const override = css`
  display: block;
  margin: 0 auto;
  margin-right: 5.938rem;
  padding-right: 5.938rem;
  right: 5.938rem;
`;

const CompanyProfile = ({ t, match }) => {
  const [width, height] = useWindowSize();
  const userId = auth.getId();
  const [sliderLoaded, setSliderLoaded] = useState(false);
  const [userRatings, setUserRatings] = useState([]);
  const [companyReviewsCard, setCompanyReviewsCard] = useState([]);
  const [data, setData] = useState({
    company: {
      picture: null,
      name: null,
      description: null,
      memberDate: 'May 15, 2021',
      verification: `${t('profile.verified')}`
    },
    truckInformations: {
      picture: `url(${'https://picsum.photos/200'})`,
      name: 'Volvo FMX',
      year: 2019
    }
  });

  const loadData = async () => {
    await axios
      .get(`${config.apiUrl}/users/cips/${match.params.userId}/`)
      .then((res) => {
        console.log(res);
        const {
          name,
          description,
          date_joined,
          email_verified,
          photo
        } = res.data;

        setData({
          ...data,
          company: {
            ...data.company,
            name: name,
            description:
              description?.trim() &&
              description !== 'null' &&
              description !== null
                ? description
                : `${t('profile.noDescription')}`,
            picture: `url(${config.assetUrl}${photo})`,
            verification: email_verified
              ? `${t('profile.verified')}`
              : `${t('profile.unverified')}`,
            memberDate: moment(date_joined).format('DD.MM.YYYY')
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(
        `${config.apiUrl}/shipments/transport-vehicle/list/${match.params.userId}/`
      )
      .then((res) => {
        let arrayOfTrucks = res.data.map((singleTruck) => {
          if (singleTruck.photos.length !== 0) {
            return {
              picture: `url(${config.assetUrl}${singleTruck.photos[0].file})`,
              name: singleTruck.name,
              year: singleTruck.year
            };
          }
        });

        arrayOfTrucks = arrayOfTrucks.filter(Boolean);

        setItems({
          ...items,
          trucks: arrayOfTrucks.map((singleTruck, index) => (
            <TruckCard key={index} data={singleTruck} noDetails={true} />
          ))
        });

        setTrucksLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRatings = async () => {
    setSliderLoaded(true);
    await axios
      .get(`${config.apiUrl}/ratings/rated/${match.params.userId}/`)
      .then((res) => {
        setUserRatings(res.data);
        setSliderLoaded(true);

        let ratings = res.data.map((item) => {
          return {
            statusOfReview: item.rating,
            reviewer: item.rater.company
              ? item.rater.company.name
              : `${
                  item.rater.person.first_name
                } ${item.rater.person.last_name.slice(0, 1)}.`,
            picture: `url(${item.shipment.photos[0].file})`,
            content: item.notes,
            car: item.shipment.vehicle.manufacturer,
            carType: item.shipment.vehicle.model,
            transportDate: moment(item.updated_at).format('DD.MM.YYYY')
          };
        });

        setCompanyReviewsCard(
          ratings.map((rating, index) => (
            <CompanyReviewsCard key={index} data={rating} />
          ))
        );
        setSliderLoaded(false);
      })
      .catch((err) => {
        console.log(err);
        setSliderLoaded(true);
      });
  };

  const getSatisfactionPercentage = () => {
    if (!userRatings.length) return 0;

    const positiveRatings = userRatings.filter(
      (rating) => rating.rating === 'positive'
    );

    return (positiveRatings.length / userRatings.length) * 100;
  };

  const getRatingNumber = (rating) => {
    if (!userRatings.length) return 0;

    const ratings = userRatings.filter((item) => item.rating === rating);

    return ratings.length;
  };

  const getRatingPercentage = (rating) => {
    if (!userRatings.length) return 0;

    const ratings = userRatings.filter((item) => item.rating === rating);

    return ratings.length / userRatings.length;
  };

  useEffect(() => {
    loadData();
    getRatings();
  }, []);

  const [trucksLoaded, setTrucksLoaded] = useState(false);
  const [items, setItems] = useState({
    trucks: []
  });

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    cssEase: 'linear',
    variableWidth: true
  };

  // var settingsCompanyReviews = {
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: false,
  //   vertical: true,
  //   verticalSwiping: true,
  //   swipeToSlide: true
  // };
  return (
    <div className="companyProfile">
      <Helmet>
        <title>Autoversand | Company profile</title>
      </Helmet>

      {auth.isAuthenticated() ? <Header /> : <Navbar />}
      <div className="companyProfile__main">
        {auth.isAuthenticated() ? <DashboardNavbar /> : null}
        <div
          className={
            auth.isAuthenticated()
              ? 'companyProfile__content'
              : 'companyProfile__content companyProfile__contentPublic'
          }
        >
          <section className="companyProfile__section1">
            {data.company.picture === null ? (
              <div
                className="section1__left"
                style={{
                  background: 'white',
                  boxShadow: '0 20px 40px 0 rgba(6, 1, 108, 0.1)'
                }}
              >
                <ContentLoader viewBox="0 0 380 200">
                  <rect x="0" y="0" rx="5" ry="5" width="500" height="200" />
                </ContentLoader>
              </div>
            ) : (
              <div
                className="section1__left"
                style={{ backgroundImage: data.company.picture }}
              ></div>
            )}
            <div className="section1__right">
              <div className="section1Right__companyInformation">
                <p className="companyInformation__member">
                  <span>{data.company.verification}</span>{' '}
                  {t('profile.memberSince')} {data.company.memberDate}
                </p>
                <h2 className="companyInformation__name">
                  {data.company.name === null ? (
                    <ContentLoader viewBox="0 0 380 20">
                      <rect x="0" y="5" rx="3" ry="3" width="80" height="8" />
                    </ContentLoader>
                  ) : (
                    data.company.name
                  )}
                  {data.company.name !== null && (
                    <Check className="companyInformation__check" />
                  )}
                </h2>
                <p className="companyInformation__description">
                  {data.company.description == null ? (
                    <ContentLoader viewBox="0 0 380 70">
                      <rect x="0" y="5" rx="3" ry="3" width="350" height="8" />
                      <rect x="0" y="20" rx="3" ry="3" width="350" height="8" />
                      <rect x="0" y="35" rx="3" ry="3" width="350" height="8" />
                    </ContentLoader>
                  ) : (
                    data.company.description
                  )}
                </p>
              </div>
              {match.params.userId !== userId ? (
                <div className="companyProfile__messageButton">
                  <ChatButton
                    handleClick={() =>
                      window.open(
                        `/messenger?newChat=${match.params.userId}`,
                        '_self'
                      )
                    }
                  />
                </div>
              ) : null}
            </div>
          </section>

          <section className="companyProfile__section2">
            <div className="section2__truckList">
              <p className="section2__truckList--paragraph">
                <span>
                  {items.trucks.length} {t('profile.vehicle')}
                </span>{' '}
                {t('profile.registered')}
              </p>
              <div className="section2__slide">
                {!trucksLoaded && (
                  <PuffLoader
                    size={60}
                    css={override}
                    color={'#123abc'}
                    loading={true}
                  />
                )}
                {trucksLoaded &&
                  (items.trucks.length === 0 ? (
                    <p className="shipments__notFound">NO TRUCKS</p>
                  ) : (
                    <Slider {...settings}>{items.trucks}</Slider>
                  ))}
              </div>
            </div>
            <div className="section2__dashedContainer"></div>
          </section>

          <section className="shipperProfile__section2Mobile">
            <p className="section2__truckList--paragraph">
              <span>
                {items.trucks.length} {t('profile.vehicle')}
              </span>{' '}
              {t('profile.registered')}
            </p>
            <div className="section2__slide">
              {!trucksLoaded && (
                <PuffLoader
                  size={60}
                  css={override}
                  color={'#123abc'}
                  loading={true}
                />
              )}
              {trucksLoaded &&
                (items.trucks.length === 0 ? (
                  <p className="shipments__notFound">NO TRUCKS</p>
                ) : (
                  <Slider {...settings}>{items.trucks}</Slider>
                ))}
            </div>
          </section>

          <section className="companyProfile__section3">
            <ContentHeader>{t('ratings.ratings')}</ContentHeader>
            <div className="section3__group">
              <div className="section3__percentages">
                <div className="percentages__group">
                  <p className="percentages__group--paragraph">
                    {t('profile.positive')}
                  </p>
                  <p className="percentages__group--paragraph">
                    {t('profile.neutral')}
                  </p>
                  <p className="percentages__group--paragraph">
                    {t('profile.negative')}
                  </p>
                </div>
                <div className="percentages__group">
                  <input
                    type="range"
                    readOnly
                    min={0}
                    max={userRatings.length}
                    class="slider"
                    name="excelent"
                    value={getRatingNumber('positive')}
                    style={{
                      background:
                        'linear-gradient(to right, #2699fb 0%, #7426fb ' +
                        getRatingPercentage('positive') * 100 +
                        '%, #eef7ff  ' +
                        getRatingPercentage('positive') * 100 +
                        '%,#eef7ff  100%)'
                    }}
                  />
                  <input
                    type="range"
                    readOnly
                    min={0}
                    max={userRatings.length}
                    class="slider"
                    name="mediocore"
                    value={getRatingNumber('neutral')}
                    style={{
                      background:
                        'linear-gradient(to right, #2699fb 0%, #7426fb ' +
                        getRatingPercentage('neutral') * 100 +
                        '%, #eef7ff  ' +
                        getRatingPercentage('neutral') * 100 +
                        '%,#eef7ff  100%)'
                    }}
                  />
                  <input
                    type="range"
                    readOnly
                    min={0}
                    max={userRatings.length}
                    class="slider"
                    name="supbar"
                    value={getRatingNumber('negative')}
                    style={{
                      background:
                        'linear-gradient(to right, #2699fb 0%, #7426fb ' +
                        getRatingPercentage('negative') * 100 +
                        '%, #eef7ff  ' +
                        getRatingPercentage('negative') * 100 +
                        '%,#eef7ff  100%)'
                    }}
                  />
                </div>
                <div className="percentages__group">
                  <p className="percentages__group--votes">
                    <span>{getRatingNumber('positive')}</span>{' '}
                    {t('profile.votes')}
                  </p>
                  <p className="percentages__group--votes">
                    <span>{getRatingNumber('neutral')}</span>{' '}
                    {t('profile.votes')}
                  </p>
                  <p className="percentages__group--votes">
                    <span>{getRatingNumber('negative')}</span>{' '}
                    {t('profile.votes')}
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="shipperProfile__section4">
            {sliderLoaded ? (
              <PuffLoader
                css={override}
                size={60}
                margin={2}
                color={'rgba(116, 38, 251, 0.4)'}
                loading={sliderLoaded}
              />
            ) : companyReviewsCard.length ? (
              <div>
                {/* <Slider {...settingsCompanyReviews}> */}
                {companyReviewsCard}
                {/* </Slider> */}
              </div>
            ) : null}
          </section>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(CompanyProfile);
