import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import './i18n';
import rootReducer from './redux/reducers';
import { Provider } from 'react-redux';
import Redux from 'redux-thunk';
import App from './App';
import './axios';
// import { initializeFirebase } from "./push-notifications";

const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate;
const store = createStore(rootReducer, applyMiddleware(Redux));

renderMethod(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
