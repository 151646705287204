import React from 'react';
import axios from 'axios';
import NotificationImportant from '@material-ui/icons/NotificationImportant';
import MobileHeader from '../../../Layout/Mobile/Header';

import { withHooksHOC } from '../../../components/WindowSize/WithHooksHOC';
import auth from '../../../auth.js';
import config from '../../../config/api.config.js';
import './chatlist.css';

const firebase = require('firebase');

interface IHooksHOCProps {
  width: number;
}

class ChatListComponent extends React.Component<IHooksHOCProps> {
  constructor(props) {
    super(props);

    this.state = {
      bearerToken: null,
      usersNames: [
        {
          id: null,
          name: null
        }
      ],
      visibility: true
    };

    // this.setUserName = this.setUserName.bind(this);
  }

  componentDidUpdate() {
    let arrayOfIds = new Array();
    this.props.chats.map((element) => {
      element.users.map((singleId) => {
        if (!arrayOfIds.includes(singleId)) {
          arrayOfIds.push(singleId);
          // this.setUserName(singleId);
        }
      });
    });
  }

  // async setUserName(userId) {
  //   await axios
  //     .get(
  //       `${config.apiUrl}/users/cips/${userId}/`
  //     )
  //     .then((res) => {
  //       this.setState({
  //         usersNames: [
  //           ...this.state.usersNames,
  //           {
  //             id: userId,
  //             name: res.data.first_name + " " + res.data.last_name,
  //           },
  //         ],
  //       });
  //     });
  // }

  render() {
    const { classes } = this.props;
    console.log(this.props);
    if (this.props.chats.length > 0) {
      return (
        <main
          className="chatlist-root"
          style={{
            display:
              this.props.width <= 500
                ? this.props.visible
                  ? 'block'
                  : 'none'
                : 'block'
          }}
        >
          <MobileHeader />
          <button style={{ visibility: 'hidden' }} onClick={this.newChat}>
            New Message
          </button>
          <ul className="chatlist-mainlist">
            {this.props.chats.map((_chat, _index) => {
              let fullName = '';
              if (!this.userCreatedChat(_chat) && _chat.messages.length <= 1)
                return;

              return (
                <div key={_index}>
                  <li
                    onClick={() => {
                      this.selectChat(_index);
                      this.props.changeVisibility(false);
                    }}
                    className={
                      this.props.selectChatIndex === _index
                        ? 'selectedChat'
                        : !_chat.receiverHasRead && !this.userIsSender(_chat)
                        ? 'unreadChat'
                        : ''
                    }
                  >
                    <div className="avatarIcon">
                      <span alt="Autoversand User">
                        <img
                          style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            objectFit: 'cover'
                          }}
                          src={`${config.assetUrl}${
                            _chat.usersData.filter(
                              (_singleUser) =>
                                _singleUser.id != this.props.userEmail
                            )[0].picture
                          }`}
                        />
                      </span>
                    </div>
                    <div>
                      <h2>
                        {
                          _chat.usersData.filter(
                            (_singleUser) =>
                              _singleUser.id != this.props.userEmail
                          )[0].name
                        }
                      </h2>
                      <h3>
                        {_chat.messages[
                          _chat.messages.length - 1
                        ].message.substring(0, 30)}
                      </h3>
                    </div>
                    {/* {_chat.receiverHasRead === false &&
                    !this.userIsSender(_chat) ? (
                      <NotificationImportant
                        style={{
                          color: 'red',
                          position: 'absolute',
                          top: '0',
                          right: '5px'
                        }}
                      ></NotificationImportant>
                    ) : null} */}
                  </li>
                </div>
              );
            })}
          </ul>
        </main>
      );
    } else {
      return (
        <main className="chatlist-root">
          <button style={{ visibility: 'hidden' }} onClick={this.newChat}>
            New Message
          </button>
          <ul className="chatlist-mainlist"></ul>
        </main>
      );
    }
  }

  newChat = () => {
    this.props.newChatBtnFn();
  };

  selectChat = (index) => {
    this.props.selectChatFn(index);
  };

  userIsSender = (chat) =>
    chat.messages[chat.messages.length - 1].sender === this.props.userEmail;

  userCreatedChat = (chat) => chat.messages[0].sender === this.props.userEmail;
}

export default withHooksHOC(ChatListComponent);
