import React, { useState } from 'react';
import { Animated } from 'react-animated-css';

import FileUpload from '../../Upload/FileUpload/FileUpload';
import ImageUpload from '../../Upload/ImageUpload/ImageUpload';
import Button from '../../Buttons/BlueButton';
import BackButton from '../../Buttons/BackButton';
import FormInput from '../../FormInput/FormInput';

import '../AddTruck/AddTruck.css';
import './UpdateTruck.css';
import truckImage1 from '../../../assets/images/ACTROS2.jpg';
import truckImage2 from '../../../assets/images/ACFRONT.jpg';
import truckImage3 from '../../../assets/images/ATROS.jpg';

const UpdateTruck = ({ onClickCloseEdit }) => {
  const [vehicleNameValid, setVehicleNameValid] = useState(true);
  const [yearValid, setYearValid] = useState(true);
  const [capacityValid, setCapacityValid] = useState(true);
  const [descriptionValid, setDescriptionValid] = useState(true);
  const [imageValid, setImageValid] = useState(true);
  const [fileValid, setFileValid] = useState(true);

  const [state, setState] = useState({
    truckName: 'Mercedes Actros',
    truckDecription:
      'Add new truck to your fuhrpark so we can check is it in accordanace to our policy.',
    capacity: 3,
    year: 2019,
    uploadedFiles: [
      { path: 'sdfsdf', name: 'Service Book', size: 3800000 },
      { path: 'sdfsadf', name: 'Service Book', size: 3800000 },
      { path: 'sdrtrzdf', name: 'Service Book', size: 3800000 }
    ],
    uploadedImages: [truckImage1, truckImage2, truckImage3]
  });

  const setImages = (uploadedImages) => {
    setState({ ...state, uploadedImages });
  };
  const handleChange = (event) => {
    const { value, name } = event.target;
    setState({ [name]: value });
  };
  const handleClick = () => {
    onClickCloseEdit(false);
  };
  return (
    <div className="updateTruck">
      {
        <div className="truckDetails">
          <h2 className="truckDetails--heading">{state.truckName}</h2>
          <p className="truckDetails--paragraph">{state.truckDecription}</p>
          <div className="truckDetails__form">
            <ImageUpload
              className="form__ImageUpload"
              updateImage={true}
              uploadedImages={state.uploadedImages}
              onImageChange={setImages}
              setImageArray={() => {}}
            >
              <p className="uploadPlaceholder">Drop images here or </p>
            </ImageUpload>
            <div className="updateTruck__firstInputGroup">
              <FormInput
                type="text"
                name="vehicleName"
                labelText="Vehicle name"
                defaultValue={state.truckName}
                required
                onChange={handleChange}
              />
            </div>
            <div className="truckDetails__form--exception">
              <FormInput
                type="text"
                name="year"
                labelText="Year"
                defaultValue={state.year}
                required
                onChange={handleChange}
              />
              <FormInput
                type="text"
                name="capacity"
                defaultValue={state.capacity}
                labelText="Capacity"
                required
                onChange={handleChange}
              />
            </div>
            <FileUpload
              className="form__DocumentUpload"
              updateFile={true}
              uploadedFiles={state.uploadedFiles}
              setFileArray={() => {}}
            >
              <p className="uploadPlaceholder">Drop to update documents or </p>
            </FileUpload>
            <Button>SAVE</Button>
          </div>
          <BackButton handleClick={handleClick} />
        </div>
      }
    </div>
  );
};

export default UpdateTruck;
