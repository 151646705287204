import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import Notifications from "../../components/Notification/Notification";

import HeaderSearchList from "../../components/HeaderSearchList/HeaderSearchList";

import { connect } from "react-redux";

import { getShipments } from "../../redux/actions";

import "./searchHeader.css";
const SearchHeader = ({ setSearchTerm = null, children, ...props }) => {
  // console.log("SHIPMENTS FROM HEADER", props.shipmentsState.shipments);

  const [background, showBackground] = useState(true);

  const handleInput = (event) => {
    if (setSearchTerm != null) setSearchTerm(event.target.value);

    if (event.target.value === "") showBackground(true);
    else showBackground(false);
  };

  return (
    <div className="searchHeader">
      <div className="header__rightSide">
        <input
          type="text"
          className={background ? "searchBox" : "searchBox__none"}
          placeholder="Search"
          onChange={handleInput}
          onFocus={() => props.shipmentsReducer()}
        />
      </div>
      {children}
    </div>
  );
};

const mapStateToProps = (state) => ({
  shipmentsState: state.shipmentsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  shipmentsReducer: () => dispatch(getShipments()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchHeader);
