import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import axios from 'axios';
import config from '../../../config/api.config.js';

import Button from '../../Buttons/BlueButton';
import BackButton from '../../Buttons/BackButton';
import FileUpload from '../../Upload/FileUpload/FileUpload';

import './documentation.css';

const Documentation = ({
  t,
  enableOnClickClose,
  enableOnClickOpen,
  toggleVehicleInforamtion,
  setOwnershipId
}) => {
  const [fileArray, setFileArray] = useState([]);
  const [choosenType, setChosenType] = useState(null);

  const [typeValid, setTypeValid] = useState(true);
  const [fileValid, setFileValid] = useState(true);

  const handleClick = () => {
    enableOnClickClose(false);
    toggleVehicleInforamtion(true);
  };

  const getDocumentText = (type) => {
    switch (type) {
      case 'owner':
        return t('document.owner');
        break;
      case 'rented':
        return t('document.rented');
        break;
      case 'purchased':
        return t('document.purchased');
        break;
      default:
        return '';
    }
  };

  const handleOpen = () => {
    let isValid = true;

    if (choosenType == null) {
      setTypeValid(false);
      isValid = false;
    }
    if (fileArray.length <= 0) {
      setFileValid(false);
      isValid = false;
    }

    if (!isValid) {
      return setTimeout(() => {
        setTypeValid(true);
        setFileValid(true);
      }, 500);
    }

    const formData = new FormData();
    formData.append('type', choosenType);
    formData.append('file', fileArray[0]);

    axios
      .post(`${config.apiUrl}/shipments/ownership/`, formData)
      .then((res) => {
        setOwnershipId(res.data.id);
        enableOnClickClose(false);
        enableOnClickOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="addTransportInformation documentation">
      <div className="addTransportInformation__content">
        <h2 className="addTransportInformation__content--heading">
          {t('shipments.documentation')}
        </h2>
        <p className="addTransportInformation__content--paragraph">
          {t('shipments.givInfo')}
        </p>
        <div className={typeValid && 'shakeIt'}>
          <div className="documentation__buttons">
            <div className="documentation_radioButton">
              <input
                type="radio"
                id="owner"
                name="userType"
                value="owner"
                onClick={(e) => setChosenType(e.target.value)}
              />
              <label for="owner">{t('shipments.carOwner')}</label>
            </div>

            <div className="documentation_radioButton">
              <input
                type="radio"
                id="rented"
                name="userType"
                value="rented"
                onClick={(e) => setChosenType(e.target.value)}
              />
              <label for="rented">{t('shipments.carRented')}</label>
            </div>

            <div className="documentation_radioButton">
              <input
                type="radio"
                id="purchased"
                name="userType"
                value="purchased"
                onClick={(e) => setChosenType(e.target.value)}
              />
              <label for="purchased">{t('shipments.carPurchased')}</label>
            </div>
          </div>
        </div>

        <p
          className="addTransportInformation__content--paragraph"
          style={{ width: '85%', margin: '4rem auto 1rem auto' }}
        >
          {getDocumentText(choosenType)}
        </p>

        <div className={fileValid && 'shakeIt'}>
          <FileUpload
            className="form__DocumentUpload"
            setFileArray={setFileArray}
          >
            {/* <p className="uploadPlaceholder">{t('settings.dropDocuments')} </p> */}
          </FileUpload>
        </div>

        <Button handleOpen={handleOpen}>{t('shipments.next')}</Button>
      </div>
      <BackButton handleClick={handleClick} />
    </div>
  );
};

export default withNamespaces()(Documentation);
