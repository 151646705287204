import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import PhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Animated } from 'react-animated-css';
import PinInput from 'react-pin-input';
import validator from 'validator';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import ReactFlagsSelect from 'react-flags-select';
import { withNamespaces } from 'react-i18next';
import BarLoader from 'react-spinners/BarLoader';
import { BsArrowRight } from 'react-icons/bs';
import Autocomplete from '../../components/GoogleMaps/Autocomplete';

import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as InstagramLogo } from '../../assets/images/instagram_logo.svg';
import { ReactComponent as FacebookLogo } from '../../assets/images/facebook_logo.svg';
import { ReactComponent as ArrowsRight } from '../../assets/images/arrows_right.svg';
import { ReactComponent as Check } from '../../assets/images/check.svg';
import { ReactComponent as CarIllustration } from '../../assets/images/carBg.svg';
import { ReactComponent as TruckImage } from '../../assets/images/truckIcon.svg';
import fbIcon from '../../assets/images/fb_2.png';
import googleIcon from '../../assets/images/google.png';
import auth from '../../auth';
import config from '../../config/api.config.js';

import { connect } from 'react-redux';
import { setToken } from '../../redux/actions';

import 'react-toastify/dist/ReactToastify.css';
import 'react-flags-select/css/react-flags-select.css';
import 'react-phone-input-2/lib/style.css';
import './registration.css';

const override = css`
  position: absolute;
  top: 0px;
  width: 40%;
`;

const PINinput = css`
  width: 70px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 10px;
  color: #06016c;
  line-height: 1.33;
  font-size: 36px;
`;

const PHONE_PREFIX = '+';

class Registration extends Component {
  constructor() {
    super();

    this.state = {
      socialLogin: false,
      gmapsLoaded: false,
      zeroWindowVisible: true,
      firstWindowVisible: false,
      secondWindowVisible: false,
      secondMiddleWindowVisible: false,
      thirdWindowVisible: false,
      fourthWindowVisible: false,
      formCompleted: false,
      accountType: '',
      termsSelected: false,
      secondWindowVisiblePostponed: false,
      secondWindowVisiblePostponed: false,
      secondMiddleWindowVisiblePostponed: false,
      thirdWindowVisiblePostponed: false,
      fourthWindowVisiblePostponed: false,
      secondBonusWindowPostponedVisible: false,
      firstName: '',
      lastName: '',
      address: '',
      companyName: '',
      vat: '',
      phone: '',
      email: '',
      password: '',
      passwordAgain: '',
      firstNameValid: true,
      lastNameValid: true,
      addressValid: true,
      companyNameValid: true,
      vatValid: true,
      phoneValid: true,
      emailValid: true,
      passwordValid: true,
      passwordAgainValid: true,
      checkedValid: true,
      pinValid: true,
      validationProcess: false,
      PIN: '',
      resetTimer: 0,
      focusReady: false,
      userID: 0
    };

    this.initMap = this.initMap.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);

    this.PINlistener = this.PINlistener.bind(this);
    this.PINsubmit = this.PINsubmit.bind(this);
    this.sendSMSAgain = this.sendSMSAgain.bind(this);
    this.notifyUser = this.notifyUser.bind(this);
    this.switchNotification = this.switchNotification.bind(this);
  }

  initMap = () => {
    this.setState({
      gmapsLoaded: true
    });
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  sendSMSAgain = async () => {
    if (this.state.resetTimer != 0) {
      return;
    }
    this.pin.clear();
    this.setState({ resetTimer: 30 });
    for (let i = 30; i >= 0; i--) {
      setTimeout(() => this.setState({ resetTimer: i }), (30 - i) * 1000);
    }
    await axios
      .post(
        `${config.apiUrl}/send_sms_verification_code/`,
        {
          user: this.state.userID
        },
        {
          headers: {
            Authorization: this.state.jwtToken
          }
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  switchNotification = (notificationFor) => {
    switch (notificationFor) {
      case 'email':
        return (
          <>
            <span style={{ fontSize: '18px', marginRight: '5px' }}>📬</span>
            &nbsp; That <strong>&nbsp;email address&nbsp;</strong> already
            exists.
          </>
        );
        break;
      case 'phoneNotValid':
        return (
          <>
            <span style={{ fontSize: '18px', marginRight: '5px' }}>📱</span>
            &nbsp; That <strong>&nbsp;phone number&nbsp;</strong> is not valid.
          </>
        );
        break;
      case 'acceptTerms':
        return <>Please accept the Terms!</>;
        break;
      case 'companyNameShort':
        return <>Company name needs to be at least 5 characters short!</>;
        break;
      case 'vatNotValid':
        return <>VAT name is not valid!</>;
        break;
      case 'userAlreadyExists':
        return <>User with that e-mail already exists!</>;
        break;
      case 'emailInvalid':
        return <>That e-mail doesn't exist, check it again!</>;
        break;
      case 'phone':
        return (
          <>
            <span style={{ fontSize: '18px', marginRight: '5px' }}>📱</span>
            &nbsp; That <strong>&nbsp;phone number&nbsp;</strong> already
            exists.
          </>
        );
        break;
      case 'emptyField':
        return <>Please fill in all fields!</>;
        break;
      case 'differentPasswords':
        return <>The two passwords do not match!</>;
        break;
      case 'shortPassword':
        return <>Password needs to be at least 8 characters long.</>;
        break;
    }
  };
  notifyUser = (notificationFor) => {
    toast.error(
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          {this.switchNotification(notificationFor)}
        </p>
      </div>,
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      }
    );
  };

  PINlistener = (value) => {
    this.setState({ PIN: value });
  };

  tempSubmit = async (event) => {
    event.preventDefault();

    // localStorage.removeItem('access_token');
    // localStorage.removeItem('refresh_token');

    let isValid = true;

    if (
      this.state.email != '' &&
      (this.state.password == '' || this.state.passwordAgain == '')
    ) {
      isValid = false;
      this.setState({ passwordValid: false });
      this.notifyUser('emptyField');
    }

    if (
      this.state.password == '' ||
      this.state.password != this.state.passwordAgain ||
      this.state.password.length < 8
    ) {
      isValid = false;
      this.setState({ passwordValid: false });
      this.setState({ passwordAgainValid: false });
    }

    if (this.state.password != '' && this.state.password.length < 8) {
      isValid = false;
      this.setState({ passwordValid: false });
      this.notifyUser('shortPassword');
    }

    if (
      this.state.password != '' &&
      this.state.password != this.state.passwordAgain
    ) {
      isValid = false;
      this.setState({ passwordValid: false });
      this.notifyUser('differentPasswords');
    }

    if (this.state.email == '') {
      isValid = false;
      this.setState({ emailValid: false });
      this.notifyUser('emptyField');
    }

    if (this.state.email != '' && !validator.isEmail(this.state.email)) {
      isValid = false;
      this.setState({ emailValid: false });
      this.notifyUser('emailInvalid');
    }

    let type = '';
    if (this.state.accountType == 'private') {
      type = 'natural_shipper';
    } else if (
      this.state.userType == 'sender' &&
      this.state.accountType == 'business'
    ) {
      type = 'legal_shipper';
    } else if (
      this.state.userType == 'transporter' &&
      this.state.accountType == 'business'
    ) {
      type = 'carrier';
    }
    if (isValid) {
      this.setState({ validationProcess: true });
      await axios
        .post(`${config.apiUrl}/users/create/`, {
          email: this.state.email.toLowerCase(),
          password: this.state.password,
          type: type
        })
        .then((res) => {
          this.setState({ secondWindowVisible: false });
          setTimeout(() => {
            this.setState({
              formCompleted: true,
              secondMiddleWindowVisible: true,
              secondMiddleWindowVisiblePostponed: true,
              secondWindowVisiblePostponed: false
            });
          }, 500);
          this.setState({
            userID: res.data.id,
            userEmail: res.data.email,
            userPassword: res.data.password
          });

          axios
            .post(`${config.apiUrl}/token/default/`, {
              email: res.data.email,
              password: this.state.password
            })
            .then((res) => {
              this.setState({ jwtToken: `Bearer ${res.data.access}` });
              // localStorage.setItem('access_token', res.data.access);
              // localStorage.setItem('refresh_token', res.data.refresh);
            });
        })
        .catch((err) => {
          if (typeof err.response.data.email.length) {
            if (
              err.response.data.email[0] ==
              'user with this email address already exists.'
            ) {
              this.notifyUser('userAlreadyExists');
            }
          }
        });
      await this.setState({ validationProcess: false });
    } else {
      setTimeout(() => {
        this.setState({
          emailValid: true,
          passwordValid: true,
          passwordAgainValid: true
        });
      }, 500);
    }
  };

  handlePatch = async (e) => {
    e.preventDefault();

    let isValid = true;

    const sanitizedPhone = this.state.phone.trim();
    if (
      !isValidPhoneNumber(
        sanitizedPhone.startsWith(PHONE_PREFIX)
          ? sanitizedPhone
          : PHONE_PREFIX.concat(sanitizedPhone)
      )
    ) {
      this.setState({ phoneValid: false });
      isValid = false;
      this.notifyUser('phoneNotValid');
    }

    if (!this.state.termsSelected) {
      this.setState({ checkedValid: false });
      this.notifyUser('acceptTerms');
      isValid = false;
    }

    if (this.state.accountType == 'private') {
      if (this.state.firstName == '') {
        this.setState({ firstNameValid: false });
        this.notifyUser('emptyField');
        isValid = false;
      }
      if (this.state.lastName == '') {
        this.setState({ lastNameValid: false });
        isValid = false;
      }
      if (this.state.firstName != '' && this.state.lastName == '') {
        this.notifyUser('emptyField');
        this.setState({ lastNameValid: false });
        isValid = false;
      }
    } else {
      if (this.state.companyName != '' && this.state.companyName.length < 5) {
        this.setState({ companyNameValid: false });
        this.notifyUser('companyNameShort');
        isValid = false;
      }
      if (this.state.companyName == '') {
        this.setState({ companyNameValid: false });
        this.notifyUser('emptyField');
        isValid = false;
      }
      if (this.state.vat == '') {
        this.setState({ vatValid: false });
        isValid = false;
      }
      if (this.state.companyName != '' && this.state.vat == '') {
        this.setState({ vatValid: false });
        this.notifyUser('emptyField');
        isValid = false;
      }
      if (this.state.vat != '' && this.state.vat < 3) {
        this.setState({ vatValid: false });
        this.notifyUser('vatNotValid');
        isValid = false;
      }
    }

    this.setState({ validationProcess: true });
    if (isValid && this.state.accountType == 'business') {
      isValid = true;
      await this.setState({ vatCompany: 'NOT VALIDATED USING API' });
    }
    await this.setState({ validationProcess: false });

    if (isValid) {
      let type = '';
      if (this.state.accountType == 'private') type = 'natural_shipper';
      else if (
        this.state.userType == 'sender' &&
        this.state.accountType == 'business'
      )
        type = 'legal_shipper';
      else if (
        this.state.userType == 'transporter' &&
        this.state.accountType == 'business'
      )
        type = 'carrier';
      this.setState({ validationProcess: true });
      if (this.state.accountType == 'private') {
        await axios
          .patch(
            `${config.apiUrl}/users/update/${this.state.userID}/`,
            {
              phone: this.state.phone.split(' ').join(''),
              first_name: this.state.firstName,
              last_name: this.state.lastName
            },
            {
              headers: {
                Authorization: this.state.jwtToken
              }
            }
          )
          .then((res) => {
            console.log(res);
            setTimeout(() => {
              this.setState({ formCompleted: true });
              this.setState({ secondMiddleWindowVisible: false });
              this.setState({
                secondMiddleWindowVisiblePostponed: false,
                thirdWindowVisible: true,
                thirdWindowVisiblePostponed: true
              });
            }, 500);
            setTimeout(() => {
              this.setState({ focusReady: true });
            }, 950);
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (this.state.accountType == 'business') {
        await axios
          .patch(
            `${config.apiUrl}/users/update/${this.state.userID}/`,
            {
              user: this.state.userID,
              phone: this.state.phone.split(' ').join(''),
              name: this.state.companyName,
              vat_number: this.state.vat
            },
            {
              headers: {
                Authorization: this.state.jwtToken
              }
            }
          )
          .then((res) => {
            setTimeout(() => {
              this.setState({ formCompleted: true });
              this.setState({ secondMiddleWindowVisible: false });
              this.setState({
                secondMiddleWindowVisiblePostponed: false,
                thirdWindowVisible: true,
                thirdWindowVisiblePostponed: true
              });
            }, 500);
            setTimeout(() => {
              this.setState({ focusReady: true });
            }, 950);
          })
          .catch((err) => {
            console.error(err);
          });
      }
      await this.setState({ validationProcess: false });
    } else {
      setTimeout(() => {
        this.setState({
          firstNameValid: true,
          lastNameValid: true,
          addressValid: true,
          companyNameValid: true,
          vatValid: true,
          phoneValid: true,
          emailValid: true,
          passwordValid: true,
          passwordAgainValid: true,
          checkedValid: true
        });
      }, 500);
    }
  };

  PINsubmit = async () => {
    if (this.state.PIN.length != 4) {
      this.setState({ pinValid: false });
      this.pin.clear();
      setTimeout(() => {
        this.setState({ pinValid: true });
      }, 500);
      return;
    } else {
      let isPinValid = false;
      this.setState({ validationProcess: true });
      await axios
        .post(
          `${config.apiUrl}/verify_phone/`,
          {
            user: this.state.userID,
            code: this.state.PIN
          },
          {
            headers: {
              Authorization: this.state.jwtToken
            }
          }
        )
        .then((res) => {
          console.log(res);
          this.setState({ pinValid: true });
          isPinValid = true;
        })
        .catch((err) => {
          this.setState({ pinValid: false });
          setTimeout(() => {
            this.setState({ pinValid: true });
          }, 500);
        });
      await this.setState({ validationProcess: false });

      if (isPinValid) {
        this.setState({ thirdWindowVisible: false });
        setTimeout(() => {
          this.setState({
            thirdWindowVisiblePostponed: false,
            fourthWindowVisible: true,
            fourthWindowVisiblePostponed: true
          });
        }, 500);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
      }
    }
  };

  handleCheckbox = () => {
    this.setState({ termsSelected: !this.state.termsSelected });
  };

  componentDidMount() {
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');

    /**** SOCIAL LOGIN ****/

    let url_string = window.location.href;
    let url = new URL(url_string);
    let code = url.searchParams.get('code');
    let type = url.searchParams.get('type');
    let provider = url.searchParams.get('provider');

    if (url.searchParams.get('registerType') == 'carrier') {
      this.setState({
        formCompleted: true,
        secondWindowVisible: true,
        secondWindowVisiblePostponed: true
      });
      this.setState({
        accountType: 'business',
        userType: 'transporter'
      });
    } else if (url.searchParams.get('registerType') == 'shipper') {
      this.setState({
        formCompleted: true,
        firstWindowVisible: true,
        firstWindowVisiblePostponed: true
      });
      this.setState({
        userType: 'sender'
      });
    }

    if (code !== null) {
      this.setState({
        socialLogin: true
      });

      if (type === 'natural_shipper') {
        this.setState({
          accountType: 'private',
          userType: 'sender'
        });
      }

      if (type === 'legal_shipper') {
        this.setState({
          accountType: 'business',
          userType: 'sender'
        });
      }

      if (type === 'carrier') {
        this.setState({
          accountType: 'business',
          userType: 'transporter'
        });
      }

      axios
        .get(
          `${config.apiUrl}/oauth/${provider}/login?type=${type}&code=${code}&intent=register`
        )
        .then((res) => {
          this.setState({
            userID: res.data.id
          });
          this.setState({ jwtToken: `Bearer ${res.data.token.access}` });
          localStorage.setItem('access_token', res.data.token.access);
          localStorage.setItem('refresh_token', res.data.token.refresh);
          this.props.tokenReducer({ token: `Bearer ${res.data.refresh}` });

          if (res.data.type === 'carrier')
            auth.login(
              res.data.access,
              res.data.refresh,
              res.data.user,
              res.data.type,
              () => {
                this.props.history.push('/dashboard');
              }
            );
          else
            auth.login(
              res.data.access,
              res.data.refresh,
              res.data.user,
              res.data.type,
              () => {
                this.props.history.push('/shipments');
              }
            );
        })
        .catch((err) => {
          console.error('SOCIAL CREATE GET ERROR', err);
        });

      this.setState({
        formCompleted: true,
        secondMiddleWindowVisible: true,
        secondMiddleWindowVisiblePostponed: true,
        secondWindowVisiblePostponed: false
      });
    }

    window.initMap = this.initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBQKpE6JxPpRDnbINcFFdM7XNBEg-yOX9c&language=en&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);

    // if (code === null) {
    //   axios
    //     .post(`${config.apiUrl}/token/`, {
    //       email: 'faris@meta5.io',
    //       password: 'test123'
    //     })
    //     .then((res) => {
    //       this.setState({ jwtToken: `Bearer ${res.data.access}` });
    //     });
    // }
  }

  render() {
    const { t, i18n } = this.props;

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    return (
      <div
        className={
          this.state.accountType != '' ? 'registration newBg' : 'registration'
        }
      >
        <Helmet>
          <title>Autoversand | Registration</title>
        </Helmet>
        <div className="container">
          <ToastContainer
            position={toast.POSITION.TOP_RIGHT}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            enableMultiContainer
            containerId={'A'}
          />

          <Link to="/">
            <Logo className="logo" />
          </Link>

          <Animated
            animationIn="fadeInRightBig"
            animationOut="fadeOutLeftBig"
            animationInDuration={900}
            animationOutDuration={900}
            isVisible={this.state.zeroWindowVisible}
          >
            <main
              className={
                !this.state.formCompleted
                  ? 'content contentModified contentZeroStep'
                  : 'content contentHide'
              }
            >
              <div>
                <h1>{t('registration.plans')}</h1>
                <h2 style={{ color: '#B4B2D3' }} className="contentZero__h2">
                  {t('registration.account')}
                </h2>

                <div
                  className="illustrationHolder"
                  onClick={() => {
                    this.setState({
                      zeroWindowVisible: false,
                      userType: 'sender'
                    });
                    setTimeout(() => {
                      this.setState({
                        formCompleted: true,
                        firstWindowVisible: true,
                        firstWindowVisiblePostponed: true
                      });
                    }, 500);
                  }}
                >
                  <CarIllustration />
                  <p>{t('registration.needTransport')}</p>
                </div>
                <div className="orHolder">
                  <span></span>
                  <p>{t('registration.or')}</p>
                  <span></span>
                </div>
                <div
                  className="illustrationHolder"
                  onClick={() => {
                    this.setState({
                      zeroWindowVisible: false,
                      userType: 'transporter',
                      accountType: 'business'
                    });
                    setTimeout(() => {
                      this.setState({
                        formCompleted: true,
                        secondWindowVisible: true,
                        secondWindowVisiblePostponed: true
                      });
                    }, 500);
                  }}
                >
                  <img src="https://i.imgur.com/52iHFDD.png" />
                  <p style={{ marginTop: '15px' }}>
                    {t('registration.provideTransport')}
                  </p>
                </div>
              </div>
            </main>
          </Animated>

          <Animated
            animationIn="fadeInRightBig"
            animationOut="fadeOutLeftBig"
            animationInDuration={900}
            animationOutDuration={1000}
            isVisible={this.state.firstWindowVisible}
          >
            {this.state.firstWindowVisiblePostponed && (
              <main className="content contentModified contentFirstStep">
                <div>
                  <h1>{t('registration.accountType')}</h1>
                  <h2>{t('registration.chooseType')}</h2>
                  <button
                    onClick={() => {
                      this.setState({
                        firstWindowVisible: false,
                        accountType: 'private'
                      });
                      setTimeout(() => {
                        this.setState({
                          firstWindowVisiblePostponed: false,
                          secondWindowVisible: true,
                          secondWindowVisiblePostponed: true
                        });
                      }, 500);
                    }}
                  >
                    {t('registration.private')}
                  </button>
                  <div className="orHolder">
                    <span></span>
                    <p>{t('registration.or')}</p>
                    <span></span>
                  </div>
                  <button
                    onClick={() => {
                      this.setState({
                        firstWindowVisible: false,
                        accountType: 'business'
                      });
                      setTimeout(() => {
                        this.setState({
                          firstWindowVisiblePostponed: false,
                          secondWindowVisible: true,
                          secondWindowVisiblePostponed: true
                        });
                      }, 500);
                    }}
                  >
                    {t('registration.business')}
                  </button>
                </div>
              </main>
            )}
          </Animated>

          <Animated
            animationIn="fadeInRightBig"
            animationOut="fadeOutLeftBig"
            animationInDuration={900}
            animationOutDuration={1000}
            isVisible={this.state.secondWindowVisible}
          >
            {this.state.secondWindowVisiblePostponed && (
              <main className="content secondStep">
                <BarLoader
                  css={override}
                  size={150}
                  color={'#06016c'}
                  loading={this.state.validationProcess}
                />
                <div>
                  <ReactFlagsSelect
                    defaultCountry={i18n.language.toLocaleUpperCase()}
                    onSelect={(value) =>
                      changeLanguage(value.toLocaleLowerCase())
                    }
                    countries={['DE', 'GB']}
                    customLabels={{ DE: 'Deutsch', GB: 'English' }}
                  />

                  <h1>{t('registration.register')}.</h1>
                  <h2>{t('registration.startUsingAV')}.</h2>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="register__googleFbButton">
                      <button className="loginPopUp__button">
                        <span
                          onClick={() => {
                            window.open(
                              `${config.apiUrl}/oauth/google?type=${
                                this.state.userType === 'transporter'
                                  ? 'carrier'
                                  : this.state.accountType === 'business'
                                  ? 'legal_shipper'
                                  : 'natural_shipper'
                              }&intent=register`,
                              '_self'
                            );
                          }}
                        >
                          {t('modal.singIn')}
                        </span>
                        <img
                          src={googleIcon}
                          onClick={() => {
                            window.open(
                              `${config.apiUrl}/oauth/google?type=${
                                this.state.userType === 'transporter'
                                  ? 'carrier'
                                  : this.state.accountType === 'business'
                                  ? 'legal_shipper'
                                  : 'natural_shipper'
                              }&intent=register`,
                              '_self'
                            );
                          }}
                        />
                      </button>
                      <img
                        src={fbIcon}
                        onClick={() => {
                          window.open(
                            `${config.apiUrl}/oauth/facebook?type=${
                              this.state.userType === 'transporter'
                                ? 'carrier'
                                : this.state.accountType === 'business'
                                ? 'legal_shipper'
                                : 'natural_shipper'
                            }&intent=register`,
                            '_self'
                          );
                        }}
                      />
                    </div>
                    <div className="orHolder">
                      <span></span>
                      <p>{t('registration.or')}</p>
                      <span></span>
                    </div>
                    <div className="row">
                      <input
                        type="email"
                        value={this.state.email}
                        name="email"
                        onChange={this.handleChange}
                        placeholder={t('registration.email')}
                        className={!this.state.emailValid && 'shakeIt'}
                        spellCheck="false"
                      />
                    </div>
                    <div className="row">
                      <input
                        type="password"
                        value={this.state.password}
                        name="password"
                        onChange={this.handleChange}
                        placeholder={t('registration.password')}
                        className={!this.state.passwordValid && 'shakeIt'}
                        spellCheck="false"
                      />
                    </div>
                    <div className="row">
                      <input
                        type="password"
                        value={this.state.passwordAgain}
                        name="passwordAgain"
                        onChange={this.handleChange}
                        placeholder={t('registration.repeatPassword')}
                        className={!this.state.passwordAgainValid && 'shakeIt'}
                        spellCheck="false"
                      />
                    </div>
                    <button
                      className="secondMiddle__button"
                      onClick={this.tempSubmit}
                    >
                      {t('registration.next')}
                    </button>
                  </form>
                </div>
              </main>
            )}
          </Animated>

          {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}

          <Animated
            animationIn="fadeInRightBig"
            animationOut="fadeOutLeftBig"
            animationInDuration={900}
            animationOutDuration={1000}
            isVisible={this.state.secondMiddleWindowVisible}
          >
            {this.state.secondMiddleWindowVisiblePostponed && (
              <main className="content secondStep">
                <BarLoader
                  css={override}
                  size={150}
                  color={'#06016c'}
                  loading={this.state.validationProcess}
                />
                <div style={{ width: '65%' }}>
                  <ReactFlagsSelect
                    defaultCountry={i18n.language.toLocaleUpperCase()}
                    onSelect={(value) =>
                      changeLanguage(value.toLocaleLowerCase())
                    }
                    countries={['DE', 'GB']}
                    customLabels={{ DE: 'Deutsch', GB: 'English' }}
                  />

                  <h1>{t('registration.register')}.</h1>
                  <h2>{t('registration.provideInfo')}.</h2>
                  <form onSubmit={(e) => e.preventDefault()}>
                    {this.state.accountType == 'private' && (
                      <div>
                        <div
                          className={
                            !this.state.fromValid || !this.state.toValid
                              ? 'rowHolder shakeIt'
                              : 'rowHolder'
                          }
                        >
                          <div className="row">
                            <input
                              type="text"
                              name="firstName"
                              value={this.state.firstName}
                              className={
                                !this.state.firstNameValid && 'shakeIt'
                              }
                              onChange={this.handleChange}
                              placeholder={t('registration.name')}
                              spellCheck="false"
                            />
                          </div>
                          <div className="row">
                            <input
                              type="text"
                              name="lastName"
                              value={this.state.lastName}
                              className={!this.state.lastNameValid && 'shakeIt'}
                              onChange={this.handleChange}
                              placeholder={t('registration.surname')}
                              spellCheck="false"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.accountType == 'business' && (
                      <div>
                        <div className="row">
                          <input
                            type="text"
                            value={this.state.companyName}
                            name="companyName"
                            onChange={this.handleChange}
                            placeholder={t('registration.companyName')}
                            className={
                              !this.state.companyNameValid && 'shakeIt'
                            }
                            spellCheck="false"
                          />
                        </div>
                        <div className="row">
                          <input
                            type="text"
                            value={this.state.vat}
                            name="vat"
                            onChange={this.handleChange}
                            placeholder={t('registration.vat')}
                            className={!this.state.vatValid && 'shakeIt'}
                            spellCheck="false"
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className={!this.state.phoneValid ? 'row shakeIt' : 'row'}
                    >
                      <PhoneInput
                        name="phone"
                        value={this.state.phone}
                        enableSearch="true"
                        disableSearchIcon="true"
                        inputClass="phoneInput"
                        country={'at'}
                        value={this.state.phone}
                        onChange={(phone) => this.setState({ phone })}
                      />
                    </div>

                    <div
                      className={
                        !this.state.checkedValid ? 'row shakeIt' : 'row'
                      }
                    >
                      <label className="containerCheck">
                        {t('registration.accept')}{' '}
                        <Link
                          to="/datenschutz"
                          style={{
                            color: '#06016c',
                            fontFamily: 'SFProDisplay-Bold'
                          }}
                          target="_blank"
                        >
                          {t('registration.termsConditions')}
                        </Link>
                        <input
                          type="checkbox"
                          checked="checked"
                          checked={this.state.termsSelected}
                          onChange={this.handleCheckbox}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <button type="submit" onClick={this.handlePatch}>
                      {t('registration.next')}
                    </button>
                  </form>
                </div>
              </main>
            )}
          </Animated>

          {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}

          <Animated
            animationIn="fadeInRightBig"
            animationOut="fadeOutLeftBig"
            animationInDuration={900}
            animationOutDuration={1000}
            isVisible={this.state.thirdWindowVisible}
          >
            {this.state.thirdWindowVisiblePostponed && (
              <main className="content contentModified contentZeroStep">
                <BarLoader
                  css={override}
                  size={150}
                  color={'#06016c'}
                  loading={this.state.validationProcess}
                />
                <div className="SMSholder">
                  <h1>{t('registration.smsConfirm')}</h1>
                  {/* <h2>Please check your mobile phone.</h2> */}
                  <div className={!this.state.pinValid && 'shakeItAlt'}>
                    {this.state.focusReady && (
                      <PinInput
                        length={4}
                        initialValue=""
                        focus={this.state.focusReady}
                        ref={(p) => (this.pin = p)}
                        onChange={this.PINlistener}
                        type="numeric"
                        style={{ padding: '10px' }}
                        onComplete={(value, index) => {}}
                      />
                    )}
                  </div>
                  <h2
                    style={{
                      color: '#B4B2D3',
                      fontSize: '16px',
                      marginBottom: '20px'
                    }}
                  >
                    {t('registration.enter4Digit')}
                  </h2>
                  <h2
                    style={{
                      color: '#B4B2D3',
                      fontSize: '20px',
                      margin: '10px auto 35px auto'
                    }}
                  >
                    {t('registration.skipSMSConfirmation1')}{' '}
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        fontSize: '18px'
                      }}
                      onClick={() =>
                        this.setState({
                          thirdWindowVisible: false,
                          thirdWindowVisiblePostponed: false,
                          fourthWindowVisible: true,
                          fourthWindowVisiblePostponed: true
                        })
                      }
                    >
                      <p>{t('registration.skipSMSConfirmation2')}</p>{' '}
                      <BsArrowRight />
                    </span>{' '}
                    {t('registration.skipSMSConfirmation3')}
                  </h2>
                  <span onClick={this.sendSMSAgain}>
                    {this.state.resetTimer != 0
                      ? '00:' +
                        (this.state.resetTimer < 10 ? '0' : '') +
                        this.state.resetTimer
                      : 'Resend Code'}
                  </span>
                  <button
                    type="submit"
                    onClick={this.PINsubmit}
                    style={{ marginTop: '40px' }}
                  >
                    {t('registration.next')}
                  </button>
                </div>
              </main>
            )}
          </Animated>

          <Animated
            animationIn="fadeInRightBig"
            animationOut="fadeOutLeftBig"
            animationInDuration={900}
            animationOutDuration={1000}
            isVisible={this.state.fourthWindowVisible}
          >
            {this.state.fourthWindowVisiblePostponed && (
              <main className="content contentModified contentZeroStep">
                <div>
                  <h1>{t('registration.congrats')}!</h1>
                  <h2>{t('registration.welcome')}</h2>
                  <Check />
                  {!this.state.socialLogin && (
                    <h2 style={{ color: '#B4B2D3', fontSize: '17px' }}>
                      {t('registration.checkAccount')}
                    </h2>
                  )}
                  {this.state.socialLogin && (
                    <h2 style={{ color: '#B4B2D3', fontSize: '17px' }}>
                      Use this social account as your
                      <br />
                      sign-in method.
                    </h2>
                  )}
                  <Link to="/login" style={{ textDecoration: 'none' }}>
                    <button type="submit" style={{ marginTop: '20px' }}>
                      {t('login.login')}
                    </button>
                  </Link>
                </div>
              </main>
            )}
          </Animated>

          <footer className="footer">
            <h3>COPYRIGHT</h3>
            <div className="footer__copyright">
              <p>© Autoversand. {t('homepage.copyright')}</p>
              <div>
                <InstagramLogo
                  className="footer__copyright--icon"
                  onClick={() =>
                    window.open('https://instagram.com/autoversand', '_blank')
                  }
                />
                <FacebookLogo
                  className="footer__copyright--icon"
                  onClick={() =>
                    window.open('https://facebook.com/autoversand', '_blank')
                  }
                />
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tokenState: state.tokenReducer
});

const mapDispatchToProps = (dispatch) => ({
  tokenReducer: (data) => dispatch(setToken(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Registration));
