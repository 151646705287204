import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import PlacesAutocomplete from '../../components/GoogleMaps/PlacesAutocomplete';
import { ReactComponent as NothingFound } from '../../assets/images/nothingFound.svg';

import { DatePicker } from '../../components/DatePicker/DatePicker';
import Header from '../../Layout/Dashboard/DashboardHeader/DashboardHeader';
import DashboardNavbar from '../../Layout/Dashboard/DashboardNavbar/DashboardNavbar';
import Navbar from '../../Layout/Navbar/Navbar';
import SearchHeader from '../../Layout/Mobile/SearchHeader';
import MobileNavbar from '../../Layout/Mobile/Navbar';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import FormInput from '../../components/FormInput/FormInput';
import ShipmentsCard from '../../components/ShipmentsCard/ShipmentsCard';
import BackFilterButton from '../../components/Buttons/BackFilterButton';
import Button from '../../components/Buttons/BlueButton';
import Range from './Range';
import RangeKilograms from './RangeKilograms';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox,
  InfiniteHits,
  Configure,
  connectMenu,
  connectStats,
  connectRange,
  RangeInput
} from 'react-instantsearch-dom';
import axios from 'axios';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';

import { ReactComponent as ArrowButton } from '../../assets/images/arrowButton.svg';

import { ReactComponent as Coupe } from '../../assets/images/SearchCars/coupe.svg';
import { ReactComponent as Sedan } from '../../assets/images/SearchCars/sedan.svg';
import { ReactComponent as Cabrio } from '../../assets/images/SearchCars/cabrio.svg';
import { ReactComponent as SmallCar } from '../../assets/images/SearchCars/small.svg';
import { ReactComponent as SUV } from '../../assets/images/SearchCars/suv.svg';
import { ReactComponent as PickupTruck } from '../../assets/images/SearchCars/pickup.svg';
import { ReactComponent as MiniVan } from '../../assets/images/SearchCars/mini.svg';
import { ReactComponent as Van } from '../../assets/images/SearchCars/van.svg';
import { ReactComponent as Truck } from '../../assets/images/SearchCars/offroad.svg';

import { ReactComponent as CoupeBlue } from '../../assets/images/SearchCars/coupeBlue.svg';
import { ReactComponent as SedanBlue } from '../../assets/images/SearchCars/sedanBlue.svg';
import { ReactComponent as CabrioBlue } from '../../assets/images/SearchCars/cabrioBlue.svg';
import { ReactComponent as SmallCarBlue } from '../../assets/images/SearchCars/smallBlue.svg';
import { ReactComponent as SUVBlue } from '../../assets/images/SearchCars/suvBlue.svg';
import { ReactComponent as PickupTruckBlue } from '../../assets/images/SearchCars/pickupBlue.svg';
import { ReactComponent as MiniVanBlue } from '../../assets/images/SearchCars/miniBlue.svg';
import { ReactComponent as VanBlue } from '../../assets/images/SearchCars/vanBlue.svg';
import { ReactComponent as TruckBlue } from '../../assets/images/SearchCars/offroadBlue.svg';

import { ReactComponent as FilterIcon } from '../../assets/images/NavbarIcons/User.svg';
import { useWindowSize } from '../../components/WindowSize/WindowSize';

import { Animated } from 'react-animated-css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import auth from '../../auth';

import { connect } from 'react-redux';

import '../Orders/Orders.css';
import './Results.css';

const searchClient = algoliasearch(
  '5YO70YNTRI',
  '7028ae0ec0daedc97b492a5fe6254437'
);

const MIN_WEIGHT = 0;
const MAX_WEIGHT = 20000;

const override = css`
  margin: 0 auto;
  width: 100px;
  margin-top: 150px;
  margin-bottom: 50px;
`;

const MenuSelect = ({ items }) => (
  <select>
    <option value="">See all options</option>
    {items.map((item) => (
      <option key={item.label} value={item.value}>
        {item.label}
      </option>
    ))}
  </select>
);

const Results = (props) => {
  const { t } = props;
  const location = useLocation();
  const element = useRef();
  const [width, height] = useWindowSize();

  let shipments = [];
  const [sliderLoaded, setSliderLoaded] = useState(false);

  const [shipment, setShip] = useState([]);
  const [filterState, setFilterState] = useState(
    props.shipmentsState.shipments
  );
  const [searchTerm, setSearchTerm] = useState('');

  const [pickupStartDate, pickupEndDate, PickupDatePicker] = DatePicker(
    'Pickup date from',
    'Pickup date to',
    'pickupStartDate',
    'pickupEndDate'
  );

  const [visible, setVisible] = useState(false);
  const [sliderWeight, setSliderWeight] = useState([MIN_WEIGHT, MAX_WEIGHT]);

  const [pickup, setPickup] = useState(null);
  const [destination, setDestination] = useState(null);
  const [pickupText, setPickupText] = useState('');
  const [destinationText, setDestinationText] = useState('');
  const onClickOpen = () => {
    setVisible(true);
  };
  const onClickClose = () => {
    setVisible(false);
  };
  const toggleVisible = () => {
    setVisible(!visible);
  };
  const [iconState, setIconState] = useState([
    {
      name: 'sedan',
      displayName: 'Sedan',
      checked: false,
      active: <SedanBlue />,
      inactive: <Sedan />
    },
    {
      name: 'coupe',
      displayName: 'Coupe',
      checked: false,
      active: <CoupeBlue />,
      inactive: <Coupe />
    },
    {
      name: 'cabriolet',
      displayName: 'Cabrio',
      checked: false,
      active: <CabrioBlue />,
      inactive: <Cabrio />
    },
    {
      name: 'hatchback',
      displayName: 'Small car',
      checked: false,
      active: <SmallCarBlue />,
      inactive: <SmallCar />
    },
    {
      name: 'suv',
      displayName: 'Suv',
      checked: false,
      active: <SUVBlue />,
      inactive: <SUV />
    },
    {
      name: 'pick-up',
      displayName: 'Pickup',
      checked: false,
      active: <PickupTruckBlue />,
      inactive: <PickupTruck />
    },
    {
      name: 'minivan',
      displayName: 'Mini van',
      checked: false,
      active: <MiniVanBlue />,
      inactive: <MiniVan />
    },
    {
      name: 'van',
      displayName: 'Van',
      checked: false,
      active: <VanBlue />,
      inactive: <Van />
    },
    {
      name: 'offroad',
      displayName: 'Off road',
      checked: false,
      active: <TruckBlue />,
      inactive: <Truck />
    }
  ]);

  const handleChange = (target, value) => {
    setIconState(
      iconState.map((x) => (x.name === target ? { ...x, checked: value } : x))
    );
  };

  const getShipmentData = () => {
    setSliderLoaded(true);

    filterState.map((shipment) => {
      shipments.push({
        shipmentID: shipment.id,
        backgroundImage:
          shipment.photos.length == 0
            ? `url(${'https://picsum.photos/200'})`
            : `url(${shipment.photos[0].file})`,
        transportType:
          shipment.vehicle.manufacturer +
          ' ' +
          shipment.vehicle.model +
          ' ' +
          shipment.vehicle.year,
        location: {
          from: {
            city: shipment.pickup.city,
            country: shipment.pickup.country
          },
          to: {
            city: shipment.destination.city,
            country: shipment.destination.country
          }
        }
      });
    });

    setShip(
      shipments.map((shipment) => (
        <ShipmentsCard key={shipment.shipmentID} data={shipment} />
      ))
    );
    setSliderLoaded(false);
  };

  useEffect(() => {
    getShipmentData();
  }, [filterState]);

  useEffect(() => {
    setFilterState(props.shipmentsState.shipments);
  }, [props.shipmentsState.shipments]);

  useEffect(() => {
    if (pickupText == '') setPickup(null);

    if (destinationText == '') setDestination(null);
  }, [pickupText, destinationText]);

  useEffect(() => {
    if (location.state != undefined) {
      setPickup(location.state._pickup);
      setDestination(location.state._destination);
    }
  }, []);
  useEffect(() => {
    const filterSearchTerm = () => {
      return props.shipmentsState.shipments.filter(
        (item) =>
          item.vehicle.manufacturer
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.vehicle.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.pickup.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.pickup.country
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.destination.city
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.destination.country
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
    };
    const filterPickupLocation = () => {
      return props.shipmentsState.shipments.filter(
        (item) => item.pickup.place_id === pickup.place_id
      );
    };
    const filterDeliveryLocation = () => {
      return props.shipmentsState.shipments.filter(
        (item) => item.destination.place_id === destination.place_id
      );
    };
    const filterPickupDate = () => {
      return props.shipmentsState.shipments.filter(
        (item) =>
          moment(moment(pickupStartDate).format('YYYY-MM-DD')).diff(
            moment(item.startdate_earliest)
          ) <= 0
      );
    };
    const filterDeliveryDate = () => {
      return props.shipmentsState.shipments.filter(
        (item) =>
          moment(moment(pickupEndDate).format('YYYY-MM-DD')).diff(
            moment(item.startdate_latest)
          ) >= 0
      );
    };
    const filterDateRange = () => {
      return props.shipmentsState.shipments.filter((item) => {
        const a = moment(moment(pickupStartDate).format('YYYY-MM-DD'));
        const b = moment(moment(pickupEndDate).format('YYYY-MM-DD'));
        const c = b.diff(a, 'days');
        let allDates = [];
        for (let i = 0; i <= c; i++) {
          const newDate = moment(
            moment(pickupStartDate).format('YYYY-MM-DD'),
            'YYYYMMDD'
          )
            .add(i, 'days')
            .format('YYYY-MM-DD');
          allDates = [...allDates, newDate];
        }
        for (let i = 0; i < allDates.length; i++) {
          if (
            item.startdate_earliest === allDates[i] ||
            item.startdate_latest === allDates[i]
          )
            return item;
        }
      });
    };
    const filterWeight = () => {
      return props.shipmentsState.shipments.filter(
        (item) =>
          parseInt(item.vehicle.weight) >= sliderWeight[0] &&
          parseInt(item.vehicle.weight) <= sliderWeight[1]
      );
    };
    const filterVehicleType = (selectedTypeArray) => {
      return props.shipmentsState.shipments.filter((item) => {
        for (let i = 0; i < selectedTypeArray.length; i++)
          if (selectedTypeArray[i].name === item.vehicle_category) return item;
      });
    };
    let searchFilterArray = [];
    let weightFilterArray = [];
    let pickupFilterArray = [];
    let deliveryFilterArray = [];
    let pickupDateFilterArray = [];
    let deliveryDateFilterArray = [];
    let dateRangeFilterArray = [];
    let vehicleTypeFilterArray = [];
    searchTerm !== ''
      ? (searchFilterArray = filterSearchTerm())
      : (searchFilterArray = props.shipmentsState.shipments);
    pickup !== null
      ? (pickupFilterArray = filterPickupLocation())
      : (pickupFilterArray = props.shipmentsState.shipments);
    destination !== null
      ? (deliveryFilterArray = filterDeliveryLocation())
      : (deliveryFilterArray = props.shipmentsState.shipments);
    pickupStartDate !== null && pickupEndDate === null
      ? (pickupDateFilterArray = filterPickupDate())
      : (pickupDateFilterArray = props.shipmentsState.shipments);
    pickupEndDate !== null && pickupStartDate === null
      ? (deliveryDateFilterArray = filterDeliveryDate())
      : (deliveryDateFilterArray = props.shipmentsState.shipments);
    pickupStartDate !== null && pickupEndDate !== null
      ? (dateRangeFilterArray = filterDateRange())
      : (dateRangeFilterArray = props.shipmentsState.shipments);
    const selectedTypeArray = iconState.filter((item) => item.checked);
    selectedTypeArray.length > 0
      ? (vehicleTypeFilterArray = filterVehicleType(selectedTypeArray))
      : (vehicleTypeFilterArray = props.shipmentsState.shipments);
    weightFilterArray = filterWeight();
    //INTERSECTION OF ARRAYS
    var object = {
      searchFilterArray,
      weightFilterArray,
      pickupFilterArray,
      deliveryFilterArray,
      pickupDateFilterArray,
      deliveryDateFilterArray,
      dateRangeFilterArray,
      vehicleTypeFilterArray
    };
    const result = Object.values(object).reduce((a, b) => {
      return b.filter(Set.prototype.has, new Set(a));
    });
    setFilterState(result);
  }, [
    searchTerm,
    pickup,
    destination,
    pickupStartDate,
    pickupEndDate,
    sliderWeight,
    iconState
  ]);

  const renderIcons = () => {
    return iconState.map((item) => (
      <div
        onClick={() => {
          handleChange(item.name, !item.checked);
        }}
      >
        {item.checked ? item.active : item.inactive}
        <span>{item.displayName}</span>
      </div>
    ));
  };
  return (
    <div>
      <div className="orders results">
        <Helmet>
          <title>Autoversand | Search Results</title>
        </Helmet>
        <SearchHeader setSearchTerm={setSearchTerm}>
          <FilterIcon onClick={toggleVisible} />
        </SearchHeader>
        {auth.isAuthenticated() ? (
          <Header setSearchTerm={setSearchTerm} />
        ) : (
          <div className="results__nav">
            {' '}
            <Navbar />{' '}
          </div>
        )}
        <div className="orders__main" style={{ display: 'flex' }}>
          {auth.isAuthenticated() ? <DashboardNavbar /> : null}
          <div
            className={
              auth.isAuthenticated()
                ? 'orders__content'
                : 'orders__content order__contentPublic'
            }
          >
            <section className="orders__section1">
              <ContentHeader>Results</ContentHeader>
              <p className="orders__section--paragraph">
                Want to send or recieve car? Use this page to give us more
                information and recieve offers from available carriers.
              </p>
              {sliderLoaded ? (
                <PuffLoader
                  css={override}
                  size={60}
                  margin={2}
                  color={'rgba(116, 38, 251, 0.4)'}
                  loading={sliderLoaded}
                />
              ) : shipment.length > 0 ? (
                <div
                  className="orders__slider results__slider"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    flexWrap: 'wrap',
                    width: '100%',
                    overflowY: 'scroll',
                    paddingLeft: '5rem'
                  }}
                >
                  {shipment}
                </div>
              ) : (
                <div className="nothingFound">
                  <NothingFound />
                </div>
              )}
            </section>
          </div>
          <div
            className={
              visible
                ? 'fuhrpark__content-blur'
                : 'fuhrpark__content-blurDisplay'
            }
          ></div>
          <Animated
            className={
              auth.isAuthenticated()
                ? 'addTruckAnimated addTruckSpecial'
                : 'addTruckAnimated addTruckSpecial addTruckPublic'
            }
            animationIn="fadeInRight"
            animationOut="fadeOutRight"
            animateOnMount={false}
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={visible}
          >
            <div className="addTruck resultsFilter">
              <div
                className={
                  auth.isAuthenticated()
                    ? 'truckDetails'
                    : 'truckDetails truckDetailsPublic'
                }
              >
                <h2 className="truckDetails--heading">Filter</h2>

                <div className="truckDetails__form updatedResultsForm">
                  <div
                    className="searchFilterHolder"
                    style={{
                      position: 'relative',
                      height: '210px',
                      marginTop: '-30px'
                    }}
                  >
                    <div className="inputHolder" style={{ left: '0px' }}>
                      <span>{t('results.pickup')}</span>
                      <PlacesAutocomplete
                        _value={
                          pickup != null ? pickup.formatted_address : null
                        }
                        _setValue={setPickup}
                        setTextValue={setPickupText}
                        placeholder="From"
                        id="vehicle_pickup"
                        name="vehicle_pickup"
                      />
                    </div>
                    <div
                      className="inputHolder"
                      style={{ left: '0px', top: '135px' }}
                    >
                      <span>{t('results.destionation')}</span>
                      <PlacesAutocomplete
                        _value={
                          destination != null
                            ? destination.formatted_address
                            : null
                        }
                        _setValue={setDestination}
                        setTextValue={setDestinationText}
                        placeholder="To"
                        id="vehicle_destination"
                        name="vehicle_destination"
                      />
                    </div>
                  </div>
                  <div className="addTransportInformation__groupInput">
                    <PickupDatePicker />
                  </div>
                  <span>{t('results.weight')}</span>
                  <RangeKilograms setWeight={setSliderWeight} />
                  <span>{t('results.vehicleType')}</span>
                  <div className="resultsIconHolder">{renderIcons()}</div>
                </div>
                <BackFilterButton handleClick={onClickClose} />
              </div>
            </div>
          </Animated>
          <div className="filterSidebar">
            <button className="customBackButton" onClick={onClickOpen}>
              <ArrowButton /> <span>FILTER</span>
            </button>
          </div>
        </div>
      </div>
      {auth.isAuthenticated() ? <MobileNavbar /> : null}
    </div>
  );
};

const CustomMenuSelect = connectMenu(MenuSelect);

const mapStateToProps = (state) => ({
  shipmentsState: state.shipmentsReducer
});

export default connect(mapStateToProps, false)(withNamespaces()(Results));
