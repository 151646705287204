import axios from 'axios';
import LocalStorageService from './LocalStorageService';
import config from './config/api.config.js';

class Auth {
  constructor() {
    this.load();
  }

  login(accessToken, refreshToken, userId, type, redirectTo) {
    const _config = {
      access_token: accessToken,
      refresh_token: refreshToken,
      loggedUserId: userId,
      type
    };
    LocalStorageService.set(_config);
    this.load(_config);

    redirectTo();
  }

  load(_config) {
    const storage = _config ?? LocalStorageService.all();
    const { access_token, refresh_token, loggedUserId, type } = storage;
    this.authenticated = !!access_token;
    this.refreshToken = refresh_token;
    this.type = type;
    this.userId = loggedUserId;
    this.accessToken = access_token;
  }

  dispose() {
    this.authenticated = false;
    this.refreshToken = this.type = this.userId = this.accessToken = null;
  }

  async getToken() {
    const [, refresh] = this.refreshToken.split(' ', 2);
    return await axios.post(`${config.apiUrl}/token/refresh/`, {
      refresh
    });
  }

  getId() {
    return this.userId;
  }

  getType() {
    return this.type;
  }

  isCarrier() {
    return this.type === 'carrier';
  }

  isNaturalShipper() {
    return this.type === 'natural_shipper';
  }

  isLegalShipper() {
    return this.type === 'legal_shipper';
  }

  isShipper() {
    return ['natural_shipper', 'legal_shipper'].includes(this.type);
  }

  isAuthenticated() {
    return this.authenticated;
  }

  logout(logoutRedirect) {
    LocalStorageService.clear();
    this.dispose();
    logoutRedirect();
  }
}

export default new Auth();
