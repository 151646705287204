import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Header from '../../Layout/Header/Header';
import Navbar from '../../Layout/Navbar/Navbar';
import Footer from '../../Layout/Footer/Footer';
import SectionHeader from '../../components/SectionHeader/SectionHeader';

import { useWindowSize } from '../../components/WindowSize/WindowSize';

import './impressum.css';

const Impressum = () => {
  const [width, height] = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Autoversand | Impressum</title>
      </Helmet>
      <Navbar />
      <main
        className="main__about"
        style={{ height: 'auto', minHeight: '100vh' }}
      >
        <div className="main__sections">
          <section>
            <SectionHeader type="autoversand">IMPRESSUM</SectionHeader>
            <div className="fontStyle__paragraph">
              <p className="fontStyle__impressum">
                Für den Inhalt verantwortlich:
              </p>
              <p style={{ marginBottom: '20px' }}>
                Two Dots Digital GmbH, Neue Heimat 22, 4663 Laakirchen,
                Österreich
              </p>
              <p>
                Änderungen und Irrtümer vorbehalten. Es ist untersagt, die
                Inhalte und Informationen (Texte, Bilder etc.), auch nicht
                auszugsweise, ohne unsere ausdrückliche Genehmigung zu
                verwerten, zu vervielfältigen oder zu verbreiten.
              </p>
            </div>
            <div className="fontStyle__paragraph fontStyle__impressum impressum__secondDiv">
              <p>
                <strong>Medieninhaber</strong>: Two Dots Digital GmbH
              </p>
              <p>
                <strong>Anschrift</strong>: Neue Heimat 22,4663 Laakirchen,
                Österreich
              </p>
              <p>
                <strong>Berufsgruppe</strong>: Werbeagentur
              </p>
              <p>
                <strong>Behörde</strong> gem. ECG: LG Wels
              </p>
              <p>
                <strong>UID</strong>-Nummer: ATU 72776828
              </p>
              <p>
                <strong>Firmenbuchnummer</strong>: FN 481009 t
              </p>
              <p>
                <strong>Kontakt</strong> E-Mail: office@twodotsdigital.at
              </p>
              <p>
                <strong>Realisierung</strong>: Two Dots Digital GmbH in
                Kooperation mit Partnern
              </p>
            </div>
            <div className="fontStyle__paragraph">
              <p className="fontStyle__impressum">Rechtlicher Hinweis</p>
              <p style={{ marginBottom: '20px' }}>
                Im Hinblick auf die technischen Eigenschaften des Internet kann
                keine Gewähr für die Authentizität, Richtigkeit und
                Vollständigkeit der im Internet zur Verfügung gestellten
                Informationen übernommen werden. Es wird auch keine Gewähr für
                die Verfügbarkeit oder den Betrieb der gegenständlichen Webseite
                und ihrer Inhalte übernommen.
              </p>
              <p style={{ marginBottom: '20px' }}>
                Jede Haftung für unmittelbare, mittelbare oder sonstige Schäden,
                unabhängig von deren Ursachen, die aus der Benutzung oder
                Nichtverfügbarkeit der Daten und Informationen dieser Homepage
                erwachsen, wird, soweit rechtlich zulässig, ausgeschlossen.
              </p>
              <p>
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht erkennbar.
              </p>
            </div>
            <div className="fontStyle__paragraph">
              <p className="fontStyle__impressum">
                Nutzungshinweis zu Google Analytics
              </p>
              <p style={{ marginBottom: '20px' }}>
                Diese Website benutzt Google Analytics, einen Webanalysedienst
                der Google Inc., um eine Analyse der Benutzung der Website zu
                ermöglichen. Die durch den Einsatz von Google Analytics
                erzeugten Informationen über Ihre Nutzung diese Website
                (einschließlich Ihrer IP-Adresse) werden an einen Server der
                Google Inc. in den USA übertragen und dort gespeichert. Google
                wird diese Informationen benutzen, um Ihre Nutzung der Website
                auszuwerten, um Reports über die Websiteaktivitäten für die
                Websitebetreiber zusammenzustellen und um weitere mit der
                Websitenutzung und der Internetnutzung verbundene
                Dienstleistungen zu erbringen. Google wird in keinem Fall Ihre
                IP-Adresse mit anderen Daten von Google in Verbindung bringen.
                Durch die Nutzung dieser Website erklären Sie sich mit der
                Bearbeitung der über Sie erhobenen Daten durch Google in der
                zuvor beschriebenen Art und Weise und zu dem zuvor benannten
                Zweck einverstanden
              </p>
            </div>
          </section>
          <Footer styleFooter="about__footer" />
        </div>
      </main>
    </div>
  );
};

export default Impressum;
