import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { ReactComponent as ShipmentsFinishedImage } from '../../../assets/images/shipmentFinished.svg';
import Button from '../../Buttons/BlueButton';

import './AddInformationFinished.css';

const AddInformationFinished = ({ t, onClickClose, shipmentID }) => {
  let history = useHistory();

  const handleOpen = () => {
    onClickClose(false);
    history.push(`/shipment/${shipmentID}`);
  };

  return (
    <div className="addInformationFinished">
      <div className="addInformationFinished__content">
        <h2 className="addInformationFinished__content--heading">
          {t('shipments.congratulations')}
        </h2>
        <p className="addInformationFinished__content--paragraph">
          {t('shipments.checkDashboard')}
        </p>
        <ShipmentsFinishedImage className="addInformationFinished__contentImage" />
        <Button handleOpen={handleOpen}>{t('shipments.viewRequest')}</Button>
      </div>
    </div>
  );
};

export default withNamespaces()(AddInformationFinished);
