import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Animated } from 'react-animated-css';
import validator from 'validator';
import { css } from '@emotion/core';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import ReactFlagsSelect from 'react-flags-select';
import { withNamespaces } from 'react-i18next';
import BarLoader from 'react-spinners/BarLoader';
import { connect } from 'react-redux';

import { setToken } from '../../redux/actions';
import auth from '../../auth';
import config from '../../config/api.config.js';

import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as InstagramLogo } from '../../assets/images/instagram_logo.svg';
import { ReactComponent as FacebookLogo } from '../../assets/images/facebook_logo.svg';
import { ReactComponent as Check } from '../../assets/images/check.svg';
import { ReactComponent as CarIllustration } from '../../assets/images/carBg.svg';

import 'react-toastify/dist/ReactToastify.css';
import 'react-flags-select/css/react-flags-select.css';
import './passwordReset.css';

const override = css`
  position: absolute;
  top: 0px;
  width: 40%;
`;

class Password extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zeroWindowVisible: true,
      firstWindowVisible: false,
      email: '',
      emailValid: true,
      validationProcess: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.notifyUser = this.notifyUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;

    if (!validator.isEmail(this.state.email)) {
      this.setState({ emailValid: false });
      isValid = false;
    }

    // let statusOfLogin = true;

    if (!isValid) {
      return setTimeout(() => {
        this.setState({
          emailValid: true
        });
      }, 500);
    }

    await this.setState({ validationProcess: true });

    await axios
      .post(`${config.apiUrl}/password_reset/`, {
        email: this.state.email
      })
      .then((res) => {
        this.setState({
          zeroWindowVisible: false,
          firstWindowVisible: true,
          email: ''
        });
      })
      .catch((err) => {
        console.log(err);
        this.notifyUser('passwordReset');
        this.setState({ emailValid: false });
        setTimeout(() => {
          this.setState({
            emailValid: true,
            email: ''
          });
        }, 500);
      });

    // setTimeout(() => {
    //   this.setState({
    //     zeroWindowVisible: false,
    //     firstWindowVisible: true,
    //     email: "",
    //   });
    //   this.setState({
    //   });
    // }, 500);

    await this.setState({ validationProcess: false });
  };

  notifyUser = (notificationFor) => {
    const Msg_PasswordReset = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ fontSize: '18px', marginRight: '5px' }}></span>&nbsp;
          {this.props.t('toast.emailIncorrect')}
        </p>
      </div>
    );
    if (notificationFor === 'passwordReset')
      toast.error(<Msg_PasswordReset />, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
  };

  render() {
    const { t, i18n } = this.props;

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    if (i18n.language != 'de' && i18n.language != 'gb') {
      i18n.language = 'de';
    }

    return (
      <div
        className={
          this.state.accountType != '' ? 'registration newBg' : 'registration'
        }
      >
        <Helmet>
          <title>Autoversand | {t('password.reset')}</title>
        </Helmet>
        <div className="container">
          <ToastContainer
            position={toast.POSITION.TOP_RIGHT}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            enableMultiContainer
            containerId={'A'}
          />

          <Link to="/">
            <Logo className="logo" />
          </Link>

          <Animated
            animationIn="fadeInRightBig"
            animationOut="fadeOutLeftBig"
            animationInDuration={900}
            animationOutDuration={1000}
            isVisible={this.state.zeroWindowVisible}
          >
            <main className="content secondStep loginNew">
              <BarLoader
                css={override}
                size={150}
                color={'#06016c'}
                loading={this.state.validationProcess}
              />
              <div>
                <ReactFlagsSelect
                  defaultCountry={i18n.language.toLocaleUpperCase()}
                  onSelect={(value) =>
                    changeLanguage(value.toLocaleLowerCase())
                  }
                  countries={['DE', 'GB']}
                  customLabels={{ DE: 'Deutsch', GB: 'English' }}
                />

                <h1>{t('password.reset')}.</h1>
                <h2>{t('registration.startUsingAV')}.</h2>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <input
                      type="email"
                      value={this.state.email}
                      name="email"
                      onChange={this.handleChange}
                      placeholder="Email"
                      className={!this.state.emailValid && 'shakeIt'}
                      spellCheck="false"
                    />
                  </div>

                  <button type="submit" onClick={this.handleSubmit}>
                    Reset
                  </button>
                </form>
              </div>
            </main>
          </Animated>
          <Animated
            animationIn="fadeInRightBig"
            animationOut="fadeOutLeftBig"
            animationInDuration={900}
            animationOutDuration={1000}
            isVisible={this.state.firstWindowVisible}
          >
            {this.state.firstWindowVisible && (
              <main className="content contentModified contentZeroStep resetLink">
                <div>
                  <h1>Success!</h1>
                  <h2>{t('password.resetLink')}</h2>
                  <Check />
                  <h2 style={{ color: '#B4B2D3', fontSize: '1.25rem' }}>
                    {t('password.checkEmail')}
                  </h2>
                  <Link to="/login" className="backToLogin">
                    <button
                      type="submit"
                      className="backToLogin__button"
                      style={{ marginTop: '20px' }}
                    >
                      {t('password.backToLogin')}
                    </button>
                  </Link>
                </div>
              </main>
            )}
          </Animated>
          <footer className="footer">
            <h3>COPYRIGHT</h3>
            <div className="footer__copyright">
              <p>© Autoversand. {t('homepage.copyright')}</p>
              <div>
                <InstagramLogo
                  className="footer__copyright--icon"
                  onClick={() =>
                    window.open('https://instagram.com/autoversand', '_blank')
                  }
                />
                <FacebookLogo
                  className="footer__copyright--icon"
                  onClick={() =>
                    window.open('https://facebook.com/autoversand', '_blank')
                  }
                />
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tokenState: state.tokenReducer
});

const mapDispatchToProps = (dispatch) => ({
  tokenReducer: (data) => dispatch(setToken(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Password));
