import React, { useRef, useEffect } from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { GoogleApiWrapper } from 'google-maps-react';

import './placeAutocomplete.css';

const PlacesAutocomplete = ({
  placeholder,
  handle,
  id,
  name,
  style,
  _value = null,
  _setValue = null,
  setTextValue = null
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: { types: ['(cities)'] },
    debounce: 300
  });

  useEffect(() => {
    setValue(_value);
    clearSuggestions();
  }, []);

  const ref = useRef();
  useOnclickOutside(ref, () => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    clearSuggestions();

    if (setTextValue != null) setTextValue(e.target.value);
    clearSuggestions();
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(description, false);

    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => {
        getLatLng(results[0]);
        if (_setValue != null) {
          _setValue(results[0]);
          clearSuggestions();
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <li key={id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
  return (
    <div ref={ref} style={{ position: 'relative' }}>
      <input
        type="text"
        placeholder={placeholder}
        id={id}
        name={name}
        className="newInputSearch"
        autoComplete="off"
        style={style}
        value={value}
        onInput={handle}
        onChange={handleInput}
        onFocus={handleInput}
        disabled={!ready}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && <ul className="listPlaces">{renderSuggestions()}</ul>}
    </div>
  );
};

//   export default withNamespaces()(PlacesAutocomplete);

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBQKpE6JxPpRDnbINcFFdM7XNBEg-yOX9c',
  language: 'en',
  libraries: ['places']
})(PlacesAutocomplete);
