import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';

import FileUpload from '../../Upload/FileUpload/FileUpload';
import ImageUpload from '../../Upload/ImageUpload/ImageUpload';
import Button from '../../Buttons/BlueButton';
import BackButton from '../../Buttons/BackButton';
import FormInput from '../../FormInput/FormInput';
import Textarea from '../../Textarea/Textarea';
import ClipLoader from 'react-spinners/ClipLoader';

import truck1 from '../../../assets/images/truck1.png';
import truck2 from '../../../assets/images/truck2.png';
import truck3 from '../../../assets/images/truck3.png';

import auth from '../../../auth';
import config from '../../../config/api.config.js';
import axios from 'axios';

import './AddTruck.css';

const AddTruck = ({ t, onClickClose }) => {
  const [loading, setLoading] = useState(false);
  const [imageArray, setImageArray] = useState([]);
  const [fileArray, setFileArray] = useState([]);

  const [vehicleNameValid, setVehicleNameValid] = useState(true);
  const [yearValid, setYearValid] = useState(true);
  const [capacityValid, setCapacityValid] = useState(true);
  const [descriptionValid, setDescriptionValid] = useState(true);
  const [imageValid, setImageValid] = useState(true);
  const [fileValid, setFileValid] = useState(true);

  const [values, setValues] = useState({
    vehicleName: '',
    year: '',
    capacity: '',
    shortDescription: ''
  });

  let years = new Array();

  const prepareUpload = async (data, url) => {
    let formData = new FormData();
    formData.append('file', data);
    try {
      const response = await axios.post(url, formData);
      const { data } = response;
      return data;
    } catch (e) {
      console.log('TRY ERROR', e);
    }
  };

  const uploadImages = async () => {
    const images = await Promise.all(
      imageArray.map((item) =>
        prepareUpload(
          item,
          `${config.apiUrl}/shipments/transport-vehicle/photo/create/`
        )
      )
    );

    const paths = images.map((image) => image.file);

    const formattedUrls = paths.map((item) => {
      return item.split('/media/transport_vehicles_photos/')[1];
    });

    return formattedUrls;
  };

  const uploadDocuments = async () => {
    const documents = await Promise.all(
      fileArray.map((item) =>
        prepareUpload(
          item,
          `${config.apiUrl}/shipments/transport-vehicle/document/create/`
        )
      )
    );

    const paths = documents.map((document) => document.file);

    const formattedUrls = paths.map((item) => {
      return item.split('/media/transport_vehicles_documents/')[1];
    });

    return formattedUrls;
  };

  const handleClick = () => {
    onClickClose(false);
  };

  const handleOpen = async () => {
    for (let i = 2020; i > 1980; i--) {
      years.push(i);
    }

    let isValid = true;

    if (values.vehicleName == '') {
      setVehicleNameValid(false);
      isValid = false;
    }
    if (values.year == '' || years.includes(parseInt(values.year)) == false) {
      setYearValid(false);
      isValid = false;
    }
    if (values.capacity == '' || isNaN(values.capacity)) {
      setCapacityValid(false);
      isValid = false;
    }
    if (values.shortDescription == '') {
      setDescriptionValid(false);
      isValid = false;
    }
    if (imageArray.length == 0) {
      setImageValid(false);
      isValid = false;
    }
    if (fileArray.length == 0) {
      setFileValid(false);
      isValid = false;
    }

    if (isValid) {
      setLoading(true);
      const imageUrls = await uploadImages();
      const documentUrls = await uploadDocuments();

      const createResponse = await axios.post(
        `${config.apiUrl}/shipments/transport-vehicle/create/`,
        {
          name: values.vehicleName,
          capacity: values.capacity,
          carrier: auth.getId().toString(),
          year: values.year,
          description: values.shortDescription,
          photos: imageUrls,
          documents: documentUrls
        }
      );

      await onClickClose(false);
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    setTimeout(() => {
      setVehicleNameValid(true);
      setYearValid(true);
      setCapacityValid(true);
      setDescriptionValid(true);
      setImageValid(true);
      setFileValid(true);
    }, 500);
  };

  const handleChange = (e) => {
    const value = e.target.value;

    setValues({
      ...values,
      [e.target.name]: value
    });
  };

  return (
    <div className="addTruck">
      <div className="truckDetails">
        <h2 className="truckDetails--heading">{t('fuhrpark.addTruck')}</h2>
        <p className="truckDetails--paragraph">{t('fuhrpark.policy')}</p>
        <div className="truckDetails__form">
          <div className={imageValid && 'shakeIt'}>
            <ImageUpload
              className="form__ImageUpload"
              setImageArray={setImageArray}
            >
              <img src={truck1} className="imageUpload__firstImage" />
              <div className="imageUpload__images">
                <img src={truck2} />
                <img src={truck3} />
              </div>
            </ImageUpload>
          </div>
          <div className={vehicleNameValid && 'shakeIt'}>
            <FormInput
              type="text"
              name="vehicleName"
              labelText={t('fuhrpark.vehicleName')}
              required
              onChange={handleChange}
            />
          </div>
          <div className="truckDetails__form--exception">
            <div className={capacityValid && 'shakeIt'}>
              <FormInput
                type="text"
                name="capacity"
                labelText={t('fuhrpark.capacity')}
                required
                onChange={handleChange}
              />
            </div>
            <div className={yearValid && 'shakeIt'}>
              <FormInput
                type="text"
                name="year"
                labelText={t('fuhrpark.year')}
                required
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={descriptionValid && 'shakeIt'}>
            <Textarea
              name="shortDescription"
              labelText={t('fuhrpark.shortDesc')}
              required
              onChange={handleChange}
            />
          </div>
          <div className={fileValid && 'shakeIt'}>
            <FileUpload
              className="form__DocumentUpload"
              setFileArray={setFileArray}
            >
              <p className="uploadPlaceholder">
                {t('settings.dropDocuments')}{' '}
              </p>
            </FileUpload>
          </div>
          {loading ? (
            <Button>
              <ClipLoader size={15} color={'#fff'} loading={loading} />
            </Button>
          ) : (
            <Button handleOpen={handleOpen}>
              {t('fuhrpark.addTruckButton')}
            </Button>
          )}
        </div>
        <BackButton handleClick={handleClick} />
      </div>
    </div>
  );
};

export default withNamespaces()(AddTruck);
