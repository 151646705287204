import React from 'react';
import { GoChevronRight } from 'react-icons/go';
import { withNamespaces } from 'react-i18next';

import './BackButton.css';
const BackFilterButton = ({ t, handleClick, ...otherProps }) => {
  return (
    <button onClick={handleClick} className="customBackButton">
      <span>{t('button.back')}</span> <GoChevronRight />
    </button>
  );
};

export default withNamespaces()(BackFilterButton);
