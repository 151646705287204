import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Animated } from 'react-animated-css';
import validator from 'validator';
import { css } from '@emotion/core';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import ReactFlagsSelect from 'react-flags-select';
import { withNamespaces } from 'react-i18next';
import BarLoader from 'react-spinners/BarLoader';
import { connect } from 'react-redux';

import { setToken } from '../../../redux/actions';
import auth from '../../../auth';
import config from '../../../config/api.config.js';

import { ReactComponent as Logo } from '../../../assets/images/logo.svg';
import { ReactComponent as InstagramLogo } from '../../../assets/images/instagram_logo.svg';
import { ReactComponent as FacebookLogo } from '../../../assets/images/facebook_logo.svg';
import { ReactComponent as Check } from '../../../assets/images/check.svg';
import { ReactComponent as CarIllustration } from '../../../assets/images/carBg.svg';

import 'react-toastify/dist/ReactToastify.css';
import 'react-flags-select/css/react-flags-select.css';

const override = css`
  position: absolute;
  top: 0px;
  width: 40%;
`;

class NewPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zeroWindowVisible: true,
      password: '',
      repeatPassword: '',
      passwordValid: true,
      repeatPasswordValid: true,
      validationProcess: false,
      token: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.notifyUser = this.notifyUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.setState({ token: values.token });
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log(this.state.token);

    let isValid = true;

    if ((this.state.password ?? '').length < 8) {
      this.setState({ passwordValid: false });
      isValid = false;
      this.notifyUser('password');
    }

    if (
      this.state.password != this.state.repeatPassword ||
      this.state.repeatPassword === ''
    ) {
      this.setState({ repeatPasswordValid: false });
      isValid = false;
      this.notifyUser('repeatPassword');
    }

    let statusOfLogin = true;

    if (!isValid) {
      return setTimeout(() => {
        this.setState({
          passwordValid: true,
          repeatPasswordValid: true
        });
      }, 500);
    }

    await this.setState({ validationProcess: true });
    //statusOfLogin=false;

    await axios
      .post(`${config.apiUrl}/password_reset/confirm/`, {
        token: this.state.token,
        password: this.state.password
      })
      .then((res) => {
        console.log(res);
        this.setState({
          //   zeroWindowVisible: false,
          password: '',
          repeatPassword: ''
        });
        this.props.history.push('/login');
      })
      .catch((err) => {
        console.log(err);
        this.notifyUser('passwordReset');
        this.setState({ passwordValid: false, repeatPasswordValid: false });
        setTimeout(() => {
          this.setState({
            passwordValid: true,
            repeatPasswordValid: true
          });
        }, 500);
      });

    //   setTimeout(() => {
    //     this.setState({
    //       zeroWindowVisible: false,
    //       password: "",
    //       repeatPassword: "",
    //     });
    //   }, 500);

    await this.setState({ validationProcess: false });
    //   if (!statusOfLogin) {
    //     this.notifyUser("passwordReset");
    //     this.setState({ passwordValid: false, repeatPasswordValid: false });
    //     setTimeout(() => {
    //       this.setState({
    //         passwordValid: true,
    //         repeatPasswordValid: true
    //       });
    //     }, 500);
    //   }
  };

  notifyUser = (notificationFor) => {
    const Msg_PasswordReset = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ fontSize: '18px', marginRight: '5px' }}></span>&nbsp;
          Password is incorrect.
        </p>
      </div>
    );
    const Msg_ShortPassword = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          Password needs to be at least 8 characters long.
        </p>
      </div>
    );
    const Msg_DifferentPassword = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          The two passwords do not match!
        </p>
      </div>
    );

    if (notificationFor === 'passwordReset')
      toast.error(<Msg_PasswordReset />, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
    if (notificationFor === 'password')
      toast.error(<Msg_ShortPassword />, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
    if (notificationFor === 'repeatPassword')
      toast.error(<Msg_DifferentPassword />, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
  };

  render() {
    const { t, i18n } = this.props;

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    if (i18n.language != 'de' && i18n.language != 'gb') {
      i18n.language = 'de';
    }

    return (
      <div
        className={
          this.state.accountType != '' ? 'registration newBg' : 'registration'
        }
      >
        <Helmet>
          <title>Autoversand | Password Reset</title>
        </Helmet>
        <div className="container">
          <ToastContainer
            position={toast.POSITION.TOP_RIGHT}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            enableMultiContainer
            containerId={'A'}
          />

          <Link to="/">
            <Logo className="logo" />
          </Link>

          <Animated
            animationIn="fadeInRightBig"
            animationOut="fadeOutLeftBig"
            animationInDuration={900}
            animationOutDuration={1000}
            isVisible={this.state.zeroWindowVisible}
          >
            <main className="content secondStep loginNew">
              <BarLoader
                css={override}
                size={150}
                color={'#06016c'}
                loading={this.state.validationProcess}
              />
              <div>
                <ReactFlagsSelect
                  defaultCountry={i18n.language.toLocaleUpperCase()}
                  onSelect={(value) =>
                    changeLanguage(value.toLocaleLowerCase())
                  }
                  countries={['DE', 'GB']}
                  customLabels={{ DE: 'Deutsch', GB: 'English' }}
                />

                <h1>New Password.</h1>
                <h2>Start using Autoversand and save money and time.</h2>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <input
                      type="password"
                      value={this.state.password}
                      name="password"
                      onChange={this.handleChange}
                      placeholder="Password"
                      className={!this.state.passwordValid && 'shakeIt'}
                      spellCheck="false"
                    />
                  </div>
                  <div className="row">
                    <input
                      type="password"
                      value={this.state.repeatPassword}
                      name="repeatPassword"
                      onChange={this.handleChange}
                      placeholder="Repeat password"
                      className={!this.state.repeatPasswordValid && 'shakeIt'}
                      spellCheck="false"
                    />
                  </div>

                  <button type="submit" onClick={this.handleSubmit}>
                    Submit
                  </button>
                </form>
              </div>
            </main>
          </Animated>

          <footer className="footer">
            <h3>COPYRIGHT</h3>
            <div className="footer__copyright">
              <p>© Autoversand. {t('homepage.copyright')}</p>
              <div>
                <InstagramLogo
                  className="footer__copyright--icon"
                  onClick={() =>
                    window.open('https://instagram.com/autoversand', '_blank')
                  }
                />
                <FacebookLogo
                  className="footer__copyright--icon"
                  onClick={() =>
                    window.open('https://facebook.com/autoversand', '_blank')
                  }
                />
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tokenState: state.tokenReducer
});

const mapDispatchToProps = (dispatch) => ({
  tokenReducer: (data) => dispatch(setToken(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(NewPassword));
