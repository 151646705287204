import React from "react";
import Card from "../../components/Card/Card";

import "./FuhrparkCard.css";

const FuhrparkCard = ({ data, onClickOpenPreview, setData, noDetails }) => {
  const handleClick = () => {
    setData(data);
    onClickOpenPreview(true);
  };
  return (
    <Card
      backgroundImage={data.picture}
      className="fuhrparkCardStyle"
      type="fuhrpark"
    >
      <p className="fuhrparkCard__name">{data.name}</p>
      <p className="fuhrparkCard__year">{data.year}</p>
      {!noDetails && (
        <button className="fuhrparkCard__details" onClick={handleClick}>
          Details
        </button>
      )}
    </Card>
  );
};

export default FuhrparkCard;
