import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Notifications from '../../../components/Notification/Notification';

import './mobileNotifications.css';

const MobileNotifications = ({ ...props }) => {
  const [notifications, setNotifications] = useState();
  const [notificationItems, setNotificationItems] = useState([]);

  const getNotifications = async () =>
    setNotificationItems(
      notifications.map((item, index) => (
        <Notifications key={index} data={item} />
      ))
    );

  useEffect(() => {
    setNotifications(props.notificationsState.notifications);
  }, [props.notificationsState.notifications]);

  useEffect(() => {
    if (notifications) getNotifications();
  }, [notifications]);

  return <div className="mobileNotifications">{notificationItems}</div>;
};

const mapStateToProps = (state) => ({
  notificationsState: state.notificationsReducer
});

export default connect(mapStateToProps, null)(MobileNotifications);
