const defaultState = {
  shipments: [],
};

const shipmentsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "GET_SHIPMENTS":
      defaultState.shipments = action.data;
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default shipmentsReducer;
