import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import ContentHeader from '../../components/ContentHeader/ContentHeader';
import DashboardNavbar from '../../Layout/Dashboard/DashboardNavbar/DashboardNavbar';
import MobileHeader from '../../Layout/Mobile/Header';
import MobileNavbar from '../../Layout/Mobile/Navbar';
import Header from '../../Layout/Dashboard/DashboardHeader/DashboardHeader';
import Navbar from '../../Layout/Navbar/Navbar';
import ShipmentViewCard from '../../components/ShipmentViewContent/ShipmentViewCard/ShipmentViewCard';
import ShipmentPosition from '../../components/ShipmentViewContent/ShipmentPosition/ShipmentPosition';
import ShipmentViewUserCard from '../../components/ShipmentViewContent/ShipmentViewUserCard/ShipmentViewUserCard';
import GiveBid from '../../components/ShipmentViewContent/GiveBid/GiveBid';
import TransportSection from '../../components/ShipmentViewContent/TransportSection/TransportSection';
import Button from '../../components/Buttons/BlueButton';
import ShipperBidsShipment from '../../components/ShipperCurrentBid/ShipperBidsShipment/ShipperBidsShipment';
import { ReactComponent as Dots } from '../../assets/images/dots.svg';
import { ReactComponent as DeliveryIcon } from '../../assets/images/Delivery.svg';
import config from '../../config/api.config.js';

import auth from '../../auth';
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import PuffLoader from 'react-spinners/PuffLoader';
import { css } from '@emotion/core';

import { useBeforeFirstRender } from '../../components/BeforeFirstRender/useBeforeFirstRender';

import { ReactComponent as Line } from '../../assets/images/shipmentViewLine.svg';

import { Animated } from 'react-animated-css';

import './ShipmentView.css';

const override = css`
  margin: 0 auto;
  width: 100px;
  margin-top: 150px;
  margin-bottom: 150px;
`;

const ShipmentView = ({ t, match }) => {
  const [document, setDocument] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (document != null) {
        if (location.state !== undefined) {
          if (location.state.detail == 'scroll') {
            document.scrollIntoView({ alignToTop: true, behavior: 'smooth' });
          }
        }
      }
    }, 1000);
  }, [document, location]);

  const userId = parseInt(auth.getId());
  let history = useHistory();

  const [visible, setVisible] = useState(false);
  const [shipper, setShipper] = useState();
  const [shipperId, setShipperId] = useState(0);
  const [offerUsers, setOfferUsers] = useState([]);
  const [offerAccepted, setOfferAccepted] = useState(false);
  const [data, setData] = useState(null);
  const [sliderLoaded, setSliderLoaded] = useState(false);
  const [shipmentState, setShipmentState] = useState('');
  const [winningOffer, setWinningOffer] = useState(null);
  const [transportSection, setTransportSection] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  const getVerificationStatus = async () => {
    await axios.get(`${config.apiUrl}/users/cips/${userId}/`).then((res) => {
      setIsVerified(res.data.is_verified);
    });
  };

  const getShipments = async () => {
    setSliderLoaded(true);

    await axios
      .get(`${config.apiUrl}/shipments/${match.params.shipmentId}/`)
      .then((res) => {
        const { data } = res;

        setShipper(data.shipper);
        setShipperId(data.shipper.id);
        setOfferUsers(data.offer_user_ids);
        setShipmentState(data.state);
        setWinningOffer(data.winning_offer);

        let aditionalThings = data.items.map((i) => i.name).join(', ');

        setVehicle({
          backgroundImages: data.photos.filter(
            (photo) => photo.type === 'default'
          ),
          manufacturer: data.vehicle.manufacturer,
          weight: data.vehicle.weight,
          height: data.vehicle.height,
          aditionalThings: aditionalThings,
          model: data.vehicle.model,
          year: data.vehicle.year,
          length: data.vehicle.length,
          transportType: data.type,
          variant: data.vehicle.variant,
          state: data.vehicle.condition,
          width: data.vehicle.width,
          shipmentId: data.id,
          fin_number: data.fin_number
        });
        setData({
          ...data,
          shipmentPosition: {
            from: {
              city: data.pickup.city,
              country: data.pickup.country,
              pickupDate:
                moment(data.startdate_earliest).date() +
                '-' +
                moment(data.startdate_latest).format('DD.MM.YYYY'),
              fromPosition: {
                lat: data.pickup.latitude,
                long: data.pickup.longitude
              }
            },
            to: {
              city: data.destination.city,
              country: data.destination.country,
              deliveryDate:
                moment(data.duedate_earliest).date() +
                '-' +
                moment(data.duedate_latest).format('DD.MM.YYYY'),
              toPosition: {
                lat: data.destination.latitude,
                long: data.destination.longitude
              }
            },
            state: data.state
          },
          userCard: {
            userName:
              data.shipper.company ??
              `${data.shipper.first_name} ${data.shipper.last_name.slice(
                0,
                1
              )}.`,
            userId: data.shipper.id,
            photo: `${config.assetUrl}${data.shipper.photo}`,
            percentage: data.shipper.ratings.length ? data.shipper.ratings : 0,
            description: data.description,
            verified: data.shipper.email_verified,
            ratings: data.shipper.ratings
          }
        });

        setTransportSection({
          delivery_notes: data.delivery_notes,
          items: data.items,
          notes: data.notes,
          photos: data.photos
        });
        setSliderLoaded(false);
      })
      .catch((err) => {
        console.log(err);
        setSliderLoaded(false);
      });
  };

  useEffect(() => {
    getShipments();
    getVerificationStatus();
  }, []);

  const notifyUser = (notificationFor) => {
    const Msg_OfferPlaced = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          {t('toast.offerPlaced')}
        </p>
      </div>
    );

    const Msg_OfferStatus = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          {t('toast.cantPlacedOffer')}
        </p>
      </div>
    );

    const Msg_IsVerified = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          {t('toast.isVerified')}
        </p>
      </div>
    );

    if (notificationFor == 'offerPlaced') {
      toast.error(<Msg_OfferPlaced />, {
        position: 'top-right',
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
    }

    if (notificationFor == 'offerStatus') {
      toast.error(<Msg_OfferStatus />, {
        position: 'top-right',
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
    }

    if (notificationFor == 'isVerified') {
      toast.error(<Msg_IsVerified />, {
        position: 'top-right',
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
    }
  };
  const onClickOpen = () => {
    if (isVerified === false) return notifyUser('isVerified');

    if (data?.shipmentPosition.state !== 'pending')
      return notifyUser('offerStatus');

    if (offerAccepted) return notifyUser('offerPlaced');

    if (offerUsers.length && offerUsers.includes(parseInt(userId)))
      notifyUser('offerPlaced');
    else setVisible(true);
  };

  const onClickClose = () => {
    setVisible(false);
  };

  const [vehicle, setVehicle] = useState({
    backgroundImages: [],
    manufacturer: '',
    weight: 0,
    height: 0,
    aditionalThings: '',
    model: '',
    year: 0,
    length: '',
    transportType: '',
    variant: '',
    state: '',
    width: 0,
    shipmentId: null
  });

  const [offer, setOffer] = useState({
    shipmentId: parseInt(match.params.shipmentId)
  });

  const filterItemsByType = (transportType) => {
    const isPickup = transportType == 'pickup';

    return {
      photos: transportSection.photos.filter(
        ({ type }) => type == transportType
      ),
      notes: transportSection[isPickup ? 'notes' : 'delivery_notes'],
      type: isPickup ? 'Pickup' : 'Delivery',
      items: transportSection.items.map((item) => ({
        id: item.id,
        name: item.name,
        checked: (isPickup && item.taken) || (!isPickup && item.delivered)
      }))
    };
  };

  return (
    <div className="shipmentView">
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        enableMultiContainer
        containerId={'A'}
      />
      {auth.isAuthenticated() ? <Header /> : <Navbar />}
      <div className="shipmentView__main">
        {auth.isAuthenticated() ? <DashboardNavbar /> : null}
        <div
          className={
            auth.isAuthenticated()
              ? 'shipmentView__content'
              : 'shipmentView__content order__contentPublic'
          }
        >
          <ContentHeader>{t('shipmentView.transportInfo')}</ContentHeader>
          <p className="shipmentView__paragraph">
            {t('shipmentView.checkInfo')}
          </p>

          {transportSection &&
          ['live', 'completed'].includes(shipmentState) &&
          [shipperId, winningOffer?.carrier ?? null].includes(userId) ? (
            <div
              style={{
                marginBottom: '5.813rem',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <TransportSection data={filterItemsByType('pickup')} />
              <Dots
                style={{
                  height: '3.625rem',
                  width: 'auto',
                  marginBottom: '2rem'
                }}
              />

              {shipmentState === 'completed' ? (
                <TransportSection data={filterItemsByType('delivery')} />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end'
                  }}
                >
                  <div className="deliverySection">
                    {t('shipmentView.waitingDelivery')}
                  </div>
                  <p
                    style={{
                      fontFamily: 'SF Pro DisplayMedium',
                      fontSize: '1.125rem',
                      lineHeight: '1.33',
                      color: '#06016c',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {t('shipmentView.delivery')}{' '}
                    <DeliveryIcon
                      style={{ width: '1.125rem', marginLeft: '0.375rem' }}
                    />
                  </p>
                </div>
              )}
            </div>
          ) : null}

          <ShipmentViewCard
            data={vehicle}
            shipperId={shipperId}
            winningOffer={winningOffer}
          />
          {sliderLoaded ? (
            <PuffLoader
              css={override}
              size={60}
              margin={2}
              color={'rgba(116, 38, 251, 0.4)'}
              loading={sliderLoaded}
            />
          ) : data ? (
            <div>
              <ShipmentPosition data={data.shipmentPosition} />
              <ShipmentViewUserCard data={data.userCard} />
            </div>
          ) : null}

          {auth.isCarrier() ? (
            <div className="shipmentView__content--bottom">
              <Line style={{ width: '100%' }} />
              <p className="shipmentView__paragraph">
                {t('shipmentView.readInfo')}
              </p>
              <Button handleOpen={onClickOpen}>
                {t('shipmentView.placeOffer')}
              </Button>
            </div>
          ) : shipperId == userId ? (
            <ShipperBidsShipment
              data={data}
              shipmentId={match.params.shipmentId}
              callback={setDocument}
            />
          ) : null}
        </div>
      </div>

      <Animated
        className="givBidAnimated"
        animationIn="fadeInRight"
        animationOut="fadeOutRight"
        animateOnMount={false}
        animationInDelay={500}
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={visible}
      >
        <GiveBid
          onClickClose={onClickClose}
          data={offer}
          setOfferAccepted={setOfferAccepted}
        />
      </Animated>
    </div>
  );
};

export default withNamespaces()(ShipmentView);
