import React from 'react';
import { withNamespaces } from 'react-i18next';

import { ReactComponent as HappyIcon } from '../../assets/images/happy.svg';
import { ReactComponent as SadIcon } from '../../assets/images/sad.svg';
import { ReactComponent as SmileIcon } from '../../assets/images/smile.svg';
import './CompanyReviewsCard.css';

const CompanyReviewsCard = ({ t, data }) => {
  return (
    <div className="companyReviewsCard">
      <div
        className="companyReviewsCard__header"
        style={{
          backgroundColor:
            data.statusOfReview == 'positive'
              ? '#37E677'
              : data.statusOfReview == 'neutral'
              ? '#E6BD37'
              : '#E63737'
        }}
      >
        <p>
          {data.statusOfReview == 'positive' ? (
            <HappyIcon className="companyReviewsCard__happyIcon" />
          ) : data.statusOfReview == 'neutral' ? (
            <SmileIcon className="companyReviewsCard__happyIcon" />
          ) : (
            <SadIcon className="companyReviewsCard__happyIcon" />
          )}
          {data.statusOfReview}
        </p>
        <p>
          <span>{data.reviewer}</span>
        </p>
      </div>
      <div className="companyReviewsCard__content">
        <div
          className="companyReviewsCard__contentLeft"
          style={{ backgroundImage: data.picture }}
        ></div>
        <div className="companyReviewsCard__contentRight">
          <div className="companyReviewsCard__contentRight--paragraph">
            {data.content}
          </div>
          <div className="companyReviewsCard__contentRight--info">
            <p className="contentRight__info--left">
              <span>{data.car}</span> {data.carType}
            </p>
            <div className="contentRight__info--right">
              <p className="infoRight__transportDate">
                <span>{t('payments.date')}</span>
                {data.transportDate}{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(CompanyReviewsCard);
