import tokenReducer from './tokenReducer';
import shipmentsReducer from './shipmentsReducer';
import notificationsReducer from './notificationsReducer';
import messangerReducer from './messangerReducer';

import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  tokenReducer: tokenReducer,
  shipmentsReducer: shipmentsReducer,
  notificationsReducer: notificationsReducer,
  messangerReducer: messangerReducer
});

export default rootReducer;
