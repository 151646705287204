import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modals/SignIn';
import ReactFlagsSelect from 'react-flags-select';
import { withNamespaces } from 'react-i18next';
import BurgerNavbar from './BurgerNavbar';

import auth from '../../auth';

import logo from '../../assets/images/logo.png';
import whatsappIcon from '../../assets/images/whatsapp_icon.png';
import viberIcon from '../../assets/images/viber_icon.png';
import { lngSetup, changeLanguage } from './LanguageSettings';

import 'react-flags-select/css/react-flags-select.css';

const Navbar = (props) => {
  const { t, i18n } = props;

  lngSetup(i18n);
  const [state, setState] = useState(true);
  const urlSearchParams = new URLSearchParams(window.location.search);

  function getSearchParamForKey(key) {
    return urlSearchParams.get(key);
  }

  const scrollToRef = (ref) => {
    if (ref) return window.scrollTo(0, ref.offsetTop);
  };

  useEffect(() => {
    return () => {
      let _section = getSearchParamForKey('section');
      if (_section) {
        setTimeout(() => {
          scrollToRef(document.getElementById(`${_section}`));
        }, 500);
      }
    };
  }, []);

  useEffect(() => {
    let _section = getSearchParamForKey('section');
    if (_section) {
      setTimeout(() => {
        scrollToRef(document.getElementById(`${_section}`));
      }, 500);
    }
  }, [state]);

  return (
    <div
      style={{
        paddingBottom: '25.3px',
        backgroundColor: 'rgb(6, 1, 108)'
      }}
    >
      <nav style={{ marginBottom: '0px' }}>
        <Link to="/">
          <div className="nav__logo">
            <img src={logo} />
          </div>
        </Link>
        <BurgerNavbar />
        <div className="nav__menu">
          <div className="nav__menu--links">
            <Link
              to={'/about-us'}
              onClick={() => {
                setState(!state);
              }}
            >
              {t('navbar.wer')}
            </Link>
            <Link
              to="/about-us?section=serviceSection"
              onClick={() => {
                setState(!state);
              }}
            >
              {t('navbar.unser')}
            </Link>
            <Link
              to="/about-us?section=spediteureSection"
              onClick={() => {
                setState(!state);
              }}
            >
              {t('navbar.service')}
            </Link>
            <a
              href={'https://twodotsdigital.zohorecruit.eu/jobs/Careers'}
              target="_blank"
              nofollow
            >
              {t('navbar.careers')}
            </a>
          </div>
          <div className="nav__menu--social">
            <a
              href="https://api.whatsapp.com/send?phone=436765539656"
              target="_blank"
            >
              <img src={whatsappIcon} className="whatsappIcon" />
            </a>
            <a href="viber://chat?number=+436765539656" target="_blank">
              <img src={viberIcon} className="viberIcon" />
            </a>
          </div>
          <div className="nav__menu--buttons">
            {!auth.isAuthenticated() && <Modal />}
            {!auth.isAuthenticated() && (
              <Link to="/register">
                <button className="nav__menu--buttons--filled">
                  {t('navbar.signup')}
                </button>
              </Link>
            )}
            {auth.isAuthenticated() && auth.isCarrier() && (
              <Link to="/dashboard">
                <button className="nav__menu--buttons--filled">
                  {t('navbar.dashboard')}
                </button>
              </Link>
            )}
            {auth.isAuthenticated() && !auth.isCarrier() && (
              <Link to="/shipments">
                <button className="nav__menu--buttons--filled">
                  {t('navbar.dashboard')}
                </button>
              </Link>
            )}
          </div>
          <ReactFlagsSelect
            defaultCountry={i18n.language.toLocaleUpperCase()}
            onSelect={(value) =>
              changeLanguage(i18n, value.toLocaleLowerCase())
            }
            countries={['DE', 'GB']}
            customLabels={{ DE: 'Deutsch', GB: 'English' }}
          />
        </div>
        <div className="nav__menu--buttons mobileButton">
          <Modal />
        </div>
      </nav>
    </div>
  );
};

export default withNamespaces()(Navbar);
