import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
  DemoData
} from 'react-big-scheduler';
import withDragDropContext from './withDnDContext';
//include `react-big-scheduler/lib/css/style.css` for styles, link it in html or import it here
import 'react-big-scheduler/lib/css/style.css';
import moment from 'moment';

import './CalendarTimeline.css';

class Readonly extends Component {
  constructor(props) {
    super(props);

    let schedulerData = new SchedulerData(
      Date.now(),
      ViewTypes.Month,
      false,
      false,
      {
        startResizable: false,
        endResizable: false,
        movable: false,
        creatable: false
      }
    );
    let data = props.data;
    let resources = [];
    let events = [];

    if (data != null) {
      data.forEach((item, index) => {
        resources = [
          ...resources,
          {
            id: item.id,
            name: 'Resources' + item.id
          }
        ];

        events = [
          ...events,
          {
            id: index,
            start: item.offer_pickup_date,
            end: item.offer_deliver_date,
            resourceId: item.id,
            title: item.pickup_city + ' - ' + item.destination_city
          }
        ];
      });
    }
    // let resources = [
    //   {
    //     id: "r0",
    //     name: "Resource0",
    //     groupOnly: true,
    //   },
    //   {
    //     id: "r1",
    //     name: "Resource1",
    //   },
    //   {
    //     id: "r2",
    //     name: "Resource2",
    //     parentId: "r0",
    //   },
    //   {
    //     id: "r3",
    //     name: "Resource3",
    //     parentId: "r4",
    //   },
    //   {
    //     id: "r4",
    //     name: "Resource4",
    //     parentId: "r2",
    //   },
    // ];

    // let events = [
    //   {
    //     id: 1,
    //     start: "2020-06-15 09:30:00",
    //     end: "2020-06-18 23:30:00",
    //     resourceId: "r1",
    //     title: "I am finished",
    //   },
    // ];

    schedulerData.localeMoment.locale('en');
    schedulerData.setResources(resources);
    schedulerData.setEvents(events);
    this.state = {
      viewModel: schedulerData
    };
  }
  render() {
    const { viewModel } = this.state;

    return (
      <div>
        <div>
          <Scheduler
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            toggleExpandFunc={this.toggleExpandFunc}
            eventItemClick={this.eventClicked}
          />
        </div>
      </div>
    );
  }

  prevClick = (schedulerData) => {
    // console.log(schedulerData.events.length)
    if (schedulerData.events.length > 0) {
      schedulerData.prev();
      schedulerData.setEvents(this.events);
      this.setState({
        viewModel: schedulerData
      });
    }
  };

  nextClick = (schedulerData) => {
    if (schedulerData.events.length > 0) {
      schedulerData.next();
      schedulerData.setEvents(this.events);
      this.setState({
        viewModel: schedulerData
      });
    }
  };

  onViewChange = (schedulerData, view) => {
    if (schedulerData.events.length > 0) {
      schedulerData.setViewType(
        view.viewType,
        view.showAgenda,
        view.isEventPerspective
      );
      schedulerData.setEvents(this.events);
      this.setState({
        viewModel: schedulerData
      });
    }
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    if (schedulerData.events.length > 0) {
      schedulerData.setEvents(this.events);
      this.setState({
        viewModel: schedulerData
      });
    }
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData
    });
  };

  eventClicked = (schedulerData, event) => {
    // alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}, ${event.resourceId}}`);
    this.props.history.push(`/shipment/${event.resourceId}`);
  };
}

export default withRouter(withDragDropContext(Readonly));
