import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Stripe from 'stripe';
import { loadStripe } from '@stripe/stripe-js';
import FormInput from '../../FormInput/FormInput';
import Button from '../../Buttons/BlueButton';
import BackButton from '../../Buttons/BackButton';
import { withNamespaces } from 'react-i18next';
import config from '../../../config/api.config.js';

import './Payment.css';

const Payment = ({ t, onClickClose, onClickOpen, selectedOffer }) => {
  const handleOpen = () => {
    onClickClose(false);
  };

  const [values, setValues] = useState({
    bidPrice: 0,
    description: ''
  });

  const handleChange = (e) => {
    const value = e.target.value;

    setValues({
      ...values,
      [e.target.name]: value
    });
  };

  const submitCreditCard = (e) => {
    e.preventDefault();
    window.open(
      `${config.apiUrl}/payments/stripe/?offer=${selectedOffer}`,
      '_self'
    );
  };

  const submitKlarna = (e) => {
    e.preventDefault();
    window.open(
      `${config.apiUrl}/payments/klarna/?offer=${selectedOffer}`,
      '_self'
    );
  };

  return (
    <div className="makePayment">
      <div className="makePayment__content">
        <h2 className="makePayment__content--heading">
          {t('payments.payment')}
        </h2>
        <p className="makePayment__content--paragraph">
          {t('payments.chooseMethod')}
        </p>
        <div className="makePayment__chooseCard">
          <button
            className="makePayment__klarna"
            onClick={submitKlarna}
            style={{ cursor: 'pointer' }}
          ></button>
          <div className="makePayment__sep">
            <div />
            <p>{t('modal.or')}</p>
            <div />
          </div>
          <button
            className="makePayment__creditCard"
            onClick={submitCreditCard}
            style={{ cursor: 'pointer' }}
          ></button>
          {/* <Button handleOpen={onClickOpen}>NEXT</Button> */}
        </div>
      </div>
      <BackButton handleClick={onClickClose} />
    </div>
  );
};

export default withNamespaces()(Payment);
