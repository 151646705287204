import React from 'react';
import Moment from 'react-moment';
import MessengerHeader from '../../../Layout/Mobile/MessengerHeader';

import { withHooksHOC } from '../../../components/WindowSize/WithHooksHOC';

import './chatview.css';
import { RiTreasureMapLine } from 'react-icons/ri';

interface IHooksHOCProps {
  width: number;
}

class ChatViewComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate = () => {
    const container = document.getElementById('chatview-container');
    if (container) container.scrollTo(0, container.scrollHeight);
  };

  render() {
    const { classes, chat, user } = this.props;
    if (chat === undefined) {
      return <main id="chatview-container" className="chatview-content"></main>;
    } else {
      return (
        <div
          className="chatView"
          style={{
            display:
              this.props.width <= 500
                ? this.props.visible
                  ? 'none'
                  : 'block'
                : 'block'
          }}
        >
          <MessengerHeader
            user={this.props.chat.usersData[0].name}
            onClick={() => {
              this.props.changeVisibility(true);
            }}
          />
          {/* <div className="chatview-header">
            Your conversation with{" "}
            {chat.users.filter((_usr) => _usr !== user)[0]}
          </div> */}
          <main id="chatview-container" className="chatview-content">
            {chat.messages.map((_msg, _index) => {
              if (_msg.message != ' ' && _msg.message != '') {
                return (
                  <div
                    key={_index}
                    className={_msg.sender === user ? 'userSent' : 'friendSent'}
                    style={{ position: 'relative' }}
                  >
                    {_msg.message}
                    {((_index != chat.messages.length - 1 &&
                      chat.messages[_index + 1].sender != _msg.sender) ||
                      _index == chat.messages.length - 1) && (
                      <span className="chatTimestamp">
                        <Moment format="MMM DD, YY HH:mm" local>
                          {_msg.timestamp}
                        </Moment>
                      </span>
                    )}
                  </div>
                );
              }
            })}
          </main>
        </div>
      );
    }
  }
}

export default withHooksHOC(ChatViewComponent);
