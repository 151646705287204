import React, { useState, useEffect } from 'react';
import 'react-dates/initialize';
import { DateRangePicker, DateRangePickerWrapper } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './DatePicker.css';

import moment from 'moment';

export const DatePicker = (
  placeholderFrom,
  placeholderTo,
  idStartDate,
  idEndDate
) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const labelFactory = (id, placeholder) => {
    const label = document.createElement('label');
    label.className = 'DateInput__label';
    label.id = id + '__label';
    label.innerText = placeholder;

    return label;
  };

  const changeDateStyle = (id, date) => {
    const dateLabel = date ? 'dateInput__label--exp' : 'DateInput__label';
    const dateInput = date ? 'borderStyle' : 'DateInput_input';

    document.getElementById(`${id}__label`).setAttribute('class', dateLabel);
    document.getElementById(id).setAttribute('class', dateInput);
  };

  useEffect(() => {
    if (document.getElementsByClassName('DateInput')[0]) {
      const [
        pickupStartInput,
        pickupEndInput,
        deliveryStartInput,
        deliveryEndInput
      ] = document.getElementsByClassName('DateInput');
      switch (idStartDate) {
        case 'pickupStartDate':
          pickupStartInput.appendChild(
            labelFactory(idStartDate, placeholderFrom)
          );
          pickupEndInput.appendChild(labelFactory(idEndDate, placeholderTo));
          break;
        case 'deliveryStartDate':
          deliveryStartInput.appendChild(
            labelFactory(idStartDate, placeholderFrom)
          );
          deliveryEndInput.appendChild(labelFactory(idEndDate, placeholderTo));
      }
      changeDateStyle(idStartDate, startDate);
      changeDateStyle(idEndDate, endDate);
    }
  });

  const DatePickerMaker = () => (
    <DateRangePicker
      startDate={startDate}
      startDateId={idStartDate}
      endDate={endDate}
      endDateId={idEndDate}
      startDatePlaceholderText=""
      endDatePlaceholderText=""
      openDirection="down"
      onDatesChange={handleDatesChange}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      orientation="vertical"
      verticalHeight={365}
    />
  );
  return [startDate, endDate, DatePickerMaker, setStartDate, setEndDate];
};
