import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import config from '../../../config/api.config.js';
import { Animated } from 'react-animated-css';

import DocumentUpload from '../../Upload/DocumentUpload/DocumentUpload';
import Button from '../../Buttons/BlueButton';
import BackButton from '../../Buttons/BackButton';

import '../AddTruck/AddTruck.css';
import './TruckPreview.css';

const TruckPreview = ({ t, onClickClosePreview, onClickOpenEdit, data }) => {
  const [state, setState] = useState({
    name: '',
    decription: '',
    capacity: null,
    year: null,
    uploadedFiles: [],
    uploadedImages: []
  });

  useEffect(() => {
    let docs = [];
    if (data) {
      data.documents.forEach((file) => {
        docs.push({
          path: file.file,
          name: file.file.split('/media/transport_vehicles_documents/')[1]
        });
      });
      setState({
        name: data.name,
        decription: data.description,
        capacity: data.capacity,
        year: data.year,
        uploadedFiles: docs,
        uploadedImages: data.pictures
      });
    }
  }, [data]);

  const documents = state.uploadedFiles.map((file) => (
    <DocumentUpload key={file.path} file={file} />
  ));
  const images = state.uploadedImages.map((image, index) => (
    <div key={index} className="truck__image">
      <img key={index} src={`${config.assetUrl}${image.file}`} />
    </div>
  ));

  const handleClick = () => {
    onClickClosePreview(false);
  };
  const handleClickEdit = () => {
    onClickClosePreview(false);
    onClickOpenEdit(true);
  };
  return (
    <div className="truckPreview">
      {
        <div className="truckDetails">
          <h2 className="truckDetails--heading">
            {state.name}
            {'  '}
            <span className="truckDetails__year">{state.year}</span>
          </h2>
          <p className="truckDetails__capacity">
            {t('fuhrpark.CAPACITY')} {state.capacity}
          </p>
          <p className="truckDetails__description">{state.decription}</p>
          <div className="truckDetails__formPreview">
            <div className="truckDetails__images">{images}</div>
            <p className="truckDetails__attachments">
              {t('fuhrpark.attachments')} (
              <span className="numOfAttachments">
                {state.uploadedFiles.length}
              </span>
              )
            </p>
            <hr className="truckDetails__line" />
            <div className="truckDetails__documents">{documents}</div>
            <Button handleOpen={handleClickEdit} style={{ display: 'none' }}>
              {t('fuhrpark.editInfo')}
            </Button>
          </div>
          <BackButton handleClick={handleClick} />
        </div>
      }
    </div>
  );
};

export default withNamespaces()(TruckPreview);
