import React, { useEffect, useState } from 'react';
import { ReactComponent as DownloadIcon } from '../../assets/images/downloadColor.svg';
import { withNamespaces } from 'react-i18next';
import auth from '../../auth';
import config from '../../config/api.config.js';
import { ToastContainer, toast } from 'react-toastify';

import axios from 'axios';

import './BillingTableRow.css';

const BillingTableRow = ({ t, data, cips, numOfRow, mobile = false }) => {
  const [address, setAddress] = useState(null);
  const [vatVerified, setVatVerified] = useState(false);

  useEffect(() => {
    if (cips) {
      setAddress(cips.address);
      setVatVerified(cips.vat_verified);
    }
  }, [cips]);

  const getInvoice = () => {
    if (!address) {
      notifyUser('invalidAddress');
      return;
    }
    if (auth.isLegalShipper() && !vatVerified) {
      notifyUser('vatNumber');
      return;
    }

    axios
      .get(`${config.apiUrl}/offers/${data.offerId}/invoice/`)
      .then((res) => {
        window.open(res.data.invoice);
      });
  };

  const notifyUser = (notificationFor) => {
    const Msg_Address = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          Please add your address.
        </p>
      </div>
    );
    const Msg_VatNumber = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          Your VAT number is unverfied.
        </p>
      </div>
    );

    switch (notificationFor) {
      case 'invalidAddress':
        toast.error(<Msg_Address />, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          containerId: 'A'
        });
        break;
      case 'vatNumber':
        toast.error(<Msg_VatNumber />, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          containerId: 'A'
        });
        break;
    }
  };

  if (mobile)
    return (
      <tr className="billingTableRow billingTableRowMobile">
        <td>{numOfRow}</td>
        <td>{data.date}</td>
        <td>{data.amount}€</td>
        <td>
          <span
            style={{
              backgroundColor:
                data.status == 'paid'
                  ? '#37e677'
                  : data.status == 'pending' || data.status == 'processing'
                  ? '#f8bd51'
                  : '#ff3939'
            }}
          >
            {data.status}
          </span>
        </td>
        {!auth.isCarrier() ? (
          <td onClick={getInvoice}>
            <DownloadIcon />
          </td>
        ) : null}
      </tr>
    );
  else
    return (
      <tr className="billingTableRow">
        <td>{numOfRow}</td>
        <td>{data.date}</td>
        <td>{data.description}</td>
        <td>{data.method}</td>
        <td>{data.amount}€</td>
        <td>
          <span
            style={{
              backgroundColor:
                data.status == 'paid'
                  ? '#37e677'
                  : data.status == 'pending' || data.status == 'processing'
                  ? '#f8bd51'
                  : '#ff3939'
            }}
          >
            {data.status}
          </span>
        </td>
        {auth.isShipper() ? (
          <td onClick={getInvoice}>
            <a>{t('billing.invoice')}</a>
          </td>
        ) : null}
      </tr>
    );
};

export default withNamespaces()(BillingTableRow);
