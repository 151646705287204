import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { withNamespaces } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';

import Login from './Login';

import './loginModal.css';

Modal.setAppElement(document.getElementById('root'));

function PopUp({ t }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (modalIsOpen)
      document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    else document.getElementsByTagName('html')[0].style.overflowY = 'scroll';
  }, [modalIsOpen]);
  return (
    <div>
      <button onClick={openModal}>{t('login.login')}</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{ top: '0px' }}
      >
        <div class="background"></div>
        <IoMdClose className="closeIcon" onClick={closeModal} />
        <Login />
      </Modal>
    </div>
  );
}
export default withNamespaces()(PopUp);
