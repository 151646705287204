import React from 'react';

import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { withNamespaces } from 'react-i18next';

const Footer = ({ t, styleFooter }) => {
  return (
    <footer className={styleFooter}>
      <div className="footer__kucice">
        <div className="footer__kucice--single">
          <h3>{t('footer.plattform')}</h3>
          <p
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open(
                'https://demo.autoversand.com/register?registerType=shipper',
                '_self'
              )
            }
          >
            {t('footer.angebote')}
          </p>
          <p
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open(
                'https://demo.autoversand.com/register?registerType=carrier',
                '_self'
              )
            }
          >
            {t('footer.als')}
          </p>
          <p
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open('https://demo.autoversand.com/login', '_self')
            }
          >
            {t('footer.mein')}
          </p>
        </div>
        <div className="footer__kucice--single">
          <h3>{t('footer.company')}</h3>
          <Link to="/datenschutz">
            <p>{t('footer.date')}</p>
          </Link>
          <Link to="/agb">
            <p>AGB</p>
          </Link>
          <Link to="/impressum">
            <p>{t('footer.impressum')}</p>
          </Link>
        </div>
        <div className="footer__kucice--single">
          <h3>{t('footer.copyright')}</h3>
          <p>© {t('footer.autoversand')}</p>
        </div>
      </div>
      <div className="footer__social">
        <IconContext.Provider value={{ className: 'InstaIcon' }}>
          <FaInstagram
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open('https://www.instagram.com/autoversand/', '_blank')
            }
          />
        </IconContext.Provider>
        <IconContext.Provider value={{ className: 'fbIcon' }}>
          <FaFacebookF
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open('https://www.facebook.com/AutoVersand/', '_blank')
            }
          />
        </IconContext.Provider>
      </div>
    </footer>
  );
};

export default withNamespaces()(Footer);
