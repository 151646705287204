import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';

import { ToastContainer, toast } from 'react-toastify';
import FormInput from '../../FormInput/FormInput';
import FileUpload from '../../Upload/FileUpload/FileUpload';
import Button from '../../Buttons/BlueButton';
import Textarea from '../../Textarea/Textarea';
import auth from '../../../auth';
import config from '../../../config/api.config.js';

import './PersonalSettings.css';

const PersonalSettings = ({ t }) => {
  const [cips, setCips] = useState(null);

  const [values, setValues] = useState({
    firstName: null,
    lastName: null,
    address: ''
  });
  const userId = auth.getId();

  const [firstNameValid, setFirstNameValid] = useState(true);
  const [lastNameValid, setLastNameValid] = useState(true);

  const handleChange = (e) => {
    const value = e.target.value;

    setValues({
      ...values,
      [e.target.name]: value
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    updateCips();
  };

  const getCips = async () => {
    await axios.get(`${config.apiUrl}/users/cips/${userId}/`).then((res) => {
      setCips(res.data);
    });
  };

  const updateCips = async () => {
    let isValid = true;
    if (values.firstName === '' || values.firstName === null) {
      isValid = false;
      setFirstNameValid(false);
    }
    if (values.lastName === '' || values.lastName === null) {
      isValid = false;
      setLastNameValid(false);
    }

    if (!isValid) {
      return setTimeout(() => {
        setFirstNameValid(true);
        setLastNameValid(true);
      }, 500);
    }

    const formData = new FormData();

    formData.append('first_name', values.firstName);
    formData.append('last_name', values.lastName);
    formData.append('address', values.address ?? '');

    await axios
      .patch(`${config.apiUrl}/users/update/${userId}/`, formData)
      .then((res) => {
        getCips();
        notifyUser('personalSettings');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCips();
  }, []);

  useEffect(() => {
    if (cips) {
      setValues({
        firstName: cips.first_name,
        lastName: cips.last_name,
        address: cips.address
      });
    }
  }, [cips]);

  const notifyUser = (notificationFor) => {
    const Msg_Settings = () => (
      <div>
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          {t('toast.success')}
        </p>
      </div>
    );

    if (notificationFor == 'personalSettings') {
      toast.success(<Msg_Settings />, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        containerId: 'A'
      });
    }
  };

  return (
    <div className="personalSettings">
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        enableMultiContainer
        containerId={'A'}
      />
      <form>
        <div className="personalSettings__leftSide">
          <FormInput
            type="text"
            name="firstName"
            labelText={t('settings.name')}
            settings={true}
            value={values.firstName}
            required
            onChange={handleChange}
          />
          <FormInput
            type="text"
            name="lastName"
            labelText={t('settings.surname')}
            settings={true}
            value={values.lastName}
            required
            onChange={handleChange}
          />
          <FormInput
            type="text"
            name="address"
            labelText={t('settings.address')}
            settings={true}
            value={values.address}
            required
            onChange={handleChange}
          />
          <Button
            handleOpen={handleSubmit}
            type="submit"
            style={{ margin: '0px' }}
          >
            {t('settings.save')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default withNamespaces()(PersonalSettings);
