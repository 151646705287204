import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { withNamespaces } from 'react-i18next';

import { ReactComponent as WhatsappIcon } from '../../../assets/images/whatsapp.svg';
import { ReactComponent as ViberIcon } from '../../../assets/images/viber.svg';
import auth from '../../../auth';
import config from '../../../config/api.config.js';
import './accountMobile.css';

const AccountMobile = (props) => {
  const { t } = props;
  const currentLocation = useLocation().pathname;
  const userID = auth.getId();
  const [account, setAccount] = useState(
    auth.getType() !== 'natural_shipper' ? 'company' : 'personal'
  );
  const [state, setState] = useState({
    userImage: null,
    userName: null
  });
  const [cipsData, setCipsData] = useState();
  const getCipsData = async () => {
    await axios.get(`${config.apiUrl}/users/cips/${userID}/`).then((res) => {
      console.log('USER ACCOUNT', res);
      setCipsData(res.data);
    });
  };

  useEffect(() => {
    getCipsData();

    axios.get(`${config.apiUrl}/users/cips/${auth.getId()}/`).then((res) => {
      console.log('vraceni podaci', res);
      if (res.data.hasOwnProperty('first_name')) {
        console.log({
          userName: res.data.first_name + ' ' + res.data.last_name,
          userImage: `url(${config.assetUrl}${res.data.photo})`
        });
        setState({
          userName: res.data.first_name + ' ' + res.data.last_name,
          userImage: `url(${config.assetUrl}${res.data.photo})`
        });
      } else if (res.data.hasOwnProperty('name')) {
        setState({
          userName: res.data.name,
          userImage: `url(${config.assetUrl}${res.data.photo})`
        });
      }
    });
  }, []);

  return (
    <div className="accountMobile">
      <div className="accountMobileProfile">
        <div className="accountMobileProfile__top"></div>
        <div className="accountMobileProfile__bottom">
          <div
            className="userImage userImage__mobile"
            style={{ backgroundImage: state.userImage }}
          ></div>
          <div className="userImage__mobileName">
            <p>{state.userName}</p>
            <Link
              to={
                account == 'personal'
                  ? `/shipper-profile/${auth.getId()}`
                  : `/company-profile/${auth.getId()}`
              }
            >
              {t('accountMobile.viewProfile')}
            </Link>
          </div>
        </div>
      </div>
      <div className="accountMobile__navbar">
        <Link
          className={currentLocation === '/main-settings' && 'activeSetting'}
          to={{
            pathname: '/main-settings'
            // state: cipsData,
          }}
        >
          {t('settings.account')} <MdKeyboardArrowRight />
        </Link>
        {account == 'personal' ? (
          <Link
            className={
              currentLocation === '/personal-settings' && 'activeSetting'
            }
            to={{
              pathname: '/personal-settings',
              state: cipsData
            }}
          >
            {t('settings.personal')} <MdKeyboardArrowRight />
          </Link>
        ) : (
          <Link
            className={
              currentLocation === '/company-settings' && 'activeSetting'
            }
            to="/company-settings"
          >
            {t('settings.company')} <MdKeyboardArrowRight />
          </Link>
        )}
        <Link
          className={currentLocation === '/change-password' && 'activeSetting'}
          to="/change-password"
        >
          {t('settings.changePassword')} <MdKeyboardArrowRight />
        </Link>
        <a
          onClick={() => {
            auth.logout(() => {
              props.history.push('/');
            });
          }}
        >
          {t('navbar.logout')} <MdKeyboardArrowRight />
        </a>
      </div>

      <div className="shipmentViewUserCard__right shipmentViewUserCard__mobile">
        <p>{t('account.needHelp')}</p>
        <div className="shipmentViewUserCard__rightButtons">
          <a
            href="https://api.whatsapp.com/send?phone=436765539656"
            target="_blank"
          >
            <button>
              <WhatsappIcon className="shipment__whatsappIcon" /> WhatsApp
            </button>
          </a>
          <a href="viber://chat?number=+436765539656" target="_blank">
            <button>
              {' '}
              <ViberIcon className="shipment__viberIcon" /> Viber
            </button>
          </a>
        </div>
      </div>

      <div className="accontMobile__links">
        <Link to="/agb">AGB</Link>
        <Link to="/datenschutz">Datenschutz</Link>
        <Link to="/impressum">Impressum</Link>
      </div>
    </div>
  );
};

export default withNamespaces()(AccountMobile);
